import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { connect, useDispatch } from "react-redux";
import {
  ButtonGroup,
  Typography,
  TextField,
  makeStyles,
  Grid,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";

import Feedback from "../../../../Feedback";
import {
  getAnnualBudgetById,
  approveBudget,
  getAllAnnualBudgets,
  reviewAllAnnualBudget,
  getAllAnnualBudgetsReviews,
} from "../../../../actions/annualAction";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AllBudgetReviewModal = ({
  openReview,
  handleCloseReview,
  selectBudgets,
  getAllAnnualBudgets,
  reviewAllAnnualBudget,
  selectedDate,
  getAllAnnualBudgetsReviews,
  setSelectBudgets,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [comment, setComment] = useState("");
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleChange = (e) => {
    e.persist();
    setComment(e.target.value);
  };

  const handleReview = () => {
    setLoading((prev) => !prev);

    let data = {};
    data.budgetIds = selectBudgets;
    data.financeComment = comment;
    data.year = moment(selectedDate).format("YYYY");
    data.path = "budget/all/review";

    // console.log(data);
    reviewAllAnnualBudget(data)
      .then((doc) => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message: doc.message,
          success: true,
        });
        setComment("");
        setSelectBudgets([]);

        dispatch(getAllAnnualBudgetsReviews);
      })
      .catch(() => {
        setFeed({
          loading: false,
          open: !feed.open,
          message: `An Unexpected Error has occurred, kindly contact Administrator.`,
          success: false,
        });
      })
      .finally(() => {
        handleCloseReview();
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openReview}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseReview}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                {" "}
                {"Accept Budget?"}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseReview}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            label="Comment"
            name="comment"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            value={comment}
            helperText={
              <Typography variant="overline">
                By Clicking 'Accept', it means you're satisfied with this budget
                collation.
              </Typography>
            }
            required
          />
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button
              onClick={handleCloseReview}
              color="secondary"
              className={classes.saveBtn}
            >
              Cancel
            </Button>
            <Button
              onClick={handleReview}
              color="primary"
              disabled={!comment || loading}
              variant="contained"
              className={classes.saveBtn}
              endIcon={
                loading ? <CircularProgress size={20} color="primary" /> : null
              }
            >
              {loading ? "Loading..." : "Accept"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapDispatchToProps = {
  approveBudget,
  getAnnualBudgetById,
  getAllAnnualBudgets,
  reviewAllAnnualBudget,
  getAllAnnualBudgetsReviews,
};

export default connect(null, mapDispatchToProps)(AllBudgetReviewModal);
