import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    Select,
    MenuItem,
    Button,
    Divider,
    Icon,
    darken,
    Backdrop,
    ButtonGroup,
    Hidden,
    FormControlLabel,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../../../Feedback";
import {
    getGatePass,
    getAllGatePasses,
} from "../../../../actions/inventoryActions";
import ReviewGatePassModal from "../../Modals/Inventory/GatePassModals/ReviewGatePassModal";
import AcceptGatePassModal from "../../Modals/Inventory/GatePassModals/AcceptGatePassModal";
import ApproveGatePassModal from "../../Modals/Inventory/GatePassModals/ApproveGatePassModal";
import CompleteGatePassModal from "../../Modals/Inventory/GatePassModals/CompleteGatePassModal";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold"
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
        marginBottom: 20,
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(1),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
            // color: "#fff",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        // fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 480,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    employee: "",
    division: "",
    purpose: "",
    projectName: "",
    movementSpecification: "",
    dateCreated: "",
    items: [{
        assetDescription: "",
        assetSerialNo: "",
        previousLocation: "",
        newLocation: "",
        quantity: "",
        condition: "",
        toBeReturned: "",
    }],
};

const ViewGatePass = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    const [openReview, setOpenReview] = useState(false);
    const [openAccept, setOpenAccept] = useState(false);
    const [openApprove, setOpenApprove] = useState(false);
    const [openComplete, setOpenComplete] = useState(false);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { profile } = useSelector((state) => state.user);
    const { error, loading, gatepass } = useSelector((state) => state.gatepass);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getAllGatePasses());
        dispatch(getGatePass(id));
    }, [dispatch, id]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: (gatepass && gatepass.employee) || "",
            division: (gatepass && gatepass.division) || "",
            purpose: (gatepass && gatepass.purpose) || "",
            dateCreated: (gatepass && moment(gatepass.dateCreated).format("l")) || "",
            projectName: (gatepass && gatepass.projectName) || "",
            movementSpecification: (gatepass && gatepass.movementSpecification) || "",
            items: (gatepass && gatepass.items) || [],
            status: (gatepass && gatepass.status) || "",
            documentNo: (gatepass && gatepass.documentNo) || "",

            submittedBy: (gatepass && gatepass.submittedBy && gatepass.submittedBy.userName) || "",
            submittedAt: (gatepass && gatepass.submittedAt) || "",

            reviewedBy: (gatepass && gatepass.reviewedBy && gatepass.reviewedBy.userName) || "",
            reviewedAt: (gatepass && gatepass.reviewedAt) || "",
            reviewedComment: (gatepass && gatepass.reviewedComment) || "",

            acceptedBy: (gatepass && gatepass.acceptedBy && gatepass.acceptedBy.userName) || "",
            acceptedAt: (gatepass && gatepass.acceptedAt) || "",
            acceptedComment: (gatepass && gatepass.acceptedComment) || "",

            approvedBy: (gatepass && gatepass.approvedBy && gatepass.approvedBy.userName) || "",
            approvedAt: (gatepass && gatepass.approvedAt) || "",
            approvedComment: (gatepass && gatepass.approvedComment) || "",

            rejectedBy: (gatepass && gatepass.rejectedBy && gatepass.rejectedBy.userName) || "",
            rejectedAt: (gatepass && gatepass.rejectedAt) || "",
            rejectedComment: (gatepass && gatepass.rejectedComment) || "",

            grantedBy: (gatepass && gatepass.grantedBy && gatepass.grantedBy.userName) || "",
            grantedAt: (gatepass && gatepass.grantedAt) || "",
            grantedComment: (gatepass && gatepass.grantedComment) || "",

            completedBy: (gatepass && gatepass.completedBy && gatepass.completedBy.userName) || "",
            completedAt: (gatepass && gatepass.completedAt) || "",
            completedComment: (gatepass && gatepass.completedComment) || "",

        }));
    }, [gatepass]);

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };
    // line manager
    const handleOpenReview = () => {
        setOpenReview(true);
    };
    const handleCloseReview = () => {
        setOpenReview(false);
    };
    // inventory officer
    const handleOpenAccept = () => {
        setOpenAccept(true);
    };
    const handleCloseAccept = () => {
        setOpenAccept(false);
    };
    // finance director
    const handleOpenApprove = () => {
        setOpenApprove(true);
    };
    const handleCloseApprove = () => {
        setOpenApprove(false);
    };
    // inventory officer
    const handleOpenComplete = () => {
        setOpenComplete(true);
    };
    const handleCloseComplete = () => {
        setOpenComplete(false);
    };

    const isAdmin = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "admin"
        )
        : false;

    const isDivHead = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "reviewer" && grp.module === "inventory"
        )
        : false;

    const isFinManager = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "manager" && grp.module === "inventory"
        )
        : false;

    const isFinHead = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "head" && grp.module === "inventory"
        )
        : false;

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}

            <ReviewGatePassModal {...{ openReview, handleCloseReview, id }} />
            <AcceptGatePassModal {...{ openAccept, handleCloseAccept, id }} />
            <ApproveGatePassModal {...{ openApprove, handleCloseApprove, id }} />
            <CompleteGatePassModal {...{ openComplete, handleCloseComplete, id }} />

            <Typography variant="overline" className={classes.title}>
                View Gate Pass
            </Typography>
            <Grid container justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.backBtn}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.push("/inventory/gatepass")}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <Paper variant="outlined" className={classes.status}>
                        <Typography variant="overline" className={classes.statusTitle}>
                            Document No.
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        {loading ? "Loading..." : state.documentNo}
                    </Paper>
                </Grid>
            </Grid>

            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
                style={{ marginBottom: 20 }}
            >
                <Hidden smDown>
                    <Grid item></Grid>
                </Hidden>
                <Grid item>
                    <Paper
                        variant="outlined"
                        className={clsx(
                            state.status === "draft"
                                ? classes.statusYellow
                                : state.status === "submitted"
                                    ? classes.statusOrange
                                    : (state.status === "accepted" || state.status === "approved")
                                        ? classes.statusGreen
                                        : (state.status === "granted" || state.status === "completed")
                                            ? classes.statusDarkGreen
                                            : classes.statusRed,
                            classes.status
                        )}
                    >
                        <Typography variant="overline" className={classes.statusTitle}>
                            Status
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        <Typography variant="overline" className={classes.statusTitle}>
                            {loading ? "Loading..." : state.status}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.grid}>
                <Grid item xs={12} md={6}>
                    <label style={{ fontWeight: "bold" }}>Employee Name</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        name="employee"
                        value={state.employee || ""}

                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <label style={{ fontWeight: "bold" }}>Date Created</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        value={state.dateCreated || ""}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <label style={{ fontWeight: "bold" }}>Project Name</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        name="projectName"
                        value={state.projectName || ""}

                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <label style={{ fontWeight: "bold" }}>Purpose</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        name="purpose"
                        disabled
                        value={state.purpose || ""}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControl
                        fullWidth
                        required
                        label="Movement Specification"
                        variant="outlined"
                        component="fieldset"
                    >
                        <label style={{ fontWeight: "bold" }}>Movement Specification</label>
                        <RadioGroup row value={state.movementSpecification || ""}>
                            <FormControlLabel
                                value="Outward"
                                control={<Radio />}
                                label="Outward"
                                name="movementSpecification"
                                disabled
                            />
                            <FormControlLabel
                                value="Transit"
                                control={<Radio />}
                                label="Transit"
                                name="movementSpecification"
                                disabled
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>


            <Typography variant="overline" className={classes.title2}>
                Gate Pass Items
            </Typography>

            <Paper variant="outlined" square className={classes.paper}>
                {state.items &&
                    state.items.map((item, i) => (
                        <Grid
                            container
                            justify="center"
                            alignItems="center"
                            spacing={1}
                            className={classes.grid}
                            key={i}
                        >
                            <Grid item xs={12} md={2}>
                                <FormControl fullWidth required variant="outlined">
                                    <label style={{ fontWeight: "bold" }}>Asset Description</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        disabled
                                        type="text"
                                        value={item.assetDescription}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControl fullWidth required variant="outlined">
                                    <label style={{ fontWeight: "bold" }}>Asset Serial No</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        disabled
                                        type="text"
                                        value={item.assetSerialNo}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <label style={{ fontWeight: "bold" }}>Previous Location</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    disabled
                                    type="text"
                                    value={item.previousLocation}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <label style={{ fontWeight: "bold" }}>New Location</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    disabled
                                    type="text"
                                    value={item.newLocation}
                                />
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <label style={{ fontWeight: "bold" }}>Quantity</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    type="number"
                                    value={item.quantity || "1"}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <FormControl fullWidth required variant="outlined">
                                    <label style={{ fontWeight: "bold" }}>Condition</label>
                                    <Select
                                        labelId="condition"
                                        value={item.condition || ""}
                                        disabled
                                    >
                                        <MenuItem disabled>Select</MenuItem>
                                        <MenuItem value="Good">Good</MenuItem>
                                        <MenuItem value="Faulty">Faulty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControl fullWidth required variant="outlined">
                                    <label style={{ fontWeight: "bold" }}>To Be Returned?</label>
                                    <Select
                                        labelId="toBeReturned"
                                        value={item.toBeReturned || ""}
                                        disabled
                                    >
                                        <MenuItem disabled>Select</MenuItem>
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    ))}
            </Paper>

            <Grid
                container
                alignItems="center"
                wrap="nowrap"
                spacing={1}
                style={{ marginBottom: 5 }}
            >
                <Grid item>
                    <Typography variant="overline" className={classes.title2}>
                        Audit Trail
                    </Typography>
                </Grid>
                <Grid item>
                    <Icon className="fas fa-clipboard-list" color="disabled" />
                </Grid>
            </Grid>
            <Paper variant="outlined" square className={classes.paper}>
                <Grid container justify="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Paper
                            className={classes.auditContainer}
                            component="div"
                            elevation={1}
                            variant="outlined"
                            square
                        >
                            <span className={classes.auditTitle}>Submitted By</span>
                            <Divider orientation="vertical" flexItem />
                            <span className={classes.audit}>{state.submittedBy}</span>
                            <Divider orientation="vertical" flexItem />
                            <span className={classes.audit}>{state.submittedAt}</span>
                        </Paper>
                    </Grid>
                    {(state.reviewedBy || state.reviewedAt || state.reviewedComment) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Reviewed By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.reviewedBy}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.reviewedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.reviewedComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.acceptedBy || state.acceptedAt || state.acceptedComment) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Accepted By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.acceptedBy}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.acceptedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.acceptedComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.approvedBy || state.approvedAt || state.approvedComment) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Approved By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.approvedBy}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.approvedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.approvedComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.grantedBy || state.grantedAt || state.grantedComment) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Granted By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.grantedBy}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.grantedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.grantedComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.rejectedBy || state.rejectedAt || state.rejectedComment) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Rejected By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.rejectedBy}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.rejectedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.rejectedComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.completedBy || state.completedAt || state.completedComment) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Completed By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.completedBy}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.completedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.completedComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}

                </Grid>
            </Paper>

            {((isAdmin || isDivHead) && state.status === "submitted") ? (
                <Grid container justify="center">
                    <Grid item>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenReview}
                                className={classes.saveBtn2}
                                variant="contained"
                                disabled={state.status !== "submitted"}
                            >
                                Review
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : ((isAdmin || isFinManager) && state.status === "reviewed" ? (
                <Grid container justify="center">
                    <Grid item>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenAccept}
                                className={classes.saveBtn2}
                                variant="contained"
                                disabled={state.status !== "reviewed"}
                            >
                                Accept
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : ((isAdmin || isFinHead) && state.status === "accepted" ? (
                <Grid container justify="center">
                    <Grid item>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenApprove}
                                className={classes.saveBtn2}
                                variant="contained"
                                disabled={state.status !== "accepted"}
                            >
                                Approve
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : (isAdmin || isFinHead || isFinManager) && state.status === "granted" && (
                <Grid container justify="center">
                    <Grid item>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenComplete}
                                className={classes.saveBtn2}
                                variant="contained"
                                disabled={state.status !== "granted"}
                            >
                                Complete
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            )))}

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default ViewGatePass;
