import React from "react";
import { Text, View, StyleSheet , Image } from "@react-pdf/renderer";

import procurement from '../../../../qhse.jpeg';
import femi from '../../../../assets/femi_signature.png';
import livinus from '../../../../assets/livinus_signature.png';
import moment from "moment";

const styles = StyleSheet.create({
    section: {
        margin: 10,
        marginBottom: 5,
        paddingHorizontal: 20,
        fontSize: 13,
        textAlign: "left",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.5,
    },
    footer: {
        marginTop: 30,
        fontSize: 11,
        fontWeight: 300,
        color: "#222",
        lineHeight: 1.2,
        position:"absolute",
        bottom: 15,
        left: 20,
    },
    rightSection: {
        textAlign: "right",
    },
    text: {
        fontSize: 12,
        textAlign: "left",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.7,
        display:"block",
        width: "100%",
    },
    caps: {
        fontWeight: 800,
        fontSize: 11,
    },
    mb_10: {
        fontSize: 10,
        marginBottom: 5,
        fontWeight:"bold",
    },

    textLeft: {
        textAlign: "left",
        fontSize: 12,
        marginBottom: 0,
    },
    footerTextLeft: {
        textAlign: "left",
        fontSize: 12,
    },
    textRight: {
        textAlign: "right",
        fontSize: 9,
    },
    textBold: {
        fontWeight: "bold",
    },
    textUnderline: {
        textDecoration: "underline",
        width: "100%",
        display:"block",
    },
    shipTo: {
        textAlign: "right",
        fontSize: 10,
        marginTop: -60,
    },
    table: {
        padding: 0,
        margin: 0,
        fontSize: 10,
    },
    row: {
        alignItems: "center",
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #000',
        borderLeft: '1px solid #000',
        padding: 0,
        margin: 0,
    },
    bodyrow: {
        alignItems: "stretch",
        justifyContent: 'center', // align the children vertically
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #000',
        // borderLeft: '1px solid #000',
        padding: 0,
        margin: 0,
    },
    tablerow: {
        alignItems: "stretch",
        justifyContent: 'center', // align the children vertically
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        margin: 0,
    },
    th: {
        fontWeight: "bold",
        fontSize: 9,
        padding: 4,
        margin: 0,
        backgroundColor: "#aaa",
        borderLeft: '1px solid #000',
        color: "#000"
    },
    thtransparent: {
        fontWeight: "bold",
        fontSize: 9,
        padding: 4,
        paddingLeft: 0,
        margin: 0,
        color: "#000"
    },
    thd: {
        fontWeight: "bold",
        fontSize: 9,
        padding: 4,
        margin: 0,
        color: "#444",
        textAlign:'right',
        borderRight: '1px solid #000',
    },
    thtrans: {
        fontWeight: "bold",
        fontSize: 9,
        padding: 4,
        margin: 0,
        backgroundColor: "#fff",
        borderLeft: '1px solid #000',
    },
    foot: {
        backgroundColor: "#fff",
        alignItems: "center",
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #000',
        padding: 0,
        margin: 0,
    },
    td: {
        overflow: 'hidden',
        fontSize: 9,
        padding: 4,
        margin: 0,
        backgroundColor: "#fff",
        color: "#444",
        border: "1px solid #000",
        borderTopWidth: 0,
    },
    desc: {
        width: "20%",
    },
    desc10: {
        width: "10%",
    },
    desc25: {
        width: "25%",
    },
    desc2: {
        width: "30%",
    },
    desc3: {
        width: "80%",
    },
    desc60: {
        width: "60%",
    },
    desc20right: {
        width: "20%",
        textAlign: "right",
    },
    desc4: {
        width: "40%",
        textAlign: "right",
    },
    desc5: {
        width: "50%",
        textAlign: "right",
    },
    desc40: {
        width: "40%",
    },
    desc30: {
        width: "30%",
    },
    desc50left: {
        width: "50%",
        textAlign:"left",
    },
    desc50center: {
        width: "50%",
        textAlign:"center",
    },
    desc30left: {
        width: "50%",
        textAlign:"left",
    },
    desc70left: {
        width: "50%",
        textAlign:"left",
    },
});


const TableHead = () => {
    return (
        <>
            <View style={[styles.bodyrow]}>
                <Text style={[styles.th, {width: '100%', fontSize: 12, fontWeight: 'bold', textAlign:'center'}]}>
                    Receiving Report Table
                </Text>
            </View>
            <View style={[styles.bodyrow]}>
                <Text style={[styles.th, styles.desc10]}>SN</Text>
                <Text style={[styles.th, styles.desc40]}>Description</Text>
                <Text style={[styles.th, styles.desc10]}>Ordered Quantity</Text>
                <Text style={[styles.th, styles.desc10]}>Delivered Quantity</Text>
                <Text style={[styles.th, styles.desc10]}>Received Quantity</Text>
                <Text style={[styles.th, styles.desc10]}>Inspected Quantity</Text>
                <Text style={[styles.th, styles.desc10]}>Accepted Quantity</Text>
                <Text style={[styles.th, styles.desc10, {borderRight: '1px solid #000'}]}>Rejected Quantity</Text>
            </View>
        </>
    )
}


const TableHeadPart2 = () => {
    return (
        <>
            <View style={[ styles.bodyrow ]}>
                <Text style={[styles.th, {width: '100%', backgroundColor: '#CCEEFF', fontSize: 12, fontWeight: 'bold', borderRight:'1px solid #444'
                    , borderBottom:'1px solid #444', borderLeft:'1px solid #444', paddingLeft: 10, paddingBottom: 0 }]}>
                    Inspection and Testing: Part 2
                </Text>
            </View>
            <View style={[styles.bodyrow]}>
                <Text style={[styles.th, styles.desc50center]}>Inspection Parameters (Check if acceptable)</Text>
                <Text style={[styles.th, styles.desc50center]}>Specification Discrepancies (State number rejected)</Text>
            </View>
        </>
    )
}

export const PageOne = ({ receiving }) => {

    console.log(receiving)

    const ReceivingPartOne = () => {
        return (
            <>
                <View style={[styles.bodyrow ]}>
                    <Text style={[styles.th, {width: '100%', backgroundColor: '#CCEEFF', fontSize: 12, fontWeight: 'bold', borderRight:'1px solid #444'
                        , borderBottom:'1px solid #444', borderLeft:'1px solid #444', paddingLeft: 10, paddingBottom: 0 }]}>
                        Receiving - Part 1 (Vendor Category)
                    </Text>
                </View>
                <View style={[styles.tablerow]}>
                    <Text style={[styles.thtransparent, styles.desc30left]}>Vendor/Supplier/SubContractor:</Text>
                    <Text style={[styles.thtransparent, styles.desc70left]}>{receiving && receiving.vendor?.companyName}</Text>
                </View>

                <View style={[styles.tablerow]}>
                    <Text style={[styles.thtransparent, styles.desc30left]}>Supplier ID:</Text>
                    <Text style={[styles.thtransparent, styles.desc70left]}>{receiving && receiving?.supplierId}</Text>
                </View>

                <View style={[styles.tablerow]}>
                    <Text style={[styles.thtransparent, styles.desc30left]}>Date:</Text>
                    <Text style={[styles.thtransparent, styles.desc70left]}>{receiving && moment(receiving.date).format('l')}</Text>
                </View>

                <View style={[styles.tablerow]}>
                    <Text style={[styles.thtransparent, styles.desc30left]}>RAIF No:</Text>
                    <Text style={[styles.thtransparent, styles.desc70left]}>{receiving && receiving.documentNo}</Text>
                </View>

                <View style={[styles.tablerow]}>
                    <Text style={[styles.thtransparent, styles.desc30left]}>Purchase Order No:</Text>
                    <Text style={[styles.thtransparent, styles.desc70left]}>{receiving && receiving.purchaseOrderNo}</Text>
                </View>

                <View style={[styles.tablerow]}>
                    <Text style={[styles.thtransparent, styles.desc30left]}>Delivery Location:</Text>
                    <Text style={[styles.thtransparent, styles.desc70left]}>{receiving && receiving.deliveryLocation}</Text>
                </View>

                <View style={[styles.tablerow]}>
                    <Text style={[styles.thtransparent, styles.desc30left]}>Cost Center:</Text>
                    <Text style={[styles.thtransparent, styles.desc70left]}>{receiving && receiving?.costCenter?.name}</Text>
                </View>

                <View style={[styles.tablerow]}>
                    <Text style={[styles.thtransparent, styles.desc30left]}>Was The Product/Service Delivered On Time? (Yes/No):</Text>
                    <Text style={[styles.thtransparent, styles.desc70left]}>{receiving && receiving.deliveryTime}</Text>
                </View>
            </>
        )
    }

    return (
        <>
            <View style={{position: "relative"}}>
                <Image
                    src={procurement}
                    style={{
                        height: 130,
                        width: "100%",
                        marginTop: 20,
                        paddingRight: 20,
                        paddingLeft: 20,
                        marginBottom: 10,
                    }} 
                />
                
                <View style={styles.section}>
                    <ReceivingPartOne />
                </View>
                
                {/* Items */}
                {receiving && receiving.items && receiving.items.length > 0 ? (
                    <View style={styles.section}>
                        <View style={styles.table}>
                            
                            <TableHead />

                            {(receiving.items).map((item, i) => (
                                <View key={i}>
                                    <View  style={[styles.bodyrow]}>
                                        <Text style={[styles.td, styles.desc10]}>
                                            {i + 1}
                                        </Text>
                                        <Text style={[styles.td, styles.desc40]}>
                                            {item.description && item.description}
                                        </Text>
                                        <Text style={[styles.td, styles.desc10]}>
                                            {item.quantityNeeded && item.quantityNeeded}
                                        </Text>
                                        <Text style={[styles.td, styles.desc10]}>
                                            {item.quantityDelivered && item.quantityDelivered}
                                        </Text>
                                        <Text style={[styles.td, styles.desc10]}>
                                            {item.quantityReceived && item.quantityReceived}
                                        </Text>
                                        <Text style={[styles.td, styles.desc10]}>
                                            {item.quantityInspected && item.quantityInspected}
                                        </Text>
                                        <Text style={[styles.td, styles.desc10]}>
                                            {item.quantityAccepted && item.quantityAccepted}
                                        </Text>
                                        <Text style={[styles.td, styles.desc10]}>
                                            {item.quantityRejected && item.quantityRejected}
                                        </Text>
                                    </View>
                                </View>
                            ))}

                        </View>
                    </View>
                ) : null}


                {/* Inspection Parameters and Specification Discrepancies */}
                {receiving && receiving.inspectionParams && receiving.inspectionParams.length > 0 ? (
                    <View style={styles.section}>
                        <View style={styles.table}>
                            
                            <TableHeadPart2 />

                            <View style={[styles.bodyrow]}>
                                <View style={[styles.desc50left]}>
                                    {(receiving.inspectionParams).map((item, i) => (
                                        <View style={[styles.bodyrow]} key={i}>
                                            <Text style={[styles.td, styles.desc3]}>
                                                {item.type && item.type}
                                            </Text>
                                            <Text style={[styles.td, styles.desc2]}>
                                                {item.value && item.value}
                                            </Text>
                                        </View>
                                    ))}
                                </View>
                                <View style={[styles.desc50left]}>
                                    {(receiving.specDiscrepancies).map((item, i) => (
                                        <View style={[styles.bodyrow]} key={i}>
                                            <Text style={[styles.td, styles.desc3]}>
                                                {item.type && item.type}
                                            </Text>
                                            <Text style={[styles.td, styles.desc2]}>
                                                {item.value && item.value}
                                            </Text>
                                        </View>
                                    ))}
                                </View>
                            </View>
                        </View>
                    </View>
                ) : null}

                <View style={[styles.section, {fontSize: 9}]}>
                    
                    {receiving && receiving.paymentTerm ? (
                        <>
                            <Text style={[styles.textLeft, styles.textBold, {fontSize: 9}]}><strong>Vendor Payment Terms:</strong> {" "}
                                <Text style={[styles.text, {fontSize: 9}]}>
                                    {receiving && receiving.paymentTerm ? receiving.paymentTerm : null}
                                </Text>
                            </Text>
                        </>
                    ) : null}

                    {receiving && receiving.rejectionReason ? (
                        <>
                            <Text style={[styles.textLeft, styles.textBold, {fontSize: 9}]}><strong>Reasons for Rejection/Comment:</strong> {" "}
                                <Text style={[styles.text, {fontSize: 9}]}>
                                    {receiving && receiving.rejectionReason ? receiving.rejectionReason : null}
                                </Text>
                            </Text>
                        </>
                    ) : null}

                    <Text style={[styles.textLeft, styles.textBold, {fontSize: 9, marginTop: 10}]}><strong>Requester:</strong> {" "}
                        <Text style={[styles.text, {fontSize: 9}]}>
                            {receiving && receiving.createdBy ? receiving.createdBy?.userName : null}
                        </Text>
                    </Text>

                    {/* inspected by */}
                    <Text style={[styles.textLeft, styles.textBold, {fontSize: 9}]}><strong>Inspected By:</strong> {" "}
                        <Text style={[styles.text, {fontSize: 9}]}>
                            {receiving && receiving.acceptedBy ? receiving.acceptedBy?.userName : null}
                        </Text> 
                        {"  "} 
                        <View style={[styles.text]}>
                            {receiving && receiving.acceptedBy ? 
                            <Image 
                                src={femi} 
                            /> : null}
                        </View> 
                        {"  "} 
                        <Text style={[styles.text, styles.textRight, {fontSize: 9}]}>
                            {receiving && receiving.acceptedAt ? (receiving.acceptedAt).slice(0, -12) : null}
                        </Text>
                    </Text>

                    {/* reviewed by */}
                    <Text style={[styles.textLeft, styles.textBold, {fontSize: 9}]}><strong>Reviewed By:</strong> {" "}
                        <Text style={[styles.text, {fontSize: 9}]}>
                            {receiving && receiving.reviewedBy ? receiving.reviewedBy?.userName : null}
                        </Text>
                        {" "} 
                        <View style={[styles.text]}>
                            {(receiving && receiving.reviewedBy && receiving?.reviewedBy?.userName === "Femi Awosedo") ? 
                            <Image 
                                src={femi} 
                            /> : 
                            <Image 
                                src={livinus} 
                            /> 
                            }
                        </View> 
                        {"  "} 
                        <Text style={[styles.text, styles.textRight, {fontSize: 9}]}>
                            {receiving && receiving.reviewedAt ? 
                            (receiving.reviewedAt).slice(0, -12) : null} 
                        </Text>
                    </Text>

                    {/* approved by */}
                    <Text style={[styles.textLeft, styles.textBold, {fontSize: 9}]}><strong>Approved By: </strong> {" "}
                        <Text style={[styles.text, {fontSize: 9}]}>
                            {receiving && receiving.approvedBy ? receiving.approvedBy?.userName : null}
                        </Text> 
                        {"  "} 
                        <View style={[styles.text]}>
                            {(receiving && receiving.approvedBy && receiving?.approvedBy?.userName === "Femi Awosedo") ? 
                            <Image 
                                src={femi} 
                            /> : 
                            <Image 
                                src={livinus} 
                            /> 
                            }
                        </View> 
                        {"  "} 
                        <Text style={[styles.text, {fontSize: 9}]}>
                            {receiving && receiving.approvedAt ? (receiving.approvedAt).slice(0, -12) : null}
                        </Text>
                    </Text>

                </View>
                
            </View>
        </>
    )
}

export default PageOne;
