import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Dialog,
  Typography,
  Button,
  DialogTitle,
  DialogActions,
  ButtonGroup,
  IconButton,
  makeStyles,
  Grid,
  DialogContent,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import Feedback from "../../../Feedback";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const DeleteJobTitleModal = ({
  openDelete,
  handleDeleteClose,
  id,
  deleteJobTitle,
  getJobTitles,

  error,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleDeleteTitle = () => {
    setLoading((prev) => !prev);
    deleteJobTitle(id)
      .then(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message: `Job Title with ID: ${id} deleted Successfully`,
          success: true,
        });
        dispatch(getJobTitles);
      })
      .catch((err) => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message: err,
          success: false,
        });
      })
      .finally(() => {
        handleDeleteClose();
      });
  };
  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };
  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      )}
      <Dialog
        open={openDelete}
        onClose={handleDeleteClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
        // fullScreen={fullScreen}
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Delete Job Title
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleDeleteClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography variant="overline" className={classes.title3}>
            Are You Sure You Want To Delete This Job Title?
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button onClick={handleDeleteClose} className={classes.saveBtn}>
              Close
            </Button>
            <Button
              color="secondary"
              onClick={handleDeleteTitle}
              disabled={loading}
              className={classes.saveBtn}
              variant="contained"
            >
              {loading ? "Loading..." : "Delete"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.jobTitle.error,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteJobTitleModal);
