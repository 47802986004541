import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Divider,
  IconButton,
  ButtonGroup,
  Button,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCustomer,
  getCustomers,
} from "../../../../actions/customerActions";
import Feedback from "../../../../Feedback";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },
  container: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    paddingBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  divider: {
    marginBottom: 10,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  total: {
    marginTop: 40,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  warningInfo: {
    color: "#f5f5f5",
    background: "#f44336",
    padding: 20,
    marginBottom: 20,
    marginRight: 10,
    "& > *": {
      fontWeight: 900,
    },
  },
  form: {
    marginBottom: 20,
  },
  grid: {
    marginBottom: 20,
  },
  input: {
    border: "1px solid #bdbdbd",
    padding: theme.spacing(2),
    borderRadius: 3,
  },

  btnWrapper: {
    textAlign: "center",
  },

  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
}));

const DeleteCustomerModal = ({ openDelete, handleCloseDelete, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.customer);

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  useEffect(() => {
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: error,
        success: false,
      }));
    }
  }, [error]);

  const handleDelete = () => {
    dispatch(deleteCustomer(id));

    setTimeout(() => {
      handleCloseDelete();
    }, 1500);

    setTimeout(() => {
      dispatch(getCustomers());
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: "Customer Account Deleted Successfully.",
        success: true,
      }));
    }, 2000);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Delete Customer
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseDelete}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography variant="overline" className={classes.title3}>
            Are You Sure You Want To Delete This Customer?
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button onClick={handleCloseDelete} className={classes.saveBtn}>
              Cancel
            </Button>
            <Button
              color="secondary"
              disabled={loading}
              onClick={handleDelete}
              className={classes.saveBtn}
              variant="contained"
            >
              {loading ? "Loading..." : "Delete"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteCustomerModal;
