import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import FilterListIcon from "@material-ui/icons/FilterList";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";

import {
  makeStyles,
  Grid,
  Typography,
  Paper,
  IconButton,
  TextField,
  InputAdornment,
  Divider,
  Backdrop,
  Icon,
  Chip,
  useTheme,
  // Button,
  Fade,
  Menu,
  MenuItem,
} from "@material-ui/core";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import moment from "moment";

import { TablePaginationActions } from "../../TablePaginationActions";
import SearchComponent from "../../SearchComponent";

import { connect } from "react-redux";
import { formatAmount, formatCurrency } from "../../../utils";

// import AllBudgetReviewModal from "../Modals/Approvals/AllBudgetReviewModal";
import { useHistory } from "react-router-dom";
import { getAllMonthlyBudgets } from "../../../actions/monthlyBudgetAction";
import Feedback from "../../../Feedback";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 500,
  },
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: "0.65rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    // background: "#00e676",

    borderColor: "#1de9b6",
    // color: "#fff",
  },
  statusYellow: {
    // background: "#fbc02d",

    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    // background: "#ef5350",

    borderColor: "#ef5350",
  },
  textField2: {
    marginTop: 30,
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  marginBottom: {
    marginBottom: 30,
  },
}));

const AllMonthly = ({
  getAllMonthlyBudgets,
  monthlyBudgets,
  profile,
  error,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [page, setPage] = React.useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState("");

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [search, setSearch] = useState("");
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  // const [openReview, setOpenReview] = useState(false);
  // const [selectBudgets, setSelectBudgets] = useState([]);

  const [total, setTotal] = useState(0);

  const [selectedMonth, setSelectedMonth] = useState(new Date());

  React.useEffect(() => {
    setOpenBackdrop((prev) => !prev);
    getAllMonthlyBudgets().then(() => {
      setOpenBackdrop(false);
    });
  }, [getAllMonthlyBudgets]);

  React.useEffect(() => {
    setFilter((prev) => (prev = "".toLowerCase()));
  }, []);

  // console.log(annualBudgets);

  const navigateById = (path, id) => {
    history.push(`${path}/${id}`);
  };

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
    setFilter((prev) => (prev = ""));
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDate = (date) => {
    // setSelectedDate(moment(date).format("YYYY").toString());
    setSelectedMonth(date);
  };

  // const onHandleSelectBudget = (e, data) => {
  //   e.persist();

  //   setSelectBudgets((prev) =>
  //     e.target.checked === false && prev.includes(data.id)
  //       ? prev.filter((item) => item !== data.id)
  //       : [...prev, data.id]
  //   );
  // };

  const handleOpenFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const handleSelectFilter = (e, val) => {
    setFilter(val.substr(0, 20));
    handleCloseFilter();
  };

  let filteredRows = () =>
    monthlyBudgets === undefined
      ? []
      : monthlyBudgets &&
        monthlyBudgets.filter((row) => {
          if (search !== "") {
            return (
              row.year
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1 ||
              row.documentNo
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1 ||
              row.total
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1 ||
              (row.division &&
                row.division.name
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1) ||
              row.status.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              row.type.toLowerCase().indexOf(search.toLowerCase()) !== -1
            );
          } else {
            return row;
          }
        });

  let filtered = filteredRows();

  filteredRows = () =>
    filtered.filter((item) => {
      if (selectedMonth !== null) {
        let month = moment(selectedMonth).format("MMMM");
        let year = moment(selectedMonth).format("YYYY");
        return (
          item.month.toString().toLowerCase().indexOf(month.toLowerCase()) !==
            -1 && item.year.toString().toLowerCase().indexOf(year) !== -1
        );
      } else {
        return item;
      }
    });

  let filteredSelect = filteredRows();

  filteredRows = () =>
    filteredSelect.filter((row) => {
      if (filter !== "") {
        return row.status.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
      } else {
        return row;
      }
    });

  const filteredAmount = filteredRows();

  React.useEffect(() => {
    let total = filteredAmount.reduce((acc, cur) => acc + (cur.total || 0), 0);
    total = formatAmount(total || 0);
    setTotal((prev) => (prev = total));
  }, [filteredAmount]);

  // const handleOpenReview = () => {
  //   setOpenReview(true);
  // };

  // const handleCloseReview = () => {
  //   setOpenReview(false);
  // };

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (Array(1000).length || filteredRows().length) - page * rowsPerPage
    );

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      {/* <AllBudgetReviewModal
        openReview={openReview}
        handleCloseReview={handleCloseReview}
        selectBudgets={selectBudgets}
        selectedDate={selectedDate}
      /> */}

      <div>
        {error && (
          <Feedback
            handleCloseFeed={handleCloseFeed}
            open={error && !error.success}
            severity="error"
            message={feed.message || (error && error.error)}
          />
        )}
        <Typography variant="overline" gutterBottom className={classes.title}>
          All Monthly Budgets
        </Typography>

        <Grid
          container
          justify="space-between"
          alignItems="center"
          spacing={3}
          className={classes.marginBottom}
        >
          <Grid item>
            <SearchComponent
              updateSearch={updateSearch}
              placeholder="Search..."
              search={search}
              ResetSearch={ResetSearch}
            />
          </Grid>
          <Grid item style={{ alignContent: "center" }}>
            <Grid container justify="center" alignItems="center" spacing={3}>
              {/* <Grid item>
                {profile && profile.jobTitle === "Chief Executive Officer" && (
                  <Fade in={selectBudgets.length !== 0}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      // onClick={handleOpenReview}
                      // disabled={!selectedDate}
                    >
                      Approve
                    </Button>
                  </Fade>
                )}
              </Grid> */}
              {/* <Grid item>
                {profile &&
                profile.jobTitle === "Chief Executive Officer" ? null : (
                  <Fade in={selectBudgets.length !== 0}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      onClick={handleOpenReview}
                      disabled={!selectedDate}
                    >
                      Review
                    </Button>
                  </Fade>
                )}
              </Grid> */}
              <Grid item>
                <DatePicker
                  autoOk
                  views={["month", "year"]}
                  label="Filter By Month/Year"
                  // disablePast
                  maxDate={new Date(moment().add(5, "years")._d)}
                  inputVariant="outlined"
                  value={selectedMonth}
                  onChange={handleChangeDate}
                  InputProps={{
                    endAdornment: (
                      <>
                        <Divider
                          className={classes.divider2}
                          orientation="vertical"
                        />
                        <InputAdornment position="end">
                          <IconButton
                            disabled={selectedMonth === null}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedMonth(null);
                              setFilter("");
                            }}
                          >
                            {selectedMonth ? <CloseIcon /> : <FilterListIcon />}
                          </IconButton>
                        </InputAdornment>
                      </>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Paper>
          <Grid container justify="space-between" alignItems="center">
            <Grid item></Grid>
            <Grid item>
              <IconButton
                style={{ marginRight: 10, marginTop: 10 }}
                onClick={handleOpenFilter}
              >
                <FilterListIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseFilter}
                TransitionComponent={Fade}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MenuItem onClick={(e) => handleSelectFilter(e, "")}>
                  All
                </MenuItem>
                <MenuItem onClick={(e) => handleSelectFilter(e, "Reviewed")}>
                  Reviewed
                </MenuItem>
                <MenuItem onClick={(e) => handleSelectFilter(e, "Approved")}>
                  Approved
                </MenuItem>
                <MenuItem onClick={(e) => handleSelectFilter(e, "Completed")}>
                  Completed
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
          <TableContainer>
            <Table
              className={classes.table}
              aria-label="custom pagination table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Document No.</TableCell>
                  <TableCell>Subdiv./Div.</TableCell>
                  <TableCell>Budget Type</TableCell>
                  <TableCell>Month</TableCell>
                  <TableCell>Year</TableCell>

                  <TableCell>Status</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? filteredRows().slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : filteredRows()
                ).map((row, i) => {
                  return (
                    <TableRow hover key={row._id}>
                      <TableCell component="th" scope="row">
                        <Chip label={row.documentNo} variant="outlined" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.division && row.division.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.type}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.month}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.year}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Chip
                          label={row.status.toUpperCase()}
                          variant="outlined"
                          className={clsx(
                            row.status === "approved" ||
                              row.status === "modified"
                              ? classes.statusOrange
                              : row.status === "completed" ||
                                row.status === "accepted" ||
                                row.status === "reviewed"
                              ? classes.statusDarkGreen
                              : classes.chip,
                            classes.chip
                          )}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          color:
                            row.total <= 200000
                              ? theme.palette.secondary.light
                              : theme.palette.success.main,
                        }}
                      >
                        {row.total
                          ? formatCurrency(row.total, "en-NG", "NGN")
                          : formatCurrency(0, "en-NG", "NGN")}
                      </TableCell>

                      <TableCell>
                        <IconButton
                          onClick={() =>
                            navigateById("/budget/monthly/view", row._id)
                          }
                        >
                          <Icon className="fas fa-eye" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
                {filteredRows().length === 0 && (
                  <TableRow hover>
                    <TableCell
                      colSpan={10}
                      align="center"
                      style={{ color: "#616161" }}
                      component="th"
                      scope="row"
                    >
                      No Records To Display
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
            colSpan={3}
            count={filteredRows().length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { "aria-label": "rows per page" },
              native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
        <Grid container justify="space-between" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <TextField
              label="Total"
              variant="outlined"
              disabled
              className={classes.textField2}
              value={total}
              InputProps={{
                startAdornment: (
                  <>
                    <InputAdornment position="start">&#8358;</InputAdornment>
                    <Divider
                      className={classes.divider2}
                      orientation="vertical"
                    />
                  </>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = (state) => ({
  monthlyBudgets:
    state.monthlyBudget.monthlyBudgets &&
    state.monthlyBudget.monthlyBudgets.data,
  profile: state.user.profile,
  error: state.monthlyBudget.error,
});

const mapDispatchToProps = {
  getAllMonthlyBudgets,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllMonthly);
