import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  Grid,
  Button,
  DialogTitle,
  IconButton,
  DialogActions,
  ButtonGroup,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../Feedback";

import {
  deleteAccess,
  getAllAccessRights,
} from "../../../actions/accessRightsActions";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  centered: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 20,
  },
}));

const DeleteAccessModal = ({
  openDelete,
  handleCloseDelete,
  name,
  deleteAccess,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleDelete = () => {
    setLoading((prev) => !prev);
    deleteAccess(name)
      .then(() => {
        dispatch(getAllAccessRights());
        setLoading(false);
        handleCloseDelete();
        setFeed({
          loading: false,
          open: !feed.open,
          message: `Access Right Deleted Successfully`,
          success: true,
        });
      })
      .catch((err) => {
        setLoading(false);

        setFeed({
          loading: false,
          open: !feed.open,
          message: err || "Error deleting Access Right",
          success: false,
        });
      });
  };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message || "Access Right Deleted Successfully."}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
        // fullScreen={fullScreen}
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Delete Access
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseDelete}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography variant="overline" className={classes.title3}>
            Are you sure you want to delete this Access Right?
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button onClick={handleCloseDelete} className={classes.saveBtn}>
              Cancel
            </Button>
            <Button
              color="secondary"
              onClick={handleDelete}
              disabled={loading}
              variant="contained"
              className={classes.saveBtn}
            >
              {loading ? "Loading..." : "Delete"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// const mapStateToProps = (state) => ({
//   error: state.module.submoduleError,
// });

export default connect(null, { deleteAccess })(DeleteAccessModal);
