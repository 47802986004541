import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Dialog,
    DialogContent,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    DialogTitle,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@material-ui/core";
import {
    DatePicker,
    MuiPickersUtilsProvider,
    TimePicker,
} from "@material-ui/pickers";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../../Feedback";
import { getVisitorAppointment, updateVisitorAppointment } from "../../../../actions/visitorsActions";
import MomentUtils from "@date-io/moment";
import { useHistory } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    centered: {
        textAlign: "center",
        marginTop: 10,
        marginBottom: 20,
    },
    saveBtn: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        width: 200,
    },
}));

const INIT_STATE = {
    visitorName: "",
    visitorEmail: "",
    visitorPhone: "",
    visitPurpose: "",
    visitDetails: "",
    visitDuration: "",
    visitTime: "",
    visitDate: "",
};

const EditVisitorRequestModal = ({ openEdit, handleCloseEdit, id }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = React.useState(INIT_STATE);
    const [time, setTime] = React.useState(new Date());
    const [date, setDate] = React.useState(new Date());
    const { loading, error, visitor } = useSelector((state) => state.visitors);

    React.useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    React.useEffect(() => {
        if (date !== "" && time !== "") {
            setState((prev) => ({
                ...prev,
                visitDate: moment(date._d).format('MMMM Do YYYY'),
                visitTime: moment(time._d).format('h:mm a'),
            }));
        }
    }, [date, time]);

    const [feed, setFeed] = React.useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    React.useEffect(() => {
        setState((prev) => ({
            ...prev,
            visitorName: (visitor && visitor.visitorName) || "",
            visitorEmail: (visitor && visitor.visitorEmail) || "",
            visitorPhone: (visitor && visitor.visitorPhone) || "",
            visitPurpose: (visitor && visitor.visitPurpose) || "",
            visitDetails: (visitor && visitor.visitDetails) || "",
            visitDuration: (visitor && visitor.visitDuration) || "",
            visitDate: (visitor && visitor.visitDate) || "",
            visitTime: (visitor && visitor.visitTime) || "",
        }));
    }, [visitor]);

    React.useEffect(() => {
        dispatch(getVisitorAppointment(id));
    }, [dispatch, id]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleChangeDate = (date) => {
        setDate(date);
    };

    const handleChangeTime = (time) => {
        setTime(time);
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            id,
        };
        await dispatch(updateVisitorAppointment(data));
        handleCloseEdit();
        setTimeout(() => {
            dispatch(getVisitorAppointment(id));
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Visitor Request Updated Successfully.",
                success: true,
            }));
        }, 2000);
        setTimeout(() => {
            history.push(`/visitor/${id}`);
        }, 3000);
    };

    const isValid = () =>
        !state.visitorName || !state.visitorEmail || !state.visitorPhone || !state.visitPurpose ||
        !state.visitTime || !state.visitDuration || !state.visitDate || !state.visitDetails;

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div>
                {error && error.error ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message || (error && error.error)}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message || `Visitor Request Updated successfully.`}
                    />
                )}

                <Dialog
                    open={openEdit}
                    onClose={handleCloseEdit}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="md"
                >
                    <DialogTitle>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="overline" className={classes.title}>
                                    Update Visitor Appointment
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={handleCloseEdit}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent dividers>
                        <form onSubmit={handleEditSubmit}>
                            <Grid container justify="center" alignItems="center" spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="visitorName"
                                        name="visitorName"
                                        label="Visitor Name"
                                        value={state.visitorName || ""}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="visitorEmail"
                                        name="visitorEmail"
                                        label="Visitor Email"
                                        value={state.visitorEmail || ""}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="visitorPhone"
                                        name="visitorPhone"
                                        label="Visitor Phone"
                                        value={state.visitorPhone || ""}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Purpose of Visit</InputLabel>
                                        <Select
                                            name="visitPurpose"
                                            inputProps={{ id: "visitPurpose", }}
                                            value={state.visitPurpose || ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="" disabled><em>Select</em></MenuItem>
                                            <MenuItem value="Personal">Personal</MenuItem>
                                            <MenuItem value="Official">Official</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="visitDetails"
                                        name="visitDetails"
                                        label="Brief details about the Visit"
                                        value={state.visitDetails || ""}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Visit Duration</InputLabel>
                                        <Select
                                            name="visitDuration"
                                            inputProps={{ id: "visitDuration", }}
                                            value={state.visitDuration || ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="" disabled><em>Select</em></MenuItem>
                                            <MenuItem value="OneHour">Less than One Hour</MenuItem>
                                            <MenuItem value="TwoHours">Less than Two Hours</MenuItem>
                                            <MenuItem value="ThreeHours">Less than Three Hours</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DatePicker
                                        autoOk
                                        format="DD/MM/yyyy"
                                        views={["year", "month", "date"]}
                                        label="Expected Date"
                                        fullWidth
                                        inputVariant="outlined"
                                        value={state.visitDate || ""}
                                        onChange={handleChangeDate}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TimePicker
                                        autoOk
                                        fullWidth
                                        format="h:mm a"
                                        inputVariant="outlined"
                                        label="Expected Time"
                                        value={state.visitTime || ""}
                                        onChange={handleChangeTime}
                                        required
                                        className={classes.textField}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justify="center" alignItems="center">
                                <Grid item>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                        size="large"
                                        disabled={isValid() || loading}
                                        className={classes.saveBtn}
                                    >
                                        {loading ? "Loading..." : "Update"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        </MuiPickersUtilsProvider>
    );
};

export default EditVisitorRequestModal;
