import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    darken,
    Backdrop,
    ButtonGroup,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Feedback from "../../../Feedback";

import CreateModuleModal from "../Modals/LMS/CreateModuleModal";
import CreateQuizModal from "../Modals/LMS/CreateQuizModal";
import { ViewContentsModal } from "../Modals/LMS/ViewContentsModal";
import { ViewOptionsModal } from "../Modals/LMS/ViewOptionsModal";

import { getLesson } from "../../../actions/ppmActions/lmsActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight:'bold',
        borderBottomColor: '#444',
        borderBottomWidth: 1,
        borderBottomStyle:'solid',
        paddingBottom: 10,
        marginBottom: 15,
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    paper3: {
        padding: theme.spacing(3),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        marginTop: 20, 
        background: darken("#fff", 0.008),
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 400,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    title: "",
    code:"",
    description: "",
    modules: [],
    quizzes: [],
};

const ViewLesson = () => {    
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    const [openCreate, setOpenCreate] = useState(false);
    const [openAddQuiz, setOpenAddQuiz] = useState(false);

    const [contents, setContents] = React.useState([]);
    const [openContents, setOpenContents] = useState(false);
    const [options, setOptions] = React.useState([]);
    const [openOptions, setOpenOptions] = useState(false);

    const handleOpenCreate = () => {
        setOpenCreate(true);
    };

    const handleCloseCreate = () => {
        setOpenCreate(false);
    };

    const handleOpenAddQuiz = () => {
        setOpenAddQuiz(true);
    };

    const handleCloseAddQuiz = () => {
        setOpenAddQuiz(false);
    };

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { error, loading, lesson } = useSelector((state) => state.lms);
    const { profile } = useSelector((state) => state.user);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getLesson(id));
    }, [dispatch, id]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            course: (lesson && lesson.course) || "",
            title: (lesson && lesson.title) || "",
            code: (lesson && lesson.code) || "",
            description: (lesson && lesson.description) || "",
            modules: (lesson && lesson.modules) || [],
            quizzes: (lesson && lesson.quizzes) || [],
        }));
    }, [lesson]);

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleOpenContents = (contents) => {
        setContents(contents);
        setOpenContents(true);
    };
    
    const handleCloseContents = () => {
        setOpenContents(false);
    };

    const handleOpenOptions = (options) => {
        setOptions(options);
        setOpenOptions(true);
    };
    
    const handleCloseOptions = () => {
        setOpenOptions(false);
    };

    const isAdmin = profile
        ? profile &&
        profile.groups &&
        profile.groups.some((grp) => grp.name === "admin") : false;

    const isPPM = profile
        ? profile &&
        profile.division &&
        profile?.division?.code === "PPM"
        : false;

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                <CreateModuleModal {...{ openCreate, handleCloseCreate, id }} />
                <CreateQuizModal {...{ openAddQuiz, handleCloseAddQuiz, id }} />

                <ViewContentsModal 
                    openContents={openContents}
                    handleCloseContents={handleCloseContents}
                    contents={contents} 
                />
                <ViewOptionsModal 
                    openOptions={openOptions}
                    handleCloseOptions={handleCloseOptions}
                    options={options} 
                />

                <Typography variant="overline" className={classes.title}>
                    Course Lesson
                </Typography>

                <Grid container justify="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.backBtn}
                                startIcon={<ArrowBackIosIcon />}
                                onClick={() => history.goBack()}
                                >
                                Back
                            </Button>

                            {((isAdmin || isPPM)) ? ( <>
                                <ButtonGroup>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        style={{ marginLeft: 10, backgroundColor:"#009933" }}
                                        className={classes.backBtn}
                                        onClick={handleOpenCreate}
                                    >
                                        Add Module
                                    </Button>

                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        style={{ marginLeft: 10, backgroundColor:"#009933" }}
                                        className={classes.backBtn}
                                        onClick={handleOpenAddQuiz}
                                    >
                                        Add Quiz
                                    </Button>
                                </ButtonGroup>
                            </>
                            ) : null}
                    </Grid>
                </Grid>

                <div>
                    <Paper variant="outlined" square className={classes.paper} style={{marginTop: 20, paddingBottom:0}}>
                        <Grid container spacing={2} className={classes.grid}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Course Name"
                                    name="course"
                                    variant="outlined"
                                    fullWidth
                                    value={(state.course && state.course?.name) || ""}
                                    disabled
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Lesson Title"
                                    name="title"
                                    variant="outlined"
                                    fullWidth
                                    value={(state.title && state.title) || ""}
                                    disabled
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Lesson Code"
                                    name="code"
                                    variant="outlined"
                                    fullWidth
                                    value={(state.code && state.code) || ""}
                                    disabled
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    label="Description of Lesson"
                                    name="description"
                                    variant="outlined"
                                    fullWidth
                                    value={state.description || ""}
                                    disabled
                                    required
                                    multiline
                                    rows={3}
                                />
                            </Grid>
                        </Grid>
                    </Paper>

                    <Typography variant="overline" className={classes.title2}>
                        Lesson Modules
                    </Typography>

                    <Paper variant="outlined" square className={classes.paper}>
                        {state.modules && state.modules.map((module, i) => (
                            <Grid
                                container
                                spacing={2}
                                className={classes.grid}
                                key={i}
                            >
                                <Grid item xs={12} md={10}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        label="Name of Module"
                                        name="name"
                                        value={module.name || ""}
                                        disabled
                                    />
                                </Grid>
                                
                                <Grid item xs={12} md={2}>
                                    <ButtonGroup>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleOpenContents(module.contents)}
                                            style={{padding: 14, backgroundColor:'#0067b8', color:"#fff"}}
                                        >
                                            View Contents
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        ))}
                    </Paper> 

                    <Typography variant="overline" className={classes.title2}>
                        Lesson Quizzes
                    </Typography>

                    <Paper variant="outlined" square className={classes.paper}>
                        {state.quizzes && state.quizzes.map((quiz, i) => (
                            <Grid
                                container
                                spacing={2}
                                className={classes.grid}
                                key={i}
                            >
                                <Grid item xs={12} md={7}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        label="Question"
                                        name="question"
                                        value={quiz.question || ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        label="Answer Type"
                                        name="answerType"
                                        value={quiz.answerType || ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <ButtonGroup>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleOpenOptions(quiz.options)}
                                            style={{padding: 14, backgroundColor:'#0067b8', color:"#fff"}}
                                        >
                                            View Options
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        ))}
                    </Paper> 
                </div>
                
            </MuiPickersUtilsProvider>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default ViewLesson;
