import React from "react";
import {
  Grid,
  makeStyles,
  Typography,
  AppBar,
  Tabs,
  Tab,
  Box,
  Card,
  CardContent,
  CardActions,
  Avatar,
  CardHeader,
  Divider,
  Button,
  TextField,
  Badge,
  withStyles,
  IconButton,
  Tooltip,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import EditIcon from "@material-ui/icons/Edit";

import SettingsIcon from "@material-ui/icons/Settings";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { useDispatch } from "react-redux";

import Feedback from "../../../Feedback";
import EditVendorModal from "../Modals/EditVendorModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box paddingTop={3} paddingLeft={1} paddingRight={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  large: {
    width: 100,
    height: 100,
    margin: "auto",
    cursor: "pointer",
  },
  card: {
    display: "grid",
    justifyItems: "center",
    aligncontent: "center",
    textAlign: "center",
    padding: theme.spacing(3),
  },
  btn: {
    marginLeft: "auto",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
  },
  colorDisabled: {
    color: "#9e9e9e",
  },
  header: {
    color: "#9e9e9e",

    // color: "#6d6d6d",
    fontFamily: "'Rubik', Roboto, 'Quicksand'",

    textTransform: "uppercase",
  },
  input: {
    display: "none",
  },
  label: {
    cursor: "pointer",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  saveBtn2: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
}));

// const SmallIcon = withStyles((theme) => ({
//   root: {
//     position: "relative",
//     width: 35,
//     height: 35,
//     display: "block",

//     color: "#fff",
//     borderRadius: "50%",
//     padding: theme.spacing(1),
//     boxShadow: "2px 2px 15px rgba(0,0,0,0.45)",
//     background: theme.palette.secondary.light,
//   },
// }))(PhotoCameraIcon);

const PencilIcon = withStyles((theme) => ({
  root: {
    width: 30,
    height: 30,
    // border: `3px solid ${theme.palette.background.paper}`,
    border: `3px solid #d9d9d9`,
    boxShadow: theme.shadows[10],
    padding: 3,
    borderRadius: 50,
    background: theme.palette.background.paper,
    transform: "rotate(270deg)",
  },
}))(EditIcon);

export const VendorsView = ({
  vendor,
  error,
  updateVendorPassword,
  uploadVendorPhoto,
  getVendorProfile,
  updateVendorProfile,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);

  const [loader, setLoader] = React.useState(false);
  const [helper, showHelper] = React.useState(false);

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  const [pass, setPass] = React.useState({
    currentPassword: "",
    newPassword: "",
  });

  const [visibility, showVisibility] = React.useState({
    password1: false,
    password2: false,
  });

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleVisibility = (key) => {
    showVisibility((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangePass = (e) => {
    e.persist();
    setPass((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangePassword = (e) => {
    e.preventDefault();

    setLoader((prev) => !prev);
    updateVendorPassword(pass)
      .then((doc) => {
        if (!doc.success) {
          setLoader(false);
          setFeed((prev) => ({
            ...prev,
            loading: false,
            open: !feed.open,
            message: "Password is incorrect",
            success: false,
          }));
        } else {
          setLoader(false);
          setPass((prev) => ({
            ...prev,
            currentPassword: "",
            newPassword: "",
          }));
          setFeed((prev) => ({
            ...prev,
            loading: false,
            open: !feed.open,
            message:
              doc.message || "Your Password has been changed successfully.",
            success: true,
          }));
          // dispatch(getProfile());
        }
      })
      .catch(() => {
        setLoader(false);
        setFeed((prev) => ({
          ...prev,
          loading: false,
          open: !feed.open,
          message: "Password is incorrect",
          success: false,
        }));
      });
  };

  const handleFocus = () => {
    showHelper((prev) => !prev);
  };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <EditVendorModal
        vendor={vendor}
        openEdit={openEdit}
        handleCloseEdit={handleCloseEdit}
        getVendorProfile={getVendorProfile}
        updateVendorProfile={updateVendorProfile}
      />
      <VendorPhotoModal
        open={open}
        handleClose={handleClose}
        vendorProfile={vendor}
        uploadVendorPhoto={uploadVendorPhoto}
        getVendorProfile={getVendorProfile}
        error={error}
      />
      <Typography
        variant="overline"
        gutterBottom
        style={{ display: "flex", alignItems: "center" }}
        className={classes.colorDisabled}
      >
        Settings <SettingsIcon color="disabled" style={{ marginLeft: 5 }} />
      </Typography>
      <AppBar
        position="static"
        style={{ borderBottom: "1px solid #e8e8e8" }}
        color="transparent"
        elevation={0}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          indicatorColor="secondary"
          //   textColor="secondary"
          variant="fullWidth"
        >
          <Tab
            label={
              <Typography variant="overline" className={classes.title}>
                General
              </Typography>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Typography variant="overline" className={classes.title}>
                Security
              </Typography>
            }
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card className={classes.card}>
              <CardContent>
                <Tooltip
                  title="Click Icon to Select Photo"
                  arrow
                  placement="right"
                >
                  <IconButton onClick={handleClickOpen}>
                    <Badge
                      overlap="circle"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      badgeContent={<PencilIcon fontSize="large" />}
                    >
                      <Avatar
                        sizes="30"
                        className={classes.large}
                        src={vendor && vendor.photo}
                      />
                    </Badge>
                  </IconButton>
                </Tooltip>

                {/* <Badge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <Tooltip title="Click Icon to Select Photo" arrow>
                      <IconButton onClick={handleClickOpen}>
                        <SmallIcon />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <Avatar
                    sizes="30"
                    className={classes.large}
                    src={vendor && vendor.photo}
                  />
                </Badge> */}

                <Typography
                  gutterBottom
                  variant="h5"
                  color="inherit"
                  className={classes.colorDisabled}
                >
                  {(vendor && vendor.companyName) || "Loading..."}
                </Typography>
                <Typography gutterBottom variant="overline">
                  {(vendor &&
                    (vendor.state || "Loading") +
                      ", " +
                      (vendor.country || "Loading")) ||
                    "Loading..."}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card>
              <CardHeader
                title={
                  <Typography
                    variant="h6"
                    style={{ fontSize: 18, fontWeight: 900 }}
                    className={classes.header}
                  >
                    Profile
                  </Typography>
                }
              />
              <Divider light />
              <CardContent style={{ textAlign: "center" }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleOpenEdit}
                  className={classes.saveBtn}
                >
                  Update Profile
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Card>
          <CardHeader
            title={
              <Typography
                variant="h6"
                style={{ fontSize: 18, fontWeight: 900 }}
                className={classes.header}
              >
                Change Password
              </Typography>
            }
          />
          <Divider light />
          <form onSubmit={handleChangePassword}>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Old Password"
                    type={visibility.password1 ? "text" : "password"}
                    variant="outlined"
                    fullWidth
                    name="currentPassword"
                    value={pass.currentPassword || ""}
                    onChange={handleChangePass}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleVisibility("password1")}
                          >
                            {visibility.password1 ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="New Password"
                    type={visibility.password2 ? "text" : "password"}
                    variant="outlined"
                    fullWidth
                    name="newPassword"
                    value={pass.newPassword}
                    onChange={handleChangePass}
                    onFocus={handleFocus}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleVisibility("password2")}
                          >
                            {visibility.password2 ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      helper
                        ? "Password needs to have at least one lower case, one uppercase, one number, one special character, and must be at least 8 characters but no more than 35."
                        : null
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider light />
            <CardActions style={{ padding: 16 }}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                className={classes.btn}
                disabled={!pass.currentPassword || !pass.newPassword || loader}
                endIcon={
                  loader ? <CircularProgress color="primary" size={20} /> : null
                }
              >
                {loader ? "Loading..." : "Change Password"}
              </Button>
            </CardActions>
          </form>
        </Card>
      </TabPanel>
    </div>
  );
};

/* --------------------------- Vendor Photo Modal ------------------------------- */

const VendorPhotoModal = ({
  open,
  handleClose,
  vendorProfile,
  uploadVendorPhoto,
  getVendorProfile,
  error,
}) => {
  const classes = useStyles();
  const [mediaPreview, setMediaPreview] = React.useState("");
  const [photo, setPhoto] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  const dispatch = useDispatch();

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleChange = (e) => {
    e.persist();
    const { files } = e.target;

    setPhoto(files[0]);
    setMediaPreview(window.URL.createObjectURL(files[0]));
  };

  const handleUploadPhoto = () => {
    setLoading((prev) => !prev);
    const formData = new FormData();
    formData.append("img", photo);

    uploadVendorPhoto(formData)
      .then((doc) => {
        setLoading(false);
        handleClose();

        setFeed({
          loading: false,
          open: !feed.open,
          message: `Profile Photo uploaded successfully`,
          success: true,
        });
        dispatch(getVendorProfile());
      })
      .catch(() => {
        // setLoading(false);
        // setFeed({
        //   loading: false,
        //   open: !feed.open,
        //   message: `Unable to upload photo. Kindly contact Admin`,
        //   success: false,
        // });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ textAlign: "center", fontSize: 18 }}
        >
          <span className={classes.header}>Update Profile Picture</span>
        </DialogTitle>
        <Divider light />
        <DialogContent>
          <label htmlFor="img">
            <Tooltip title="Click to Select Profile Picture" placement="right">
              <Avatar
                sizes="30"
                className={classes.large}
                src={mediaPreview || (vendorProfile && vendorProfile.photo)}
              />
            </Tooltip>
          </label>

          <input
            type="file"
            name="img"
            id="img"
            accept="image/*"
            className={classes.input}
            onChange={handleChange}
          />
          {photo && photo.name}
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            //   disableElevation
            startIcon={<PhotoCameraIcon />}
            disabled={!photo || !mediaPreview || loading}
            onClick={handleUploadPhoto}
            style={{ marginBottom: 5, marginTop: 5 }}
          >
            {loading ? "Loading..." : "Upload Photo"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
