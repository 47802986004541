import React, { useState } from "react";
import { connect } from "react-redux";

import {
  makeStyles,
  Typography,
  Paper,
  Grid,
  Button,
  // Backdrop,
  IconButton,
  Chip,
  Icon,
  Menu,
  MenuItem,
} from "@material-ui/core";
// import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import FilterListIcon from "@material-ui/icons/FilterList";

import Fade from "@material-ui/core/Fade";
import clsx from "clsx";

import SearchComponent from "../SearchComponent";
import { TablePaginationActions } from "../TablePaginationActions";

import { getAllVendors } from "../../actions/vendorsActions";
import ViewVendorModal from "./Modals/ViewVendorModal";
import EditVendorModal from "./Modals/Quality/EditVendorModal";
import SetupVendorModal from "./Modals/Quality/SetUpVendorModals";

import DeleteVendorModal from "./Modals/DeleteVendorModal";

import Feedback from "../../Feedback";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 500,
  },
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: "0.65rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    // background: "#00e676",

    borderColor: "#1de9b6",
    // color: "#fff",
  },
  statusYellow: {
    // background: "#fbc02d",

    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    // background: "#ef5350",

    borderColor: "#ef5350",
  },
  btn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  createBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
  },
  grid: {
    marginBottom: 30,
  },
}));

const VendorsList = ({ getAllVendors, vendors, profile, error }) => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [getId, setGetId] = useState("");
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  // const [openBackdrop, setOpenBackdrop] = useState(false);
  React.useEffect(() => {
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: error,
        success: false,
      }));
    }
  }, [error]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState("");

  const handleClickOpen = (id) => {
    setGetId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const handleOpenEdit = (id) => {
    setOpenEdit(true);
    setGetId(id)
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setGetId(id)
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  React.useEffect(() => {
    // setOpenBackdrop((prev) => !prev);
    getAllVendors().then(() => {
      // setOpenBackdrop(false);
    });
  }, [getAllVendors]);

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };

  const handleOpenFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const filteredRows = () =>
    vendors === undefined
      ? []
      : vendors &&
        vendors.filter((row) => {
          if (search !== "") {
            return (
              (row.companyName &&
                row.companyName.toLowerCase().indexOf(search.toLowerCase()) !==
                  -1) ||
              row.email.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              (row.state &&
                row.state
                  .toString()
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1) ||
              (row.country &&
                row.country.toLowerCase().indexOf(search.toLowerCase()) !==
                  -1) ||
              (row.companyTelephone &&
                row.companyTelephone
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1) ||
              (row.status &&
                row.status.toLowerCase().indexOf(search.toLowerCase()) !== -1)
            );
          } else if (filter !== "") {
            return (
              row.status.toLowerCase().indexOf(filter.toLowerCase()) !== -1
            );
          } else {
            return row;
          }
        });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectFilter = (e, val) => {
    setFilter(val.substr(0, 20));
    handleCloseFilter();
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const dataLength = Array(1000).length || filteredRows().length;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, dataLength - page * rowsPerPage);

    const isQHSE = profile
        ? profile &&
        profile.division &&
        profile?.division?.code === "QHS"
        : false;

    const isAdmin = profile
      ? profile &&
      profile.groups &&
      profile.groups.some(
          (grp) => grp.name === "admin" 
      )
      : false;


  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <ViewVendorModal
        id={getId}
        open={open}
        handleClose={handleClose}
        getAllVendors={getAllVendors}
      />
      <SetupVendorModal
        openCreate={openCreate}
        handleCloseCreate={handleCloseCreate}
        getAllVendors={getAllVendors}
      />
      <EditVendorModal 
        openEdit={openEdit}
        handleCloseEdit={handleCloseEdit}
        id={getId}
      />
      <DeleteVendorModal
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        id={getId}
      />
      <Typography variant="overline" className={classes.title}>
        Vendors List
      </Typography>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={3}
        className={classes.grid}
      >
        <Grid item>
          {(isQHSE || isAdmin) && (
            <Button
              color="primary"
              variant="contained"
              // style={{ marginRight: 5, marginBottom: 30 }}
              onClick={handleOpenCreate}
              className={classes.btn}
            >
              Create
            </Button>
          )}
        </Grid>
        <Grid item>
          <SearchComponent
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
      </Grid>
      <Paper>
        <Grid container justify="space-between" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <IconButton
              style={{ marginRight: 10, marginTop: 10 }}
              onClick={handleOpenFilter}
            >
              <FilterListIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseFilter}
              TransitionComponent={Fade}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem onClick={(e) => handleSelectFilter(e, "")}>
                All
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "New")}>
                New
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Pending")}>
                Pending
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Approved")}>
                Approved
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Blacklisted")}>
                Blacklisted
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>

        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>Company Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Supplier ID</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Registered On</TableCell>
                <TableCell>Warning</TableCell>
                <TableCell>Status</TableCell>

                <TableCell>View</TableCell>
                {(isAdmin || isQHSE) ? <TableCell>Edit</TableCell> : null}
                {(isAdmin || isQHSE) ? <TableCell>Delete</TableCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows()
              ).map((row, i) => {
                return (
                  <TableRow hover key={row._id}>
                    <TableCell component="th" scope="row">
                      {row.companyName}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Chip
                        label={(row.supplierId && row.supplierId) || "NIL"}
                        className={classes.chip}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.businessCategory}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {row.companyTelephone}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Chip
                        label={row.createdAt}
                        className={classes.chip}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Chip
                        label={row.completeRegWarning ? row.completeRegWarning : 'NIL'}
                        className={classes.chip}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Chip
                        label={row.status.toUpperCase()}
                        className={clsx(
                          row.status === "pending"
                            ? classes.statusYellow
                            : row.status === "approved"
                            ? classes.statusDarkGreen
                            : row.status === "new"
                            ? classes.statusOrange
                            : classes.statusRed,
                          classes.chip
                        )}
                        variant="outlined"
                      />
                    </TableCell>

                    <TableCell>
                      <IconButton onClick={() => handleClickOpen(row._id)}>
                        <Icon className="fas fa-eye" />
                      </IconButton>
                    </TableCell>
                    {(isAdmin || isQHSE) ? <TableCell> 
                      <IconButton onClick={() => handleOpenEdit(row._id)} >
                        <Icon className="fas fa-eye" />
                      </IconButton>
                    </TableCell> : null}

                    {(isAdmin || isQHSE) ? <TableCell> 
                      <IconButton onClick={() => handleOpenDelete(row._id)} disabled={row.status !== "pending" && 
                        row.status !== "new" && row.status !== "blacklisted"}>
                        <Icon className="fas fa-trash" color="secondary" />
                      </IconButton>
                    </TableCell> : null}
                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              {filteredRows().length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={10}
                    align="center"
                    style={{ color: "#616161" }}
                    component="th"
                    scope="row"
                  >
                    No Records To Display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      {/* <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  vendors: state.vendors.vendors,
  profile: state.user.profile,
  error: state.vendors.error,
});

const mapDispatchToProps = {
  getAllVendors,
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorsList);
