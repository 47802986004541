import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Grid,
  Button,
  Backdrop,
  IconButton,
  Chip,
  Icon,
  Menu,
  MenuItem,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import FilterListIcon from "@material-ui/icons/FilterList";
import Fade from "@material-ui/core/Fade";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import clsx from "clsx";
import { TablePaginationActions } from "../../TablePaginationActions";
import SearchComponent from "../../SearchComponent";
import { formatCurrency } from "../../../utils";
import CreateCashAdvanceModal from "../Modals/CreateCashAdvanceModal";
import { getAllCashAdvances } from "../../../actions/cashAdvanceAction";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 500,
  },
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: "0.65rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    // background: "#00e676",

    borderColor: "#1de9b6",
    // color: "#fff",
  },
  statusYellow: {
    // background: "#fbc02d",

    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    // background: "#ef5350",

    borderColor: "#ef5350",
  },
  marginBottom: {
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  createBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const CashAdvance = ({ profile, getAllCashAdvances, cashAdvances }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [filter, setFilter] = useState("");

  const [search, setSearch] = useState("");

  useEffect(() => {
    setOpenBackdrop((prev) => !prev);
    getAllCashAdvances().then(() => {
      setOpenBackdrop(false);
    });
  }, [getAllCashAdvances]);

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };

  let filteredRows = () =>
    cashAdvances === undefined
      ? []
      : cashAdvances.filter((row) => {
        if (search !== "") {
          return (
            row.documentNo
              .toString()
              .toLowerCase()
              .indexOf(search.toLowerCase()) !== -1 ||
            row.employee.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            (row.total &&
              row.total
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1) ||
            row.month.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            row.status.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
            (row.division &&
              row.division.name
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1)
          );
        } else {
          return row;
        }
      });

  let filtered = filteredRows();

  filteredRows = () =>
    filtered.filter((row) => {
      if (filter !== "") {
        return row.status.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
      } else {
        return row;
      }
    });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const navigateById = (path, id) => {
    history.push(`${path}/${id}`);
  };

  const handleOpenFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const handleSelectFilter = (e, val) => {
    setFilter(val.substr(0, 20));
    handleCloseFilter();
  };

  const handleCreateOpen = () => {
    setOpenCreate(true);
  };

  const handleCreateClose = () => {
    setOpenCreate(false);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (Array(1000).length || filteredRows().length) - page * rowsPerPage
    );

  return (
    <div>
      <CreateCashAdvanceModal {...{ openCreate, handleCreateClose }} />
      <Typography variant="overline" gutterBottom className={classes.title}>
        Cash Advance & Retirement
      </Typography>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={3}
        className={classes.marginBottom}
      >
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            style={{ marginRight: 5 }}
            onClick={handleCreateOpen}
            className={classes.createBtn}
          >
            Create
          </Button>
        </Grid>
        <Grid item>
          <SearchComponent
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
      </Grid>

      <Paper>
        <Grid container justify="space-between" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <IconButton
              style={{ marginRight: 10, marginTop: 10 }}
              onClick={handleOpenFilter}
            >
              <FilterListIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseFilter}
              TransitionComponent={Fade}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem onClick={(e) => handleSelectFilter(e, "")}>
                All
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Draft")}>
                Draft
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Submitted")}>
                Submitted
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Approved")}>
                Approved
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Paid")}>
                Paid
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Confirmed")}>
                Confirmed
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Accepted")}>
                Accepted
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Retired")}>
                Retired
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Rejected")}>
                Rejected
              </MenuItem>
              <MenuItem onClick={(e) => handleSelectFilter(e, "Overdue")}>
                Overdue
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>

        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>Document No.</TableCell>
                <TableCell>Employee</TableCell>
                <TableCell>Creator Div./Subdiv.</TableCell>
                <TableCell>CashAdvance Subdiv</TableCell>
                <TableCell>Month</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : filteredRows()
              ).map((row, i) => {
                return (
                  <TableRow hover key={row._id}>
                    <TableCell component="th" scope="row">
                      <Chip label={row.documentNo} variant="outlined" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.employee}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.division && row.division.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {(row.advanceDivision && row.advanceDivision.name) ?
                        row.advanceDivision.name : <Chip label="None" variant="outlined" />}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.month}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        color:
                          row.total <= 500000
                            ? theme.palette.warning.main
                            : theme.palette.success.main,
                      }}
                    >
                      {row.total
                        ? formatCurrency(
                          row.total,
                          row.rate === 1 ? "en-NG" : "en-US",
                          row.currency ? row.currency : "NGN"
                        )
                        : formatCurrency(0, "en-NG", "NGN")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Chip
                        label={row.status.toUpperCase()}
                        className={clsx(
                          row.status === "draft"
                            ? classes.statusYellow
                            : row.status === "submitted"
                              ? classes.statusOrange
                              : row.status === "approved" ||
                                row.status === "reviewed" ||
                                row.status === "confirmed"
                                ? classes.statusGreen
                                : row.status === "completed" ||
                                  row.status === "retired" ||
                                  row.status === "paid" ||
                                  row.status === "accepted"
                                  ? classes.statusDarkGreen
                                  : classes.statusRed,
                          classes.chip
                        )}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell align="center">
                      {row.status === "draft" || row.status === "rejected" ? (
                        <Tooltip title="Edit Cash Advance" arrow>
                          <span>
                            <IconButton
                              color="primary"
                              onClick={() =>
                                navigateById("/cashadvance", row._id)
                              }
                              disabled={profile._id !== row.createdBy._id}
                            >
                              <Icon
                                className="fas fa-pencil-alt"
                                color={
                                  profile._id !== row.createdBy._id
                                    ? "disabled"
                                    : "primary"
                                }
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip arrow title="View Cash Advance">
                          <IconButton
                            onClick={() =>
                              navigateById("/cashadvance/view", row._id)
                            }
                          >
                            <Icon className="fas fa-eye" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              {filteredRows().length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={10}
                    align="center"
                    style={{ color: "#616161" }}
                    component="th"
                    scope="row"
                  >
                    No Records To Display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.user.profile,
  cashAdvances: state.cashAdvance.cashAdvances,
});

const mapDispatchToProps = {
  getAllCashAdvances,
};

export default connect(mapStateToProps, mapDispatchToProps)(CashAdvance);
