import React, { useState, useEffect } from "react";
import {
    Paper,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    IconButton,
    Dialog,
    Tooltip,
    Backdrop,
    AppBar,
    Divider,
    InputAdornment,
    Toolbar,
    Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from 'moment';

import { useDispatch, useSelector } from "react-redux";
import { getSymbol } from "../../../../../utils/currencyconvert";
import { getCurrencies } from "../../../../../actions/currencyActions";
import { getDivisionsByService } from "../../../../../actions/divisionActions";
import {
    getEquipmentPriceListById, updateEquipmentPriceList, getAllEquipmentPriceList
} from "../../../../../actions/masterListActions/equipmentAction";
import Feedback from "../../../../../Feedback";


const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        fontWeight: "bold",
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    title2: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 2,
    },
    container: {
        padding: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(4),
        marginBottom: 30,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    saveBtn: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        width: 300,
    },
}));

const initialState = {
    description: "",
    division: "",
    provider: "",
    condition: "",
    elife: "",
    code: "",
    cost: "",
    depRate: "",
    dateOfPurchase: "",
    wearRate: "",
    margin: "",
    currentPeriod: "",
    usedLife: "",
    accumDep: "",
    netBookValue: "",
    usefulLife: "",
    dayRate: "",
    wear: null,
    withholdingRate: "",
    withholdingTax: null,
    totalDayRate: null,
};

const EditEquipmentModal = ({ openEdit, handleCloseEdit, id }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });
    const [state, setState] = useState(initialState);
    const [selectedDate, setSelectedDate] = useState(null);
    const [wearRate, setWearRate] = useState("");
    const [depRate, setDepRate] = useState(null);
    const [withholdingRate, setWithholdingRate] = useState("");

    const { divisions } = useSelector((state) => state.division);
    const { loading, error, equipment } = useSelector((state) => state.equipment);
    const { currencies } = useSelector((state) => state.currency);

    useEffect(() => {
        dispatch(getCurrencies());
        dispatch(getEquipmentPriceListById(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            description: (equipment && equipment.description) || "",
            division: (equipment && equipment.division) || "",
            code: (equipment && equipment.code) || "",
            currency: (equipment && equipment.currency) || "",
            cost: (equipment && equipment.cost) || "",
            netBookValue: (equipment && equipment.netBookValue) || "",
            provider: (equipment && equipment.provider) || "",
            accumDep: (equipment && equipment.accumDep) || "",
            depRate: (equipment && equipment.depRate) || "",
            dateOfPurchase: (equipment && equipment.dateOfPurchase) || "",
            withholdingTax: (equipment && equipment.withholdingTax) || "",
            withholdingRate: (equipment && equipment.withholdingRate) || "",
            wearRate: (equipment && equipment.wearRate) || "",
            wear: (equipment && equipment.wear) || "",
            usefulLife: (equipment && equipment.usefulLife) || "",
            usedLife: (equipment && equipment.usedLife) || "",
            currentPeriod: (equipment && equipment.currentPeriod) || "",
            dayRate: (equipment && equipment.dayRate) || "",
            condition: (equipment && equipment.condition) || "",
            totalDayRate: (equipment && equipment.totalDayRate) || "",
            margin: (equipment && equipment.margin) || "",
        }));
    }, [equipment]);

    useEffect(() => {
        dispatch(getDivisionsByService());
    }, [dispatch]);

    useEffect(() => {
        setWithholdingRate(5); setWearRate(15); setDepRate(10);
        const netBookValue =
            state.cost - state.accumDep;
        if (wearRate && withholdingRate && depRate) {
            const withholdingTax = (+state.dayRate * (+withholdingRate / 100)).toFixed(2);
            const wear = (+state.dayRate * (wearRate / 100)).toFixed(2);
            setState((prev) => ({
                ...prev, withholdingRate, wearRate, wear, depRate, netBookValue, withholdingTax
            }));
        }
    }, [dispatch, withholdingRate, wearRate, depRate, state.dayRate, state.cost, state.accumDep]);

    useEffect(() => {
        let usefulLife, accumDep;
        let usedLife = Math.abs((moment(state.dateOfPurchase).diff(state.currentPeriod, 'days') / 365) * 1).toFixed(2);
        if (usedLife <= 10) {
            usefulLife = (5 - usedLife).toFixed(2);
            accumDep = (+state.cost * (state.depRate / 100) * usedLife).toFixed(2);
        } else {
            usefulLife = (10 - usedLife).toFixed(2);
            accumDep = (+state.cost - (state.depRate)).toFixed(2);
        }
        setState((prev) => ({
            ...prev, usedLife, usefulLife, accumDep
        }));
    }, [dispatch, state.division, state.cost, state.depRate, state.dateOfPurchase, state.currentPeriod]);

    useEffect(() => {
        const totalDayRate = ((+state.dayRate + +state.withholdingTax) * +state.elife).toFixed(2);
        setState((prev) => ({
            ...prev, totalDayRate
        }));
    }, [state.dayRate, state.withholdingTax, state.elife]);

    const handleChangeDate = (date) => {
        setSelectedDate(date);
        let dateOfPurchase = moment(selectedDate);
        setState((prev) => ({
            ...prev, dateOfPurchase
        }));
    };

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            id,
        };
        dispatch(updateEquipmentPriceList(data));
        setTimeout(() => {
            dispatch(getAllEquipmentPriceList());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Equipment Price List Updated Successfully.",
                success: true,
            }));
        }, 1500);

        setTimeout(() => {
            handleCloseEdit();
        }, 2000);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Dialog
                open={openEdit}
                onClose={handleCloseEdit}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                fullScreen
                maxWidth="md"
            >
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <AppBar
                    className={classes.appBar}
                    variant="elevation"
                    position="fixed"
                    color="inherit"
                    elevation={1}
                >
                    <Toolbar>
                        <Tooltip title="close" arrow>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleCloseEdit}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <Toolbar />
                <div className={classes.container}>
                    <Typography className={classes.title} variant="overline">
                        Edit Equipment Price List
                    </Typography>
                    <div>
                        <form
                            component={Paper}
                            className={classes.form}
                            onSubmit={handleEditSubmit}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Equipment Description"
                                        name="description"
                                        variant="outlined"
                                        value={state.description}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Asset Code"
                                        name="code"
                                        variant="outlined"
                                        value={state.code}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Division</InputLabel>
                                        <Select
                                            inputProps={{
                                                id: "division",
                                                name: "division",
                                            }}
                                            value={state.division}
                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled>Select Division</MenuItem>
                                            {divisions.map((division) => (
                                                <MenuItem key={division._id} value={division._id}>
                                                    {division.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Service Provider</InputLabel>
                                        <Select
                                            inputProps={{
                                                id: "provider",
                                                name: "provider",
                                            }}
                                            value={state.provider}
                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled>Select Provider</MenuItem>
                                            <MenuItem value="In House">In House</MenuItem>
                                            <MenuItem value="Vendor">Vendor</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Currency</InputLabel>
                                        <Select
                                            inputProps={{
                                                id: "currency",
                                                name: "currency",
                                            }}
                                            value={state.currency}
                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled>Select Currency</MenuItem>
                                            {currencies.map((currency) => (
                                                <MenuItem key={currency._id} value={currency.code}>
                                                    {currency.code}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Cost of Equipment"
                                        name="cost"
                                        type="number"
                                        variant="outlined"
                                        value={state.cost}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Equipment life"
                                        name="elife"
                                        type="text"
                                        variant="outlined"
                                        value={state.elife}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <DatePicker
                                        autoOk
                                        format="DD/MM/yyyy"
                                        label="Date of Purchase"
                                        value={state.dateOfPurchase}
                                        name="dateOfPurchase"
                                        maxDate={new Date()}
                                        onChange={handleChangeDate}
                                        fullWidth
                                        inputVariant="outlined"
                                        animateYearScrolling={true}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Condition of Equipment</InputLabel>
                                        <Select
                                            inputProps={{
                                                id: "condition",
                                                name: "condition",
                                            }}
                                            value={state.condition}
                                            onChange={handleChange}
                                        >
                                            <MenuItem disabled>Select Condition</MenuItem>
                                            <MenuItem value="New">New</MenuItem>
                                            <MenuItem value="Good">Good</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <DatePicker
                                        format="DD/MM/yyyy"
                                        label="Current Period"
                                        name="currentPeriod"
                                        value={state.currentPeriod}
                                        fullWidth todayLabel="Today"
                                        required disabled
                                        inputVariant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Used Life"
                                        name="usedLife"
                                        type="number"
                                        variant="outlined"
                                        value={state.usedLife || 0}
                                        onChange={handleChange}
                                        fullWidth disabled
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Useful Life (years)"
                                        name="usefulLife"
                                        type="number"
                                        variant="outlined"
                                        value={state.usefulLife || 0}
                                        onChange={handleChange}
                                        fullWidth disabled
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Depreciation Rate (%)"
                                        name="depRate"
                                        type="text"
                                        variant="outlined"
                                        value={state.depRate + "%" || 0}
                                        disabled
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label={`Accumulated Depreciation (${getSymbol(
                                            state.currency || "NGN",
                                            0
                                        )})`}
                                        name="accumDep"
                                        type="text"
                                        variant="outlined"
                                        value={state.accumDep || 0}
                                        fullWidth disabled
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <>
                                                    <InputAdornment position="start">
                                                        {getSymbol(state.currency || "NGN", 0)}
                                                    </InputAdornment>
                                                    <Divider
                                                        className={classes.divider2}
                                                        orientation="vertical"
                                                    />
                                                </>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label={`Net Book Value (${getSymbol(
                                            state.currency || "NGN",
                                            0
                                        )})`}
                                        name="netBookValue"
                                        type="text"
                                        variant="outlined"
                                        value={state.netBookValue || 0}
                                        fullWidth disabled
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <>
                                                    <InputAdornment position="start">
                                                        {getSymbol(state.currency || "NGN", 0)}
                                                    </InputAdornment>
                                                    <Divider
                                                        className={classes.divider2}
                                                        orientation="vertical"
                                                    />
                                                </>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Base Rate"
                                        name="dayRate"
                                        type="text"
                                        onChange={handleChange}
                                        variant="outlined"
                                        value={state.dayRate || ""}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Wear And Tear (%)"
                                        name="wearRate"
                                        type="text"
                                        variant="outlined"
                                        value={state.wearRate + "%" || 0}
                                        disabled
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Wear"
                                        name="wear"
                                        type="text"
                                        variant="outlined"
                                        value={state.wear || 0}
                                        onChange={handleChange}
                                        disabled
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Withholding Rate (%)"
                                        name="withholdingRate"
                                        type="text"
                                        variant="outlined"
                                        value={state.withholdingRate + "%" || 0}
                                        disabled
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Withholding Tax"
                                        name="withholdingTax"
                                        type="number"
                                        variant="outlined"
                                        value={state.withholdingTax || 0}
                                        disabled
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Profit Margin"
                                        name="margin"
                                        type="text"
                                        variant="outlined"
                                        value={state.margin}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        label="Day Rate (%)"
                                        name="totalDayRate"
                                        type="text"
                                        variant="outlined"
                                        value={state.totalDayRate}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                            </Grid>

                            <Grid container justify="center" alignItems="center" spacing={3}>
                                <Grid item>
                                    <Button
                                        onClick={handleEditSubmit}
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className={classes.saveBtn}
                                        disabled={loading}
                                        endIcon={loading ? <CircularProgress size={20} /> : null}
                                    >
                                        {loading ? "updating" : "Save Changes"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        </MuiPickersUtilsProvider>
    );
};

export default EditEquipmentModal;
