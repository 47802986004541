import React from "react";
import { Typography, Button, makeStyles } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { connect } from "react-redux";
import SubModuleTable from "./SubModuleTable";
import { getModule } from "../../actions/moduleAction";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 10,
  },
  createBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 300,
  },
  divider: {
    marginTop: 30,
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  floatingBtn: {
    transform: "translateY(0px)",
    animation: "$floating 1.2s infinite alternate",
  },

  "@keyframes floating": {
    "0%": {
      transform: "translateY(0px)",
    },

    "100%": {
      transform: "translateY(-10px)",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  paperText: {
    fontFamily: "Rubik",
    fontSize: 13,
    fontWeight: 500,
    color: "#fff",

    height: "fit-content",
    padding: theme.spacing(1) + 2,
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "#0176ff",

    position: "relative",
    "&::after": {
      position: "absolute",
      content: "''",
      right: -5,
      top: "40%",
      width: 10,
      height: 10,

      backgroundColor: "#0176ff",

      transform: "rotate(45deg)",
    },
  },
}));

const SubModule = ({ getModule, moduleData }) => {
  const classes = useStyles();
  const history = useHistory();

  const [load, setLoad] = React.useState(false);
  const { id } = useParams();

  const getModuleCallback = React.useCallback(() => {
    setLoad((prev) => !prev);
    getModule(id).then(() => {
      setLoad(false);
    });
  }, [getModule, id]);

  React.useEffect(() => {
    getModuleCallback();
  }, [getModuleCallback]);

  return (
    <div>
      <Typography variant="overline" className={classes.title}>
        {load ? "Loading..." : moduleData.name} / SubModule
      </Typography>
      <Button
        onClick={() => history.goBack()}
        variant="contained"
        color="primary"
        startIcon={<ArrowBackIosIcon />}
        id="scroll-to-top"
        className={classes.btn}
      >
        Back
      </Button>

      <SubModuleTable />
    </div>
  );
};

const mapStateToProps = (state) => ({
  moduleData: state.module.module,
});

const mapDispatchToProps = {
  getModule,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubModule);
