import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Divider,
    Icon,
    darken,
    Backdrop,
    InputAdornment,
    Fab,
    ButtonGroup,
    Hidden,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import AddIcon from "@material-ui/icons/Add";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../../Feedback";
import { getProfile } from "../../../actions/userActions";
import { getAllAllCwsrs } from "../../../actions/cwsrActions";
import { getProjectMob, updateProjectMob, submitProjectMob, getProjectMobs, reviewProjectMob } from "../../../actions/projectMobActions";
// service division
import AcceptMobModal from "../Modals/ProjectMob/AcceptMobModal";
import DeclineMobModal from "../Modals/ProjectMob/DeclineMobModal";
// Commercials
import ApproveMobModal from "../Modals/ProjectMob/ApproveMobModal";
import RejectMobModal from "../Modals/ProjectMob/RejectMobModal";
import ConfirmMobModal from "../Modals/ProjectMob/ConfirmMobModal";
import DemobMobModal from "../Modals/ProjectMob/DemobMobModal";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 250,
        [theme.breakpoints.down("sm")]: {
            width: 250,
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 250,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
            // color: "#fff",
        },
        [theme.breakpoints.down("sm")]: {
            width: 250,
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        // fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 15,
        textAlign: "center",
        maxWidth: 400,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));


const INIT_STATE = {
    projectTitle: "",
    projectName: "",
    projectScope: "",
    projectDuration: "",
    cwsr: "",
    cwsrId:"",
    jobNumber: "",
    purchaseOrderNumber: "",
    employee: "",
    division: "",
    tenMobilizationDate: "",
    mobilizationDate: "",
    serviceDivision: "",
    serviceType:"",
    contactPerson: "",
    personnel:[],
    equipment:[],
    contactPersonEmail: "",
    contactPersonJob: "",
    contactPersonPhone: "",
};

const ViewProjectMob = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [mobDate, setMobDate] = useState(null);
    const [state, setState] = useState(INIT_STATE);

    const [openAccept, setOpenAccept] = useState(false);
    const [openDecline, setOpenDecline] = useState(false);

    const [openApprove, setOpenApprove] = useState(false);
    const [openReject, setOpenReject] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openDemob, setOpenDemob] = useState(false);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { profile } = useSelector((state) => state.user);
    const { allcwsrs } = useSelector((state) => state.cwsr);
    const { error, loading, mobnotice } = useSelector((state) => state.projectMob);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getProjectMob(id));
        dispatch(getProfile());
        dispatch(getAllAllCwsrs());
    }, [dispatch, id]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            cwsr: (mobnotice && mobnotice.cwsr) || "",
            projectTitle: (mobnotice && mobnotice.projectTitle) || "",
            projectDuration: (mobnotice && mobnotice.projectDuration) || "",
            projectScope: (mobnotice && mobnotice.projectScope) || "",
            projectName: (mobnotice && mobnotice.projectName) || "",
            jobNumber: (mobnotice && mobnotice.jobNumber) || "",
            purchaseOrderNumber: (mobnotice && mobnotice.purchaseOrderNumber) || "",
            mobilizationDate: (mobnotice && mobnotice.mobilizationDate) || "",

            employee: (mobnotice && mobnotice.employee) || "",
            division: (mobnotice && mobnotice.division && mobnotice.division._id) || "",
            divisionName: (mobnotice && mobnotice.division && mobnotice.division.name) || "",
            jobTitle: (mobnotice && mobnotice.jobTitle) || "",
            employeeId: (mobnotice && mobnotice.employeeId) || "",
            customer: (mobnotice && mobnotice.customer && mobnotice.customer.name) || "",

            personnel: (mobnotice && mobnotice.personnel) || [],
            equipment: (mobnotice && mobnotice.equipment) || [],

            status: (mobnotice && mobnotice.status) || "",
            createdBy: (mobnotice && mobnotice.createdBy) || "",
            createdAt: (mobnotice && mobnotice.createdAt) || "",

            submittedBy: (mobnotice && mobnotice.submittedBy) || "",
            submittedAt: (mobnotice && mobnotice.submittedAt) || "",

            approvedBy: (mobnotice && mobnotice.approvedBy) || "",
            approvedAt: (mobnotice && mobnotice.approvedAt) || "",
            approveComment: (mobnotice && mobnotice.approveComment) || "",
            
            rejectedBy: (mobnotice && mobnotice.rejectedBy) || "",
            rejectedAt: (mobnotice && mobnotice.rejectedAt) || "",
            rejectComment: (mobnotice && mobnotice.rejectComment) || "",

            acceptedBy: (mobnotice && mobnotice.acceptedBy) || "",
            acceptedAt: (mobnotice && mobnotice.acceptedAt) || "",
            acceptComment: (mobnotice && mobnotice.acceptComment) || "",

            declinedBy: (mobnotice && mobnotice.declinedBy) || "",
            declinedAt: (mobnotice && mobnotice.declinedAt) || "",
            declineComment: (mobnotice && mobnotice.declineComment) || "",

            reviewedBy: (mobnotice && mobnotice.reviewedBy) || "",
            reviewedAt: (mobnotice && mobnotice.reviewedAt) || "",
            documentNo: (mobnotice && mobnotice.documentNo) || "",

            confirmedBy: (mobnotice && mobnotice.confirmedBy) || "",
            confirmedAt: (mobnotice && mobnotice.confirmedAt) || "",
            confirmComment: (mobnotice && mobnotice.confirmComment) || "",

            demobedBy: (mobnotice && mobnotice.demobedBy) || "",
            demobedAt: (mobnotice && mobnotice.demobedAt) || "",
            demobComment: (mobnotice && mobnotice.demobComment) || "",

        }));
    }, [mobnotice]);

    useEffect(() => {
        if (state.cwsr) {
            allcwsrs && allcwsrs.map(
            (item) =>
                item._id === state.cwsr &&
                setState((prev) => ({
                    ...prev,
                    customer: item.customer && item.customer?._id,
                    customerName: item.customer && item.customer?.name,
                    contactPerson: item.contactPerson && item.contactPerson,
                    serviceRequirement: item.serviceRequirement && item.serviceRequirement,
                    serviceLocation: item.serviceLocation && item.serviceLocation,
                    serviceDuration: item.serviceDuration && item.serviceDuration,
                    serviceType: item.serviceType && item.serviceType,
                    serviceMethod: item.serviceMethod && item.serviceMethod,
                    serviceDivision: item.serviceDivision && item.serviceDivision,
                    tenMobilizationDate: (item.mobilizationDate && moment(item.mobilizationDate).format("l")) || "",
                }))
            );
        }
    }, [state.cwsr, allcwsrs]);

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleChangeDate = (date) => {
        setMobDate(date);
    };

    // Service Division
    const handleOpenAccept = () => {
        setOpenAccept(true);
    };
    const handleCloseAccept = () => {
        setOpenAccept(false);
    };
    const handleOpenDecline = () => {
        setOpenDecline(true);
    };
    const handleCloseDecline = () => {
        setOpenDecline(false);
    };

    // Commercials
    const handleOpenApprove = () => {
        setOpenApprove(true);
    };
    const handleCloseApprove = () => {
        setOpenApprove(false);
    };
    const handleOpenReject = () => {
        setOpenReject(true);
    };
    const handleCloseReject = () => {
        setOpenReject(false);
    };

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };
    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };
    const handleOpenDemob = () => {
        setOpenDemob(true);
    };
    const handleCloseDemob = () => {
        setOpenDemob(false);
    };

    const handleChangePersonnelCharges = (i) => (e) => {
        e.persist();
        const newPersonnelCharges = state.personnel.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            personnel: newPersonnelCharges,
        }));
    };

    const handleChangeEquipmentCharges = (i) => (e) => {
        e.persist();
        const newEquipmentCharges = state.equipment.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            equipment: newEquipmentCharges,
        }));
    };

    const isServiceDivision = profile
        ? profile &&
        profile.division &&
        profile?.division?.id === state.serviceDivision?.id
        : false;

    const isAdmin = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "admin" 
        )
        : false;

    const isCommHead = profile
        ? profile &&
        (profile?.id === "5fb65e3fc521c80017c15e75")
        : false;

    const handleAddPersonnelCharges = () => {
        const data = {
            name: "",
            role: "",
            certificate:"",
            source: "",
        };
        setState((prev) => ({
            ...prev,
            personnel: prev.personnel.concat([data]),
        }));
        console.log(state.personnel)
    };

    const handleRemovePersonnelCharges = (i) => {
        setState((prev) => ({
            ...prev,
            personnel: prev.personnel.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleAddEquipmentCharges = () => {
        const data = {
            description: "",
            quantity: 1,
        };
        setState((prev) => ({
            ...prev,
            equipment: prev.equipment.concat([data]),
        }));
    };

    const handleRemoveEquipmentCharges = (i) => {
        setState((prev) => ({
            ...prev,
            equipment: prev.equipment.filter(
                (_, index) => index !== i
            ),
        }));
    };


    const handleSave = async (e) => {
        e.preventDefault();
        const data = {
            projectTitle: state.projectTitle && state.projectTitle,
            projectName: state.projectName && state.projectName,
            projectScope: state.projectScope && state.projectScope,
            projectDuration: state.projectDuration && state.projectDuration,
            cwsr: state.cwsr && state.cwsr,
            jobNumber: state.jobNumber && state.jobNumber,
            purchaseOrderNumber: state.purchaseOrderNumber && state.purchaseOrderNumber,
            employee: state.employee && state.employee,
            mobilizationDate: state.mobDate && state.mobDate,
            serviceDivision: state.serviceDivision && state.serviceDivision,
            id
        };
        await dispatch(updateProjectMob(data));
        setTimeout(async () => {
            await dispatch(getProjectMob(id));
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Project Mobilization Notice Updated Successfully.",
                success: true,
            }));
        }, 500);
    };

    const handleReview = async (e) => {
        e.preventDefault();
        const data = {
            personnel: state.personnel && state.personnel,
            equipment: state.equipment && state.equipment,
            id
        };
        await dispatch(reviewProjectMob(data));
        setTimeout(async () => {
            await dispatch(getProjectMob(id));
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Project Mobilization Reviewed Successfully.",
                success: true,
            }));
        }, 500);
    };

    const handleSubmitHandler = async (e) => {
        e.preventDefault();
        const data = {
            projectTitle: state.projectTitle && state.projectTitle,
            projectName: state.projectName && state.projectName,
            projectScope: state.projectScope && state.projectScope,
            projectDuration: state.projectDuration && state.projectDuration,
            cwsr: state.cwsr && state.cwsr,
            jobNumber: state.jobNumber && state.jobNumber,
            purchaseOrderNumber: state.purchaseOrderNumber && state.purchaseOrderNumber,
            employee: state.employee && state.employee,
            mobilizationDate: state.mobDate && state.mobDate,
            serviceDivision: state.serviceDivision && state.serviceDivision,
            id,
            path: `pml/mobdemob/view/${id}`,
        };
        await dispatch(submitProjectMob(data));
        await dispatch(getProjectMobs);
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Project Mobilization Notice Submitted Successfully.",
            success: true,
        }));
        setTimeout(() => {
            history.push("/pml/mobdemob");
        }, 1500);
    };
    
    const isCanEdit = state ? state.status &&
        ((state.status === "draft" ||
            state.status === "declined" ||
            state.status === "rejected")
        )
        : false;
    
    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}
            {/* Service Division */}
            <AcceptMobModal {...{ openAccept, handleCloseAccept, id }} />
            <DeclineMobModal {...{ openDecline, handleCloseDecline, id }} />

            {/* Commercials Head */}
            <ApproveMobModal {...{ openApprove, handleCloseApprove, id }} />
            <RejectMobModal {...{ openReject, handleCloseReject, id }} />

            <ConfirmMobModal {...{ openConfirm, handleCloseConfirm, id }} />
            <DemobMobModal {...{ openDemob, handleCloseDemob, id }} />

            <Typography variant="overline" className={classes.title}>
                Project Mobilization /  Demobilization
            </Typography>
            <Grid container justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.backBtn}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.push("/pml/mobdemob")}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <Paper variant="outlined" className={classes.status}>
                        <Typography variant="overline" className={classes.statusTitle}>
                            Document No.
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        {loading ? "Loading..." : state.documentNo}
                    </Paper>
                </Grid>
            </Grid>

            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
                style={{ marginBottom: 20 }}
            >
                <Hidden smDown>
                    <Grid item></Grid>
                </Hidden>
                <Grid item>
                    <Paper
                        variant="outlined"
                        className={clsx(
                            state.status === "draft"
                                ? classes.statusYellow
                                : state.status === "submitted" || state.status === "declined" || state.status === "rejected"
                                    ? classes.statusOrange
                                    : state.status === "approved" || state.status === "accepted"
                                        ? classes.statusGreen
                                        : (state.status === "reviewed" || state.status === "confirmed" || state.status === "demobilized")
                                            ? classes.statusDarkGreen
                                            : classes.statusRed,
                            classes.status
                        )}
                    >
                        <Typography variant="overline" className={classes.statusTitle}>
                            Status
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        <Typography variant="overline" className={classes.statusTitle}>
                            {loading ? "Loading..." : state.status}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            <Typography variant="overline" className={classes.title2}>
                CWSR Details
            </Typography>

            <Paper variant="outlined" square className={classes.paper}>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={2}
                    className={classes.grid}
                >
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Project CWSR</label>
                        <Autocomplete
                            id="cwsr"
                            options={allcwsrs}
                            value={state.cwsr}
                            debug
                            onChange={(event, newValue) => {
                                setState((prev) => ({
                                    ...prev,
                                    cwsr: newValue,
                                }));
                            }}
                            disabled={!isCanEdit}
                            getOptionLabel={(option) => option.serviceRequirement || ""}
                            renderOption={(option) => (
                                <React.Fragment>{option.serviceRequirement}</React.Fragment>
                            )}
                            renderInput={(params) => (
                                <>
                                    <TextField
                                        {...params}
                                        placeholder="Type something..."
                                        fullWidth
                                        required
                                        variant="outlined"
                                        value={(state.cwsr && state.cwsr) || ""}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "disabled",
                                            name: "cwsr",
                                        }}
                                    />
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Customer Name</label>
                        <TextField
                            id="customerName"
                            name="customerName"
                            placeholder="Customer Name"
                            value={state.customerName || ""}
                            variant="outlined"
                            fullWidth
                            required
                            disabled
                            />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Contact Person</label>
                        <TextField
                            id="contactPerson"
                            name="contactPerson"
                            placeholder="Contact Person"
                            value={state.contactPerson || ""}
                            variant="outlined"
                            fullWidth
                            required
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Service Division</label>
                        <TextField
                            id="serviceDivision"
                            name="serviceDivision"
                            placeholder="Service Division"
                            value={state.serviceDivision?.name || ""}
                            variant="outlined"
                            fullWidth
                            required
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Service Location</label>
                        <TextField
                            id="serviceLocation"
                            name="serviceLocation"
                            placeholder="Service Location"
                            value={state.serviceLocation || ""}
                            variant="outlined"
                            fullWidth
                            required
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Service Type</label>
                        <TextField
                            id="serviceType"
                            name="serviceType"
                            placeholder="Service Type"
                            value={state.serviceType || ""}
                            variant="outlined"
                            fullWidth
                            required
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Prepared By</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder="Prepared By"
                            value={state.createdBy?.userName || ""}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Division/Subdivision</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder="Division/Subdivision"
                            name="division"
                            value={state.divisionName || ""}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Date Created</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder="Date Created"
                            value={state.createdAt || ""}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <label style={{ fontWeight: "bold" }}>Service Requirement </label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            required
                            placeholder="Service Requirement"
                            name="serviceRequirement"
                            value={state.serviceRequirement || ""}
                            disabled
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Typography variant="overline" className={classes.title2}>
                Project Information
            </Typography>

            <Paper variant="outlined" square className={classes.paper}>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={1}
                    className={classes.grid}
                >
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Project Title</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            placeholder="Project Title"
                            name="projectTitle"
                            value={state.projectTitle || ""}
                            disabled={!isCanEdit}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Project Name</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            placeholder="Project Name"
                            name="projectName"
                            value={state.projectName || ""}
                            disabled={!isCanEdit}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Project Duration</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            placeholder="Project Duration"
                            name="projectDuration"
                            value={state.projectDuration || ""}
                            disabled={!isCanEdit}
                            onChange={handleChange}
                        />
                    </Grid>
                    
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Job Number</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            placeholder="Job Number"
                            name="jobNumber"
                            value={state.jobNumber || ""}
                            disabled={!isCanEdit}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Purchase Order Number</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            placeholder="Purchase Order Number"
                            name="purchaseOrderNumber"
                            value={state.purchaseOrderNumber || ""}
                            disabled={!isCanEdit}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Mobilization Date</label>
                        <DatePicker
                            className={classes.marginBottom}
                            autoOk
                            format="DD/MM/yyyy"
                            views={["year", "month", "date"]}
                            placeholder="Mobilization Date"
                            fullWidth
                            inputVariant="outlined"
                            value={mobDate ? mobDate : state.mobilizationDate}
                            onChange={handleChangeDate}
                            disabled={!isCanEdit}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        <Divider
                                            className={classes.divider2}
                                            orientation="vertical"
                                        />
                                        <InputAdornment position="end">
                                            <IconButton
                                                disabled={(mobDate ? mobDate : state.mobilizationDate) === null}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setMobDate(null);
                                                }}
                                            >
                                                {(mobDate ? mobDate : state.mobilizationDate) ? (
                                                    <CloseIcon />
                                                ) : (
                                                    <FilterListIcon />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    </>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <label style={{ fontWeight: "bold" }}>Scope of Work</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            required
                            placeholder="Scope of Work"
                            name="projectScope"
                            value={state.projectScope || ""}
                            onChange={handleChange}
                            disabled={!isCanEdit}
                        />
                    </Grid>
                </Grid>
            </Paper>

            {/* reviewed personnels and equipments */}
            {state.status === "reviewed" ? (
                <>
                    {state.personnel && state.personnel.length > 0 ? (
                        <>
                            <Typography variant="overline" className={classes.title2}>
                                Project Personnel Checklist
                            </Typography>
                            <Paper variant="outlined" square className={classes.paper}>
                                {state.personnel && state.personnel.map((item, i) => (
                                    <Grid
                                        container
                                        spacing={2}
                                        className={classes.grid}
                                        key={i}
                                    >
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Personnel Name"
                                                name="name"
                                                value={item.name}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Role"
                                                name="tole"
                                                value={item.role}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Certificates Required"
                                                name="certificate"
                                                value={item.certificate || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Source"
                                                name="source"
                                                value={item.source || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Status"
                                                name="status"
                                                value={item.status || ""}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                ))}
                            </Paper>
                        </>
                    ) : null}

                    {state.equipment && state.equipment.length > 0 ? (
                        <>
                            <Typography variant="overline" className={classes.title2}>
                                Project Equipment Checklist
                            </Typography>

                            <Paper variant="outlined" square className={classes.paper}>
                                {state.equipment && state.equipment.map((item, i) => (
                                    <Grid
                                        container
                                        spacing={2}
                                        className={classes.grid}
                                        key={i}
                                    >
                                        <Grid item xs={12} md={10}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Equipment Description"
                                                type="text"
                                                name="description"
                                                value={item.description}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Quantity"
                                                type="number"
                                                name="quantity"
                                                value={item.quantity}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                ))}
                            </Paper>
                        </>
                    ) : null}
                </>
            ) : null}


            {/* Add Personnel Requirements */}
            {((isServiceDivision || isAdmin) && state.status === "accepted") ? (
                <>
                    <Grid item>
                        <Typography variant="overline" className={classes.title2}>
                            Project Personnel Requirements
                        </Typography>
                    </Grid>

                    <Paper variant="outlined" square className={classes.paper}>
                        {state.personnel && state.personnel.map((item, i) => (
                            <Grid
                                container
                                spacing={2}
                                className={classes.grid}
                                key={i}
                            >
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        label="Name of Personnel"
                                        type="text"
                                        name="name"
                                        value={item.name || ""}
                                        onChange={handleChangePersonnelCharges(i)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        label="Role of Personnel"
                                        type="text"
                                        name="role"
                                        value={item.role || ""}
                                        onChange={handleChangePersonnelCharges(i)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        label="Certification Required"
                                        type="text"
                                        name="certificate"
                                        value={item.certificate || ""}
                                        onChange={handleChangePersonnelCharges(i)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <FormControl fullWidth required variant="outlined">
                                        <InputLabel id="source">
                                            National / Expat
                                        </InputLabel>
                                        <Select
                                            labelId="source"
                                            value={item.source || ""}
                                            name="source"
                                            onChange={handleChangePersonnelCharges(i)}
                                        >
                                            <MenuItem disabled>Select</MenuItem>
                                            <MenuItem value="National">National</MenuItem>
                                            <MenuItem value="Expat">Expat</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <Fab
                                        color="secondary"
                                        onClick={() => handleRemovePersonnelCharges(i)}
                                    >
                                        <CloseIcon />
                                    </Fab>
                                </Grid>
                            </Grid>
                        ))}

                        <Button
                            variant="outlined"
                            color="default"
                            onClick={() =>
                                handleAddPersonnelCharges(state.personnel && state.personnel.length + 1)
                            }
                            endIcon={<AddIcon fontSize="large" />}
                        >
                            Insert Item
                        </Button>
                    </Paper>

                    <Typography variant="overline" className={classes.title2}>
                        Project Equipments Requirements
                    </Typography>

                    <Paper variant="outlined" square className={classes.paper}>
                        {state.equipment && state.equipment.map((item, i) => (
                            <Grid
                                container
                                spacing={2}
                                className={classes.grid}
                                key={i}
                            >
                                <Grid item xs={12} md={8}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        label="Description of Equipment"
                                        type="text"
                                        name="description"
                                        value={item.description || ""}
                                        onChange={handleChangeEquipmentCharges(i)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        label="Quantity"
                                        type="number"
                                        name="quantity"
                                        value={item.quantity}
                                        onChange={handleChangeEquipmentCharges(i)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <Fab
                                        color="secondary"
                                        onClick={() => handleRemoveEquipmentCharges(i)}
                                    >
                                        <CloseIcon />
                                    </Fab>
                                </Grid>
                            </Grid>
                        ))}

                        <Button
                            variant="outlined"
                            color="default"
                            onClick={() =>
                                handleAddEquipmentCharges(state.equipment.length + 1)
                            }
                            endIcon={<AddIcon fontSize="large" />}
                        >
                            Insert Item
                        </Button>
                    </Paper>
                </>
            ) : null}

            {state.status !== "draft" ? (
                <>
                    <Grid
                        container
                        alignItems="center"
                        wrap="nowrap"
                        spacing={1}
                        style={{ marginBottom: 5 }}
                    >
                        <Grid item>
                            <Typography variant="overline" className={classes.title2}>
                                Audit Trail
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Icon className="fas fa-clipboard-list" color="disabled" />
                        </Grid>
                    </Grid>
                    <Paper variant="outlined" square className={classes.paper}>
                        <Grid container justify="space-between" alignItems="center" spacing={2}>
                            {state.submittedBy && (
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Submitted By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.submittedBy && state.submittedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.submittedAt}</span>
                                    </Paper>
                                </Grid>
                            )}
                            {state.approvedBy && (
                                <>
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Approved By</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.approvedBy && state.approvedBy?.userName}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.approvedAt}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.auditTitle}>Comment</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.approveComment || ""}</span>
                                        </Paper>
                                    </Grid>
                                </>
                            )}
                            {state.acceptedBy && (
                                <>
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Accepted By</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.acceptedBy && state.acceptedBy?.userName}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.acceptedAt}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.auditTitle}>Comment</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.acceptComment || ""}</span>
                                        </Paper>
                                    </Grid>
                                </>
                            )}
                            {state.reviewedBy && (
                                <>
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Reviewed By</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.reviewedBy && state.reviewedBy?.userName}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.reviewedAt}</span>
                                        </Paper>
                                    </Grid>
                                </>
                            )}
                            {state.confirmedBy && (
                                <>
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Confirmed By</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.confirmedBy && state.confirmedBy?.userName}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.confirmedAt}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.auditTitle}>Comment</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.confirmComment || ""}</span>
                                        </Paper>
                                    </Grid>
                                </>
                            )}
                            {state.demobedBy && (
                                <>
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Demobilized By</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.demobedBy && state.demobedBy?.userName}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.demobedAt}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.auditTitle}>Comment</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.demobComment || ""}</span>
                                        </Paper>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Paper>
                </>
            ) : null}

            {isCanEdit ? (
                <Grid container justify="center">
                    <Grid item>
                        <ButtonGroup>
                            <Button
                                className={classes.saveBtn}
                                color="primary"
                                variant="contained"
                                disabled={loading}
                                onClick={handleSave}
                            >
                                {loading ? "Loading..." : "Save Changes"}
                            </Button>
                            <Button
                                type="submit"
                                className={classes.saveBtn2}
                                variant="contained"
                                onClick={handleSubmitHandler}
                                disabled={loading}
                            >
                                Submit
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : null}   

            {((isCommHead || isAdmin) && state.status === "submitted") ? (
                <Grid container justify="center">
                    <Grid item>
                        <div className={classes.centered}>
                            <Paper className={classes.paperBlack}>
                            <HelpOutlineIcon fontSize="large" />
                            <Typography placeholder="Info" className={classes.info2}>
                                This project mobilization notice approval or rejection is to be done by the Head of Commercials Subdivision.
                            </Typography>
                            </Paper>
                        </div>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenApprove}
                                className={classes.saveBtn2}
                                variant="contained"
                            >
                                Approve
                            </Button>
                            <Button
                                onClick={handleOpenReject}
                                className={classes.saveBtn}
                                color="secondary"
                                variant="contained"
                            >
                                Reject
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : null}   
            
             {((isServiceDivision || isAdmin) && state.status === "approved") ? (
                <Grid container justify="center">
                    <Grid item>
                        <div className={classes.centered}>
                            <Paper className={classes.paperBlack}>
                            <HelpOutlineIcon fontSize="large" />
                            <Typography placeholder="Info" className={classes.info2}>
                                This project mobilization notice acceptance or decline is to be done by the Head of {state.serviceDivision.name} Subdivision.
                            </Typography>
                            </Paper>
                        </div>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenAccept}
                                className={classes.saveBtn2}
                                variant="contained"
                            >
                                Accept
                            </Button>
                            <Button
                                onClick={handleOpenDecline}
                                className={classes.saveBtn}
                                color="secondary"
                                variant="contained"
                            >
                                Decline
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : null}

            {((isServiceDivision || isAdmin) && state.status === "accepted") ? (
                <Grid container justify="center">
                    <Grid item>
                        <div className={classes.centered}>
                            <Paper className={classes.paperBlack}>
                                <HelpOutlineIcon fontSize="large" />
                                <Typography placeholder="Info" className={classes.info2}>
                                    This project mobilization review (assign personnel and equipments) is to be done 
                                    by {state.serviceDivision.name} Subdivision.
                                </Typography>
                            </Paper>
                        </div>
                        <div className={classes.btnWrapper}>
                            <Button
                                className={classes.saveBtn}
                                color="primary"
                                variant="contained"
                                disabled={loading}
                                onClick={handleReview}
                            >
                                {loading ? "Loading..." : "Submit Review"}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            ) : null}   

            {((isCommHead || isAdmin) && (state.status === "reviewed" || state.status === "confirmed" || state.status === "demobilized")) ? (
                <Grid container justify="center">
                    <Grid item>
                        <div className={classes.centered}>
                            <Paper className={classes.paperBlack}>
                            <HelpOutlineIcon fontSize="large" />
                            <Typography placeholder="Info" className={classes.info2}>
                                This project mobilization confirmation or demobilization is to be done by the Head of Commercials Subdivision.
                            </Typography>
                            </Paper>
                        </div>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenConfirm}
                                className={classes.saveBtn2}
                                variant="contained"
                                disabled={state.status !== "reviewed"}
                            >
                                Confirm
                            </Button>
                            <Button
                                onClick={handleOpenDemob}
                                className={classes.saveBtn}
                                color="secondary"
                                variant="contained"
                                disabled={state.status !== "confirmed"}
                            >
                                Demobilize
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : null}   

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default ViewProjectMob;
