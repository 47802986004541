import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Typography,
    Dialog,
    DialogContent,
    Grid,
    DialogTitle,
    TextField,
    Button,
    IconButton,
    Backdrop,
    CircularProgress,
    Divider,
    InputAdornment,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import { createInventoryItem, getAllInventoryItems, getAllInventoryCategories } from "../../../../../actions/inventoryActions";
import Feedback from "../../../../../Feedback";
import { useHistory } from "react-router-dom";
import { getCurrencies } from "../../../../../actions/currencyActions";
import { getDivisionsByService } from "../../../../../actions/divisionActions";
import { getSymbol } from "../../../../../utils/currencyconvert";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 18,
        fontWeight: 600,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
    },
    btn: {
        margin: "30px 0",
        width: 300,
        letterSpacing: 2.5,
        padding: theme.spacing(2),
        paddingLeft: 20,
        paddingRight: 20,
        [theme.breakpoints.down("sm")]: {
            width: 200,
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 10000,
        color: "#fff",
    },
}));

const INIT_STATE = {
    name: "",
    division: "",
    cost: "",
    code: "",
    category: "",
    currency: "",
    totalQuantity: 1,
    unit: "",
    totalValue: "",
};

const CreateItemModal = ({ openCreate, handleCloseCreate }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { currencies } = useSelector((state) => state.currency);
    const { divisions } = useSelector((state) => state.division);
    const [state, setState] = useState(INIT_STATE);

    const { error, loading, invCates } = useSelector((state) => state.inventoryItem);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        let abortController = new AbortController();

        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
        return () => {
            abortController.abort();
            dispatch({
                type: "RESET_ITEM",
            });
        };
    }, [error, dispatch]);

    useEffect(() => {
        dispatch(getCurrencies());
        dispatch(getDivisionsByService());
        dispatch(getAllInventoryCategories());
    }, [dispatch]);

    useEffect(() => {
        const totalValue = (state.totalQuantity * state.cost);

        setState((prev) => ({
            ...prev,
            totalValue,
        }));
    }, [state.totalQuantity, state.totalValue, state.cost]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    useEffect(() => {
        dispatch(getAllInventoryItems());
    }, [dispatch]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await new Promise((resolve, reject) => {
            resolve(dispatch(createInventoryItem(state)));
        });
        if (res) {
            await dispatch(getAllInventoryItems());
            handleCloseCreate();
            setState(INIT_STATE);
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Store Item Created Successfully.",
                success: true,
            }));
            setTimeout(() => {
                history.push(`/inventory/storeitems`);
            }, 2000);
        }
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isValid = () =>
        !state.name ||
        !state.division ||
        !state.totalQuantity ||
        !state.category ||
        !state.code ||
        !state.cost ||
        !state.totalValue ||
        !state.currency ||
        !state.unit;

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}

            <Dialog
                open={openCreate}
                onClose={handleCloseCreate}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle id="form-dialog-title">
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="overline" className={classes.title}>
                                Add Store / Inventory Item
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCloseCreate}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3} justify="center" alignItems="center">
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Name of Item"
                                    name="name"
                                    variant="outlined"
                                    value={state.name}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel>Inventory Category</InputLabel>
                                    <Select
                                        inputProps={{
                                            id: "category",
                                            name: "category",
                                        }}
                                        value={state.category}
                                        onChange={handleChange}
                                    >
                                        <MenuItem disabled>Select Category</MenuItem>
                                        {invCates.map((category) => (
                                            <MenuItem key={category._id} value={category._id}>
                                                {category.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Item Code"
                                    name="code"
                                    variant="outlined"
                                    value={state.code}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel>Division</InputLabel>
                                    <Select
                                        inputProps={{
                                            id: "division",
                                            name: "division",
                                        }}
                                        value={state.division}
                                        onChange={handleChange}
                                    >
                                        <MenuItem disabled>Select Division</MenuItem>
                                        {divisions.map((division) => (
                                            <MenuItem key={division._id} value={division._id}>
                                                {division.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Item Quantity"
                                    name="totalQuantity"
                                    type="number"
                                    variant="outlined"
                                    value={state.totalQuantity}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel>Unit of Measurement</InputLabel>
                                    <Select
                                        inputProps={{
                                            id: "unit",
                                            name: "unit",
                                        }}
                                        value={state.unit}
                                        onChange={handleChange}
                                    >
                                        <MenuItem disabled>Select Unit</MenuItem>
                                        <MenuItem value="packets">Packets</MenuItem>
                                        <MenuItem value="reams">Reams</MenuItem>
                                        <MenuItem value="kg">Kg</MenuItem>
                                        <MenuItem value="cartons">Cartons</MenuItem>
                                        <MenuItem value="crates">Crates</MenuItem>
                                        <MenuItem value="meters">Meters</MenuItem>
                                        <MenuItem value="Kkilometers">Kilometers</MenuItem>
                                        <MenuItem value="pieces">Pieces</MenuItem>
                                        <MenuItem value="dozens">Dozens</MenuItem>
                                        <MenuItem value="litres">Litres</MenuItem>
                                        <MenuItem value="gallons">Gallons</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label="Item Unit Cost/Price"
                                    name="cost"
                                    type="number"
                                    variant="outlined"
                                    value={state.cost}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel>Currency</InputLabel>
                                    <Select
                                        inputProps={{
                                            id: "currency",
                                            name: "currency",
                                        }}
                                        value={state.currency}
                                        onChange={handleChange}
                                    >
                                        <MenuItem disabled>Select Currency</MenuItem>
                                        {currencies.map((currency) => (
                                            <MenuItem key={currency._id} value={currency.code}>
                                                {currency.code}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label={`Total Cost (${getSymbol(
                                        state.currency || "NGN",
                                        0
                                    )})`}
                                    name="totalValue"
                                    variant="outlined"
                                    value={state.totalValue}
                                    type="number"
                                    fullWidth
                                    disabled
                                    InputProps={{
                                        startAdornment: (
                                            <>
                                                <InputAdornment position="start">
                                                    {getSymbol(state.currency || "NGN", 0)}
                                                </InputAdornment>
                                                <Divider
                                                    className={classes.divider2}
                                                    orientation="vertical"
                                                />
                                            </>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justify="center" alignItems="center">
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                color="primary"
                                className={classes.btn}
                                disabled={isValid() || loading}
                                endIcon={
                                    loading ? (
                                        <CircularProgress size={20} color="primary" />
                                    ) : null
                                }
                            >
                                {loading ? "Loading..." : "Create"}
                            </Button>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export default CreateItemModal;

