import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    Divider,
    Icon,
    darken,
    Backdrop,
    ButtonGroup,
    Hidden,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    InputLabel,
    InputAdornment,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../../Feedback";
import { getOrderDatabase, getOrderDatabases, submitOrderDatabase, updateOrderDatabase } from "../../../actions/salesOrderActions";
import { getProfile } from "../../../actions/userActions";
import { getCustomers } from "../../../actions/customerActions";
import { getDivisionsByService } from "../../../actions/divisionActions";
import { getServiceTypesByDivision, getServiceTypes } from "../../../actions/serviceTypeActions";
import { getSequence } from "../../../actions/sequenceAction";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold"
    },
    title2: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(1),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
            // color: "#fff",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        // fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 480,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    division: "",
    employee: "",
    employeeId:"",
    customer: "",
    crewName: "",
    projectDescription: "",
    projectTeamName: "",
    projectTitle: "",
    assetFacility: "",
    orderDate: "",
    serviceDivision: "",
    serviceType: "",
    serviceDescription: "",
    jobNumber: "",
    pmdnNumber: "",
};

const ViewOrderDatabase = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    const [seqId] = useState("654b830ba067a300147192e5");
    const [requestDate] = useState(new Date());
    const [orderDate, setOrderDate] = useState(new Date());

    const { divisions } = useSelector((state) => state.division);
    const { customers } = useSelector((state) => state.customer);
    const { services } = useSelector((state) => state.service);
    const { sequence } = useSelector((state) => state.sequence);
    const { profile } = useSelector((state) => state.user);
    const { error, loading, order_database } = useSelector((state) => state.salesorder);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getCustomers())
        dispatch(getDivisionsByService());
        dispatch(getOrderDatabase(id));
        dispatch(getSequence(seqId));
    }, [dispatch, id, seqId]);

    const isAdmin = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "admin" 
        )
        : false;

    const isFinanceHead = profile
        ? profile &&
        (profile?.id === "5fb65e3fc521c80017c15e75")
        : false;

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: profile && profile._id,
            employeeName: profile && profile.userName,
            employeeId: profile && profile.employeeId,
            division: profile && profile.division && profile.division._id,
            divisionName: profile && profile.division && profile.division.name,
            requestDate: moment(requestDate).toDate(),
        }));
    }, [profile, requestDate]);
        
    useEffect(() => {
        setState((prev) => ({
            ...prev,
            customer: (order_database && (order_database.customer && order_database.customer._id)) || "",
            crewName: (order_database && order_database.crewName) || "",
            projectTitle: (order_database && order_database.projectTitle) || "",
            projectTeamName: (order_database && order_database.projectTeamName) || "",
            projectDescription: (order_database && order_database.projectDescription) || "",
            assetFacility: (order_database && order_database.assetFacility) || "",
            orderDate: (order_database && order_database.orderDate) || "",
            serviceDivision: (order_database && order_database.serviceDivision) || "",
            jobNumber: (order_database && order_database.jobNumber) || "",
            pmdnNumber: (order_database && order_database.pmdnNumber) || "",
            serviceType: (order_database && order_database.serviceType) || "",
            serviceDescription: (order_database && order_database.serviceDescription) || "",
            customerCode: (order_database && order_database.customerCode) || "",

            employee: (order_database && order_database.employee) || "",
            division: (order_database && order_database.division) || "",
            requestDate: (order_database && moment(order_database.requestDate).format("l")) || "",
            
            status: (order_database && order_database.status) || "",
            documentNo: (order_database && order_database.documentNo) || "",

            createdBy: (order_database && order_database.createdBy) || "",
            createdAt: (order_database && order_database.createdAt) || "",

            submittedBy: (order_database && (order_database.submittedBy && order_database.submittedBy.userName)) || "",
            submittedAt: (order_database && order_database.submittedAt) || "",
        }));

    }, [order_database]);

    useEffect(() => {
        if (state.serviceDivision !== "") {
          dispatch(getServiceTypesByDivision(state.serviceDivision._id));
        }else {dispatch(getServiceTypes());}
      }, [dispatch, state.serviceDivision]);

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleChangeOrderDate = (date) => {
        setOrderDate(date);
    };
    
    useEffect(() => {
        if (state.customerCode !== "" && sequence !== "") {
            let pmdnNumber = "RS/PMDN/" + state.customerCode + "/" + sequence.data.nextNumber;
            let jobNumber = sequence.data.nextNumber;
            setState((prev) => ({
                ...prev,
                pmdnNumber: pmdnNumber,
                jobNumber: jobNumber
            }));
        } 
      }, [state.customerCode, sequence]);

    const handleSubmitHandler = async (e) => {
        e.preventDefault();
        const data = {
            customer: state.customer, 
            crewName: state.crewName, 
            projectTitle: state.projectTitle,
            projectTeamName: state.projectTeamName, 
            projectDescription: state.projectDescription, 
            assetFacility: state.assetFacility,
            orderDate: orderDate,
            jobNumber: state.jobNumber,
            pmdnNumber: state.pmdnNumber,
            serviceDescription: state.serviceDescription,
            serviceType: state.serviceType,
            serviceDivision: state.serviceDivision,
            id,
            path: `sales/orderdatabase/view/${id}`,
        };
        await dispatch(submitOrderDatabase(data));
        await dispatch(getOrderDatabases);
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Customer Order Submitted Successfully.",
            success: true,
        }));
        setTimeout(() => {
            history.push("/sales/orderdatabase");
        }, 1500);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const data = {
            customer: state.customer, 
            crewName: state.crewName, 
            projectTitle: state.projectTitle,
            projectTeamName: state.projectTeamName, 
            projectDescription: state.projectDescription, 
            assetFacility: state.assetFacility,
            orderDate: orderDate,
            jobNumber: state.jobNumber,
            pmdnNumber: state.pmdnNumber,
            serviceDescription: state.serviceDescription,
            serviceType: state.serviceType,
            serviceDivision: state.serviceDivision,
            id
        }
        await dispatch(updateOrderDatabase(data));
        setTimeout(async () => {
            await dispatch(getOrderDatabase(id));
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Customer Order Updated Successfully.",
                success: true,
            }));
        }, 500);
    };

    const validCheck = () =>
        !state.division ||
        !state.employee ||
        !state.requestDate ||
        !state.customer || 
        !state.crewName || 
        !state.projectTitle || 
        !state.projectTeamName || 
        !state.projectDescription || 
        !state.assetFacility || 
        !orderDate || 
        !state.jobNumber || 
        !state.pmdnNumber || 
        !state.serviceDescription || 
        !state.serviceType || 
        !state.serviceDivision;

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}         

            <Typography variant="overline" className={classes.title}>
                View Customer Order
            </Typography>
            <Grid container justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.backBtn}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.push("/sales/orderdatabase")}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <Paper variant="outlined" className={classes.status}>
                        <Typography variant="overline" className={classes.statusTitle}>
                            Document No.
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        {loading ? "Loading..." : state.documentNo}
                    </Paper>
                </Grid>
            </Grid>

            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
                style={{ marginBottom: 20 }}
            >
                <Hidden smDown>
                    <Grid item></Grid>
                </Hidden>
                <Grid item>
                    <Paper
                        variant="outlined"
                        className={clsx(
                            state.status === "draft"
                                ? classes.statusYellow
                                : state.status === "submitted"
                                    ? classes.statusOrange
                                    : (state.status === "approved" || state.status === "reviewed") ? classes.statusGreen
                                        : (state.status === "first_approved" || state.status === "second_approved" || state.status === "third_approved" || 
                                        state.status === "fourth_approved" || state.status === "fifth_approved" || state.status === "completed")
                                            ? classes.statusDarkGreen
                                            : classes.statusRed,
                            classes.status
                        )}
                    >
                        <Typography variant="overline" className={classes.statusTitle}>
                            Status
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        <Typography variant="overline" className={classes.statusTitle}>
                            {loading ? "Loading..." : state.status}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            <form onSubmit={handleSubmitHandler}>
            <Grid container spacing={2} className={classes.grid}>
                <Grid item xs={12} md={4}>
                    <TextField
                    label="Employee Name"
                    name="employeeName"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    value={state.employeeName || ""}
                    disabled
                    required
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Division/Subdivision"
                        name="division"
                        variant="outlined"
                        fullWidth
                        onChange={handleChange}
                        value={(state.division && state.divisionName) || ""}
                        disabled
                        required
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DatePicker
                        className={classes.marginBottom}
                        autoOk
                        format="DD/MM/yyyy"
                        views={["year", "month", "date"]}
                        label="Order Date"
                        fullWidth
                        inputVariant="outlined"
                        value={state.status === "submitted" ? state.orderDate : orderDate}
                        onChange={handleChangeOrderDate}
                        disabled={state.status === "submitted"}
                        InputProps={{
                            endAdornment: (
                                <>
                                    <Divider
                                        className={classes.divider2}
                                        orientation="vertical"
                                    />
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={(state.status === "submitted") || (orderDate === null)}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setOrderDate(null);
                                            }}
                                        >
                                            {orderDate ? (
                                                <CloseIcon />
                                            ) : (
                                                <FilterListIcon />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                </>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="customer">Customer</InputLabel>
                        <Select
                            name="customer"
                            value={state.customer}
                            onChange={handleChange}
                            labelId="customer"
                            disabled={state.status === "submitted"}
                        >
                        <MenuItem disabled>Select</MenuItem>
                            {customers && customers.map((item) => (
                                <MenuItem key={item._id} value={item._id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label="Crew Name"
                        name="crewName"
                        value={state.crewName || ""}
                        onChange={handleChange}
                        disabled={state.status === "submitted"}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label="Project Title"
                        name="projectTitle"
                        value={state.projectTitle || ""}
                        onChange={handleChange}
                        disabled={state.status === "submitted"}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label="Project Team Name"
                        name="projectTeamName"
                        value={state.projectTeamName || ""}
                        onChange={handleChange}
                        disabled={state.status === "submitted"}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label="Project Description"
                        name="projectDescription"
                        value={state.projectDescription || ""}
                        onChange={handleChange}
                        disabled={state.status === "submitted"}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label="Asset/Facility Location"
                        name="assetFacility"
                        value={state.assetFacility || ""}
                        onChange={handleChange}
                        disabled={state.status === "submitted"}
                    />
                </Grid>
            </Grid>

            <Typography variant="overline" className={classes.title2}>
                Requested Services/Jobs
            </Typography>

            <Paper variant="outlined" square className={classes.paper}>
                <Grid
                    container
                    spacing={2}
                    className={classes.grid}
                >
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth required variant="outlined">
                            <InputLabel id="serviceDivision">Service Division</InputLabel>
                            <Select
                                labelId="serviceDivision"
                                name="serviceDivision"
                                value={state.serviceDivision._id || ""}
                                onChange={handleChange}
                                disabled={state.status === "submitted"}
                            >
                                <MenuItem disabled>Select</MenuItem>
                                    {divisions && divisions.map((item) => (
                                    <MenuItem key={item._id} value={item._id}>
                                        {item.name}
                                    </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl variant="outlined" fullWidth required>
                        <InputLabel id="serviceType">Service Type</InputLabel>
                            <Select
                                name="serviceType"
                                value={state.serviceType}
                                onChange={handleChange}
                                disabled={state.status === "submitted"}
                                labelId="serviceType"
                            >
                            <MenuItem disabled>Select</MenuItem>
                                {(state.serviceDivision._id) && services && services.map((item) => (
                                    <MenuItem key={item._id} value={item._id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Service Description"
                            name="serviceDescription"
                            value={state.serviceDescription || ""}
                            onChange={handleChange}
                            disabled={state.status === "submitted"}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            label="Customer Code"
                            name="customerCode"
                            value={state.customerCode || ""}
                            onChange={handleChange}
                            disabled={state.status === "submitted"}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Job Number"
                            name="jobNumber"
                            value={state.jobNumber || ""}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="PMDN Number"
                            name="pmdnNumber"
                            value={state.pmdnNumber || ""}
                            disabled
                        />
                    </Grid>
                </Grid>
            </Paper>
            
            <Grid
                container
                alignItems="center"
                wrap="nowrap"
                spacing={1}
                style={{ marginBottom: 5 }}
            >
                <Grid item>
                    <Typography variant="overline" className={classes.title2}>
                        Audit Trail
                    </Typography>
                </Grid>
                <Grid item>
                    <Icon className="fas fa-clipboard-list" color="disabled" />
                </Grid>
            </Grid>
            <Paper variant="outlined" square className={classes.paper}>
                <Grid container justify="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Paper
                            className={classes.auditContainer}
                            component="div"
                            elevation={1}
                            variant="outlined"
                            square
                        >
                            <span className={classes.auditTitle}>Created By</span>
                            <Divider orientation="vertical" flexItem />
                            <span className={classes.audit}>{state.createdBy && state.createdBy.userName}</span>
                            <Divider orientation="vertical" flexItem />
                            <span className={classes.audit}>{state.createdAt}</span>
                        </Paper>
                    </Grid>
                    
                    {(state.submittedBy && state.submittedAt) && (
                    <Grid item xs={12} md={12}>
                        <Paper
                            className={classes.auditContainer}
                            component="div"
                            elevation={1}
                            variant="outlined"
                            square
                        >
                            <span className={classes.auditTitle}>Submitted By</span>
                            <Divider orientation="vertical" flexItem />
                            <span className={classes.audit}>{state.submittedBy && state.submittedBy}</span>
                            <Divider orientation="vertical" flexItem />
                            <span className={classes.audit}>{state.submittedAt}</span>
                        </Paper>
                    </Grid>
                    )}

                    {(state.approvedAt && state.approvedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Approved By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.approvedBy && state.approvedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.approvedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.approveComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.disapprovedAt && state.disapprovedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Disapproved By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.disapprovedBy && state.disapprovedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.disapprovedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.disapproveComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}

                    {(state.reviewedAt && state.reviewedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Reviewed By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.reviewedBy && state.reviewedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.reviewedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.reviewComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.rejectedAt && state.rejectedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Rejected By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.rejectedBy && state.rejectedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.rejectedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.rejectComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.acceptedAt && state.acceptedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Reviewed By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.acceptedBy && state.acceptedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.acceptedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.acceptComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    
                </Grid>
            </Paper>

            {/* ACTION BUTTONS */}
            {((isFinanceHead || isAdmin) && (state.status !== "submitted")) ? (
                    <>
                    <div className={classes.centered}>
                        <Paper className={classes.paperBlack}>
                            <HelpOutlineIcon fontSize="large" />
                            <Typography placeholder="Info" className={classes.info2}>
                                This customer order actions are to be done by the Head of Commercials Division.
                            </Typography>
                        </Paper>
                    </div>
                    <Grid container justify="center">
                        <Grid item>
                            <ButtonGroup>
                                <Button
                                    className={classes.saveBtn}
                                    color="primary"
                                    variant="contained"
                                    disabled={loading || validCheck()}
                                    onClick={handleSave}
                                >
                                    {loading ? "Loading..." : "Save Changes"}
                                </Button>
                                <Button
                                    type="submit"
                                    className={classes.saveBtn2}
                                    variant="contained"
                                    onClick={handleSubmitHandler}
                                    disabled={loading}
                                >
                                    Submit
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </>
            ) : null}
            </form>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default ViewOrderDatabase;
