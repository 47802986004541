import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    AppBar,
    IconButton,
    Dialog,
    Toolbar,
    Tooltip,
    Fab,
    DialogContent,
    Backdrop,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { connect, useDispatch, useSelector } from "react-redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory } from "react-router-dom";

import Feedback from "../../../../../Feedback";
import { getProfile } from "../../../../../actions/userActions";
import { getAllVendors } from "../../../../../actions/vendorsActions";
import {
    createReceipt,
    getAllReceipts,
    getAllStores,
    getLocations,
    getAllInventoryItems,
    getAllInventoryCategories,
    getInventoryCategoryItems,
} from "../../../../../actions/inventoryActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        color: "#444",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 10,
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000000,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 300,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const INIT_STATE = {
    employee: "",
    division: "",
    vendor: "",
    orderNo: "",
    documentNo: "",
    store: "",
    location: "",
    totalQuantity: "",
    totalValue: "",
    items: [],
};

const INIT_ITEM = {
    name: "",
    item: "",
    category: "",
    quantityOrdered: "",
    quantityReceived: "",
    unit: '',
    cost: '',
    value: '',
};

const CreateReceiptModal = ({ openCreate, handleCloseCreate, createReceipt }) => {
    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const [state, setState] = useState(INIT_STATE);
    const [item, setItem] = useState(INIT_ITEM);
    const [category, setCategory] = useState("");

    const { profile } = useSelector((state) => state.user);
    const { invItems, invCates, invCateItems } = useSelector((state) => state.inventoryItem);
    const { locations } = useSelector((state) => state.location);
    const { allstores } = useSelector((state) => state.stores);
    const { vendors } = useSelector((state) => state.vendors);
    const { loading, error } = useSelector((state) => state.receipt);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getAllVendors());
        dispatch(getAllReceipts());
        dispatch(getAllStores());
        dispatch(getLocations());
        dispatch(getAllInventoryItems());
        dispatch(getAllInventoryCategories());
        if (category !== "") {
            dispatch(getInventoryCategoryItems(category));
        }
    }, [dispatch, category]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: profile && profile.userName,
            division: profile && profile.division && profile.division._id,
        }));
    }, [profile]);

    useEffect(() => {
        if (state.items.length > 0) {
            let total1 = state.items.reduce((acc, cur) => { return acc + +cur.quantityReceived; }, 0);
            let total2 = state.items.reduce((acc, cur) => { return acc + +cur.value; }, 0);
            setState((prev) => ({
                ...prev,
                totalQuantity: total1,
                totalValue: total2,
            }));
        }
    }, [state.items]);

    const handleChangeCategory = (e) => {
        setCategory(e.target.value);
        setItem((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleItemChange = (e) => {
        e.persist();
        setItem((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleAddItemsArray = (item) => {
        if (item.category !== "" && item.item !== "" && item.cost !== "" && item.quantityReceived !== "" && item.quantityOrdered !== "" && item.value !== "") {
            setState((prev) => ({
                ...prev,
                items: prev.items.concat([item]),
            }));
            setItem(INIT_ITEM);
        }
    };

    const handleRemoveItemsArray = (i) => {
        setState((prev) => ({
            ...prev,
            items: prev.items.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            items: state.items,
            totalQuantity: state.totalQuantity,
            totalValue: state.totalValue,
        };
        const res = await new Promise((resolve, reject) => {
            resolve(createReceipt(data));
        });
        if (res) {
            dispatch(getAllReceipts());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Inventory Receipt Created Successfully.",
                success: true,
            }));
            setTimeout(() => {
                history.push(`/inventory/receipt/edit/${res._id}`);
                handleCloseCreate();
            }, 2000);
        }
    };

    const validCheck = () =>
        !state.division ||
        !state.employee ||
        // !state.vendor ||
        !state.documentNo ||
        !state.orderNo ||
        !state.store ||
        !state.location ||
        (state.items.length === 0) ||
        (state.totalQuantity === 0) ||
        (state.totalValue === 0);

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <Dialog
                    open={openCreate}
                    onClose={handleCloseCreate}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    fullScreen
                    maxWidth="md"
                >
                    <AppBar
                        className={classes.appBar}
                        variant="elevation"
                        position="fixed"
                        color="inherit"
                    >
                        <Toolbar>
                            <Tooltip title="close" arrow>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseCreate}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.container}>
                                <Typography variant="overline" className={classes.title}>
                                    Create Inventory Receipt (Receiving &amp; Inspection)
                                </Typography>

                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Employee"
                                            disabled
                                            name="employee"
                                            value={state.employee || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Document Number"
                                            name="documentNo"
                                            value={state.documentNo || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Order Number"
                                            name="orderNo"
                                            value={state.orderNo || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth variant="outlined" required>
                                            <InputLabel>Vendor </InputLabel>
                                            <Select
                                                inputProps={{
                                                    id: "vendor",
                                                    name: "vendor",
                                                }}
                                                value={state.vendor}
                                                onChange={handleChange}
                                            >
                                                <MenuItem disabled>Select Vendor</MenuItem>
                                                {vendors.map((vendor) => (
                                                    <MenuItem key={vendor._id} value={vendor._id}>
                                                        {vendor.companyName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth variant="outlined" required>
                                            <InputLabel>Store</InputLabel>
                                            <Select
                                                inputProps={{
                                                    id: "store",
                                                    name: "store",
                                                }}
                                                value={state.store}
                                                onChange={handleChange}
                                            >
                                                <MenuItem disabled>Select Store</MenuItem>
                                                {allstores.map((store) => (
                                                    <MenuItem key={store._id} value={store._id}>
                                                        {store.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth variant="outlined" required>
                                            <InputLabel>Location</InputLabel>
                                            <Select
                                                inputProps={{
                                                    id: "location",
                                                    name: "location",
                                                }}
                                                value={state.location}
                                                onChange={handleChange}
                                            >
                                                <MenuItem disabled>Select Location</MenuItem>
                                                {locations.map((location) => (
                                                    <MenuItem key={location._id} value={location._id}>
                                                        {location.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Paper variant="outlined" square className={classes.paper}>
                                    {item ? (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                        >
                                            <Grid item xs={12} md={2}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="category">
                                                        Inventory Category
                                                    </InputLabel>
                                                    <Select
                                                        labelId="category"
                                                        name="category"
                                                        value={item.category || ""}
                                                        onChange={handleChangeCategory}
                                                    >
                                                        <MenuItem disabled>Select</MenuItem>
                                                        {invCates &&
                                                            invCates.map((cate) => (
                                                                <MenuItem key={cate._id} value={cate._id}>
                                                                    {cate.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel id="item">
                                                        Inventory item
                                                    </InputLabel>
                                                    <Select
                                                        labelId="item"
                                                        name="item"
                                                        value={item.item || ""}
                                                        onChange={handleItemChange}
                                                    >
                                                        <MenuItem disabled>Select</MenuItem>
                                                        {invCateItems &&
                                                            invCateItems.map((citem) => (
                                                                <MenuItem key={citem._id} value={citem._id}>
                                                                    {citem.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Quantity Ordered"
                                                    type="number"
                                                    name="quantityOrdered"
                                                    value={item.quantityOrdered || ""}
                                                    onChange={handleItemChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Quantity Received"
                                                    type="number"
                                                    name="quantityReceived"
                                                    value={item.quantityReceived || ""}
                                                    onChange={handleItemChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Item Cost"
                                                    type="number"
                                                    name="cost"
                                                    value={item.cost || ""}
                                                    onChange={handleItemChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Item Value"
                                                    type="number"
                                                    name="value"
                                                    value={item.value || ""}
                                                    onChange={handleItemChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2} >
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel>Unit</InputLabel>
                                                    <Select
                                                        inputProps={{
                                                            id: "unit",
                                                            name: "unit",
                                                        }}
                                                        value={item.unit}
                                                        onChange={handleItemChange}
                                                    >
                                                        <MenuItem disabled>Select Unit</MenuItem>
                                                        <MenuItem value="Packets">Packets</MenuItem>
                                                        <MenuItem value="Reams">Reams</MenuItem>
                                                        <MenuItem value="Kg">Kg</MenuItem>
                                                        <MenuItem value="Cartons">Cartons</MenuItem>
                                                        <MenuItem value="Crates">Crates</MenuItem>
                                                        <MenuItem value="Meters">Meters</MenuItem>
                                                        <MenuItem value="Kilometers">Kilometers</MenuItem>
                                                        <MenuItem value="Pieces">Pieces</MenuItem>
                                                        <MenuItem value="Dozens">Dozens</MenuItem>
                                                        <MenuItem value="Litres">Litres</MenuItem>
                                                        <MenuItem value="Gallons">Gallons</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <Fab
                                                    color="primary"
                                                    onClick={() => handleAddItemsArray(item)}
                                                >
                                                    <AddIcon />
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </Paper>

                                {/* To be filled by the Requestor */}
                                <>
                                    <Typography variant="overline" className={classes.title2}>
                                        Inventory Item
                                    </Typography>

                                    <Paper variant="outlined" square className={classes.paper}>
                                        {state.items.map((sitem, i) => (
                                            <Grid
                                                container
                                                spacing={2}
                                                className={classes.grid}
                                                key={i}
                                            >
                                                <Grid item xs={12} md={2}>
                                                    <FormControl fullWidth required variant="outlined">
                                                        <label style={{ fontWeight: "bold" }}>Inventory Category</label>
                                                        <Select
                                                            labelId="category"
                                                            name="category"
                                                            value={sitem.category || ""}
                                                            disabled
                                                        >
                                                            <MenuItem disabled>Select</MenuItem>
                                                            {invCates &&
                                                                invCates.map((cate) => (
                                                                    <MenuItem key={cate._id} value={cate._id}>
                                                                        {cate.name}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <FormControl fullWidth required variant="outlined">
                                                        <label style={{ fontWeight: "bold" }}>Inventory Item</label>
                                                        <Select
                                                            labelId="item"
                                                            name="item"
                                                            value={sitem.item || ""}
                                                            disabled
                                                        >
                                                            <MenuItem disabled>Select</MenuItem>
                                                            {invItems &&
                                                                invItems.map((nitem) => (
                                                                    <MenuItem key={nitem._id} value={nitem._id}>
                                                                        {nitem.name}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <label style={{ fontWeight: "bold" }}>Quantity Ordered</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        type="text"
                                                        name="quantityOrdered"
                                                        value={sitem.quantityOrdered + " " + sitem.unit || 1}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <label style={{ fontWeight: "bold" }}>Quantity Received</label>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        type="text"
                                                        name="quantityReceived"
                                                        value={sitem.quantityReceived + " " + sitem.unit || ""}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <label style={{ fontWeight: "bold" }}>Item Cost</label>
                                                    <TextField
                                                        name="cost"
                                                        type="number"
                                                        variant="outlined"
                                                        value={sitem.cost}
                                                        disabled
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <label style={{ fontWeight: "bold" }}>Item Value</label>
                                                    <TextField
                                                        name="value"
                                                        type="number"
                                                        variant="outlined"
                                                        value={sitem.value}
                                                        disabled
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={1}>
                                                    <Fab
                                                        color="secondary"
                                                        onClick={() => handleRemoveItemsArray(i)}
                                                    >
                                                        <CloseIcon />
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </Paper>
                                </>

                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={6} />
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Total Quantity Received"
                                            name="totalQuantity"
                                            value={state.totalQuantity || "0"}
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            label="Total Value"
                                            name="totalValue"
                                            value={state.totalValue || "0"}
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                </Grid>

                                <div className={classes.btnWrapper}>
                                    <Button
                                        type="submit"
                                        className={classes.saveBtn}
                                        disabled={loading || validCheck()}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {loading ? "Loading..." : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </form>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </DialogContent>
                </Dialog>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default connect(null, { createReceipt })(CreateReceiptModal);
