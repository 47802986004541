import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { connect, useDispatch } from "react-redux";
import {
  ButtonGroup,
  Typography,
  makeStyles,
  Grid,
  TextField,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../../Feedback";

import {
  getMonthlyBudgetById,
  confirmMonthlyBudget,
} from "../../../../actions/monthlyBudgetAction";
import { useHistory } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const MonthlyConfirmModal = ({
  openConfirm,
  handleCloseConfirm,
  id,
  getMonthlyBudgetById,
  confirmMonthlyBudget,
  error,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [comment, setComment] = useState('');
  const [loading, setLoading] = React.useState(false);
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleChange = (e) => {
    e.persist();
    setComment(e.target.value);
  };

  const handleConfirm = () => {
    setLoading((prev) => !prev);
    const data = {
      id,
      comment,
      path: "budget/monthly/view",
    };
    confirmMonthlyBudget(data)
      .then(() => {
        setLoading(false);
        setFeed((prev) => ({
          ...prev,
          loading: false,
          open: !prev.open,
          message: `You have successfully reviewed this budget.`,
          success: true,
        }));
        dispatch(getMonthlyBudgetById(id));
        handleCloseConfirm();
      })
      .catch(() => {
        setLoading(false);

        setFeed({
          loading: false,
          open: !feed.open,
          // message: error && error.error,
          // success: false,
        });
      })
      .finally(() => {
        handleCloseConfirm();
        setTimeout(() => {
          history.push("/budget/monthly");
        }, 1500);
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
      {error && error.success === false ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={
            feed.message || `You have successfully reviewed this budget.`
          }
        />
      )}
      <Dialog
        open={openConfirm}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseConfirm}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                {" "}
                {"Review budget?"}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseConfirm}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography variant="overline" className={classes.title3}>
              By Clicking "Review", it means you're satisfied with this budget.
            </Typography>
            <TextField
              label="Comment"
              className={classes.textField}
              variant="outlined"
              fullWidth
              value={comment || ""}
              onChange={handleChange}
              helperText="State your reason for Review."
              multiline
              rows={4}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button
              onClick={handleCloseConfirm}
              color="secondary"
              className={classes.saveBtn}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirm}
              color="primary"
              disabled={loading}
              variant="contained"
              className={classes.saveBtn}
            >
              {loading ? "Loading..." : "Review"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.monthlyBudget.error,
});

const mapDispatchToProps = {
  getMonthlyBudgetById,
  confirmMonthlyBudget,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonthlyConfirmModal);
