import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    Divider,
    Icon,
    darken,
    Backdrop,
    ButtonGroup,
    Hidden,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../../Feedback";
import {
    getAssetAcquisitions,
    getAssetAcquisition,
} from "../../../actions/assetAcquisitionActions";
import { getProfile } from "../../../actions/userActions";

// Head of Division
import ApproveAssetAcquisitionModal from "../Modals/Asset/Acquisition/ApproveAssetAcquisitionModal";
import DisapproveAssetAcquisitionModal from "../Modals/Asset/Acquisition/DisapproveAssetAcquisitionModal";

// Finance Head
import ReviewAssetAcquisitionModal from "../Modals/Asset/Acquisition/ReviewAssetAcquisitionModal";
import RejectAssetAcquisitionModal from "../Modals/Asset/Acquisition/RejectAssetAcquisitionModal";

// CEO
import AuthorizeAssetAcquisitionModal from "../Modals/Asset/Acquisition/AuthorizeAssetAcquisitionModal";
import DeauthorizeAssetAcquisitionModal from "../Modals/Asset/Acquisition/DeauthorizeAssetAcquisitionModal";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold"
    },
    title2: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(1),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
            // color: "#fff",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        // fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 480,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    employee: "",
    employeeId:"",
    month: "",
    year: "",
    division: "",
    assetName: "",
    description: "",
    contact: "",
    otherBenefits: "",
    paymentMode: "",
    requestDate: "",
    costs: [],
    earnings: [],
    analysis: [],
};


const INIT_ANALYSIS = [
    {
        label: "Internal Return on Investment Results",
        value: "",
    },
    {
        label: "Net Present Value Results",
        value: "",
    },
    {
        label: "Pay Back Period Results",
        value: "",
    },
    {
        label: "Regulatory Implications",
        value: "",
    },
];

const ViewAssetAcquisition = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    const [analysis, setAnalysis] = useState(INIT_ANALYSIS);

    const [openApprove, setOpenApprove] = useState(false);
    const [openDisapprove, setOpenDisapprove] = useState(false);

    const [openReview, setOpenReview] = useState(false);
    const [openReject, setOpenReject] = useState(false);

    const [openAuthorize, setOpenAuthorize] = useState(false);
    const [openDeauthorize, setOpenDeauthorize] = useState(false);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { profile } = useSelector((state) => state.user);
    const { error, loading, asset_acquisition } = useSelector((state) => state.assetAcquisition);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getAssetAcquisitions());
        dispatch(getAssetAcquisition(id));
        dispatch(getProfile);
    }, [dispatch, id]);

    const isAdmin = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "admin" 
        )
        : false;
        
    const isDivManager = profile
        ? profile && (profile?.id === state.division?.manager)
        : false;

    const isFinanceHead = profile
        ? profile &&
        (profile?.id === "5fb65e3fc521c80017c15e75")
        : false;

    const isCEO = profile
        ? profile &&
        (profile?.id === "5f63554578b9af0017a3dec2")
        : false;
        
    useEffect(() => {
        setState((prev) => ({
            ...prev,
            assetName: (asset_acquisition && asset_acquisition.assetName) || "",
            description: (asset_acquisition && asset_acquisition.description) || "",
            contact: (asset_acquisition && asset_acquisition.contact) || "",
            otherBenefits: (asset_acquisition && asset_acquisition.otherBenefits) || "",
            earnings: (asset_acquisition && asset_acquisition.earnings) || [],
            costs: (asset_acquisition && asset_acquisition.costs) || [],
            analysis: (asset_acquisition && asset_acquisition.analysis) || analysis,
            totalCost: (asset_acquisition && asset_acquisition.totalCost) || "",
            totalCostAverage: (asset_acquisition && asset_acquisition.totalCostAverage) || "",
            totalEarning: (asset_acquisition && asset_acquisition.totalEarning) || "",
            totalEarningAverage: (asset_acquisition && asset_acquisition.totalEarningAverage) || "",

            employee: (asset_acquisition && asset_acquisition.employee) || "",
            division: (asset_acquisition && asset_acquisition.division) || "",
            month: (asset_acquisition && asset_acquisition.month) || "",
            year: (asset_acquisition && asset_acquisition.year) || "",
            requestDate: (asset_acquisition && moment(asset_acquisition.requestDate).format("l")) || "",
            
            status: (asset_acquisition && asset_acquisition.status) || "",
            documentNo: (asset_acquisition && asset_acquisition.documentNo) || "",

            createdBy: (asset_acquisition && asset_acquisition.createdBy) || "",
            createdAt: (asset_acquisition && asset_acquisition.createdAt) || "",

            submittedBy: (asset_acquisition && asset_acquisition.submittedBy) || "",
            submittedAt: (asset_acquisition && asset_acquisition.submittedAt) || "",

            approvedBy: (asset_acquisition && asset_acquisition.approvedBy) || "",
            approvedAt: (asset_acquisition && asset_acquisition.approvedAt) || "",
            approveComment: (asset_acquisition && asset_acquisition.approveComment) || "",

            disapprovedBy: (asset_acquisition && asset_acquisition.disapprovedBy) || "",
            disapprovedAt: (asset_acquisition && asset_acquisition.disapprovedAt) || "",
            disapproveComment: (asset_acquisition && asset_acquisition.disapproveComment) || "",

            reviewedBy: (asset_acquisition && asset_acquisition.reviewedBy) || "",
            reviewedAt: (asset_acquisition && asset_acquisition.reviewedAt) || "",
            reviewComment: (asset_acquisition && asset_acquisition.reviewComment) || "",
            
            rejectedBy: (asset_acquisition && asset_acquisition.rejectedBy) || "",
            rejectedAt: (asset_acquisition && asset_acquisition.rejectedAt) || "",
            rejectComment: (asset_acquisition && asset_acquisition.rejectComment) || "",

            authorizedBy: (asset_acquisition && asset_acquisition.authorizedBy) || "",
            authorizedAt: (asset_acquisition && asset_acquisition.authorizedAt) || "",
            authorizeComment: (asset_acquisition && asset_acquisition.authorizeComment) || "",

            deauthorizedBy: (asset_acquisition && asset_acquisition.deauthorizedBy) || "",
            deauthorizedAt: (asset_acquisition && asset_acquisition.deauthorizedAt) || "",
            deauthorizeComment: (asset_acquisition && asset_acquisition.deauthorizeComment) || "",
        }));

    }, [asset_acquisition, analysis]);

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    // head of subdivision
    const handleOpenApprove = () => {
        setOpenApprove(true);
    };
    const handleCloseApprove = () => {
        setOpenApprove(false);
    };
    const handleOpenDisapprove = () => {
        setOpenDisapprove(true);
    };
    const handleCloseDisapprove = () => {
        setOpenDisapprove(false);
    };

    // finance head
    const handleOpenReview = () => {
        setOpenReview(true);
    };
    const handleCloseReview = () => {
        setOpenReview(false);
    };
    const handleOpenReject = () => {
        setOpenReject(true);
    };
    const handleCloseReject = () => {
        setOpenReject(false);
    };
    
    // CEO
    const handleOpenAuthorize = () => {
        setOpenAuthorize(true);
    };
    const handleCloseAuthorize = () => {
        setOpenAuthorize(false);
    };
    const handleOpenDeauthorize = () => {
        setOpenDeauthorize(true);
    };
    const handleCloseDeauthorize = () => {
        setOpenDeauthorize(false);
    };

    // earning
    const handleAnalysisChange = (i) => (e) => {
        e.persist();
        const newAnalysis = analysis.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setAnalysis(newAnalysis);
    };

    const analysisCheck = () => analysis && analysis.some(item => Object.values(item).some(value => value === ''));

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}

            {/* Head of subdivision */}
            <ApproveAssetAcquisitionModal {...{ openApprove, handleCloseApprove, id }} />
            <DisapproveAssetAcquisitionModal {...{ openDisapprove, handleCloseDisapprove, id }} />

            {/* Finance Head */}
            <ReviewAssetAcquisitionModal {...{ openReview, handleCloseReview, id, analysis }} />
            <RejectAssetAcquisitionModal {...{ openReject, handleCloseReject, id }} />

            {/* CEO */}
            <AuthorizeAssetAcquisitionModal {...{ openAuthorize, handleCloseAuthorize, id }} />
            <DeauthorizeAssetAcquisitionModal {...{ openDeauthorize, handleCloseDeauthorize, id }} />            

            <Typography variant="overline" className={classes.title}>
                View Asset Acquisition Request
            </Typography>
            <Grid container justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.backBtn}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.push("/asset/acquisition")}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <Paper variant="outlined" className={classes.status}>
                        <Typography variant="overline" className={classes.statusTitle}>
                            Document No.
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        {loading ? "Loading..." : state.documentNo}
                    </Paper>
                </Grid>
            </Grid>

            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
                style={{ marginBottom: 20 }}
            >
                <Hidden smDown>
                    <Grid item></Grid>
                </Hidden>
                <Grid item>
                    <Paper
                        variant="outlined"
                        className={clsx(
                            (state.status === "draft" || state.status === "rejected" || state.status === "deauthorized" || state.status === "disapproved")
                                ? classes.statusYellow
                                : state.status === "submitted"
                                    ? classes.statusOrange
                                    : (state.status === "approved") ? classes.statusGreen
                                        : (state.status === "reviewed" || state.status === "authorized")
                                            ? classes.statusDarkGreen
                                            : classes.statusRed,
                            classes.status
                        )}
                    >
                        <Typography variant="overline" className={classes.statusTitle}>
                            Status
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        <Typography variant="overline" className={classes.statusTitle}>
                            {loading ? "Loading..." : state.status}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.grid}>
                <Grid item xs={12} md={3}>
                    <label style={{ fontWeight: "bold" }}>Employee</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        value={(state.createdBy && state.createdBy.userName) || ""}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <label style={{ fontWeight: "bold" }}>Division/Subdivision</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        value={(state.division && state.division.name) || ""}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <label style={{ fontWeight: "bold" }}>Document No</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={state.documentNo || ""}
                        disabled
                        required
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <label style={{ fontWeight: "bold" }}>Request Date</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        value={state.requestDate || ""}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <label style={{ fontWeight: "bold" }}>Asset Name</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        name="assetName"
                        value={(state.assetName && state.assetName) || ""}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <label style={{ fontWeight: "bold" }}>Contact</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        name="contact"
                        value={(state.contact && state.contact) || ""}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <label style={{ fontWeight: "bold" }}>Description</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        name="description"
                        value={(state.description && state.description) || ""}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <label style={{ fontWeight: "bold" }}>Other Material Benefits</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        multiline
                        disabled
                        rows={4}
                        name="otherBenefits"
                        value={(state.otherBenefits && state.otherBenefits) || ""}
                    />
                </Grid>
            </Grid>


            {(state.costs && state.costs.length > 0) ? <>

            <Typography variant="overline" className={classes.title2}>
                Costs
            </Typography>

            <Paper variant="outlined" square className={classes.paper}>
                {state.costs ? state.costs.map((sCost, i) => (
                    <Grid
                        container
                        spacing={2}
                        className={classes.grid}
                        style={{paddingBottom:0}}
                        key={i}
                    >
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Cost"
                                name="cost"
                                value={sCost.cost || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Useful Life"
                                name="usefullife"
                                value={(sCost.usefullife && sCost.usefullife) || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Years"
                                name="years"
                                value={sCost.years || ""}
                                type="number"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Average Naira/Year"
                                name="average"
                                type="number"
                                value={sCost.average || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Total"
                                name="total"
                                value={sCost.total || ""}
                                disabled
                            />
                        </Grid>
                    </Grid>
                )) : null }
            </Paper> 

            <Grid container spacing={2} className={classes.grid}>
                <Grid item xs={12} md={8} />
                <Grid item xs={12} md={2}>
                    <TextField
                        label="Total Average Cost"
                        name="totalCostAverage"
                        value={state.totalCostAverage || '0'}
                        variant="outlined"
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField
                        label="Total Cost"
                        name="totalCost"
                        value={state.totalCost || '0'}
                        variant="outlined"
                        fullWidth
                        disabled
                    />
                </Grid>
            </Grid>
            </> : null}

            {(state.earnings && state.earnings.length > 0) ? <>

            <Typography variant="overline" className={classes.title2}>
                Projected Earnings/Income
            </Typography>

            <Paper variant="outlined" square className={classes.paper}>
                {state.earnings ? state.earnings.map((sEarning, i) => (
                    <Grid
                        container
                        spacing={2}
                        className={classes.grid}
                        style={{paddingBottom:0}}
                        key={i}
                    >
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Cost"
                                name="cost"
                                value={sEarning.earning || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Useful Life"
                                name="usefullife"
                                value={(sEarning.usefullife && sEarning.usefullife) || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Years"
                                name="years"
                                value={sEarning.years || ""}
                                type="number"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Average Naira/Year"
                                name="average"
                                value={sEarning.average || ""}
                                disabled
                                />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Total"
                                name="total"
                                value={sEarning.total || ""}
                                type="number"
                                disabled
                            />
                        </Grid>
                    </Grid>
                )) : null }
            </Paper> 
            
            <Grid container spacing={2} className={classes.grid}>
                <Grid item xs={12} md={8} />
                <Grid item xs={12} md={2}>
                    <TextField
                        label="Total Average Earning"
                        name="totalEarningAverage"
                        value={state.totalEarningAverage || '0'}
                        variant="outlined"
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <TextField
                        label="Total Earning"
                        name="totalEarning"
                        value={state.totalEarning || '0'}
                        variant="outlined"
                        fullWidth
                        disabled
                    />
                </Grid>
            </Grid>
            </> : null}

            <Typography variant="overline" className={classes.title2}>
                Profit Analysis
            </Typography>

            <Paper variant="outlined" square className={classes.paper}>
                <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={12} md={2}>
                        <TextField
                            label="Total Earning"
                            value={state.totalEarning || '0'}
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            label="Total Cost"
                            value={state.totalCost || '0'}
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <TextField
                            label="Profit / Loss"
                            name="profit"
                            value={(state.profit ? state.profit : (state.totalEarning - state.totalCost)) || '0'}
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6} />
                </Grid>
            </Paper> 

            {((state.status !== "approved") && state.analysis && state.analysis.length > 0) ? <>

            <Typography variant="overline" className={classes.title2}>
                Financial Analysis
            </Typography>

            <Paper variant="outlined" square className={classes.paper}>
                {state.analysis ? state.analysis.map((analys, i) => (
                    <Grid
                        container
                        spacing={2}
                        className={classes.grid}
                        style={{paddingBottom:0}}
                        key={i}
                    >
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Label"
                                name="label"
                                value={analys.label || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Value"
                                name="value"
                                value={analys.value || ""}
                                disabled
                            />
                        </Grid>
                    </Grid>
                )) : null }
            </Paper> 
            </> : null}

            {((isFinanceHead || isAdmin) && (state.status === "approved") && analysis) ? <>

            <Typography variant="overline" className={classes.title2}>
                Financial Analysis
            </Typography>

            <Paper variant="outlined" square className={classes.paper}>
                {analysis && analysis.map((analys, i) => (
                    <Grid
                        container
                        spacing={2}
                        className={classes.grid}
                        style={{paddingBottom:0}}
                        key={i}
                    >
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Label"
                                name="label"
                                value={analys.label || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Value"
                                name="value"
                                value={analys.value || ""}
                                onChange={handleAnalysisChange(i)}
                            />
                        </Grid>
                    </Grid>            
                )) }
            </Paper> 
            </> : null}
            
            <Grid
                container
                alignItems="center"
                wrap="nowrap"
                spacing={1}
                style={{ marginBottom: 5 }}
            >
                <Grid item>
                    <Typography variant="overline" className={classes.title2}>
                        Audit Trail
                    </Typography>
                </Grid>
                <Grid item>
                    <Icon className="fas fa-clipboard-list" color="disabled" />
                </Grid>
            </Grid>
            <Paper variant="outlined" square className={classes.paper}>
                <Grid container justify="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Paper
                            className={classes.auditContainer}
                            component="div"
                            elevation={1}
                            variant="outlined"
                            square
                        >
                            <span className={classes.auditTitle}>Created By</span>
                            <Divider orientation="vertical" flexItem />
                            <span className={classes.audit}>{state.createdBy && state.createdBy.userName}</span>
                            <Divider orientation="vertical" flexItem />
                            <span className={classes.audit}>{state.createdAt}</span>
                        </Paper>
                    </Grid>
                    
                    <Grid item xs={12} md={12}>
                        <Paper
                            className={classes.auditContainer}
                            component="div"
                            elevation={1}
                            variant="outlined"
                            square
                        >
                            <span className={classes.auditTitle}>Submitted By</span>
                            <Divider orientation="vertical" flexItem />
                            <span className={classes.audit}>{state.submittedBy && state.submittedBy.userName}</span>
                            <Divider orientation="vertical" flexItem />
                            <span className={classes.audit}>{state.submittedAt}</span>
                        </Paper>
                    </Grid>

                    {(state.approvedAt && state.approvedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Approved By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.approvedBy && state.approvedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.approvedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.approveComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.disapprovedAt && state.disapprovedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Disapproved By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.disapprovedBy && state.disapprovedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.disapprovedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.disapproveComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}

                    {(state.reviewedAt && state.reviewedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Reviewed By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.reviewedBy && state.reviewedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.reviewedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.reviewComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.rejectedAt && state.rejectedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Rejected By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.rejectedBy && state.rejectedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.rejectedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.rejectComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}

                    {(state.authorizedAt && state.authorizedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Authorized By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.authorizedBy && state.authorizedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.authorizedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.authorizeComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.deauthorizedAt && state.deauthorizedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Declined By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.deauthorizedBy && state.deauthorizedBy.userName}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.deauthorizedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.deauthorizeComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Paper>

            {/* ACTION BUTTONS */}
            {(isDivManager || isAdmin) && state.status === "submitted" ? (
                <Grid container justify="center">
                    <Grid item>
                        <div className={classes.centered}>
                            <Paper className={classes.paperBlack}>
                                <HelpOutlineIcon fontSize="large" />
                                <Typography placeholder="Info" className={classes.info2}>
                                    This asset acquisition approval or disapproval is to be done by the Head of the 
                                    Requesting Subdivision.
                                </Typography>
                            </Paper>
                        </div>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenApprove}
                                className={classes.saveBtn2}
                                variant="contained"
                            >
                                Approve
                            </Button>
                            <Button
                                onClick={handleOpenDisapprove}
                                className={classes.saveBtn}
                                color="secondary"
                                variant="contained"
                            >
                                Disapprove
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : null}

            {(isFinanceHead || isAdmin) && state.status === "approved" ? (
                <Grid container justify="center">
                    <Grid item>
                        <div className={classes.centered}>
                            <Paper className={classes.paperBlack}>
                                <HelpOutlineIcon fontSize="large" />
                                <Typography placeholder="Info" className={classes.info2}>
                                    This asset acquisition review or rejection is to be done by the Finance Manager. Please complete the financial analysis section before submitting your review.
                                </Typography>
                            </Paper>
                        </div>
                        <ButtonGroup>
                            <Button
                                className={classes.saveBtn}
                                color="primary"
                                variant="contained"
                                disabled={loading || analysisCheck()}
                                onClick={handleOpenReview}
                            >
                                Review
                            </Button>
                            <Button
                                onClick={handleOpenReject}
                                className={classes.saveBtn}
                                color="secondary"
                                variant="contained"
                                disabled={loading}
                            >
                                Reject
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : null}

            {(isCEO || isAdmin) && state.status === "reviewed" ? (
                <Grid container justify="center">
                    <Grid item>
                        <div className={classes.centered}>
                            <Paper className={classes.paperBlack}>
                                <HelpOutlineIcon fontSize="large" />
                                <Typography placeholder="Info" className={classes.info2}>
                                    This asset acquisition authorization or non-authorization is to be done by the CEO.
                                </Typography>
                            </Paper>
                        </div>
                        <ButtonGroup>
                            <Button
                                color="primary"
                                onClick={handleOpenAuthorize}
                                className={classes.saveBtn2}
                                variant="contained"
                                disabled={loading}
                            >
                                Authorize
                            </Button>
                            <Button
                                onClick={handleOpenDeauthorize}
                                className={classes.saveBtn}
                                color="secondary"
                                variant="contained"
                                disabled={loading}
                            >
                                Not Authorize
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : null}

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default ViewAssetAcquisition;
