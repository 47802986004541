import React, { useState, useEffect } from "react";
import {
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    AppBar,
    IconButton,
    Dialog,
    Toolbar,
    Tooltip,
    DialogContent,
    Backdrop,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { connect, useDispatch, useSelector } from "react-redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory } from "react-router-dom";
import Feedback from "../../../../Feedback";
import { setupVendor, getAllVendors, getAllProducts, getServiceCategories } from "../../../../actions/vendorsActions";
import { countries } from "../../../../utils";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#fff",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 10,
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000000,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 300,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const INIT_STATE = {
    companyName: "",
    officeAddress: "",
    email: "",
    city: "",
    state: "",  
    companyTelephone: "",
    website: "",
    contactPerson: "",
    contactDesignation: "",
    contactTelephone: "",
    contactEmail: "",
    businessCategory:"",
    relatedProduct:"",
  };

const SetUpVendorModal = ({ openCreate, handleCloseCreate, setupVendor }) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [country, setCountry] = useState(null);
    const [state, setState] = useState(INIT_STATE);
    const [loading, setLoading] = useState(false);
    const { products, services } = useSelector((state) => state.vendors);

    useEffect(() => {
        dispatch(getServiceCategories());
        dispatch(getAllProducts());
    }, [dispatch]);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const handleCountry = (e, country) => {
        setCountry(country);
    };

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            country: country && country.label,
            password: "Password@123",
            path: "setvendorpassword",
        };

        if(data.email && data.companyName && data.companyTelephone && data.contactPerson && data.country){ 
            setLoading((prev) => !prev);

            const res = await new Promise((resolve, reject) => {
                resolve(setupVendor(data));
            });
        
            if (res) {
                dispatch(getAllVendors());
                setLoading(false);
                setFeed((prev) => ({
                    loading: false,
                    open: !prev.open,
                    message: "Vendor Account Created Successfully.",
                    success: true,
                }));
                setTimeout(() => {
                    history.push(`/quality/vendors`);
                    handleCloseCreate();
                }, 2000);
            }
        }
    };

    const validCheck = () =>
        !state.email ||
        !state.companyName ||
        !state.companyTelephone ||
        !state.state ||
        !state.city || 
        !country || 
        !state.website || 
        !state.contactDesignation || 
        !state.contactEmail || 
        !state.contactPerson || 
        !state.contactTelephone ||  
        !state.businessCategory;
    
    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <Dialog
                    open={openCreate}
                    onClose={handleCloseCreate}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    fullScreen
                    maxWidth="md"
                >
                    <AppBar
                        className={classes.appBar}
                        variant="elevation"
                        position="fixed"
                        color="inherit"
                    >
                        <Toolbar>
                            <Tooltip title="close" arrow>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseCreate}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.container}>
                                <Typography variant="overline" className={classes.title}>
                                    Create Vendor Account
                                </Typography>

                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            id="companyName"
                                            name="companyName"
                                            label="Company Name"
                                            onChange={handleChange}
                                            value={state.companyName || ""}
                                            fullWidth
                                            required
                                            placeholder="Company Name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            id="email"
                                            type="email"
                                            name="email"
                                            label="Email"
                                            onChange={handleChange}
                                            value={state.email || ""}
                                            fullWidth
                                            required
                                            placeholder="Email"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            id="officeAddress"
                                            name="officeAddress"
                                            label="Office Address"
                                            onChange={handleChange}
                                            value={state.officeAddress || ""}
                                            fullWidth
                                            required
                                            placeholder="Office Address"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            id="city"
                                            name="city"
                                            label="City"
                                            value={state.city || ""}
                                            onChange={handleChange}
                                            fullWidth
                                            required
                                            placeholder="City"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            id="state"
                                            name="state"
                                            label="State"
                                            value={state.state || ""}
                                            onChange={handleChange}
                                            fullWidth
                                            required
                                            placeholder="State"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Autocomplete
                                            fullWidth
                                            id="combo-box-demo"
                                            options={countries}
                                            value={country}
                                            name="country"
                                            onChange={handleCountry}
                                            getOptionLabel={(option) => option.label}
                                            renderOption={(option) => (
                                                <React.Fragment>{option.label}</React.Fragment>
                                            )}
                                            autoComplete
                                            renderInput={(params) => (
                                                <TextField
                                                {...params}
                                                label="Country"
                                                variant="outlined"
                                                required
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: "disabled",
                                                    name: "country",
                                                }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            id="companyTelephone"
                                            name="companyTelephone"
                                            label="Company Telephone"
                                            fullWidth
                                            required
                                            value={state.companyTelephone || ""}
                                            onChange={handleChange}
                                            placeholder="Company Telephone"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            id="website"
                                            name="website"
                                            label="Website"
                                            fullWidth
                                            required
                                            value={state.website || ""}
                                            onChange={handleChange}
                                            placeholder="Website"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            id="contactPerson"
                                            name="contactPerson"
                                            label="Contact Person"
                                            fullWidth
                                            required
                                            onChange={handleChange}
                                            value={state.contactPerson || ""}
                                            placeholder="Contact Person"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            id="contactDesignation"
                                            name="contactDesignation"
                                            label="Designation"
                                            fullWidth
                                            required
                                            placeholder="Designation"
                                            value={state.contactDesignation || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            id="contactTelephone"
                                            name="contactTelephone"
                                            label="Contact Telephone"
                                            fullWidth
                                            required
                                            placeholder="Contact Telephone"
                                            value={state.contactTelephone || ""}
                                            onChange={handleChange}
                                            type="tel"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            type="email"
                                            id="contactEmail"
                                            name="contactEmail"
                                            label="Contact Email"
                                            fullWidth
                                            placeholder="Contact Email"
                                            value={state.contactEmail || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth variant="outlined" required>
                                            <InputLabel id="business_category">
                                                Business Category
                                            </InputLabel>
                                            <Select
                                                labelId="business_category"
                                                id="businessCategory"
                                                name="businessCategory"
                                                inputProps={{
                                                    id: "businessCategory",
                                                    name: "businessCategory",
                                                }}
                                                value={state.businessCategory || ""}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value="" disabled>
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value="products">Products</MenuItem>
                                                <MenuItem value="services">Services</MenuItem>
                                                <MenuItem value="productsAndServices">
                                                    Products & Services
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {state.businessCategory === "products" ||
                                        state.businessCategory === "productsAndServices" ? (
                                        <Grid item xs={12} md={4}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="related_product_label">
                                                    Related Product
                                                </InputLabel>
                                                <Select
                                                labelId="related_product_label"
                                                id="relatedProduct"
                                                name="relatedProduct"
                                                inputProps={{
                                                    id: "relatedProduct",
                                                    name: "relatedProduct",
                                                }}
                                                value={state.relatedProduct || ""}
                                                onChange={handleChange}
                                                >
                                                <MenuItem value="" disabled>
                                                    <em>None</em>
                                                </MenuItem>
                                                {products &&
                                                    products.map((product) => (
                                                    <MenuItem key={product._id} value={product.name}>
                                                        {product.name}
                                                    </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        ) : null}

                                        {state.businessCategory === "services" ||
                                        state.businessCategory === "productsAndServices" ? (
                                        <Grid item xs={12} md={4}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel id="related_service_label">
                                                Related Service
                                                </InputLabel>
                                                <Select
                                                labelId="related_service_label"
                                                id="relatedService"
                                                name="relatedService"
                                                inputProps={{
                                                    id: "relatedService",
                                                    name: "relatedService",
                                                }}
                                                value={state.relatedService || ""}
                                                onChange={handleChange}
                                                >
                                                {services &&
                                                    services.map((service) => (
                                                    <MenuItem key={service._id} value={service.name}>
                                                        {service.name}
                                                    </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        ) : null}
                                </Grid>

                                <div className={classes.btnWrapper}>
                                    <Button
                                        type="submit"
                                        className={classes.saveBtn}
                                        disabled={loading || validCheck()}
                                        color="primary"
                                        variant="contained"
                                        endIcon={loading ? <CircularProgress size={20} /> : null}
                                    >
                                        {loading ? "Loading..." : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </form>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </DialogContent>
                </Dialog>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default connect(null, { setupVendor })(SetUpVendorModal);
