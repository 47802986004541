import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  ButtonGroup,
  Button,
  DialogActions,
  makeStyles,
  Grid,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import Feedback from "../../../../Feedback";
import { useHistory } from "react-router-dom";
import {
  getCashAdvanceById,
  submitCashAdvance,
} from "../../../../actions/cashAdvanceAction";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const SubmitCashAdvanceModal = ({
  openSubmit,
  handleCloseSubmit,
  id,
  error,
  getCashAdvanceById,
  submitCashAdvance,
}) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const submitAdvance = async () => {
    setLoading((prev) => !prev);

    let data = {
      id,
      path: "cashadvance/view",
    };

    try {
      const doc = await new Promise((resolve, _) => {
        resolve(submitCashAdvance(data));
      });
      if (doc.success === true) {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message: `Cash Advance Submitted Successfully.`,
          success: true,
        });

        getCashAdvanceById(id);
        if (doc.success) {
          setTimeout(() => {
            history.push("/cashadvance");
          }, 3000);
        }
        handleCloseSubmit();
      } else {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message: doc.error,
          success: false,
        });
        handleCloseSubmit();
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setFeed({
        loading: false,
        open: !feed.open,
        message: err.error,
        success: false,
      });
      handleCloseSubmit();
    }

    // try {
    //   const res = await new Promise((resolve, reject) => {
    //     resolve(submitCashAdvance(data));
    //   });
    //   const doc = res;

    //   if (doc) {
    //     setLoading(false);
    //     setFeed({
    //       loading: false,
    //       open: !feed.open,
    //       message: `Cash Advance Submitted Successfully.`,
    //       success: true,
    //     });

    //     getCashAdvanceById(id);
    //     if (doc.success) {
    //       setTimeout(() => {
    //         history.push("/cashadvance");
    //       }, 2000);
    //     }
    //   }
    // } catch (error) {
    //   console.log(error);
    //   setLoading(false);

    //   setFeed({
    //     loading: false,
    //     open: !feed.open,
    //     message: error.error,
    //     success: false,
    //   });
    // }

    // submitCashAdvance(data)
    //   .then((doc) => {
    //     if (doc === undefined) {
    //       setFeed({
    //         loading: false,
    //         open: !feed.open,
    //         // message: `An Error has occured, failed to submit Annual Budget`,
    //         success: false,
    //       });
    //     } else {
    //       console.log(doc);
    //       setLoading(false);
    //       setFeed({
    //         loading: false,
    //         open: !feed.open,
    //         // message: `Cash Advance Submitted Successfully.`,
    //         success: true,
    //       });

    //       dispatch(getCashAdvanceById(id));
    //       setTimeout(() => {
    //         history.push("/cashadvance");
    //       }, 2000);
    //     }
    //   })
    //   .catch(() => {
    //     setLoading(false);
    //     handleCloseSubmit();
    //     // setFeed({
    //     //   loading: false,
    //     //   open: !feed.open,
    //     //   // message: `An Error has occured, failed to submit Annual Budget`,
    //     //   success: false,
    //     // });
    //   });
    // // .finally(() => {
    // //   handleCloseSubmit();
    // //   console.log(err);
    // //   // setTimeout(() => {
    // //   //   history.push("/cashadvance");
    // //   // }, 2000);
    // // });
  };

  return (
    <div>
      {/* {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message || "Cash Advance Submitted Successfully."}
        />
      )} */}

      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}

      <Dialog
        open={openSubmit}
        onClose={handleCloseSubmit}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Submit Cash Advance?
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseSubmit}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="overline" className={classes.title3}>
            Note: Once submitted, it's assumed you're done preparing this cash
            advance and won't be available for modification except if rejected.
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button
              color="secondary"
              className={classes.saveBtn}
              onClick={handleCloseSubmit}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={loading}
              onClick={submitAdvance}
              variant="contained"
              className={classes.saveBtn}
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.cashAdvance.cashAdvanceError,
});

const mapDispatchToProps = {
  getCashAdvanceById,
  submitCashAdvance,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitCashAdvanceModal);
