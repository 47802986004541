import React from "react";

import { connect } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  uploadPhoto,
  getProfile,
  updateProfile,
  updateUserPassword,
} from "../../actions/userActions";
import { UsersView } from "./AccountViews/UsersView";
import { VendorsView } from "./AccountViews/VendorsView";
import {
  uploadVendorPhoto,
  updateVendorProfile,
  updateVendorPassword,
  getVendorProfile,
} from "../../actions/vendorsActions";

const MyAccount = ({
  profile,
  vendor,
  uploadPhoto,
  getProfile,
  updateProfile,
  updateUserPassword,
  uploadVendorPhoto,
  updateVendorProfile,
  updateVendorPassword,
  getVendorProfile,
  message,
  error,
}) => {
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    setLoader((prev) => !prev);
    setTimeout(() => {
      setLoader(false);
    }, 4000);
  }, []);

  return (
    <div>
      {profile && profile.userName ? (
        loader ? (
          <>
            <Skeleton
              variant="text"
              width={120}
              height={20}
              style={{
                background: "#e0e0e0",
                padding: 20,
                borderRadius: 3,
                marginBottom: 10,
              }}
            />
            <Skeleton
              variant="rectangular"
              width="100%"
              height={418}
              style={{ background: "#e0e0e0", padding: 10, borderRadius: 3 }}
            />
          </>
        ) : (
          <UsersView
            profile={profile}
            uploadPhoto={uploadPhoto}
            getProfile={getProfile}
            updateProfile={updateProfile}
            updateUserPassword={updateUserPassword}
            message={message}
            error={error}
          />
        )
      ) : loader ? (
        <>
          <Skeleton
            variant="text"
            width={120}
            height={20}
            style={{
              background: "#e0e0e0",
              padding: 20,
              borderRadius: 3,
              marginBottom: 10,
            }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={418}
            style={{ background: "#e0e0e0", padding: 10, borderRadius: 3 }}
          />
        </>
      ) : (
        <VendorsView
          vendor={vendor}
          uploadVendorPhoto={uploadVendorPhoto}
          updateVendorPassword={updateVendorPassword}
          updateVendorProfile={updateVendorProfile}
          getVendorProfile={getVendorProfile}
          error={error}
        />
      )}
      {/* {vendor && <VendorsView vendor={vendor} />} */}
    </div>
  );
};

/* Users View */

/* ---------------------------------------------- Vendors View --------------------------------------- */

const mapStateToProps = (state) => ({
  profile: state.user.profile,
  vendor: state.vendors.vendor,
  message: state.user.message,
  error: state.error.error,
});

const mapDispatchToProps = {
  uploadPhoto,
  getProfile,
  updateProfile,
  updateUserPassword,
  uploadVendorPhoto,
  updateVendorPassword,
  updateVendorProfile,
  getVendorProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
