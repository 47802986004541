import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    AppBar,
    IconButton,
    Dialog,
    Toolbar,
    Tooltip,
    InputAdornment,
    DialogContent,
    Divider,
    Backdrop,
    InputLabel,
    FormControl,
    MenuItem,
    Select,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import { connect, useDispatch, useSelector } from "react-redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import Feedback from "../../../../Feedback";
import { getProfile } from "../../../../actions/userActions";
import { getAllAllCwsrs } from "../../../../actions/cwsrActions";
import { getCustomerOrders } from "../../../../actions/salesOrderActions";
import { createProjectMob, getProjectMobs } from "../../../../actions/projectMobActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        color: "#444",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000000,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 10,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 300,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const INIT_STATE = {
    projectTitle: "",
    assetLocation: "",
    projectName: "",
    projectScope: "",
    projectDuration: "",
    cwsr: "",
    cwsrId:"",
    jobNumber: "",
    pdmnNumber:"",
    validation:"",
    purchaseOrderNumber: "",
    employee: "",
    division: "",
    tenMobilizationDate: "",
    mobilizationDate: "",
    serviceDivision: "",
    serviceType:"",
    contactPerson: "",
    contactPersonEmail: "",
    contactPersonJob: "",
    contactPersonPhone: "",
};

const CreateMobModal = ({ openCreate, handleCreateClose, createProjectMob }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });
    const [dateCreated, setDateCreated] = useState(new Date());
    const [state, setState] = useState(INIT_STATE);
    
    const { profile } = useSelector((state) => state.user);
    const { allcwsrs } = useSelector((state) => state.cwsr);
    const { loading, error } = useSelector((state) => state.projectMob);
    const { customer_orders } = useSelector((state) => state.salesorder);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getAllAllCwsrs());
    }, [dispatch]);

    useEffect(() => {
        if (state.cwsr) {
            setState((prev) => ({
                ...prev,
                cwsrId: state.cwsr && state.cwsr._id,
                customer: state.cwsr && state.cwsr.customer && state.cwsr.customer?._id,
                customerName: state.cwsr && state.cwsr.customer && state.cwsr.customer?.name,
                contactPerson: state.cwsr && state.cwsr.contactPerson,
                contactPersonEmail: state.cwsr && state.cwsr.contactPersonEmail,
                contactPersonJob: state.cwsr && state.cwsr.contactPersonJob,
                contactPersonPhone: state.cwsr && state.cwsr.contactPersonPhone,
                serviceRequirement: state.cwsr && state.cwsr.serviceRequirement,
                serviceLocation: state.cwsr && state.cwsr.serviceLocation,
                serviceDuration: state.cwsr && state.cwsr.serviceDuration,
                serviceType: state.cwsr && state.cwsr.serviceType,
                serviceMethod: state.cwsr && state.cwsr.serviceMethod,
                serviceDivision: state.cwsr && state.cwsr.serviceDivision,
                tenMobilizationDate: (state.cwsr && moment(state.cwsr.mobilizationDate).format("l")) || "",
            }))
        }
    }, [state.cwsr]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: profile && profile._id,
            division: profile && profile.division && profile.division.name,
            jobTitle: profile && profile.jobTitle,
            employeeId: profile && profile.employeeId,
        }));
    }, [profile]);

    useEffect(() => {
        if(state.customer !== ""){
            dispatch(getCustomerOrders(state.customer));
        }
    }, [state, dispatch]);

    const handleChangeDate = (date) => {
        setDateCreated(date);
    };

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const isCommercials = profile
        ? profile &&
        profile.division &&
        profile?.division?.name === "Commercials"
        : false;

    const isAdmin = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "admin" 
        )
        : false;
    
    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            projectTitle: state.projectTitle && state.projectTitle,
            assetLocation: state.assetLocation && state.assetLocation,
            projectName: state.projectName && state.projectName,
            projectScope: state.projectScope && state.projectScope,
            projectDuration: state.projectDuration && state.projectDuration,
            cwsr: state.cwsrId && state.cwsrId,
            jobNumber: state.jobNumber && state.jobNumber,
            pdmnNumber: state.pdmnNumber && state.pdmnNumber,
            validation: state.validation && state.validation,
            purchaseOrderNumber: state.purchaseOrderNumber && state.purchaseOrderNumber,
            employee: state.employee && state.employee,
            division: profile && profile.division._id,
            jobTitle: profile && profile.jobTitle._id,
            mobilizationDate: dateCreated,
        };
        const res = await new Promise((resolve, reject) => {
            resolve(createProjectMob(data));
        });
        console.log(res)
        dispatch(getProjectMobs());
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Project Mobilization Notice Created Successfully.",
            success: true,
        }));
        handleCreateClose();
    };

    const validCheck = () =>
        !state.projectTitle || !state.projectName || !dateCreated ||
        !state.projectScope || !state.projectDuration || !state.cwsr || !state.jobNumber || !state.pdmnNumber || !state.purchaseOrderNumber ||
        !state.division || !state.jobTitle || !state.employee;

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <Dialog
                    open={openCreate}
                    onClose={handleCreateClose}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    fullScreen
                    maxWidth="md"
                >
                    <AppBar
                        className={classes.appBar}
                        variant="elevation"
                        position="fixed"
                        color="inherit"
                    >
                        <Toolbar>
                            <Tooltip title="close" arrow>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCreateClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.container}>
                                <Typography variant="overline" className={classes.title}>
                                    Create Project Mobilization Notice
                                </Typography>

                                <Typography variant="overline" className={classes.title2}>
                                    CWSR Details
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper}>
                                    <Grid
                                        container
                                        justify="center"
                                        alignItems="center"
                                        spacing={2}
                                        className={classes.grid}
                                    >
                                        <Grid item xs={12} md={4}>
                                            <Autocomplete
                                                id="cwsr"
                                                options={allcwsrs}
                                                value={state.cwsr}
                                                debug
                                                onChange={(event, newValue) => {
                                                    setState((prev) => ({
                                                        ...prev,
                                                        cwsr: newValue,
                                                    }));
                                                }}
                                                getOptionLabel={(option) => option.serviceRequirement || ""}
                                                renderOption={(option) => (
                                                    <React.Fragment>{option.serviceRequirement}</React.Fragment>
                                                )}
                                                renderInput={(params) => (
                                                    <>
                                                        <TextField
                                                            {...params}
                                                            placeholder="Type something..."
                                                            fullWidth
                                                            required
                                                            label="Select CWSR"
                                                            variant="outlined"
                                                            value={(state.cwsr && state.cwsr) || ""}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "disabled",
                                                                name: "cwsr",
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="customerName"
                                                name="customerName"
                                                label="Customer Name"
                                                value={state.customerName || ""}
                                                onChange={handleChange}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled
                                                />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="contactPerson"
                                                name="contactPerson"
                                                label="Contact Person"
                                                value={state.contactPerson || ""}
                                                onChange={handleChange}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="contactPersonEmail"
                                                name="contactPersonEmail"
                                                label="Contact Person Email"
                                                value={state.contactPersonEmail || ""}
                                                onChange={handleChange}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="contactPersonPhone"
                                                name="contactPersonPhone"
                                                label="Contact Person Phone"
                                                value={state.contactPersonPhone || ""}
                                                onChange={handleChange}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="serviceDivision"
                                                name="serviceDivision"
                                                label="Service Division"
                                                value={state.serviceDivision?.name || ""}
                                                onChange={handleChange}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="serviceLocation"
                                                name="serviceLocation"
                                                label="Service Location"
                                                value={state.serviceLocation || ""}
                                                onChange={handleChange}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="serviceType"
                                                name="serviceType"
                                                label="Service Type"
                                                value={state.serviceType || ""}
                                                onChange={handleChange}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                id="tenMobilizationDate"
                                                name="tenMobilizationDate"
                                                label="Tentative Mobilization Date"
                                                value={state.tenMobilizationDate || ""}
                                                onChange={handleChange}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                id="serviceRequirement"
                                                name="serviceRequirement"
                                                label="Service Requirement"
                                                value={state.serviceRequirement || ""}
                                                onChange={handleChange}
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={3}
                                                required
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>

                                <Typography variant="overline" className={classes.title2}>
                                    Project Details
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper}>
                                    <Grid
                                        container
                                        justify="center"
                                        alignItems="center"
                                        spacing={2}
                                        className={classes.grid}
                                    >   
                                        <Grid item xs={12} md={4}>
                                            <FormControl variant="outlined" fullWidth required>
                                            <InputLabel id="projectTitle">Project Title</InputLabel>
                                                <Select
                                                    name="projectTitle"
                                                    value={state.projectTitle || ""}
                                                    onChange={handleChange}
                                                    labelId="projectTitle"
                                                >
                                                <MenuItem disabled>Select</MenuItem>
                                                    {customer_orders && customer_orders.map((item) => (
                                                        <MenuItem key={item._id} value={item.projectTitle}>
                                                            {item.projectTitle}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControl variant="outlined" fullWidth required>
                                            <InputLabel id="assetLocation">Asset/Facility Location</InputLabel>
                                                <Select
                                                    name="assetLocation"
                                                    value={state.assetLocation || ""}
                                                    onChange={handleChange}
                                                    labelId="assetLocation"
                                                >
                                                <MenuItem disabled>Select</MenuItem>
                                                    {customer_orders && customer_orders.map((item) => (
                                                        <MenuItem key={item._id} value={item.assetFacility}>
                                                            {item.assetFacility}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {/* <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Project Title"
                                                name="projectTitle"
                                                value={state.projectTitle || ""}
                                                onChange={handleChange}
                                            />
                                        </Grid> */}
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Project Name"
                                                name="projectName"
                                                value={state.projectName || ""}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Project Duration"
                                                name="projectDuration"
                                                value={state.projectDuration || ""}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Job Number"
                                                name="jobNumber"
                                                value={state.jobNumber || ""}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="PDMN Number"
                                                name="pdmnNumber"
                                                value={state.pdmnNumber || ""}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Purchase Order Number"
                                                name="purchaseOrderNumber"
                                                value={state.purchaseOrderNumber || ""}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControl variant="outlined" fullWidth required>
                                                <InputLabel id="validation">Purchase Order/Contract Validation</InputLabel>
                                                <Select
                                                    name="validation"
                                                    value={state.validation}
                                                    onChange={handleChange}
                                                    labelId="validation"
                                                >
                                                <MenuItem disabled>Select</MenuItem>
                                                    <MenuItem value="Yes">Yes</MenuItem>
                                                    <MenuItem value="No">No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <DatePicker
                                                className={classes.marginBottom}
                                                autoOk
                                                format="DD/MM/yyyy"
                                                views={["year", "month", "date"]}
                                                label="Actual Mobilization Date"
                                                fullWidth
                                                inputVariant="outlined"
                                                value={dateCreated}
                                                onChange={handleChangeDate}
                                                InputProps={{
                                                    endAdornment: (
                                                        <>
                                                            <Divider
                                                                className={classes.divider2}
                                                                orientation="vertical"
                                                            />
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    disabled={dateCreated === null}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setDateCreated(null);
                                                                    }}
                                                                >
                                                                    {dateCreated ? (
                                                                        <CloseIcon />
                                                                    ) : (
                                                                        <FilterListIcon />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        </>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                required
                                                label="Scope of Work"
                                                name="projectScope"
                                                value={state.projectScope || ""}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>

                                {(isCommercials || isAdmin) ? (
                                    <div className={classes.btnWrapper}>
                                        <Button
                                            type="submit"
                                            className={classes.saveBtn}
                                            disabled={loading || validCheck()}
                                            color="primary"
                                            variant="contained"
                                        >
                                            {loading ? "Loading..." : "Create"}
                                        </Button>
                                    </div>
                                ) : null}

                            </div>
                        </form>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </DialogContent>
                </Dialog>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default connect(null, { createProjectMob })(CreateMobModal);
