import React, { useState } from "react";
import {
    TextField,
    makeStyles,
    Typography,
    Paper,
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Button,
    Backdrop,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";

import SearchComponent from "../../SearchComponent";
import { connect, useDispatch } from "react-redux";
import {
    createTitle,
    getJobTitles,
} from "../../../actions/jobTitlePpmActions";
import { getDivisions } from "../../../actions/divisionActions";
import Feedback from "../../../Feedback";
import { TablePaginationActions } from "../../TablePaginationActions";
import EditJobTitleModal from "../Modals/EditJobTitleModal";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
    },

    action: {
        marginBottom: 30,
    },
    btn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    table: {
        minWidth: 500,
    },
    createBtn: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
    },
    marginBottom: {
        marginBottom: 30,
    },
    saveBtn: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        width: 200,
        // [theme.breakpoints.down("sm")]: {
        //   width: "100%",
        // },
    },
}));

const INIT_STATE = {
    name: "",
    division: "",
};

const CreateJobTitle = ({
    createTitle,
    getDivisions,
    getJobTitles,
    jobTitles,
    error,
}) => {
    const classes = useStyles();
    const [state, setState] = useState(INIT_STATE);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [loading, setLoading] = useState(false);
    const [divisions, setDivisions] = useState([]);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [search, setSearch] = React.useState("");
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [getId, setGetId] = useState("");
    const dispatch = useDispatch();

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    React.useEffect(() => {
        setLoading((prev) => !prev);
        getDivisions()
            .then((data) => {
                setLoading(false);
                setDivisions(data.data);
            })
            .catch((err) => console.log(err));
    }, [getDivisions]);

    React.useEffect(() => {
        setOpenBackdrop((prev) => !prev);
        getJobTitles().then(() => {
            setOpenBackdrop(false);
        });
    }, [getJobTitles]);

    const handleChange = (e) => {
        e.persist();
        setState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const updateSearch = (e) => {
        setSearch(e.target.value.substr(0, 20));
    };

    const ResetSearch = (e) => {
        e.preventDefault();

        setSearch("");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            name: state.name,
            division: state.division,
        };
        setLoading(!loading);
        setOpenBackdrop(!openBackdrop);
        createTitle(data)
            .then((data) => {
                setLoading(false);
                setState(INIT_STATE);
                setFeed({
                    loading: false,
                    open: !feed.open,
                    message: `Job Title created Successfully`,
                    success: true,
                });
                setOpenBackdrop(false);
                setOpen(false);
                dispatch(getJobTitles);
            })
            .catch((err) => {
                setLoading(false);
                setFeed({
                    loading: false,
                    open: !feed.open,
                    message: err,
                    success: false,
                });
                setOpen(false);

                console.error(err);
            });
    };

    const validCheck = () => {
        return !state.name || !state.division;
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const filteredRows = () =>
        jobTitles === undefined
            ? []
            : jobTitles.filter((row) => {
                const { name } = row.division;
                if (search !== "") {
                    return (
                        row.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                        name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                    );
                } else {
                    return row;
                }
            });

    const emptyRows =
        rowsPerPage -
        Math.min(
            rowsPerPage,
            (Array(10000).length || filteredRows().length) - page * rowsPerPage
        );

    const handleOpenEdit = (id) => {
        setGetId(id);
        setOpenEdit(true);
    };

    const handleEditClose = () => {
        setOpenEdit(false);
    };

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}
            {/* {error ? (
        <Feedback
          open={!error.success}
          severity="error"
          message={error.error}
        />
      ) : null} */}
            <EditJobTitleModal
                id={getId}
                openEdit={openEdit}
                handleEditClose={handleEditClose}
                divisions={divisions}
                getJobTitles={getJobTitles}
            />
            <Typography variant="overline" className={classes.title}>
                Job Title
            </Typography>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={3}
                className={classes.marginBottom}
            >
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        style={{ marginRight: 5 }}
                        onClick={handleClickOpen}
                        className={classes.btn}
                    >
                        Create
                    </Button>
                </Grid>

                <Grid item>
                    <SearchComponent
                        updateSearch={updateSearch}
                        placeholder="Search..."
                        search={search}
                        ResetSearch={ResetSearch}
                    />
                </Grid>
            </Grid>

            <Paper>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item></Grid>
                    <Grid item>
                        <IconButton style={{ marginRight: 10, marginTop: 10 }}>
                            <FilterListIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <TableContainer>
                    <Table className={classes.table} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Division</TableCell>
                                <TableCell>Edit</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRows().slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                : filteredRows()
                            ).map((row) => {
                                return (
                                    <TableRow hover key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell>{row.division && row.division.name}</TableCell>

                                        <TableCell>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleOpenEdit(row._id)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}

                            {filteredRows().length === 0 && (
                                <TableRow hover>
                                    <TableCell
                                        colSpan={4}
                                        align="center"
                                        style={{ color: "#616161" }}
                                    >
                                        No Records To Display
                                    </TableCell>
                                </TableRow>
                            )}

                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                    colSpan={3}
                    count={filteredRows().length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </Paper>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle id="form-dialog-title">
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="overline" className={classes.title}>
                                Create Job Title
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3} justify="center" alignItems="center">
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="name"
                                    name="name"
                                    label="Name"
                                    value={state.name}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" fullWidth required>
                                    <InputLabel id="division-label-id">Division</InputLabel>
                                    <Select
                                        labelId="division-label-id"
                                        id="division"
                                        inputProps={{
                                            id: "division",
                                            name: "division",
                                        }}
                                        label="Division"
                                        name="division"
                                        value={state.division || ""}
                                        onChange={handleChange}
                                    >
                                        <MenuItem disabled>---Select Division----</MenuItem>
                                        {divisions &&
                                            divisions.map((division) => (
                                                <MenuItem key={division._id} value={division._id}>
                                                    {division.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container justify="center">
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                className={classes.saveBtn}
                                disabled={validCheck() || loading}
                            >
                                {loading ? "Loading..." : "Submit"}
                            </Button>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>

            <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

const mapStateToProps = (state) => ({
    jobTitles: state.jobTitle.jobTitles.data,
    error: state.jobTitle.error,
});

export default connect(mapStateToProps, {
    createTitle,
    getDivisions,
    getJobTitles,
})(CreateJobTitle);
