import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    Fab,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    Icon,
    MenuItem,
    Button,
    Divider,
    darken,
    Backdrop,
    ButtonGroup,
    Hidden,
    Radio,
    RadioGroup,
    FormLabel,
    FormControlLabel,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../../../Feedback";
import { getProfile } from "../../../../actions/userActions";
import {
    updateRequisition,
    submitRequisition,
    getRequisition,
    getAllInventoryItems,
    getAllStores,
    getLocations,
    getRequisitions,
    getAllInventoryCategories,
    getInventoryCategoryItems,
    getInventoryItem
} from "../../../../actions/inventoryActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 400,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    employee: "",
    division: "",
    mainCategory: "",
    purpose: "",
    dateNeeded: "",
    store: "",
    locationFrom: "",
    locationTo: "",
    items: [{
        itemId: "",
        item: "",
        category: "",
        quantityRequested: 1,
        condition: "Good",
        toBeReturned: "Not To Be Returned",
    }],
    totalQuantityRequested: '',
};

const INIT_ITEM = {
    id: "",
    item: "",
    category: "",
    quantity: "",
    quantityRequested: 1,
    condition: "Good",
    toBeReturned: "Not To Be Returned",
}

const EditRequisition = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    const [item, setItem] = useState(INIT_ITEM);
    const [category, setCategory] = useState("");
    const [invCate, setMainCategories] = useState("");

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const [dateNeeded, setDateNeeded] = useState(new Date());
    const { profile } = useSelector((state) => state.user);
    const { invItems, invCates, invCateItems, invItem } = useSelector((state) => state.inventoryItem);
    const { locations } = useSelector((state) => state.location);
    const { allstores } = useSelector((state) => state.stores);
    const { error, loading, requisition } = useSelector((state) => state.requisition);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getAllInventoryItems());
        dispatch(getAllStores());
        dispatch(getLocations());
        dispatch(getAllInventoryCategories());
        dispatch(getRequisition(id));
        if (category !== "") {
            dispatch(getInventoryCategoryItems(category));
        }
    }, [dispatch, id, category]);

    useEffect(() => {
        if (item.item !== "") {
            dispatch(getInventoryItem(item.item));
        }
    }, [dispatch, item]);

    const handleChangeCategory = (e) => {
        setItem(INIT_ITEM);
        setCategory(e.target.value);
        setItem((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleItemChange = (e) => {
        e.persist();
        setItem((prev) => ({
            ...prev,
            id,
            [e.target.name]: e.target.value,
        }));
    };

    const handleAddItemsArray = (item) => {
        if (item.quantity > "0" && item.category !== "" && item.item !== "" && item.quantityRequested !== "" && item.condition !== "" && item.toBeReturned !== "" && (item.quantityRequested <= item.quantity)) {
            setState((prev) => ({
                ...prev,
                items: prev.items.concat([item]),
            }));
            setItem(INIT_ITEM);
        }
    };

    useEffect(() => {
        if (state.mainCategory === "IT Equipments") {
            let cates = invCates.filter((item) => item.name === "Solutions");
            setMainCategories(cates)
        } else if (state.mainCategory === "Others") {
            let cates = invCates.filter((item) => item.name !== "Solutions");
            let cates2 = cates.filter((item) => item.name !== "PPE");
            setMainCategories(cates2)
        } else if (state.mainCategory === "PPE") {
            let cates = invCates.filter((item) => item.name === "PPE");
            setMainCategories(cates)
        }
    }, [state.mainCategory, invCates]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            purpose: (requisition && requisition.purpose) || "",
            employee: (requisition && requisition.employee) || "",
            division: (requisition && requisition.division) || "",
            dateNeeded: (requisition && moment(requisition.dateNeeded).format("l")) || "",
            store: (requisition && requisition.store?.id) || "",
            mainCategory: (requisition && requisition.mainCategory) || "",
            locationFrom: (requisition && requisition.locationFrom) || "",
            locationTo: (requisition && requisition.locationTo) || "",
            totalQuantityRequested: (requisition && requisition.totalQuantityRequested) || "",

            items: (requisition && requisition.items) || [],
            status: (requisition && requisition.status) || "",
            documentNo: (requisition && requisition.documentNo) || "",

            createdBy: (requisition && requisition.createdBy) || "",
            createdAt: (requisition && requisition.createdAt) || "",

            submittedBy: (requisition && requisition.submittedBy) || "",
            submittedAt: (requisition && requisition.submittedAt) || "",

            rejectedBy: (requisition && requisition.rejectedBy) || "",
            rejectedAt: (requisition && requisition.rejectedAt) || "",
            rejectComment: (requisition && requisition.rejectComment) || "",

            declinedBy: (requisition && requisition.declinedBy) || "",
            declinedAt: (requisition && requisition.declinedAt) || "",
            declineComment: (requisition && requisition.declineComment) || "",

        }));
    }, [requisition]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: profile && profile.userName,
            division: profile && profile.division && profile.division._id,
            dateNeeded: moment(dateNeeded).toDate(),
        }));
    }, [profile, dateNeeded]);

    useEffect(() => {
        if (invItem) {
            setItem((prev) => ({
                ...prev,
                quantity: invItem && invItem.totalQuantity,
            }));
        }
    }, [invItem]);

    const handleChangeDate = (date) => {
        setDateNeeded(date);
    };

    useEffect(() => {
        if (state.items && state.items.length > 0) {
            let total1 = state.items.reduce((acc, cur) => { return acc + +cur.quantityRequested; }, 0);
            setState((prev) => ({
                ...prev,
                totalQuantityRequested: total1,
            }));
        }
    }, [state.items]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleRemoveItemsArray = (i) => {
        setState((prev) => ({
            ...prev,
            items: prev.items.filter(
                (_, index) => index !== i
            ),
        }));
    };
    
    const handleSubmitHandler = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            items: state.items,
            createdBy: (state.createdBy && state.createdBy._id) || "",
            submittedBy: (state.createdBy && state.createdBy._id) || "",
            declinedBy: (state.declinedBy && state.declinedBy._id) || null,
            rejectedBy: (state.rejectedBy && state.rejectedBy._id) || null,
            id,
        };
        await dispatch(submitRequisition(data));
        await dispatch(getRequisitions);
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Requisition Submitted Successfully.",
            success: true,
        }));
        setTimeout(() => {
            history.push("/inventory/requisition");
        }, 1500);
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            items: state.items,
            division: profile && profile.division,
            createdBy: (state.createdBy && state.createdBy._id) || "",
            submittedBy: (state.createdBy && state.createdBy._id) || "",
            declinedBy: (state.declinedBy && state.declinedBy._id) || null,
            rejectedBy: (state.rejectedBy && state.rejectedBy._id) || null,
            id
        }
        await dispatch(updateRequisition(data));
        setTimeout(async () => {
            await dispatch(getRequisition(id));
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Requisition Updated Successfully.",
                success: true,
            }));
        }, 500);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isCanEdit = state ? state.status &&
        ((state.status === "draft" ||
            state.status === "declined" ||
            state.status === "rejected")
        )
        : false;

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                <Typography variant="overline" className={classes.title}>
                    Edit Requisition
                </Typography>
                <Grid container justify="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.backBtn}
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.push("/inventory/requisition")}
                        >
                            Back
                        </Button>
                    </Grid>
                    <Grid item>
                        <Paper variant="outlined" className={classes.status}>
                            <Typography variant="overline" className={classes.statusTitle}>
                                Document No.
                            </Typography>
                            <Divider className={classes.divider2} orientation="vertical" />
                            {loading ? "Loading..." : state.documentNo}
                        </Paper>
                    </Grid>
                </Grid>

                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                    style={{ marginBottom: 20 }}
                >
                    <Hidden smDown>
                        <Grid item></Grid>
                    </Hidden>
                    <Grid item>
                        <Paper
                            variant="outlined"
                            className={clsx(
                                state.status === "draft"
                                    ? classes.statusYellow
                                    : state.status === "submitted"
                                        ? classes.statusOrange
                                        : state.status === "reviewed"
                                            ? classes.statusGreen
                                            : (state.status === "approved" || state.status === "sent")
                                                ? classes.statusDarkGreen
                                                : classes.statusRed,
                                classes.status
                            )}
                        >
                            <Typography variant="overline" className={classes.statusTitle}>
                                Status
                            </Typography>
                            <Divider className={classes.divider2} orientation="vertical" />
                            <Typography variant="overline" className={classes.statusTitle}>
                                {loading ? "Loading..." : state.status}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <form onSubmit={handleSubmitHandler}>
                    <div>

                        <Grid container spacing={2} className={classes.grid}>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel>Inventory Store</InputLabel>
                                    <Select
                                        inputProps={{
                                            id: "store",
                                            name: "store",
                                        }}
                                        value={state.store}
                                        onChange={handleChange}
                                        disabled={!isCanEdit}
                                    >
                                        <MenuItem disabled>Select Store</MenuItem>
                                        {allstores.map((store) => (
                                            <MenuItem key={store._id} value={store._id}>
                                                {store.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    className={classes.marginBottom}
                                    autoOk
                                    format="DD/MM/yyyy"
                                    views={["year", "month", "date"]}
                                    label="Date Needed"
                                    fullWidth
                                    required
                                    inputVariant="outlined"
                                    value={dateNeeded}
                                    onChange={handleChangeDate}
                                    disabled={!isCanEdit}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel>Current Location</InputLabel>
                                    <Select
                                        inputProps={{
                                            id: "locationFrom",
                                            name: "locationFrom",
                                        }}
                                        value={state.locationFrom}
                                        onChange={handleChange}
                                        disabled={!isCanEdit}
                                    >
                                        <MenuItem disabled>Select Location</MenuItem>
                                        {locations.map((location) => (
                                            <MenuItem key={location._id} value={location._id}>
                                                {location.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" required>
                                    <InputLabel>Location Item is Needed</InputLabel>
                                    <Select
                                        inputProps={{
                                            id: "locationTo",
                                            name: "locationTo",
                                        }}
                                        value={state.locationTo}
                                        onChange={handleChange}
                                        disabled={!isCanEdit}
                                    >
                                        <MenuItem disabled>Select Location</MenuItem>
                                        {locations.map((location) => (
                                            <MenuItem key={location._id} value={location._id}>
                                                {location.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Purpose"
                                    multiline
                                    disabled={!isCanEdit}
                                    rows={3}
                                    name="purpose"
                                    value={state.purpose || ""}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl
                                    fullWidth
                                    required
                                    variant="outlined"
                                    component="fieldset"
                                >
                                    <FormLabel>Please select the category the inventory belongs to?</FormLabel>
                                    <RadioGroup row>
                                        <FormControlLabel
                                            value="IT Equipments"
                                            control={<Radio 
                                                disabled={(state.items && state.items.length > 0) ? true : false} 
                                                checked={state.mainCategory === "IT Equipments" ? true : false} 
                                            />}
                                            label="IT Equipments"
                                            name="mainCategory"
                                            disabled={!isCanEdit}
                                            onChange={handleChange}
                                        />
                                        <FormControlLabel
                                            value="PPE"
                                            control={<Radio 
                                                disabled={(state.items && state.items.length > 0) ? true : false} 
                                                checked={state.mainCategory === "PPE" ? true : false} 
                                            />}
                                            label="PPE"
                                            name="mainCategory"
                                            disabled={!isCanEdit}
                                            onChange={handleChange}
                                        />
                                        <FormControlLabel
                                            value="Others"
                                            control={<Radio 
                                                disabled={(state.items && state.items.length > 0) ? true : false} 
                                                checked={state.mainCategory === "Others" ? true : false} 
                                            />}
                                            label="Others"
                                            name="mainCategory"
                                            disabled={!isCanEdit}
                                            onChange={handleChange}
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Paper variant="outlined" square className={classes.paper}>
                            {item ? (
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.grid}
                                >
                                    <Grid item xs={12} md={2}>
                                        <FormControl fullWidth required variant="outlined">
                                            <InputLabel id="item">
                                                Inventory Category
                                            </InputLabel>
                                            {(state.mainCategory === "Others") ? (
                                                <Select
                                                    labelId="category"
                                                    label="Inventory Category"
                                                    name="category"
                                                    required
                                                    value={item.category || ""}
                                                    onChange={handleChangeCategory}
                                                >
                                                    <MenuItem disabled>Select</MenuItem>
                                                    {invCate &&
                                                        invCate.map((cate) => (
                                                            <MenuItem key={cate._id} value={cate._id}>
                                                                {cate.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            ) : ((state.mainCategory === "IT Equipments" || state.mainCategory === "PPE") ? (
                                                <Select
                                                    labelId="category"
                                                    label="Inventory Category"
                                                    name="category"
                                                    required
                                                    value={item.category || ""}
                                                    onChange={handleChangeCategory}
                                                >
                                                    <MenuItem disabled>Select</MenuItem>
                                                    {invCate &&
                                                        invCate.map((cate) => (
                                                            <MenuItem key={cate._id} value={cate._id}>
                                                                {cate.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            ) : null
                                            )
                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <FormControl fullWidth required variant="outlined">
                                            <InputLabel id="item">
                                                Inventory item
                                            </InputLabel>
                                            <Select
                                                labelId="item"
                                                name="item"
                                                required
                                                value={item.item || ""}
                                                onChange={handleItemChange}
                                            >
                                                <MenuItem disabled>Select</MenuItem>
                                                {invCateItems &&
                                                    invCateItems.map((item) => (
                                                        <MenuItem key={item._id} value={item._id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Available Quantity"
                                            type="text"
                                            name="quantity"
                                            disabled
                                            value={item.quantity || "0"}
                                            onChange={handleItemChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Quantity Requested"
                                            type="number"
                                            name="quantityRequested"
                                            value={item.quantityRequested || ""}
                                            onChange={handleItemChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <FormControl fullWidth required variant="outlined">
                                            <InputLabel id="condition">
                                                Condition
                                            </InputLabel>
                                            <Select
                                                labelId="condition"
                                                required
                                                value={item.condition || ""}
                                                name="condition"
                                                onChange={handleItemChange}
                                            >
                                                <MenuItem disabled>Select</MenuItem>
                                                <MenuItem value="New">New</MenuItem>
                                                <MenuItem value="Good">Good</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <FormControl fullWidth required variant="outlined">
                                            <InputLabel id="toBeReturned">
                                                To Be Returned?
                                            </InputLabel>
                                            <Select
                                                required
                                                labelId="toBeReturned"
                                                value={item.toBeReturned || ""}
                                                name="toBeReturned"
                                                onChange={handleItemChange}
                                            >
                                                <MenuItem disabled>Select</MenuItem>
                                                <MenuItem value="To Be Returned">Yes</MenuItem>
                                                <MenuItem value="Not To Be Returned">No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={1}>
                                        <Fab
                                            color="primary"
                                            onClick={() => handleAddItemsArray(item)}
                                        >
                                            <AddIcon />
                                        </Fab>
                                    </Grid>
                                </Grid>
                            ) : null}
                        </Paper>

                        {/* To be filled by the Requestor */}
                        <>
                            <Typography variant="overline" className={classes.title2}>
                                Inventory Items
                            </Typography>

                            <Paper variant="outlined" square className={classes.paper}>
                                {state.items.map((sitem, i) => (
                                    <Grid
                                        container
                                        spacing={2}
                                        className={classes.grid}
                                        key={i}
                                    >
                                        <Grid item xs={12} md={3}>
                                            <FormControl fullWidth required variant="outlined">
                                                <label style={{ fontWeight: "bold" }}>Inventory Item</label>
                                                <Select
                                                    labelId="item"
                                                    name="item"
                                                    required
                                                    value={sitem.item || ''}
                                                    disabled
                                                >
                                                    <MenuItem disabled>Select</MenuItem>
                                                    {invItems &&
                                                        invItems.map((nitem) => (
                                                            <MenuItem key={nitem._id} value={nitem._id}>
                                                                {nitem.name}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <label style={{ fontWeight: "bold" }}>Quantity</label>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                type="number"
                                                name="quantityRequested"
                                                value={sitem.quantityRequested || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <FormControl fullWidth required variant="outlined">
                                                <label style={{ fontWeight: "bold" }}>Condition</label>
                                                <Select
                                                    labelId="condition"
                                                    required
                                                    value={sitem.condition || ""}
                                                    name="condition"
                                                    disabled
                                                >
                                                    <MenuItem disabled>Select</MenuItem>
                                                    <MenuItem value="New">New</MenuItem>
                                                    <MenuItem value="Good">Good</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <FormControl fullWidth required variant="outlined">
                                                <label style={{ fontWeight: "bold" }}>To Be Returned</label>
                                                <Select
                                                    required
                                                    labelId="toBeReturned"
                                                    value={sitem.toBeReturned || ""}
                                                    name="toBeReturned"
                                                    disabled
                                                >
                                                    <MenuItem disabled>Select</MenuItem>
                                                    <MenuItem value="To Be Returned">Yes</MenuItem>
                                                    <MenuItem value="Not To Be Returned">No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <label>&nbsp;</label>
                                            <Fab
                                                color="secondary"
                                                onClick={() => handleRemoveItemsArray(i)}
                                            >
                                                <CloseIcon />
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Paper>
                        </>

                        <Grid container spacing={2} className={classes.grid}>
                            <Grid item xs={12} md={9} />
                            <Grid item xs={12} md={3}>
                                <TextField
                                    label="Total Quantity Requested"
                                    name="totalQuantityRequested"
                                    value={state.totalQuantityRequested || '0'}
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            alignItems="center"
                            wrap="nowrap"
                            spacing={1}
                            style={{ marginBottom: 5 }}
                        >
                            <Grid item>
                                <Typography variant="overline" className={classes.title2}>
                                    Audit Trail
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Icon className="fas fa-clipboard-list" color="disabled" />
                            </Grid>
                        </Grid>
                        <Paper variant="outlined" square className={classes.paper}>
                            <Grid container justify="space-between" alignItems="center" spacing={2}>
                                {state.status === "draft" && (
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Created By</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.createdBy && state.createdBy.userName}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.createdAt}</span>
                                        </Paper>
                                    </Grid>
                                )}
                                {state.status !== "draft" && (
                                    <>
                                        <Grid item xs={12} md={12}>
                                            <Paper
                                                className={classes.auditContainer}
                                                component="div"
                                                elevation={1}
                                                variant="outlined"
                                                square
                                            >
                                                <span className={classes.auditTitle}>Submitted By</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.submittedBy && state.submittedBy.userName}</span>
                                                <Divider orientation="vertical" flexItem />
                                                <span className={classes.audit}>{state.submittedAt}</span>
                                            </Paper>
                                        </Grid>
                                        {state.declineComment && (
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <Paper
                                                        className={classes.auditContainer}
                                                        component="div"
                                                        elevation={1}
                                                        variant="outlined"
                                                        square
                                                    >
                                                        <span className={classes.auditTitle}>Declined By</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.declinedBy && state.declinedBy.userName}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.declinedAt}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.auditTitle}>Comment</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.declineComment}</span>
                                                    </Paper>
                                                </Grid>
                                            </>
                                        )}
                                        {state.rejectComment && (
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <Paper
                                                        className={classes.auditContainer}
                                                        component="div"
                                                        elevation={1}
                                                        variant="outlined"
                                                        square
                                                    >
                                                        <span className={classes.auditTitle}>Rejected By</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.rejectedBy && state.rejectedBy.userName}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.rejectedAt}</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.auditTitle}>Comment</span>
                                                        <Divider orientation="vertical" flexItem />
                                                        <span className={classes.audit}>{state.rejectComment}</span>
                                                    </Paper>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Paper>

                        {isCanEdit ? (
                            <Grid container justify="center">
                                <Grid item>
                                    <ButtonGroup>
                                        <Button
                                            className={classes.saveBtn}
                                            color="primary"
                                            variant="contained"
                                            disabled={loading}
                                            onClick={handleSave}
                                        >
                                            {loading ? "Loading..." : "Save Changes"}
                                        </Button>
                                        <Button
                                            type="submit"
                                            className={classes.saveBtn2}
                                            variant="contained"
                                            onClick={handleSubmitHandler}
                                            disabled={loading}
                                        >
                                            Submit
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        ) : null}

                    </div>
                </form>
            </MuiPickersUtilsProvider>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default EditRequisition;
