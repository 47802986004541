import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Grid,
  Button,
  Backdrop,
  Chip,
  ButtonGroup,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditIcon from "@material-ui/icons/Edit";
// import DeleteIcon from "@material-ui/icons/Delete";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

import SearchComponent from "../../SearchComponent";
import { TablePaginationActions } from "../../TablePaginationActions";
import CreateContactModal from "../Modals/Sales/CreateContactModal";
import { useDispatch, useSelector } from "react-redux";
import { getContactPersons } from "../../../actions/contactPersonActions";
import Feedback from "../../../Feedback";
import EditContactModal from "../Modals/Sales/EditContactModal";
import DeleteContactModal from "../Modals/Sales/DeleteContactModal";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 500,
  },
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: "0.65rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    // background: "#00e676",

    borderColor: "#1de9b6",
    // color: "#fff",
  },
  statusYellow: {
    // background: "#fbc02d",

    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    // background: "#ef5350",

    borderColor: "#ef5350",
  },
  marginBottom: {
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  createBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const Contacts = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [getId, setGetId] = useState("");

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  const { profile } = useSelector((state) => state.user);
  const { loading, contactPersons } = useSelector(
    (state) => state.contactPerson
  );

  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getContactPersons());
  }, [dispatch]);

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };
  let filteredRows = () =>
    contactPersons === undefined
      ? []
      : contactPersons &&
        contactPersons.filter((row) => {
          if (search !== "") {
            return (
              row.name
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1 ||
              row.designation
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1 ||
              row.phone
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1 ||
              row.email.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              (row.relatedCustomer &&
                row.relatedCustomer.name
                  .toString()
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1)
            );
          } else {
            return row;
          }
        });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (Array(1000).length || filteredRows().length) - page * rowsPerPage
    );
  const handleCreateOpen = () => {
    setOpenCreate(true);
  };
  const handleCreateClose = () => {
    setOpenCreate(false);
  };
  const handleEditOpen = (id) => {
    setGetId(id);
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  // const handleDeleteOpen = (id) => {
  //   setGetId(id);
  //   setOpenDelete(true);
  // };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  let isAdminAndComm = profile
    ? profile &&
      profile.groups &&
      profile.groups.some(
        (grp) =>
          (grp.name === "admin" && grp.module === "administration") ||
          (grp.name === "reviewer" && grp.module === "crm")
      )
    : false;

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <CreateContactModal {...{ openCreate, handleCreateClose }} />
      <EditContactModal {...{ openEdit, handleEditClose, id: getId }} />
      <DeleteContactModal {...{ openDelete, handleCloseDelete, id: getId }} />
      <Typography variant="overline" gutterBottom className={classes.title}>
        Contact Person Lists
      </Typography>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          {isAdminAndComm && (
            <Button
              color="primary"
              variant="contained"
              style={{ marginRight: 5, marginBottom: 30 }}
              onClick={handleCreateOpen}
              className={classes.createBtn}
            >
              Create
            </Button>
          )}
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={3}
        className={classes.marginBottom}
      >
        <Grid item>
          <SearchComponent
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Paper>
        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Designation</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Related Customer</TableCell>

                {isAdminAndComm && <TableCell>Actions</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows()
              ).map((row, i) => {
                return (
                  <TableRow hover key={row._id}>
                    <TableCell component="th" scope="row">
                      <Chip label={row.name} variant="outlined" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.designation}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.phone}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {row.relatedCustomer && row.relatedCustomer.name}
                    </TableCell>

                    {isAdminAndComm && (
                      <TableCell>
                        <ButtonGroup variant="contained">
                          <Button
                            color="primary"
                            onClick={() => handleEditOpen(row._id)}
                          >
                            <EditIcon />
                          </Button>
                          {/* <Button
                            color="secondary"
                            onClick={() => handleDeleteOpen(row._id)}
                          >
                            <DeleteIcon />
                          </Button> */}
                        </ButtonGroup>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              {filteredRows().length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={10}
                    align="center"
                    style={{ color: "#616161" }}
                    component="th"
                    scope="row"
                  >
                    No Records To Display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Contacts;
