import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    Select,
    MenuItem,
    Button,
    Divider,
    Icon,
    darken,
    Backdrop,
    ButtonGroup,
    Hidden,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import Feedback from "../../../../Feedback";
import { getProfile } from "../../../../actions/userActions";
import {
    getAllStores,
    getLocations,
    getReceipt,
    getAllInventoryItems,
} from "../../../../actions/inventoryActions";
// QHSE Accept / Reject Receipt
import AcceptReceiptModal from "../../Modals/Inventory/ReceiptModals/AcceptReceiptModal";
import RejectReceiptModal from "../../Modals/Inventory/ReceiptModals/RejectReceiptModal";
// Inventory Manager Receive Receipt
import ReceiveReceiptModal from "../../Modals/Inventory/ReceiptModals/ReceiveReceiptModal";
// Finance Approve Receipt
import ApproveReceiptModal from "../../Modals/Inventory/ReceiptModals/ApproveReceiptModal";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold"
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(1),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
            // color: "#fff",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        // fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 400,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    employee: "",
    division: "",
    currency: "",
    vendor: "",
    orderNo: "",
    documentNo: "",
    store: "",
    location: "",
    totalQuantity: "",
    totalValue: "",
    items: [{
        name: "",
        item: "",
        quantityOrdered: "",
        quantityReceived: "",
        unit: '',
        cost: '',
        value: '',
    }],
};

const ViewReceipt = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);

    const [openAccept, setOpenAccept] = useState(false);
    const [openReject, setOpenReject] = useState(false);
    const [openReceive, setOpenReceive] = useState(false);
    const [openApprove, setOpenApprove] = useState(false);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { profile } = useSelector((state) => state.user);
    const { invItems } = useSelector((state) => state.inventoryItem);
    const { locations } = useSelector((state) => state.location);
    const { allstores } = useSelector((state) => state.stores);
    const { vendors } = useSelector((state) => state.vendors);
    const { error, loading, receipt } = useSelector((state) => state.receipt);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getAllInventoryItems());
        dispatch(getLocations());
        dispatch(getAllStores());
        dispatch(getReceipt(id));
    }, [dispatch, id]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: (receipt && receipt.employee) || "",
            division: (receipt && receipt.division) || "",
            store: (receipt && receipt.store?.id) || "",
            location: (receipt && receipt.location?._id) || "",
            documentNo: (receipt && receipt.documentNo) || "",
            orderNo: (receipt && receipt.orderNo) || "",
            vendor: (receipt && receipt.vendor?.id) || "",
            totalValue: (receipt && receipt.totalValue) || "",
            totalQuantity: (receipt && receipt.totalQuantity) || "",

            items: (receipt && receipt.items) || [],
            status: (receipt && receipt.status) || "",

            submittedBy:
                (receipt && receipt.submittedBy && receipt.submittedBy.userName) || "",
            submittedAt: (receipt && receipt.submittedAt) || "",

            approvedBy: (receipt && receipt.approvedBy && receipt.approvedBy?.userName) || "",
            approvedAt: (receipt && receipt.approvedAt) || "",
            approverComment: (receipt && receipt.approverComment) || "",

            acceptedBy: (receipt && receipt.acceptedBy && receipt.acceptedBy?.userName) || "",
            acceptedAt: (receipt && receipt.acceptedAt) || "",
            acceptComment: (receipt && receipt.acceptComment) || "",

            rejectedBy: (receipt && receipt.rejectedBy && receipt.rejectedBy?.userName) || "",
            rejectedAt: (receipt && receipt.acceptedAt) || "",
            rejectComment: (receipt && receipt.acceptComment) || "",

            issuedBy: (receipt && receipt.issuedBy && receipt.issuedBy?.userName) || "",
            issuedAt: (receipt && receipt.issuedAt) || "",

            receivedBy: (receipt && receipt.receivedBy && receipt.receivedBy?.userName) || "",
            receivedAt: (receipt && receipt.receivedAt) || "",
            receiverComment: (receipt && receipt.acceptComment) || "",

            returnedBy: (receipt && receipt.returnedBy && receipt.returnedBy?.userName) || "",
            returnedAt: (receipt && receipt.returnedAt) || "",

        }));
    }, [receipt]);

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    // QHSE
    const handleOpenAccept = () => {
        setOpenAccept(true);
    };
    const handleCloseAccept = () => {
        setOpenAccept(false);
    };
    const handleOpenReject = () => {
        setOpenReject(true);
    };
    const handleCloseReject = () => {
        setOpenReject(false);
    };

    // Inventory Manager
    const handleOpenReceive = () => {
        setOpenReceive(true);
    };
    const handleCloseReceive = () => {
        setOpenReceive(false);
    };

    // Finance Head
    const handleOpenApprove = () => {
        setOpenApprove(true);
    };
    const handleCloseApprove = () => {
        setOpenApprove(false);
    };

    const isQHSE = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "manager" && grp.module === "inventory"
        )
        : false;

    const isInvManager = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "head" && grp.module === "inventory"
        )
        : false;

    const isFinanceHead = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "reviewer" && grp.module === "inventory"
        )
        : false;

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}
            {/* QHSE */}
            <AcceptReceiptModal {...{ openAccept, handleCloseAccept, id }} />
            <RejectReceiptModal {...{ openReject, handleCloseReject, id }} />

            {/* Inventory manager */}
            <ReceiveReceiptModal {...{ openReceive, handleCloseReceive, id }} />

            {/* Finance head */}
            <ApproveReceiptModal {...{ openApprove, handleCloseApprove, id }} />

            <Typography variant="overline" className={classes.title}>
                View Receipt
            </Typography>
            <Grid container justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.backBtn}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.push("/inventory/receipt")}
                    >
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <Paper variant="outlined" className={classes.status}>
                        <Typography variant="overline" className={classes.statusTitle}>
                            Document No.
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        {loading ? "Loading..." : state.documentNo}
                    </Paper>
                </Grid>
            </Grid>

            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
                style={{ marginBottom: 20 }}
            >
                <Hidden smDown>
                    <Grid item></Grid>
                </Hidden>
                <Grid item>
                    <Paper
                        variant="outlined"
                        className={clsx(
                            state.status === "draft"
                                ? classes.statusYellow
                                : state.status === "submitted"
                                    ? classes.statusOrange
                                    : (state.status === "accepted" || state.status === "received")
                                        ? classes.statusGreen
                                        : (state.status === "approved")
                                            ? classes.statusDarkGreen
                                            : classes.statusRed,
                            classes.status
                        )}
                    >
                        <Typography variant="overline" className={classes.statusTitle}>
                            Status
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        <Typography variant="overline" className={classes.statusTitle}>
                            {loading ? "Loading..." : state.status}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.grid}>
                <Grid item xs={12} md={4}>
                    <label style={{ fontWeight: "bold" }}>Employee</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        name="employee"
                        value={state.employee || ""}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <label style={{ fontWeight: "bold" }}>Document Number</label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        disabled
                        name="documentNo"
                        value={state.documentNo || ""}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <label style={{ fontWeight: "bold" }}>Order Number </label>
                    <TextField
                        variant="outlined"
                        fullWidth
                        required
                        name="orderNo"
                        value={state.orderNo || ""}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl fullWidth variant="outlined" required>
                        <label style={{ fontWeight: "bold" }}>Vendor </label>
                        <Select
                            inputProps={{
                                id: "vendor",
                                name: "vendor",
                            }}
                            value={state.vendor}
                            disabled
                        >
                            <MenuItem disabled>Select Vendor</MenuItem>
                            {vendors.map((vendor) => (
                                <MenuItem key={vendor._id} value={vendor._id}>
                                    {vendor.companyName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl fullWidth variant="outlined" required>
                        <label style={{ fontWeight: "bold" }}>Store </label>
                        <Select
                            inputProps={{
                                id: "store",
                                name: "store",
                            }}
                            value={state.store}
                            disabled
                        >
                            <MenuItem disabled>Select Store</MenuItem>
                            {allstores.map((store) => (
                                <MenuItem key={store._id} value={store._id}>
                                    {store.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl fullWidth variant="outlined" required>
                        <label style={{ fontWeight: "bold" }}>Location </label>
                        <Select
                            inputProps={{
                                id: "location",
                                name: "location",
                            }}
                            value={state.location}
                            disabled
                        >
                            <MenuItem disabled>Select Location</MenuItem>
                            {locations.map((location) => (
                                <MenuItem key={location._id} value={location._id}>
                                    {location.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Typography variant="overline" className={classes.title3} style={{ marginTop: 30 }}>
                Inventory Items
            </Typography>

            <Paper variant="outlined" square className={classes.paper}>
                {state.items &&
                    state.items.map((item, i) => (
                        <Grid
                            container
                            justify="center"
                            alignItems="center"
                            spacing={1}
                            className={classes.grid}
                            key={i}
                        >
                            <Grid item xs={12} md={2}>
                                <FormControl fullWidth required variant="outlined">
                                    <label style={{ fontWeight: "bold" }}>Inventory Item</label>
                                    <Select
                                        value={item.item || ""}
                                        disabled
                                    >
                                        <MenuItem disabled>Select</MenuItem>
                                        {invItems &&
                                            invItems.map((item) => (
                                                <MenuItem key={item._id} value={item._id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <label style={{ fontWeight: "bold" }}>Quantity Ordered</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    type="number"
                                    name="quantityOrdered"
                                    value={item.quantityOrdered}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <label style={{ fontWeight: "bold" }}>Quantity Received</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    type="number"
                                    name="quantityReceived"
                                    value={item.quantityReceived}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={2} >
                                <FormControl fullWidth variant="outlined" required>
                                    <label style={{ fontWeight: "bold" }}>Unit</label>
                                    <Select
                                        inputProps={{
                                            id: "unit",
                                            name: "unit",
                                        }}
                                        value={item.unit}
                                        disabled
                                    >
                                        <MenuItem disabled>Select Unit</MenuItem>
                                        <MenuItem value="Packets">Packets</MenuItem>
                                        <MenuItem value="Reams">Reams</MenuItem>
                                        <MenuItem value="Kg">Kg</MenuItem>
                                        <MenuItem value="Cartons">Cartons</MenuItem>
                                        <MenuItem value="Crates">Crates</MenuItem>
                                        <MenuItem value="Meters">Meters</MenuItem>
                                        <MenuItem value="Kilometers">Kilometers</MenuItem>
                                        <MenuItem value="Pieces">Pieces</MenuItem>
                                        <MenuItem value="Dozens">Dozens</MenuItem>
                                        <MenuItem value="Litres">Litres</MenuItem>
                                        <MenuItem value="Gallons">Gallons</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <label style={{ fontWeight: "bold" }}>Item Cost</label>
                                <TextField
                                    name="cost"
                                    type="number"
                                    variant="outlined"
                                    value={item.cost}
                                    disabled
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <label style={{ fontWeight: "bold" }}>Item Value</label>
                                <TextField
                                    name="value"
                                    type="number"
                                    variant="outlined"
                                    value={item.value}
                                    disabled
                                    fullWidth
                                    required
                                />
                            </Grid>
                        </Grid>
                    ))}
            </Paper>

            <Grid container spacing={2} className={classes.grid}>
                <Grid item xs={12} md={6} />
                <Grid item xs={12} md={3}>
                    <label style={{ fontWeight: "bold" }}>Total Quantity</label>
                    <TextField
                        name="totalQuantity"
                        value={state.totalQuantity || '0'}
                        variant="outlined"
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <label style={{ fontWeight: "bold" }}>Total Value</label>
                    <TextField
                        name="totalValue"
                        value={state.totalValue || "0"}
                        variant="outlined"
                        fullWidth
                        disabled
                    />
                </Grid>
            </Grid>

            <Grid
                container
                alignItems="center"
                wrap="nowrap"
                spacing={1}
                style={{ marginBottom: 5 }}
            >
                <Grid item>
                    <Typography variant="overline" className={classes.title2}>
                        Audit Trail
                    </Typography>
                </Grid>
                <Grid item>
                    <Icon className="fas fa-clipboard-list" color="disabled" />
                </Grid>
            </Grid>
            <Paper variant="outlined" square className={classes.paper}>
                <Grid container justify="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Paper
                            className={classes.auditContainer}
                            component="div"
                            elevation={1}
                            variant="outlined"
                            square
                        >
                            <span className={classes.auditTitle}>Submitted By</span>
                            <Divider orientation="vertical" flexItem />
                            <span className={classes.audit}>{state.submittedBy}</span>
                            <Divider orientation="vertical" flexItem />
                            <span className={classes.audit}>{state.submittedAt}</span>
                        </Paper>
                    </Grid>
                    {(state.acceptedBy && state.acceptComment) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Accepted By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.acceptedBy}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.acceptedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.acceptComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.rejectedBy && state.rejectedBy) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Rejected By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.rejectedBy}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.rejectedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.rejectComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.approvedBy && state.approvedAt) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Approved By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.rejectedBy}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.rejectedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.approverComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                    {(state.receivedBy && state.receivedAt) && (
                        <>
                            <Grid item xs={12} md={12}>
                                <Paper
                                    className={classes.auditContainer}
                                    component="div"
                                    elevation={1}
                                    variant="outlined"
                                    square
                                >
                                    <span className={classes.auditTitle}>Received By</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.receivedBy}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.receivedAt}</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.auditTitle}>Comment</span>
                                    <Divider orientation="vertical" flexItem />
                                    <span className={classes.audit}>{state.receiverComment}</span>
                                </Paper>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Paper>

            {isQHSE && state.status === "submitted" ? (
                <Grid container justify="center">
                    <Grid item>
                        <div className={classes.centered}>
                            <Paper className={classes.paperBlack}>
                                <HelpOutlineIcon fontSize="large" />
                                <Typography placeholder="Info" className={classes.info2}>
                                    This action is to be done by the QHSE Subdivision.
                                </Typography>
                            </Paper>
                        </div>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenAccept}
                                className={classes.saveBtn2}
                                variant="contained"
                            >
                                Accept
                            </Button>
                            <Button
                                onClick={handleOpenReject}
                                className={classes.saveBtn}
                                color="secondary"
                                variant="contained"
                            >
                                Reject
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : (isInvManager && state.status === "accepted" ? (
                <Grid container justify="center">
                    <Grid item>
                        <div className={classes.centered}>
                            <Paper className={classes.paperBlack}>
                                <HelpOutlineIcon fontSize="large" />
                                <Typography placeholder="Info" className={classes.info2}>
                                    This action is to be done by the Inventory Officer.
                                </Typography>
                            </Paper>
                        </div>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenReceive}
                                className={classes.saveBtn2}
                                variant="contained"
                            >
                                Receive Item(s)
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : (isFinanceHead && state.status === "received" ? (
                <Grid container justify="center">
                    <Grid item>
                        <div className={classes.centered}>
                            <Paper className={classes.paperBlack}>
                                <HelpOutlineIcon fontSize="large" />
                                <Typography placeholder="Info" className={classes.info2}>
                                    This is to be done by the Finance Head.
                                </Typography>
                            </Paper>
                        </div>
                        <ButtonGroup>
                            <Button
                                onClick={handleOpenApprove}
                                className={classes.saveBtn2}
                                variant="contained"
                            >
                                Approve
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            ) : null))
            }

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default ViewReceipt;
