import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton, TablePagination, Typography } from "@material-ui/core";
import { TablePaginationActions } from "../TablePaginationActions";

const useStyles = makeStyles({
  table: {
    minWidth: 350,
  },
  tableContainer: {
    minWidth: 350,
  },
  title: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
});
const UserGroupsTable = ({
  groupList = [],
  handleRemoveList,
  isEdit = false,
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const dataLength = Array(1000).length || (groupList && groupList.length);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, dataLength - page * rowsPerPage);

//   console.log(groupList);

  return (
    <div>
      <Typography variant="overline" className={classes.title} gutterBottom>
        Access Rights
      </Typography>
      <Paper>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S/N</TableCell>
                <TableCell>Group Name</TableCell>
                <TableCell>Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? groupList &&
                  groupList.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : groupList
              ).map((row, i) => (
                <TableRow key={i}>
                  <TableCell component="th" scope="row">
                    {i + 1}
                  </TableCell>
                  {isEdit ? (
                    <TableCell>
                      {row && row.module}_{row && row.name}
                    </TableCell>
                  ) : (
                    <TableCell>{row.module}</TableCell>
                  )}
                  <TableCell>
                    <IconButton
                      color="secondary"
                      onClick={() => handleRemoveList(i)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {groupList && groupList.length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={20}
                    align="center"
                    style={{ color: "#616161" }}
                    component="th"
                    scope="row"
                  >
                    No Groups To Display
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={groupList && groupList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </div>
  );
};

export default UserGroupsTable;
