import React from "react";
import {
  Typography,
  makeStyles,
  Paper,
  Grid,
  Card,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Icon,
  Button,
} from "@material-ui/core";

import { connect } from "react-redux";
import moment from "moment";
import { formatCurrency } from "../../utils";
import Feedback from "../../Feedback";
import WelcomeFeedback from "../Admin/WelcomeFeedback";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Quicksand",
    "& ::parent": {
      padding: 0,
    },
  },
  analytics: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: 18,
    // color: darken(theme.palette.common.white, 0.3),
    letterSpacing: 2.5,
  },
  overview: {
    color: "#6F6767",
    textTransform: "uppercase",
  },
  date: {
    // marginLeft: 10,
    padding: 10,
    // background: "#07a4ace3",
    // color: "#fff",
    fontWeight: 900,
    fontSize: 12,
  },
  paper: {
    marginTop: 30,
  },
  gridContainer: {
    width: "100%",
    // padding: theme.spacing(4),
    marginTop: 30,
  },
  gridContainer2: {
    // padding: theme.spacing(4),
    marginTop: 30,
  },
  amount: {
    color: "#6F6767",
    fontWeight: 300,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  amountGradient: {
    color: "#ffffff",
  },
  wrapper: {
    marginTop: 30,
  },
  card: {
    width: "100%",
    textAlign: "center",
    padding: theme.spacing(4),
  },
  cardGradient: {
    background: "linear-gradient(120deg,#a6c0fe,#f68084) !important",
    color: "#d6d6d6",
  },
  cardGradient2: {
    background: "linear-gradient(0deg,#1e3c72 0,#1e3c72 1%,#2a5298) !important",
    color: "#d6d6d6",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  time: {
    marginLeft: 5,
    padding: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 900,
    fontSize: 12,
    maxWidth: 150,
  },
  bgBlue: {
    background: theme.palette.type === "light" ? "#00001c" : "#00001c",
    backgroundImage: "url(/img/earth.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    width: "100%",
    height: "40vh",
    padding: theme.spacing(6),
    borderRadius: 3,
    textAlign: "center",
  },
  bgBlueHeightWithWeather: {
    height: "60vh",
  },
  hero: {
    marginTop: "-25%",
    width: "80%",
    height: "60vh",
    display: "grid",
    gridGap: 30,
    justifyItems: "center",
    alignContent: "center",
    textAlign: "center",
    backgroundImage:
      theme.palette.type === "light" ? "url(/img/paper-plane.png)" : null,
    backgroundSize: "cover",
    backgroundPosition: "center",

    [theme.breakpoints.down("sm")]: {
      marginTop: "-50%",
      width: "90%",
    },
  },
  alignCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  largeText: {
    fontSize: 16,
  },

  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontWeight: 400,
    fontSize: 18,
    // color: darken(theme.palette.common.white, 0.3),
    letterSpacing: 2.5,
  },
  pos: {
    marginBottom: 12,
  },
  grid: {
    marginTop: 30,
  },
  activity: {
    background: theme.palette.secondary.light,
    backgroundImage:
      "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,212,255,1) 0%, rgba(227,51,113,0.6404761733795081) 0%, rgba(227,51,113,0.9609803750601804) 0%), url(/img/builder.jpg)",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "#fff",
  },
  secondaryColor: {
    color: theme.palette.secondary.light,
  },
  small: {
    fontSize: 13,
  },
  table: {
    minWidth: 650,
  },
  quotations: {
    display: "block",
    marginTop: 30,
  },
  iconDisabledColor: {
    color: "rgba(0, 0, 0, 0.05)",
  },
  helloText: {
    color: "#bdbdbd",
    fontFamily: "Rubik",
    letterSpacing: 1.5,
    fontSize: "1.55rem",
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  location: {
    color: "#fefefe",
    fontSize: 30,
  },
  location2: {
    color: "#fefefe",
    fontSize: 16,
  },
  index: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  cardTitle: {
    // padding: 16,
    letterSpacing: 2.5,
  },
  cardTitle2: {
    color: "rgb(82 46 39 / 81%)",
    letterSpacing: 2.5,
    fontWeight: 900,
    fontSize: 32,
    display: "block",
    lineHeight: 1,
    [theme.breakpoints.down("sm")]: {
      fontSize: 27,
    },
  },
  cardTitle3: {
    // color: "#333",
    letterSpacing: 2.5,
    fontSize: 33,
    fontWeight: 300,
    display: "block",
    lineHeight: 1.5,
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
  },
  cardContent: {
    overflow: "auto",
    padding: 16,
  },
  cardContent2: {
    padding: 16,
    overflow: "hidden",

    background: "rgb(255 193 7 / 82%)",
    height: 350,
    position: "relative",
    backgroundImage: "url(/img/pie-chart.svg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "right -50px top -10px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      backgroundPosition: "right -100px top -10px",
    },
  },
  chartIcon: {
    position: "absolute",
    top: 20,
  },
  btn: {
    padding: theme.spacing(2),
    letterSpacing: 2.5,
    paddingLeft: 20,
    paddingRight: 20,
  },
  left: {
    textAlign: "right",
    display: "block",
    fontSize: 12,
    fontStyle: "italic",
    fontFamily: "Carter One",
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const VendorDashboard = ({ vendor, weather, error }) => {
  const classes = useStyles();

  const [date, setDate] = React.useState(null);
  const [time, setTime] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  const timer = React.useRef();

  const welcome = localStorage.getItem("welcome");

  const currentDateCallback = React.useCallback(() => {
    setLoading((prev) => !prev);
    const currentDate = moment(new Date(), "LL").format("dddd, MMMM Do, YYYY");
    setDate(currentDate);
    setLoading(false);
  }, []);

  React.useEffect(() => {
    const abortController = new AbortController();
    currentDateCallback();
    return () => abortController.abort();
  }, [currentDateCallback]);

  const showTimeCallback = React.useCallback(() => {
    setTime(moment().format("LTS"));
    timer.current = setTimeout(showTimeCallback, 1000);
  }, []);

  React.useEffect(() => {
    showTimeCallback();
    return () => clearTimeout(timer.current);
  }, [showTimeCallback]);

  // let iconUrl = getWeatherIcon(
  //   weather && weather.weather && weather.weather[0].icon
  // );

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div className={classes.root}>
      {error && error.error && (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={error && error.error ? true : false}
          severity="error"
          message={(error && error.error) || feed.message}
          horizontal="center"
          vertical="bottom"
        />
      )}
      {(!welcome && vendor) || loading ? <WelcomeFeedback /> : null}
      <Typography gutterBottom variant="h4" className={classes.helloText}>
        Hello, {vendor && vendor.companyName}
      </Typography>
      <Grid container justify="space-between" alignItems="center" spacing={1}>
        <Grid item>
          <Typography variant="overline" className={classes.analytics}>
            Dashboard
          </Typography>
        </Grid>
        <Grid item>
          <Paper
            style={{ display: "flex", width: 318 }}
            component="div"
            elevation={1}
            variant="outlined"
            square
          >
            <span className={classes.date}>{date}</span>
            <Divider orientation="vertical" flexItem />

            <span className={classes.time}>{time}</span>
          </Paper>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.gridContainer2}
        justify="space-between"
        // alignItems="stretch"
        alignItems="flex-start"
        spacing={2}
        // component={Paper}
      >
        <Grid item xs={12} md={12}>
          <Card>
            <CardContent className={classes.cardContent2}>
              <Typography
                variant="overline"
                className={classes.cardTitle2}
                gutterBottom
              >
                Explore
              </Typography>
              <Typography
                gutterBottom
                className={classes.cardTitle3}
                variant="overline"
              >
                “The shorter way to do many things is to only do one thing at a
                time.”
                <span className={classes.left}>&mdash; Mozart</span>
              </Typography>

              <Button
                color="primary"
                variant="contained"
                endIcon={<Icon className="fas fa-chevron-right" />}
                className={classes.btn}
              >
                Let's get started
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.grid} wrap="wrap">
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" variant="overline">
                Your Activity
              </Typography>
              <Typography
                variant="h1"
                component="h2"
                className={classes.secondaryColor}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>
                  <span style={{ fontWeight: 900 }}>20</span>
                  <small style={{ fontSize: 20, fontWeight: 900 }}>%</small>
                </span>
                <Icon
                  className="fas fa-chart-line"
                  style={{
                    marginRight: -30,
                    fontSize: 100,
                    color: "rgba(0, 0, 0, 0.05)",
                  }}
                  color="disabled"
                  fontSize="large"
                />
              </Typography>
              <Divider light />
              <Typography component="p" className={classes.small}>
                The current statistics shows your accumulated activity on the
                platform.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card className={classes.activity}>
            <CardContent>
              <Typography gutterBottom variant="overline">
                Quotation Index
              </Typography>
              <Typography
                gutterBottom
                variant="h2"
                style={{ fontWeight: 900 }}
                className={classes.index}
              >
                {formatCurrency(1500000, "en-NG", "NGN")}
              </Typography>
              <Divider light />

              <Typography
                gutterBottom
                variant="body1"
                className={classes.small}
              >
                Estimated Quotation as of the month of {moment().format("MMMM")}
                .
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Typography
        gutterBottom
        variant="overline"
        className={classes.quotations}
      >
        Quotations
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Dessert (100g serving)</TableCell>
              <TableCell align="right">Calories</TableCell>
              <TableCell align="right">Fat&nbsp;(g)</TableCell>
              <TableCell align="right">Carbs&nbsp;(g)</TableCell>
              <TableCell align="right">Protein&nbsp;(g)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.calories}</TableCell>
                <TableCell align="right">{row.fat}</TableCell>
                <TableCell align="right">{row.carbs}</TableCell>
                <TableCell align="right">{row.protein}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  vendor: state.vendors.vendor,
  error: state.vendors.authError,
});

export default connect(mapStateToProps, null)(VendorDashboard);
