import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  TextField,
  Button,
  IconButton,
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import { getCustomers } from "../../../../actions/customerActions";
import CreateCustomerModal from "../Sales/CreateCustomerModal";
import { getContactPersonByCustomer } from "../../../../actions/contactPersonActions";
import CreateContactModal from "../Sales/CreateContactModal";
import { createLead, getLeads } from "../../../../actions/leadsAction";
import Feedback from "../../../../Feedback";
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },
  btn: {
    margin: "30px 0",
    width: 300,
    letterSpacing: 2.5,
    padding: theme.spacing(2),
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down("sm")]: {
      width: 200,
      // minWidth: "100%",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 10000,
    color: "#fff",
  },
  calendar: {
        border: "1px solid #cacaca",
        // boxShadow: theme.shadows[2],
        borderRadius: theme.shape.borderRadius,
        width: "100%",
        background: theme.palette.type === "light" ? "#fff" : "#424242",
    },
    mb2: {
        // marginBottom: 5,
    },
    mb20: {
        marginBottom: 20,
    },
    labelTwo: {
        fontSize: 13,
        display: "inline-block",
        color: theme.palette.type === "light" ? "#333" : "#fff",
        marginBottom: 5,
    },
}));

const INIT_STATE = {
  title: "",
  address: "",
  website: "",
  email: "",
  designation: "",
  phone: "",
  lineOfBusiness: "",
  relationship: "",
  yearsInBusiness: "",
  comment:"",
};

function CreateLeadsModal({ open, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  const [openCreateCustomer, setOpenCreateCustomer] = useState(false);
  const [openCreateContact, setOpenCreateContact] = useState(false);
  const [lastContactDate, setLastContactDate] = useState(new Date());
  const [nextContactDate, setNextContactDate] = useState(new Date());
  const [newCustomer, setNewCustomer] = useState(null);
  const [newContactPerson, setNewContactPerson] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [inputValueTwo, setInputValueTwo] = useState("");

  const { error, success, loading: loadLead } = useSelector(
    (state) => state.leads
  );
  const { profile } = useSelector((state) => state.user);
  const { customers, loading } = useSelector((state) => state.customer);
  const { contactPersons, loading: load } = useSelector(
    (state) => state.contactPerson
  );

  useEffect(() => {
    let abortController = new AbortController();

    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
      dispatch({
        type: "RESET_LEADS",
      });
    };
  }, [error, dispatch]);

  useEffect(() => {
    let abortController = new AbortController();

    if (success) {
      dispatch(getLeads());

      setState(INIT_STATE);
      setNewCustomer(null);
      setNewContactPerson(null);
      setInputValue("");
      setInputValueTwo("");
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: "Lead Created Successfully.",
        success: true,
      }));
    }
    return () => {
      abortController.abort();
      dispatch({
        type: "RESET_LEADS",
      });
    };
  }, [success, dispatch]);



  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  useEffect(() => {
    if (newCustomer) {
      dispatch(getContactPersonByCustomer(newCustomer?._id));
    }
  }, [dispatch, newCustomer]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      website: inputValue ? newCustomer?.website : "",
      address: inputValue ? newCustomer?.address : "",
    }));
  }, [newCustomer, inputValue]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      email: inputValueTwo ? newContactPerson?.email : "",
      designation: inputValueTwo ? newContactPerson?.designation : "",
      phone: inputValueTwo ? newContactPerson?.phone : "",
    }));
  }, [newContactPerson, inputValueTwo]);

  const handleCreateOpenCustomer = () => {
    setOpenCreateCustomer(true);
    handleClose();
  };
  const handleCreateCloseCustomer = () => {
    setOpenCreateCustomer(false);
  };

  const handleCreateOpenContact = () => {
    setOpenCreateContact(true);
    handleClose();
  };
  const handleCreateCloseContact = () => {
    setOpenCreateContact(false);
  };

  const handleChangeLastContactDate = (date) => {
    setLastContactDate(date);
  };

  const handleChangeNextContactDate = (date) => {
    setNextContactDate(date);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      ...state,
      customer: newCustomer?._id,
      lastContactDate: lastContactDate,
      nextContactDate: nextContactDate,
      contactPerson: newContactPerson?._id,
    };

    dispatch(createLead(data));
    handleClose();
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () =>
    !state.title || !newCustomer || !newContactPerson || !state.comment || !lastContactDate || !nextContactDate;

  return (
    <div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}

      <CreateCustomerModal
        {...{
          openCreate: openCreateCustomer,
          handleCreateClose: handleCreateCloseCustomer,
        }}
      />
      <CreateContactModal
        {...{
          openCreate: openCreateContact,
          handleCreateClose: handleCreateCloseContact,
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Create Leads
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item xs={12} md={6}>
                <TextField
                  id="salesPerson"
                  name="salesPerson"
                  label="Sales Person"
                  value={profile?.userName || ""}
                  variant="outlined"
                  fullWidth
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel>Lead Source</InputLabel>
                  <Select
                    name="title"
                    inputProps={{
                      id: "title",
                    }}
                    value={state.title || ""}
                    onChange={handleChange}
                  >
                    <MenuItem value="" disabled>
                      <em>--- Select ---</em>
                    </MenuItem>
                    <MenuItem value="Return Customer">Return Customer</MenuItem>
                    <MenuItem value="Customer Inquiry">Customer Inquiry</MenuItem>
                    <MenuItem value="Website Traffic">Website Traffic</MenuItem>
                    <MenuItem value="Referrals">Referrals</MenuItem>
                    <MenuItem value="Sales Call">Sales Call</MenuItem>
                    <MenuItem value="Event Networking">Event Networking</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  value={newCustomer}
                  debug
                  onChange={(event, newValue) => {
                    setNewCustomer(newValue);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  getOptionLabel={(option) => option.name}
                  options={customers}
                  noOptionsText={
                    <>
                      {inputValue.length > 0 ? (
                        <Button
                          variant="outlined"
                          fullWidth
                          onClick={handleCreateOpenCustomer}
                        >
                          Create Customer
                        </Button>
                      ) : (
                        "No Result"
                      )}
                    </>
                  }
                  renderOption={(option) => (
                    <React.Fragment>{option.name}</React.Fragment>
                  )}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        placeholder="Type something..."
                        fullWidth
                        required
                        label="Select Customer"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-customer", // disable autocomplete and autofill
                        }}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="lineOfBusiness"
                  name="lineOfBusiness"
                  label="Line of Business"
                  value={state.lineOfBusiness || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel>Relationship Demographic</InputLabel>
                  <Select
                    name="relationship"
                    inputProps={{
                      id: "relationship",
                    }}
                    value={state.relationship || ""}
                    onChange={handleChange}
                  >
                    <MenuItem value="" disabled>
                      <em>--- Select ---</em>
                    </MenuItem>
                    <MenuItem value="Topsides Services">Topsides Services</MenuItem>
                    <MenuItem value="Subsea Services">Subsea Services</MenuItem>
                    <MenuItem value="Smart Manufacturing Services">Smart Manufacturing Services</MenuItem>
                    <MenuItem value="Operations and Production Services">Operations and Production Services</MenuItem>
                    <MenuItem value="UAV Services">UAV Services</MenuItem>
                    <MenuItem value="Sustainable Energy Services">Sustainable Energy Services</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel>Years In Business</InputLabel>
                  <Select
                    name="yearsInBusiness"
                    inputProps={{
                      id: "yearsInBusiness",
                    }}
                    value={state.yearsInBusiness || ""}
                    onChange={handleChange}
                  >
                    <MenuItem value="" disabled>
                      <em>--- Select ---</em>
                    </MenuItem>
                    <MenuItem value="0-5 Years">0-5 Years</MenuItem>
                    <MenuItem value="5-10 Years">5-10 Years</MenuItem>
                    <MenuItem value="Above 10 Years">Above 10 Years</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="website"
                  name="website"
                  label="Website"
                  value={state.website || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="address"
                  name="address"
                  label="Customer Address"
                  value={state.address || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  value={newContactPerson}
                  debug
                  onChange={(event, newValue) => {
                    setNewContactPerson(newValue);
                  }}
                  inputValue={inputValueTwo}
                  onInputChange={(event, newInputValue) => {
                    setInputValueTwo(newInputValue);
                  }}
                  getOptionLabel={(option) => option.name}
                  options={contactPersons}
                  noOptionsText={
                    <>
                      {inputValueTwo.length > 0 ? (
                        <Button
                          variant="outlined"
                          fullWidth
                          onClick={handleCreateOpenContact}
                          style={{ zIndex: 9999 }}
                        >
                          Create Contact Person
                        </Button>
                      ) : (
                        "No Result"
                      )}
                    </>
                  }
                  renderOption={(option) => (
                    <React.Fragment>{option.name}</React.Fragment>
                  )}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        fullWidth
                        placeholder="Type something..."
                        required
                        label="Select Contact Person"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-contact-person", // disable autocomplete and autofill
                        }}
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="email"
                  type="email"
                  name="email"
                  label="Contact Email"
                  value={state.email || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="designation"
                  name="designation"
                  label="Designation"
                  value={state.designation || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="phone"
                  type="tel"
                  name="phone"
                  label="Contact Phone"
                  value={state.phone || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  className={classes.marginBottom}
                  autoOk
                  format="DD/MM/yyyy"
                  views={["year", "month", "date"]}
                  label="Last Contact Date"
                  fullWidth
                  required
                  inputVariant="outlined"
                  value={lastContactDate}
                  onChange={handleChangeLastContactDate}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  format="DD/MM/yyyy"
                  views={["year", "month", "date"]}
                  label="Next Contact Date"
                  fullWidth
                  required
                  inputVariant="outlined"
                  value={nextContactDate}
                  onChange={handleChangeNextContactDate}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="comment"
                  type="text"
                  multiline
                  rows={3}
                  name="comment"
                  label="Comment and Notes"
                  value={state.comment || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>

            </Grid>
            <Grid container justify="center" alignItems="center">
              <Button
                type="submit"
                variant="contained"
                fullWidth
                color="primary"
                className={classes.btn}
                disabled={isValid() || loadLead}
                endIcon={
                  loadLead ? (
                    <CircularProgress size={20} color="primary" />
                  ) : null
                }
              >
                {loadLead ? "Loading..." : "Create"}
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      </MuiPickersUtilsProvider>
      <Backdrop className={classes.backdrop} open={loading || load || loadLead}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default CreateLeadsModal;
