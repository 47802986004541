import React, { useState, useEffect, useCallback } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tabs,
  Box,
  Tab,
  AppBar,
  // withStyles,
  // Badge,
  IconButton,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Tooltip,
  Toolbar,
  Switch,
  ButtonGroup,
} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import CloseIcon from "@material-ui/icons/Close";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { connect, useDispatch } from "react-redux";
import { getDivisions } from "../../../actions/divisionActions";
import { getGroupsByModule } from "../../../actions/groupsAction";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { countries } from "../../../utils";

import Feedback from "../../../Feedback";
import {
  getUserById,
  getUsers,
  updateUser,
  getProfile,
  resendPasswordLink,
} from "../../../actions/userActions";
import { getJobTitles } from "../../../actions/jobTitleActions";
import {
  getAllModules,
  getAllModulesAndSubmodules,
} from "../../../actions/moduleAction";
import UserGroupsTable from "../UserGroupsTable";

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },
  container: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: 2,
    },
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 6px",
    },
  },
  btn: {
    letterSpacing: 2.5,
    padding: theme.spacing(2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

// const RippleEffect = withStyles((theme) => ({
//   root: {
//     backgroundColor: "#44b700",
//     color: "#44b700",
//     boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
//     width: "10px",
//     height: "10px",
//     borderRadius: "50%",
//     marginTop: -15,
//     "&::after": {
//       position: "absolute",
//       top: 0,
//       left: 0,
//       width: "100%",
//       height: "100%",
//       borderRadius: "50%",
//       animation: "$ripple 1.2s infinite ease-in-out",
//       border: "1px solid currentColor",
//       content: '""',
//     },
//   },
//   "@keyframes ripple": {
//     "0%": {
//       transform: "scale(.8)",
//       opacity: 1,
//     },
//     "100%": {
//       transform: "scale(2.4)",
//       opacity: 0,
//     },
//   },
// }))(Badge);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const INIT_STATE = {
  firstname: "",
  lastname: "",
  division: "",
  divisions: [],
  email: "",
  employeeId: "",
  jobTitle: "",
  phone: "",
  countryCode: "",
  module: "",
  groups: "",
};

const EditUserModal = ({
  openEdit,
  handleCloseEdit,
  id,
  getUserById,
  user,
  error,
  getDivisions,
  getJobTitles,
  jobTitles,
  groups,
  getGroupsByModule,
  getProfile,
  getUsers,
  updateUser,
  updateTwoFactor,
  getAllModules,
  getAllModulesAndSubmodules,
  allmodules,
  modules,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [state, setState] = useState(INIT_STATE);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadGroups, setLoadGroups] = useState(false);
  const [code, setCode] = useState(null);
  const [loadTitles, setLoadTitles] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [twoFactor, setTwoFactor] = React.useState(false);

  const [allTitles, setAllTitles] = useState([]);
  const [groupList, setGroupList] = useState([]);

  // const [errorState, setErrorState] = useState({
  //   success: false,
  //   error: "",
  // });
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  useEffect(() => {
    getAllModulesAndSubmodules();
  }, [getAllModulesAndSubmodules]);

  const getUserCallback = useCallback(() => {
    setOpenBackdrop((prev) => !prev);
    getUserById(id).then(() => {
      setOpenBackdrop(false);
    });
  }, [getUserById, id]);

  useEffect(() => {
    getUserCallback();
  }, [getUserCallback]);

  useEffect(() => {
    setLoad((prev) => !prev);
    getDivisions().then((data) => {
      setLoad(false);
      setState((prevState) => ({
        ...prevState,
        divisions: data.data,
      }));
    });
  }, [getDivisions]);

  useEffect(() => {
    let code = countries
      .map((country) => country)
      .filter(
        (item) =>
          item.phone ===
          (user && user.countryCode && user.countryCode.split("+")[1])
      );

    setState((prev) => ({
      ...prev,
      firstname: user && user.firstName,
      lastname: user && user.lastName,
      division: user && user.division && user.division._id,
      email: user && user.email,
      employeeId: user && user.employeeId,
      jobTitle: user && user.jobTitle && user.jobTitle._id,
      phone: user && user.phone,
    }));

    console.log(user)

    setCode(code && code[0]);
    setTwoFactor(user && user.isAuth);
  }, [user, groupList]);

  useEffect(() => {
    setGroupList(user && user.groups);
  }, [user]);

  // useEffect(() => {
  //   setTwoFactor(user && user.isAuth);
  // }, [user]);

  const getGroupsCallback = useCallback(() => {
    setLoadGroups(true);
    getGroupsByModule(state.module);
    setLoadGroups(false);
  }, [state.module, getGroupsByModule]);

  useEffect(() => {
    const abortController = new AbortController();
    getGroupsCallback();
    return () => {
      abortController.abort();
    };
  }, [getGroupsCallback]);

  const jobTitleCallback = React.useCallback(() => {
    setLoadTitles((prev) => !prev);
    getJobTitles().then(() => {
      setLoadTitles(false);
    });
  }, [getJobTitles]);

  useEffect(() => {
    jobTitleCallback();
  }, [jobTitleCallback]);

  useEffect(() => {
    setAllTitles(jobTitles);
  }, [jobTitles]);

  // useEffect(() => {
  //   setErrorState(error && error);
  // }, [error]);

  const defaultProps = {
    options: countries,
    getOptionLabel: (option) => option.phone,
    renderOption: (option) => (
      <React.Fragment>
        {/* <span>{countryToFlag(option.code)}</span> */}
        {option.label} ({option.code}) +{option.phone}
      </React.Fragment>
    ),
  };

  const handleToggleTwoFA = (e) => {
    // isFirstRun.current = true;
    setTwoFactor(e.target.checked);
  };

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handlePageChange = (event, newValue) => {
    setPage(newValue);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleRemoveList = (i) => {
    setGroupList((prev) => prev.filter((_, index) => index !== i));
  };

  const handleAddGroups = (e) => {
    const grp = state.groups.split("%");
    const data = {
      module: grp[1],
      _id: grp[0],
    };
    setGroupList((prev) => [...prev, data]);

    setState((prevState) => ({
      ...prevState,
      module: "",
      groups: "",
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    const data = {
      id,
      firstName: state.firstname,
      isAuth: twoFactor,
      lastName: state.lastname,
      email: state.email,
      employeeId: state.employeeId,
      division: state.division,
      jobTitle: state.jobTitle,
      phone: state.phone,
      countryCode: code ? `+${code.phone}` : "",
      groups: groupList.map((grp) => grp._id),
    };

    try {
      const doc = await new Promise((resolve) => {
        resolve(updateUser(data));
      });
      if (doc.success === true) {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message: "User data updated successfully",
          success: true,
        });
        getUserById(id);
        getUsers();
        getProfile();
        handleCloseEdit();
      } else {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message:
            doc.error ||
            "An Unexpected error has occurred, please contact admin.",
          success: false,
        });
        handleCloseEdit();
      }
    } catch (error) {
      setLoading(false);
      setFeed({
        loading: false,
        open: !feed.open,
        message:
          error.error ||
          "An Unexpected error has occurred, please contact admin.",
        success: false,
      });
    }
  };

  const resendPasswordLinkAction = () => {
    setLoading((prev) => !prev);
    let data = {
      email: state.email,
      path: "setuserpassword",
    };
    return new Promise((resolve, reject) => {
      resolve(dispatch(resendPasswordLink(data)));
    })
      .then((doc) => {
        setLoading(false);
        setFeed((prev) => ({
          ...prev,

          loading: false,
          open: !prev.open,
          message: doc.message,
          success: true,
        }));
      })
      .catch((err) => {
        setLoading(false);

        setFeed((prev) => ({
          ...prev,

          loading: false,
          open: !prev.open,
          message: err.error,
          success: false,
        }));
      });
  };

  // console.log(groupList);

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      {/* {error && error.success === false ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message || "User data updated successfully"}
        />
      )} */}
      {/* {errorState ? (
        <Feedback
          open={!errorState.success}
          severity="error"
          message={errorState.error}
        />
      ) : null} */}
      <Dialog
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
        // fullScreen={fullScreen}
        fullScreen
      >
        <AppBar
          className={classes.appBar}
          variant="elevation"
          position="fixed"
          color="default"
        >
          <Toolbar>
            <Tooltip title="close" arrow>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseEdit}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <div className={classes.container}>
          <DialogTitle id="form-dialog-title">
            <Typography variant="overline" className={classes.title}>
              Edit User Data
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <AppBar position="static" color="transparent" elevation={6}>
              <Tabs
                variant="fullWidth"
                value={page}
                onChange={handlePageChange}
                aria-label="nav tabs example"
              >
                <LinkTab
                  label={
                    <Typography variant="overline" className={classes.title}>
                      Credentials
                    </Typography>
                  }
                  href="/setup/users"
                  {...a11yProps(0)}
                />
                <LinkTab
                  label={
                    <Typography variant="overline" className={classes.title}>
                      Security
                    </Typography>
                  }
                  href={`/setup/users`}
                  {...a11yProps(1)}
                />
                <LinkTab
                  label={
                    <Typography variant="overline" className={classes.title}>
                      Groups
                    </Typography>
                  }
                  href={`/setup/users`}
                  {...a11yProps(2)}
                />
              </Tabs>
            </AppBar>
            <form onSubmit={handleUpdate}>
              <Paper className={classes.paper} variant="outlined">
                <TabPanel value={page} index={0}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="firstname"
                        name="firstname"
                        label="FirstName"
                        fullWidth
                        onChange={handleChange}
                        value={
                          openBackdrop ? "Loading..." : state.firstname || ""
                        }
                        disabled={openBackdrop}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="lastname"
                        name="lastname"
                        label="LastName"
                        fullWidth
                        onChange={handleChange}
                        value={
                          openBackdrop ? "Loading..." : state.lastname || ""
                        }
                        disabled={openBackdrop}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="division-label-id">Division</InputLabel>
                        <Select
                          labelId="division-label-id"
                          id="division"
                          label="Division"
                          inputProps={{
                            id: "division",
                            name: "division",
                          }}
                          onChange={handleChange}
                          value={state.division || ""}
                          name="division"
                        >
                          <MenuItem value="" disabled>
                            --- Select Division ---
                          </MenuItem>
                          {load ? (
                            <MenuItem>Loading</MenuItem>
                          ) : (
                            state.divisions &&
                            state.divisions.map((division) => (
                              <MenuItem key={division._id} value={division._id}>
                                {division.name}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        type="email"
                        id="email"
                        name="email"
                        label="Email"
                        fullWidth
                        onChange={handleChange}
                        value={openBackdrop ? "Loading..." : state.email || ""}
                        disabled={openBackdrop}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="employeeId"
                        name="employeeId"
                        label="EmployeeId"
                        fullWidth
                        onChange={handleChange}
                        value={
                          openBackdrop ? "Loading..." : state.employeeId || ""
                        }
                        disabled={openBackdrop}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="division-label-id">
                          Job Title
                        </InputLabel>
                        <Select
                          labelId="division-label-id"
                          id="jobTitle"
                          label="Job Title"
                          inputProps={{
                            id: "jobTitle",
                            name: "jobTitle",
                          }}
                          onChange={handleChange}
                          value={state.jobTitle || ""}
                        >
                          <MenuItem value="" disabled>
                            --- Select JobTitle ---
                          </MenuItem>
                          {loadTitles ? (
                            <MenuItem>Loading</MenuItem>
                          ) : (
                            allTitles &&
                            allTitles.map((title) => (
                              <MenuItem key={title._id} value={title._id}>
                                {title.name}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        id="phone"
                        name="phone"
                        label="Phone"
                        fullWidth
                        onChange={handleChange}
                        value={openBackdrop ? "Loading..." : state.phone || ""}
                        disabled={openBackdrop}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        className={classes.textField}
                        id="country-code"
                        {...defaultProps}
                        value={code || null}
                        onChange={(event, newValue) => {
                          setCode(newValue);
                        }}
                        getOptionLabel={(option) => option.label}
                        renderOption={(option) => (
                          <React.Fragment>
                            <span>{countryToFlag(option.code)}</span>
                            {option.label} ({option.code}) +{option.phone}
                          </React.Fragment>
                        )}
                        style={{ marginTop: -15 }}
                        autoComplete
                        autoHighlight
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Country Code"
                            margin="normal"
                            fullWidth
                            variant="outlined"
                            // error={!code ? true : false}
                            value={code}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "disabled",
                              name: "countryCode",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Divider />
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        marginTop: 1,
                        textAlign: "center",
                      }}
                    >
                      <Button
                        variant="outlined"
                        // color="default"
                        fullWidth
                        onClick={() => setPage(1)}
                        size="large"
                        className={classes.saveBtn}
                        endIcon={<ArrowForwardIosIcon />}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={page} index={1}>
                  <Grid container justify="center">
                    <Grid item xs={12} md={12}>
                      <Typography
                        variant="h6"
                        style={{ textTransform: "uppercase" }}
                      >
                        Two Factor Authentication
                      </Typography>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={twoFactor}
                            onChange={handleToggleTwoFA}
                          />
                        }
                        label={
                          <Typography variant="overline">
                            {twoFactor === true ? "Enabled" : "Disabled"}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12} style={{ marginTop: 30 }}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography
                        variant="h6"
                        style={{ textTransform: "uppercase" }}
                      >
                        Resend Password Link To User
                      </Typography>
                      <Button
                        color="primary"
                        variant="contained"
                        endIcon={
                          loading ? (
                            <CircularProgress size={20} color="primary" />
                          ) : (
                            <LinkIcon />
                          )
                        }
                        onClick={resendPasswordLinkAction}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Resend Password Link"}
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        marginTop: 1,
                        textAlign: "center",
                      }}
                    >
                      <ButtonGroup>
                        <Button
                          variant="outlined"
                          color="secondary"
                          fullWidth
                          onClick={() => setPage(0)}
                          size="large"
                          className={classes.saveBtn}
                          startIcon={<ArrowBackIosIcon />}
                        >
                          Prev
                        </Button>

                        <Button
                          variant="outlined"
                          color="primary"
                          fullWidth
                          onClick={() => setPage(2)}
                          size="large"
                          className={classes.saveBtn}
                          endIcon={<ArrowForwardIosIcon />}
                        >
                          Next
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={page} index={2}>
                  <Grid
                    container
                    spacing={3}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={4}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="module-label-id">Module</InputLabel>
                        <Select
                          labelId="module-label-id"
                          id="module"
                          name="module"
                          onChange={handleChange}
                          value={state.module || ""}
                        >
                          {allmodules &&
                            allmodules.map((mod) => (
                              <MenuItem value={mod.code} key={mod._id}>
                                {mod.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="module-label-id">Groups</InputLabel>
                        <Select
                          labelId="module-label-id"
                          id="groups"
                          name="groups"
                          onChange={handleChange}
                          value={state.groups || ""}
                        >
                          <MenuItem value="select" disabled>
                            ----Select Groups----
                          </MenuItem>
                          {loadGroups ? (
                            <MenuItem>Loading...</MenuItem>
                          ) : (
                            groups.data &&
                            groups.data.map((group) => (
                              <MenuItem
                                key={group._id}
                                value={`${group._id}%${group.displayName}`}
                              >
                                {group.name}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleAddGroups}
                        fullWidth
                        disabled={!state.module || !state.groups}
                        className={classes.btn}
                      >
                        {loadGroups ? "Loading..." : "Add"}
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      <UserGroupsTable
                        {...{ groupList, handleRemoveList, isEdit: true }}
                      />
                      {/* {groupList &&
                        groupList.map((group, i) => (
                          <div
                            key={i}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <RippleEffect variant="dot" overlap="circle" />
                            <Paper className={classes.paper2}>
                              <Typography variant="overline" color="initial">
                                {group && group.module}_{group && group.name}
                              </Typography>

                              <IconButton onClick={() => handleRemoveList(i)}>
                                <CloseIcon color="inherit" />
                              </IconButton>
                            </Paper>
                          </div>
                        ))} */}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        marginTop: 1,
                        textAlign: "center",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        className={classes.saveBtn}
                        disabled={loading}
                        endIcon={
                          loading && (
                            <CircularProgress size={20} color="primary" />
                          )
                        }
                      >
                        {loading ? "Loading..." : "Update"}
                      </Button>
                    </Grid>
                  </Grid>
                </TabPanel>
              </Paper>
            </form>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user.data,
  error: state.user.error,
  jobTitles: state.jobTitle.jobTitles.data,
  groups: state.groups.groups,
  modules: state.module.modules,
  allmodules: state.module.allmodules,
});

export default connect(mapStateToProps, {
  getUserById,
  getDivisions,
  getJobTitles,
  getGroupsByModule,
  getUsers,
  updateUser,
  getProfile,
  getAllModules,
  getAllModulesAndSubmodules,
})(EditUserModal);
