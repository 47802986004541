import React, { useEffect, useState } from "react";
import {
    makeStyles,
    Dialog,
    DialogContent,
    Paper,
    DialogTitle,
    Grid,
    Typography,
    Divider,
    IconButton,
    ButtonGroup,
    Button,
    DialogActions,
    TextField,
    darken,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { connect, useDispatch, useSelector } from "react-redux";
import Feedback from "../../../../../Feedback";
import { getQuotations, sendQuotation } from "../../../../../actions/quotationActions";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 20,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(4),
        marginBottom: 30,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        paddingBottom: 30,
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginBottom: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },
    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
            // color: "#fff",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        "&:disabled": {
            background: "#ccc",
            color: "#333",
        },
        "&:disabled:hover": {
            background: "#cccccc",
        },
    },
    small: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        color: theme.palette.warning.main,
    },
}));

const SendQuotationModal = ({ openSend, handleCloseSend, id, sendQuotation }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { error, loading, quotation } = useSelector((state) => state.quotation);
    const [comment, setComment] = useState("");

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        setComment(quotation && quotation.sendComment ? quotation.sendComment : "")
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error, quotation]);

    const handleChange = (e) => {
        e.persist();
        setComment(e.target.value);
    };

    const handleSend = async () => {
        const data = {
            comment,
            id,
            path: `customerquotation`,
        };
        const res = await new Promise((resolve, reject) => {
            resolve(sendQuotation(data));
        })
        if (res) {
            setTimeout(() => {
                handleCloseSend();
            }, 1500);
            dispatch(getQuotations());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Quotation Sent to Customer Successfully.",
                success: true,
            }));
            const link = "/sales/quotation";
            setTimeout(() => {
                history.push(link);
            }, 2000);
        }
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}
            <Dialog
                open={openSend}
                onClose={handleCloseSend}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="overline" className={classes.title}>
                                Send Quotation
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCloseSend}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Paper style={{ padding: 20, marginTop: 15, marginBottom: 25, borderWidth: 1, borderColor: "#444", borderStyle: "solid" }}>
                        <Typography variant="overline" className={classes.title2}>
                            Please write a content for the Quotation Document body below.
                        </Typography>
                    </Paper>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Quotation Document Body Content"
                        value={comment}
                        name="comment"
                        onChange={handleChange}
                        multiline
                        disabled={loading}
                        rows={8}
                        required
                        helperText={
                            <Typography variant="overline" className={classes.small}>
                                Please add a content for the document body of the Quotation to the customer.
                            </Typography>
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <ButtonGroup>
                        <Button onClick={handleCloseSend} className={classes.saveBtn}>
                            Cancel
                        </Button>
                        <Button
                            className={classes.saveBtn2}
                            disabled={loading || !comment}
                            onClick={handleSend}
                        >
                            {loading ? "Loading..." : "Send"}
                        </Button>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default connect(null, { sendQuotation })(SendQuotationModal);
