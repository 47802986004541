import * as types from "../types/purchasing";
import axios from "axios";

const token = localStorage.getItem("token:key");
const URL = `/api/v1/purchasing`;

//////////////////////////////////////////////
/////////// Purchase Requisition /////////////
//////////////////////////////////////////////
export const createPurchaseRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_PURCHASE_REQUISITION_REQUEST,
        });
        const { data } = await axios.post(`${URL}/requisition`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_PURCHASE_REQUISITION,
            payload: data.data,
        });
        return data.data;
    } catch (error) {
        console.log(error.response)
        

        dispatch({
            type: types.CREATE_PURCHASE_REQUISITION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getPurchaseRequisitions = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_PURCHASE_REQUISITIONS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/requisition`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ALL_PURCHASE_REQUISITIONS,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.GET_ALL_PURCHASE_REQUISITIONS_FAIL,
            payload: error
        });
    }
};


export const getAcceptedPurchaseRequisitions = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ACCEPTED_PURCHASE_REQUISITIONS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/requisition/accepted`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log(data)
        dispatch({
            type: types.GET_ACCEPTED_PURCHASE_REQUISITIONS,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.GET_ACCEPTED_PURCHASE_REQUISITIONS_FAIL,
            payload: error
        });
    }
};

export const getPurchaseRequisition = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_PURCHASE_REQUISITION_REQUEST,
        });
        const { data } = await axios.get(`${URL}/requisition/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_PURCHASE_REQUISITION,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_PURCHASE_REQUISITION_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updatePurchaseRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_PURCHASE_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_PURCHASE_REQUISITION,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_PURCHASE_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deletePurchaseRequisition = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_PURCHASE_REQUISITION_REQUEST,
        });
        await axios.delete(`${URL}/requisition/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_PURCHASE_REQUISITION,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_PURCHASE_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const submitPurchaseRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_PURCHASE_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SUBMIT_PURCHASE_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_PURCHASE_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const approvePurchaseRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.APPROVE_PURCHASE_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/approve/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.APPROVE_PURCHASE_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.APPROVE_PURCHASE_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const rejectPurchaseRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REJECT_PURCHASE_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/reject/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REJECT_PURCHASE_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REJECT_PURCHASE_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const acceptPurchaseRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.ACCEPT_PURCHASE_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/accept/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.ACCEPT_PURCHASE_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.ACCEPT_PURCHASE_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const declinePurchaseRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DECLINE_PURCHASE_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/decline/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        console.log(data)
        
        dispatch({
            type: types.DECLINE_PURCHASE_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DECLINE_PURCHASE_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const reviewPurchaseRequisition = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REVIEW_PURCHASE_REQUISITION_REQUEST,
        });
        const { data } = await axios.put(`${URL}/requisition/review/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REVIEW_PURCHASE_REQUISITION,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REVIEW_PURCHASE_REQUISITION_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


//////////////////////////////////////////////
///////////// Request For Quote  /////////////
//////////////////////////////////////////////
export const updateRequestForQuote = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_REQUEST_FOR_QUOTE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/rfqs/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_REQUEST_FOR_QUOTE,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_REQUEST_FOR_QUOTE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const submitRequestForQuote = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_REQUEST_FOR_QUOTE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/rfqs/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SUBMIT_REQUEST_FOR_QUOTE,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_REQUEST_FOR_QUOTE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteRequestForQuote = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_REQUEST_FOR_QUOTE_REQUEST,
        });
        await axios.delete(`${URL}/rfqs/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_REQUEST_FOR_QUOTE,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_REQUEST_FOR_QUOTE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const acceptRequestForQuote = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.ACCEPT_REQUEST_FOR_QUOTE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/rfqs/accept/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.ACCEPT_REQUEST_FOR_QUOTE,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.ACCEPT_REQUEST_FOR_QUOTE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const declineRequestForQuote = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DECLINE_REQUEST_FOR_QUOTE_REQUEST,
        });
        const { data } = await axios.put(`${URL}/rfqs/decline/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DECLINE_REQUEST_FOR_QUOTE,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.DECLINE_REQUEST_FOR_QUOTE_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getRequestForQuotes = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_REQUEST_FOR_QUOTES_REQUEST,
        });
        const { data } = await axios.get(`${URL}/rfqs`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ALL_REQUEST_FOR_QUOTES,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.GET_ALL_REQUEST_FOR_QUOTES_FAIL,
            payload: error
        });
    }
};

export const getRequestForQuote = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ONE_REQUEST_FOR_QUOTE_REQUEST,
        });
        const { data } = await axios.get(`${URL}/rfqs/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ONE_REQUEST_FOR_QUOTE,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_ONE_REQUEST_FOR_QUOTE_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getAcceptedRequestForQuotes = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ACCEPTED_REQUEST_FOR_QUOTES_REQUEST,
        });
        const { data } = await axios.get(`${URL}/rfqs/accepted`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ACCEPTED_REQUEST_FOR_QUOTES,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.GET_ACCEPTED_REQUEST_FOR_QUOTES_FAIL,
            payload: error
        });
    }
};

//////////////////////////////////////////////
//////////////// Open Market  ////////////////
//////////////////////////////////////////////
export const getAllOpenMarkets = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_OPEN_MARKETS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/opms`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ALL_OPEN_MARKETS,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.GET_ALL_OPEN_MARKETS_FAIL,
            payload: error
        });
    }
};

export const getOneOpenMarket = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ONE_OPEN_MARKET_REQUEST,
        });
        const { data } = await axios.get(`${URL}/opms/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ONE_OPEN_MARKET,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_ONE_OPEN_MARKET_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const getDivisionOpenMarket = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_OPEN_MARKETS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/opms/division/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ALL_OPEN_MARKETS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_ALL_OPEN_MARKETS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updateOpenMarket = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_OPEN_MARKET_REQUEST,
        });
        const { data } = await axios.put(`${URL}/opms/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_OPEN_MARKET,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_OPEN_MARKET_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deleteOpenMarket = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_OPEN_MARKET_REQUEST,
        });
        await axios.delete(`${URL}/opms/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_OPEN_MARKET,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_OPEN_MARKET_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const submitOpenMarket = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_OPEN_MARKET_REQUEST,
        });
        const { data } = await axios.put(`${URL}/opms/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SUBMIT_OPEN_MARKET,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_OPEN_MARKET_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const approveOpenMarket = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.APPROVE_OPEN_MARKET_REQUEST,
        });
        const { data } = await axios.put(`${URL}/opms/approve/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.APPROVE_OPEN_MARKET,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.APPROVE_OPEN_MARKET_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const rejectOpenMarket = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REJECT_OPEN_MARKET_REQUEST,
        });
        const { data } = await axios.put(`${URL}/opms/reject/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REJECT_OPEN_MARKET,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REJECT_OPEN_MARKET_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


//////////////////////////////////////////////
////////////// Purchase Order ////////////////
//////////////////////////////////////////////
export const createPurchaseOrder = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.CREATE_PURCHASE_ORDER_REQUEST,
        });
        const { data } = await axios.post(`${URL}/order`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.CREATE_PURCHASE_ORDER,
            payload: data.data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.CREATE_PURCHASE_ORDER_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getPurchaseOrders = () => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_ALL_PURCHASE_ORDERS_REQUEST,
        });
        const { data } = await axios.get(`${URL}/order`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_ALL_PURCHASE_ORDERS,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.GET_ALL_PURCHASE_ORDERS_FAIL,
            payload: error
        });
    }
};

export const getVendorPurchaseOrder = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_VENDOR_PURCHASE_ORDERS_REQUEST,
        });

        const { data } = await axios.get(`${URL}/order/vendor/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_VENDOR_PURCHASE_ORDERS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_VENDOR_PURCHASE_ORDERS_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const getPurchaseOrder = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.GET_PURCHASE_ORDER_REQUEST,
        });
        const { data } = await axios.get(`${URL}/order/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.GET_PURCHASE_ORDER,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: types.GET_PURCHASE_ORDER_FAIL,
            payload:
                error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const updatePurchaseOrder = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.UPDATE_PURCHASE_ORDER_REQUEST,
        });
        const { data } = await axios.put(`${URL}/order/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.UPDATE_PURCHASE_ORDER,
            payload: data,
        });
        return data.data;
    } catch (error) {
        dispatch({
            type: types.UPDATE_PURCHASE_ORDER_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const deletePurchaseOrder = (id) => async (dispatch) => {
    try {
        dispatch({
            type: types.DELETE_PURCHASE_ORDER_REQUEST,
        });
        await axios.delete(`${URL}/order/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DELETE_PURCHASE_ORDER,
            payload: id,
        });
    } catch (error) {
        dispatch({
            type: types.DELETE_PURCHASE_ORDER_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const submitPurchaseOrder = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.SUBMIT_PURCHASE_ORDER_REQUEST,
        });
        const { data } = await axios.put(`${URL}/order/submit/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.SUBMIT_PURCHASE_ORDER,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.SUBMIT_PURCHASE_ORDER_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const approvePurchaseOrder = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.APPROVE_PURCHASE_ORDER_REQUEST,
        });
        const { data } = await axios.put(`${URL}/order/approve/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.APPROVE_PURCHASE_ORDER,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.APPROVE_PURCHASE_ORDER_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const rejectPurchaseOrder = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REJECT_PURCHASE_ORDER_REQUEST,
        });
        const { data } = await axios.put(`${URL}/order/reject/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REJECT_PURCHASE_ORDER,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REJECT_PURCHASE_ORDER_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const acceptPurchaseOrder = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.ACCEPT_PURCHASE_ORDER_REQUEST,
        });
        const { data } = await axios.put(`${URL}/order/accept/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.ACCEPT_PURCHASE_ORDER,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.ACCEPT_PURCHASE_ORDER_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};

export const declinePurchaseOrder = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.DECLINE_PURCHASE_ORDER_REQUEST,
        });
        const { data } = await axios.put(`${URL}/order/decline/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.DECLINE_PURCHASE_ORDER,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.DECLINE_PURCHASE_ORDER_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const reviewPurchaseOrder = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.REVIEW_PURCHASE_ORDER_REQUEST,
        });
        const { data } = await axios.put(`${URL}/order/review/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.REVIEW_PURCHASE_ORDER,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.REVIEW_PURCHASE_ORDER_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};


export const payPurchaseOrder = (body) => async (dispatch) => {
    try {
        dispatch({
            type: types.PAY_PURCHASE_ORDER_REQUEST,
        });
        const { data } = await axios.put(`${URL}/order/pay/${body.id}`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        dispatch({
            type: types.PAY_PURCHASE_ORDER,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: types.PAY_PURCHASE_ORDER_FAIL,
            payload:
                error && error.response && error.response.data.error
                    ? error.response.data.error
                    : error.request
                        ? error.request.data.error
                        : error.message,
        });
    }
};
