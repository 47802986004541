import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Card,
  Grid,
  TextField,
  Button,
  Stepper,
  Step,
  CardHeader,
  StepLabel,
  darken,
  StepConnector,
  withStyles,
  Divider,
  useMediaQuery,
  fade,
  CircularProgress,
  // Avatar,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import { useTheme } from "@material-ui/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { connect } from "react-redux";
import { registerVendor } from "../actions/vendorsActions";
import Feedback from "../Feedback";
import Logo from "./Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "#011b33",
    backgroundColor: theme.palette.type === "light" ? "#011b33" : "#303030",

    // background: "url(/img/vendorreg.png)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100%",
    // height: "100vh",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    paddingTop: 30,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  card: {
    margin: "0 auto",
    marginTop: "20px",
    padding: theme.spacing(6),

    [theme.breakpoints.down("sm")]: {
      //   maxWidth: 200,
      width: "100%",
      padding: 20,
    },
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  btn: {
    padding: theme.spacing(2),
    marginTop: 20,
    marginBottom: 20,
    background: "#388e3c",
    color: "#fff",
    letterSpacing: 2.5,
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
  },
  message: {
    fontFamily: "Rubik",
  },
  logoAvatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: "auto",
    marginBottom: 20,
    boxShadow: "1px 1px 5px rgba(0,0,0,0.1)",

    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      marginBottom: 20,
    },
  },
  info: {
    lineHeight: 1.66,
  },
  logoText: {
    fontWeight: 900,
    // fontFamily: "Rubik",
    fontFamily: "Carter One",
    letterSpacing: 1.5,
    fontSize: 26,
    color:
      theme.palette.type === "light"
        ? fade(theme.palette.common.black, 0.75)
        : "#fff",
    textDecoration: "none",
    display: "inline-block",
  },
  loginText: {
    display: "block",
    // fontWeight: "bold",
    letterSpacing: 3.5,
    fontSize: 16,
    lineHeight: 1.5,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  divider: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 1,
    },
  },
  link: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.primary.main,
    fontFamily: "Rubik",
  },
}));

const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#d3d3d3",
      borderWidth: 10,
    },
  },
  completed: {
    "& $line": {
      // borderColor: "#d3d3d3",
      borderColor: theme.palette.primary.light,
      borderWidth: 2,
    },
  },
  line: {
    borderColor: "#d3d3d3",

    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);

const INIT_STATE = {
  companyName: "",
  email: "",
  password: "",
};

const VendorRegister = ({ message, registerVendor, error }) => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(5);
  const [isPassword, showIsPassword] = useState(false);
  const [state, setState] = useState(INIT_STATE);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setActiveStep(5);
  }, []);

  const showVisibility = () => {
    showIsPassword(!isPassword);
  };

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCheck = (e) => {
    setChecked((prev) => !prev);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    const data = {
      ...state,
      path: "vendor/completereg",
    };
    registerVendor(data)
      .then((doc) => {
        if (doc.success === false) {
          // setState(INIT_STATE);
          // setChecked(false);
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            message: doc.message,
            success: false,
          });
        } else {
          setState(INIT_STATE);
          setChecked(false);
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            message: doc.message,
            success: true,
          });
        }
      })
      .catch(() => {
        setState(INIT_STATE);
        setChecked(false);
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,

          success: false,
        });
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div className={classes.root}>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message || message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      )}

      <Card className={classes.card}>
        <CardHeader
          title={
            <div style={{ textAlign: "center" }}>
              <Logo path="/" size={26} />

              <Typography
                align="center"
                variant="overline"
                gutterBottom
                className={classes.loginText}
              >
                NEW VENDOR REGISTRATION
              </Typography>
            </div>
          }
        />

        <Grid container spacing={2} justify="space-between" alignItems="center">
          <Grid item xs={12} md={5}>
            <Typography variant="h6">HOW TO REGISTER</Typography>
            <Typography
              variant="overline"
              component="p"
              className={classes.info}
            >
              To begin the registration process, kindly do the following:
            </Typography>

            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              //   variant="outlined"
              style={{
                backgroundColor: "transparent",
                // textAlign: "start",
                marginLeft: -25,
              }}
              connector={<QontoConnector />}
              // nonLinear
            >
              <Step>
                <StepLabel>
                  <Typography className={classes.message}>
                    Fill the form fields with the required details
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Typography className={classes.message}>
                    You will receive an email with a link to verify your account
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Typography className={classes.message}>
                    When you click on the link in the email message, you will be
                    redirected to complete your registration.
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Typography className={classes.message}>
                    Complete the rest of the registration process, and wait for
                    your details to be vetted.
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Typography className={classes.message}>
                    You'll get an email once the above step is done, only then
                    will you be able to login.
                  </Typography>
                </StepLabel>
              </Step>
            </Stepper>
            <Typography
              variant="overline"
              color="secondary"
              className={classes.message}
              //   align="center"
            >
              * Please endeavour to follow these steps.
            </Typography>
          </Grid>

          <Divider
            flexItem
            orientation={matches ? "horizontal" : "vertical"}
            className={classes.divider}
          />

          <Grid item md={5} xs={12}>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Company Name"
                variant="outlined"
                type="text"
                color="primary"
                fullWidth
                className={classes.textField}
                name="companyName"
                value={state.companyName}
                onChange={handleChange}
                required
              />
              <TextField
                label="Email"
                variant="outlined"
                type="email"
                color="primary"
                fullWidth
                className={classes.textField}
                required
                name="email"
                value={state.email}
                onChange={handleChange}
              />
              <TextField
                label="Password"
                variant="outlined"
                type={isPassword ? "text" : "password"}
                color="primary"
                fullWidth
                className={classes.textField}
                required
                name="password"
                value={state.password}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={showVisibility}
                        edge="end"
                      >
                        {isPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText="Password needs to have at least one lower case, one uppercase, one number, one special character, and must be at least 8 characters but no more than 35."
              />
              <>
                <Checkbox
                  name="checked"
                  checked={checked}
                  onChange={handleCheck}
                />

                <span style={{ fontWeight: "400", fontFamily: "Rubik" }}>
                  I agree to RusselSmith's{" "}
                  <Link
                    to="/terms"
                    style={{ color: theme.palette.secondary.light }}
                  >
                    Terms of use
                  </Link>{" "}
                  &amp;{" "}
                  <Link
                    to="/privacy"
                    style={{ color: theme.palette.secondary.light }}
                  >
                    Privacy Policy
                  </Link>
                </span>
              </>

              <Button
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
                className={classes.btn}
                disabled={
                  !state.companyName ||
                  !state.email ||
                  !state.password ||
                  !checked ||
                  loading
                }
                endIcon={
                  loading ? (
                    <CircularProgress size={20} color="primary" />
                  ) : null
                }
              >
                {loading ? "Loading..." : "Register"}
              </Button>
            </form>
            <Typography variant="overline">
              Already Registered?{" "}
              <Link to="/" className={classes.link}>
                Click to Login
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  message: state.vendors.message,
  error: state.error.error,
});

const mapDispatchToProps = {
  registerVendor,
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorRegister);
