import React, { useState, useEffect } from "react";
import {
    Typography,
    makeStyles,
    Grid,
    AppBar,
    Paper,
    IconButton,
    Dialog,
    Toolbar,
    Tooltip,
    DialogContent,
    Backdrop,
    Button,
    Box,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { connect, useDispatch, useSelector } from "react-redux";
import Feedback from "../../../../Feedback";
import { getProfile } from "../../../../actions/userActions";
import { getLesson, getLessonRecord, createRecord } from "../../../../actions/ppmActions/lmsActions";
import { Carousel } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title3: {
        display: "block",
        fontSize: 16,
        textTransform: 'uppercase',
        lineHeight: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
        borderBottomColor:'#ccc',
        borderBottomWidth:1,
        borderBottomStyle:'solid',
        paddingBottom: 20,
        backgroundColor:"#f0f0f0",
        padding: theme.spacing(3),
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(0),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000000,
        color: "#fff",
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    viewBtn: {
        padding: theme.spacing(1),
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 10,
        // marginRight:-10,
        // marginLeft:10,
        marginBottom: 0,
        display:'block',
        width:'100%',
        textAlign:'left',
    },
    numberLabel: {
        borderRadius:'50%',
        width: 25,
        height: 25,
        fontSize: 12,
        paddingTop: 2,
        textAlign:'center',
        display: 'inline-block',
        backgroundColor:'#093',
        color:"#fff",
        marginRight: 5,
    },
    optionBtn: {
        paddingLeft: 20,
        paddingRight: 20,
        display:'block',
        textTransform:'none',
        width:450,
        marginBottom: 10,
        textAlign:'center',
        alignItems:'center',
        vertical:'center',
        verticalAlign: 'middle', 
    },
}));

const INIT_STATE = {
    title: "",
    courseId:"",
    description: "",
    image:"",
    code:"",
    modules:[],
    quizzes: [],
};

const LessonViewModal = ({ openCreate, handleCloseCreate, id }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [state, setState] = useState(INIT_STATE);
    const [contents, setContents] = useState(null);
    const [moduleNo, setModuleNo] = useState(null);
    const [quiz, setQuiz] = useState(null);
    const [carousel, setCarouselState] = useState({ index:0 });
    const { profile } = useSelector((state) => state.user);
    const { loading, error, lesson, records } = useSelector((state) => state.lms);
    const [quizAnswers] = useState([]);
    const [isCorrect, setCorrect] = useState(false);
    const [answerIndex, setAnswerIndex] = useState(null);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);
    
    useEffect(() => {
        if(id){
            dispatch(getLesson(id));
            dispatch(getLessonRecord(id));
        }
        dispatch(getProfile());
    }, [dispatch, id]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            courseId: (lesson && lesson.course?._id) || "",
            title: (lesson && lesson.title) || "",
            code: (lesson && lesson.code) || "",
            description: (lesson && lesson.description) || "",
            image: (lesson && lesson.image) || "",
            modules:  (lesson && lesson.modules) || [],
            quizzes:  (lesson && lesson.quizzes) || [],
        }));
    }, [lesson]);

    const handleSetContents = async (row, no) => {
        if(row){
            setQuiz(null);
            setContents(row);
            setModuleNo(no);
        }
    }

    const handleSetQuiz = async (row, no) => {
        if(row){
            setContents(null);
            setQuiz(row);
            setAnswerIndex(null);
        }
    }
    
    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleSelect = (selectedIndex) => {
        setCarouselState({
          index: selectedIndex,
        });
    }

    const handleSaveRecord = async (e) => {
        e.preventDefault();
        let data = {
            lesson: id,
            course: state.courseId,
            employee: profile.id,
        };
        setFeed((prevState) => ({ ...prevState, loading: true }));
        if(data.course && data.lesson){
            let doc = await new Promise((resolve, reject) => {
                resolve(dispatch(createRecord(data)));
            });
            if(doc){
                setFeed({
                    loading: false,
                    open: !feed.open,
                    message: `Record saved successfully`,
                    success: true,
                });
                dispatch(getLesson(id));
                dispatch(getLessonRecord(id));
            }                
        }
    }

    const doesQuestionIdExist = (array, questionId) => {
        return array.some(item => item.questionId === questionId);
    }
    
    const handleSetAnswers = async (quiz, mySelected, index) => {
        if(quiz && mySelected){
            setAnswerIndex(index)
            const selectedOption = quiz.options.find(option => option._id === mySelected);
            const correctOption = quiz.options.find(option => option._id && option.isCorrect === true);
            const isSelectedCorrect = selectedOption && selectedOption.isCorrect ? true : false;
            isSelectedCorrect ? setCorrect(true) : setCorrect(false)
            let response = { questionId: quiz._id, myOption: selectedOption, isSuccess: isSelectedCorrect, correctOption: correctOption._id}
            let exist = doesQuestionIdExist(quizAnswers, response.questionId);
            if(!exist) quizAnswers.push(response)
            console.log(quizAnswers.length, state.quizzes)
        }
    }

    console.log(records)
    
    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}
            <Dialog
                open={openCreate}
                onClose={handleCloseCreate}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                fullScreen
                maxWidth="md"
            >
                <AppBar
                    className={classes.appBar}
                    variant="elevation"
                    position="fixed"
                    color="inherit"
                >
                    <Toolbar>
                        <Tooltip title="close" arrow>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleCloseCreate}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <form onSubmit={()=> { }} >
                        <div className="services">
                            <div className={classes.container}>
                                <Typography variant="overline" className={classes.title}>
                                    Lesson: {state.title && state.title}
                                </Typography>

                                <Grid container spacing={0} className={classes.grid} >
                                    <Grid item xs={12} md={5}>
                                        <Paper variant="outlined" square className={classes.paper} style={{ marginTop: 20, borderRadius: 6, paddingBottom: 10, }}>
                                            <Typography variant="overline" className={classes.title3}>
                                                Finish all topics
                                            </Typography>

                                            {state.modules && state.modules.map((row, i) => {
                                                return (
                                                    <Button 
                                                        variant="contained"
                                                        className={classes.viewBtn}
                                                        // style={`${(records?.lesson?._id === lesson?.id) ? { backgroundColor:'#093' } : { backgroundColor:'#000' } }`}
                                                        key={i}
                                                        onClick={() => handleSetContents(row.contents, i)}
                                                    >
                                                        <span className={classes.numberLabel}>{i + 1}</span> {" "} {row.name}
                                                    </Button>
                                                )
                                            })}
                                        </Paper>

                                        <Paper variant="outlined" square className={classes.paper} style={{ marginTop: 20, borderRadius: 6 }}>
                                            <Typography variant="overline" className={classes.title3}>
                                                Answer A Few Questions
                                            </Typography>
                                            {state.quizzes && state.quizzes.map((row, i) => {
                                                return (
                                                    <Button 
                                                        variant="contained"
                                                        className={classes.viewBtn}
                                                        key={i}
                                                        onClick={() => handleSetQuiz(row)}
                                                    >
                                                        <span className={classes.numberLabel}>{i + 1}</span> {" "} {row.question}
                                                    </Button>
                                                )
                                            })}
                                        </Paper>

                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <Paper 
                                            variant="outlined" 
                                            square 
                                            className={classes.paper} 
                                            style={{ margin: 20, marginTop: 20, borderRadius: 6, minHeight: 450, backgroundColor:'#000' }}
                                        >
                                            {(contents && contents.length > 0) && ( <>
                                                <div
                                                    className="slider-home-banner carousel slide"
                                                    data-ride="carousel"
                                                >
                                                    <Carousel
                                                        indicators={false}
                                                        nextLabel={""}
                                                        prevLabel={""}
                                                        touch={false}
                                                        fade
                                                        wrap={false}
                                                        activeIndex={carousel.index}
                                                        onSelect={handleSelect}
                                                    >
                                                        {contents && contents.map((content, i) => (
                                                            <Carousel.Item key={i}>
                                                                <div className="centered">
                                                                    <h2 style={{color:"#fff"}}>{content.content && content.content}</h2>
                                                                </div>
                                                            </Carousel.Item>
                                                        ))}
                                                    </Carousel>
                                                </div>
                                                
                                                {((carousel.index + 1) === contents.length) && (
                                                    <Button 
                                                        color="primary" 
                                                        variant="contained" 
                                                        style={{float:'right', marginTop: 20}}
                                                        onClick={(state.modules.length === (moduleNo + 1)) ? handleSaveRecord : () => {console.log('next')}}
                                                    >
                                                        {(state.modules.length === (moduleNo + 1)) ? "Finish" : ("Next Topic")}
                                                    </Button>
                                                )}
                                                </>
                                            )}

                                            {(quiz && quiz?.options?.length > 0) && ( <>
                                                <div
                                                    className="slider-home-banner carousel slide"
                                                    data-ride="carousel"
                                                >
                                                    <div className="quiz_centered">
                                                        <h2 style={{color:"#fff"}}>{quiz.question && quiz.question}</h2>
                                                    </div>

                                                    <div className="options_centered">
                                                        <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
                                                            <Box display="flex" flexDirection="column" gap={2}>
                                                                {quiz.options && quiz.options.map((option, index) => (
                                                                    <Button 
                                                                        variant="contained"
                                                                        className={classes.optionBtn}
                                                                        key={index}
                                                                        style={isCorrect && (index === answerIndex) ? {backgroundColor:"#093", color:"#fff"} : (
                                                                                !isCorrect && (index === answerIndex) ? {backgroundColor:"#f00", color:"#fff"} : {backgroundColor:"#3377FF", color:"#fff"}
                                                                            )
                                                                        }
                                                                        color="primary"
                                                                        onClick={() => handleSetAnswers(quiz, option._id, index)}
                                                                        disabled={answerIndex ? true : false}
                                                                    >
                                                                        {option.option && option.option}
                                                                    </Button>
                                                                ))}
                                                            </Box>
                                                        </Box>
                                                    </div>
                                                </div>
                                                
                                                {(quizAnswers.length === state.quizzes.length) && (
                                                    <Button 
                                                        color="primary" 
                                                        variant="contained" 
                                                        style={{float:'right', marginTop: 20}}
                                                        onClick={() => {console.log('final submit')}}
                                                    >
                                                        Submit
                                                    </Button>
                                                )}
                                                </>
                                            )}
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </form>
                    <Backdrop className={classes.backdrop} open={loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default connect(null, { getLesson, createRecord, getLessonRecord })(LessonViewModal);
