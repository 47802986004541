import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Icon,
    Button,
    Divider,
    darken,
    Backdrop,
    ButtonGroup,
    Hidden,
    Fab
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import clsx from "clsx";
import Feedback from "../../../Feedback";
import {
    verifyWorkCompletion,
    getWorkCompletion,
    getWorkCompletions,
} from "../../../actions/qualityActions";

import ApproveWCModal from "../Modals/Quality/ApproveWCModal";
import DisapproveWCModal from "../Modals/Quality/DisapproveWCModal";

import ReviewWCModal from "../Modals/Quality/ReviewWCModal";
import DeclineWCModal from "../Modals/Quality/DeclineWCModal";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight:'bold',
        borderBottomColor: '#444',
        borderBottomWidth: 1,
        borderBottomStyle:'solid',
        paddingBottom: 10,
        marginBottom: 15,
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    paper3: {
        padding: theme.spacing(3),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        marginTop: 20, 
        background: darken("#fff", 0.008),
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 400,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    employee: "",
    employeeId:"",
    division: "",
    type:"",
    projectDescription: "",
    vendorId:"",
    vendorName: "",
    vendorEmail:"",
    vendorPhone: "",
    vendorContactPerson: "",
    purchaseOrderNo: "",
    items: [{
        description:"",
    }],
};

const ViewWorkCompletion = () => {    
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);

    const [openDecline, setOpenDecline] = useState(false);
    const [openApprove, setOpenApprove] = useState(false);
    const [openDisapprove, setOpenDisapprove] = useState(false);
    const [openReview, setOpenReview] = useState(false);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { error, loading, workcompletion } = useSelector((state) => state.workcompletion);
    const { profile } = useSelector((state) => state.user);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getWorkCompletions());
        dispatch(getWorkCompletion(id));
    }, [dispatch, id]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: (workcompletion && workcompletion.employee) || "",
            division: (workcompletion && workcompletion.division) || "",
            type: (workcompletion && workcompletion.type) || "",
            projectDescription: (workcompletion && workcompletion.projectDescription) || "",
            vendorName: (workcompletion && workcompletion.vendorName) || "",
            vendorEmail: (workcompletion && workcompletion.vendorEmail) || "",
            vendorPhone: (workcompletion && workcompletion.vendorPhone) || "",
            vendorContactPerson: (workcompletion && workcompletion.vendorContactPerson) || "",
            purchaseOrderNo: (workcompletion && workcompletion.purchaseOrderNo) || "",
            
            items: (workcompletion && workcompletion.items) || [],
            status: (workcompletion && workcompletion.status) || "",
            documentNo: (workcompletion && workcompletion.documentNo) || "",
            
            createdBy: (workcompletion && workcompletion.createdBy) || "",
            createdAt: (workcompletion && workcompletion.createdAt) || "",

            submittedBy: (workcompletion && workcompletion.submittedBy) || "",
            submittedAt: (workcompletion && workcompletion.submittedAt) || "",

            verifiedBy: (workcompletion && workcompletion.verifiedBy) || "",
            verifiedAt: (workcompletion && workcompletion.verifiedAt) || "",

            reviewedBy: (workcompletion && workcompletion.reviewedBy) || "",
            reviewedAt: (workcompletion && workcompletion.reviewedAt) || "",
            reviewComment: (workcompletion && workcompletion.reviewComment) || "",

            approvedBy: (workcompletion && workcompletion.approvedBy) || "",
            approvedAt: (workcompletion && workcompletion.approvedAt) || "",
            approveComment: (workcompletion && workcompletion.approveComment) || "",
            
            rejectedBy: (workcompletion && workcompletion.rejectedBy) || "",
            rejectedAt: (workcompletion && workcompletion.rejectedAt) || "",
            rejectComment: (workcompletion && workcompletion.rejectComment) || "",

            acceptedBy: (workcompletion && workcompletion.acceptedBy) || "",
            acceptedAt: (workcompletion && workcompletion.acceptedAt) || "",
            acceptComment: (workcompletion && workcompletion.acceptComment) || "",

            declinedBy: (workcompletion && workcompletion.declinedBy) || "",
            declinedAt: (workcompletion && workcompletion.declinedAt) || "",
            declineComment: (workcompletion && workcompletion.declineComment) || "",

        }));
    }, [workcompletion]);

    // qhse head
    const handleOpenApprove = () => {
        setOpenApprove(true);
    };
    const handleCloseApprove = () => {
        setOpenApprove(false);
    };
    const handleOpenDisapprove = () => {
        setOpenDisapprove(true);
    };
    const handleCloseDisapprove = () => {
        setOpenDisapprove(false);
    };

    // qhse
    const handleOpenReview = () => {
        setOpenReview(true);
    };
    const handleCloseReview = () => {
        setOpenReview(false);
    };
    const handleOpenDecline = () => {
        setOpenDecline(true);
    };
    const handleCloseDecline = () => {
        setOpenDecline(false);
    };

    const handleVerify = async (e) => {
        e.preventDefault();
        const data = {
            vendorName: state.vendorName,
            vendorPhone: state.vendorPhone,
            vendorContactPerson: state.vendorContactPerson,
            purchaseOrderNo: state.purchaseOrderNo,
            projectDescription: state.projectDescription,
            items: state.items,
            id
        }
        await dispatch(verifyWorkCompletion(data));
        await dispatch(getWorkCompletion(id));
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Work Completion Record Verified Successfully.",
            success: true,
        }));
        setTimeout(() => {
            history.push("/quality/workcompletion");
        }, 1500);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isAdmin = profile
        ? profile &&
        profile.groups &&
        profile.groups.some((grp) => grp.name === "admin") : false;

    const isQHSE = profile
        ? profile &&
        profile.division &&
        profile?.division?.code === "QHS"
        : false;

    const isPMG = profile
        ? profile &&
        profile.division &&
        profile?.division?.code === "PMG"
        : false;

    const isQHSEHead = profile
        ? profile &&
        profile.division &&
        ((profile?.division?.code === "QHS") && (profile?.division?.head === profile._id))
        : false;

    const isCanVerify = state ? state.status &&
        ((state.status === "submitted")
        )
        : false;

    const verifyCheck = () =>
        !state.projectDescription ||
        !state.vendorName ||
        !state.vendorPhone ||
        !state.vendorContactPerson ||
        !state.purchaseOrderNo || 
        (state.items.length === 0);

        
    const handleAddItem = () => {
        const data = {
            description: "",
        };
        setState((prev) => ({
            ...prev,
            items: prev.items.concat([data]),
        }));
    };

    const handleChangeItem = (i) => (e) => {
        e.persist();
        const newItems = state.items.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            items: newItems,
        }));
    };

    const handleRemoveItem = (i) => {
        setState((prev) => ({
            ...prev,
            items: prev.items.filter(
                (_, index) => index !== i
            ),
        }));
    };

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                <ReviewWCModal {...{ openReview, handleCloseReview, id }} />
                <DeclineWCModal  {...{ openDecline, handleCloseDecline, id }} />
                
                <ApproveWCModal {...{ openApprove, handleCloseApprove, id }} />
                <DisapproveWCModal {...{ openDisapprove, handleCloseDisapprove, id }}  />

                <Typography variant="overline" className={classes.title}>
                    Work Completion
                </Typography>
                <Grid container justify="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.backBtn}
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.push("/quality/workcompletion")}
                        >
                            Back
                        </Button>

                        {((isQHSE || isPMG || isAdmin) && (state.status === "reviewed" || state.status === "approved")) ? (
                        <Button
                            color="secondary"
                            variant="contained"
                            style={{ marginLeft: 10 }}
                            className={classes.backBtn}
                            onClick={() => history.push(`/quality/workcompletion/export/${id}`)}
                            // disabled
                        >
                            View Document
                        </Button>
                    ) : null}

                    </Grid>
                    <Grid item>
                        <Paper variant="outlined" className={classes.status}>
                            <Typography variant="overline" className={classes.statusTitle}>
                                Document No.
                            </Typography>
                            <Divider className={classes.divider2} orientation="vertical" />
                            {loading ? "Loading..." : state.documentNo}
                        </Paper>
                    </Grid>
                </Grid>

                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                    style={{ marginBottom: 20 }}
                >
                    <Hidden smDown>
                        <Grid item></Grid>
                    </Hidden>
                    <Grid item>
                        <Paper
                            variant="outlined"
                            className={clsx(
                                state.status === "draft"
                                    ? classes.statusYellow
                                    : state.status === "submitted"
                                        ? classes.statusOrange
                                        : (state.status === "verified" || state.status === "reviewed" || state.status === "accepted")
                                            ? classes.statusGreen
                                            : (state.status === "approved" || state.status === "sent")
                                                ? classes.statusDarkGreen
                                                : classes.statusRed,
                                classes.status
                            )}
                        >
                            <Typography variant="overline" className={classes.statusTitle}>
                                Status
                            </Typography>
                            <Divider className={classes.divider2} orientation="vertical" />
                            <Typography variant="overline" className={classes.statusTitle}>
                                {loading ? "Loading..." : state.status}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <div>

                    <Grid container spacing={2} className={classes.grid}>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Employee Name"
                                name="employeeName"
                                variant="outlined"
                                fullWidth
                                value={(state.createdBy && state.createdBy.userName) || ""}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Division/Subdivision"
                                name="division"
                                variant="outlined"
                                fullWidth
                                value={(state.division && state.division.name) || ""}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Document No"
                                name="documentNo"
                                variant="outlined"
                                fullWidth
                                value={state.documentNo || ""}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Type"
                                name="type"
                                variant="outlined"
                                required
                                fullWidth
                                value={(state.type && state.type) || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                label="Project Description"
                                name="projectDescription"
                                variant="outlined"
                                required
                                fullWidth
                                value={(state.projectDescription && state.projectDescription) || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Vendor Name"
                                name="vendor"
                                variant="outlined"
                                required
                                fullWidth
                                value={(state.vendorName && state.vendorName) || ""}
                                disabled
                            />
                        </Grid>
                        
                        <Grid item xs={12} md={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                label="Vendor Phone"
                                name="vendorPhone"
                                value={(state.vendorPhone && state.vendorPhone) || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                label="Vendor Contact Person"
                                name="vendorContactPerson"
                                value={(state.vendorContactPerson && state.vendorContactPerson) || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                label="Purchase Order No"
                                name="purchaseOrderNo"
                                value={(state.purchaseOrderNo && state.purchaseOrderNo) || ""}
                                disabled
                            />
                        </Grid>
                    </Grid>

                    <Paper variant="outlined" square className={classes.paper}>
                        <Typography variant="overline" className={classes.title2}>
                            Work Completion Details
                        </Typography>
                    </Paper>

                    {((isQHSE || isAdmin) && isCanVerify) ? (
                    <Paper variant="outlined" square className={classes.paper}>
                        {state.items && state.items.map((sitem, i) => (
                            <Grid
                            container
                            spacing={2}
                            className={classes.grid}
                            key={i}
                            >
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        label="Description"
                                        name="description"
                                        value={sitem.description || ""}
                                        onChange={handleChangeItem(i)}
                                        />
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <Fab
                                        color="secondary"
                                        onClick={() => handleRemoveItem(i)}
                                        >
                                        <CloseIcon />
                                    </Fab>
                                </Grid>
                            </Grid>
                        ))}

                        <Button
                            variant="outlined"
                            color="default"
                            onClick={() =>
                                handleAddItem(state.items.length + 1)
                            }
                            endIcon={<AddIcon fontSize="large" />}
                            >
                            Insert Item
                        </Button>
                    </Paper> 
                    ) : null }

                    {(state.status !== "submitted") ? 
                    <Paper variant="outlined" square className={classes.paper}>
                        {state.items && state.items.map((sitem, i) => (
                            <Grid
                                container
                                spacing={2}
                                className={classes.grid}
                                key={i}
                            >
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Description of Item"
                                        name="description"
                                        value={sitem.description || ""}
                                        disabled
                                        />
                                </Grid>                       
                            </Grid>
                        ))}
                    </Paper> : null}

                    <Grid
                        container
                        alignItems="center"
                        wrap="nowrap"
                        spacing={1}
                        style={{ marginBottom: 5 }}
                    >
                        <Grid item>
                            <Typography variant="overline" className={classes.title2}>
                                Audit Trail
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Icon className="fas fa-clipboard-list" color="disabled" />
                        </Grid>
                    </Grid>
                    <Paper variant="outlined" square className={classes.paper}>
                        <Grid container justify="space-between" alignItems="center" spacing={2}>
                            {state.status === "draft" && (
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Created By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.createdBy && state.createdBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.createdAt}</span>
                                    </Paper>
                                </Grid>
                            )}
                            {state.status !== "draft" && (
                                <>
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Requester</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.submittedBy && state.submittedBy.userName}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.submittedAt}</span>
                                        </Paper>
                                    </Grid>
                                    {(state.verifiedAt && state.verifiedBy) && (
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Prepared By</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.verifiedBy && state.verifiedBy.userName}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.verifiedAt}</span>
                                        </Paper>
                                    </Grid>
                                    )}
                                    {(state.acceptedAt && state.acceptedBy) && (
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <Paper
                                                    className={classes.auditContainer}
                                                    component="div"
                                                    elevation={1}
                                                    variant="outlined"
                                                    square
                                                >
                                                    <span className={classes.auditTitle}>Requester Signs</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.acceptedBy && state.acceptedBy.userName}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.acceptedAt}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.auditTitle}>Comment</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.acceptComment}</span>
                                                </Paper>
                                            </Grid>
                                        </>
                                    )}
                                    {(state.reviewedAt && state.reviewedBy) && (
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <Paper
                                                    className={classes.auditContainer}
                                                    component="div"
                                                    elevation={1}
                                                    variant="outlined"
                                                    square
                                                >
                                                    <span className={classes.auditTitle}>Reviewed By</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.reviewedBy && state.reviewedBy.userName}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.reviewedAt}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.auditTitle}>Comment</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.reviewComment}</span>
                                                </Paper>
                                            </Grid>
                                        </>
                                    )}
                                    {(state.approvedAt && state.approvedBy) && (
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <Paper
                                                    className={classes.auditContainer}
                                                    component="div"
                                                    elevation={1}
                                                    variant="outlined"
                                                    square
                                                >
                                                    <span className={classes.auditTitle}>Approved By</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.approvedBy && state.approvedBy.userName}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.approvedAt}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.auditTitle}>Comment</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.approveComment}</span>
                                                </Paper>
                                            </Grid>
                                        </>
                                    )}
                                    {(state.rejectedAt && state.rejectedBy) && (
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <Paper
                                                    className={classes.auditContainer}
                                                    component="div"
                                                    elevation={1}
                                                    variant="outlined"
                                                    square
                                                >
                                                    <span className={classes.auditTitle}>Rejected By</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.rejectedBy && state.rejectedBy.userName}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.rejectedAt}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.auditTitle}>Comment</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.rejectComment}</span>
                                                </Paper>
                                            </Grid>
                                        </>
                                    )}
                                    {(state.declinedAt && state.declinedBy) && (
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <Paper
                                                    className={classes.auditContainer}
                                                    component="div"
                                                    elevation={1}
                                                    variant="outlined"
                                                    square
                                                >
                                                    <span className={classes.auditTitle}>Declined By</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.declinedBy && state.declinedBy.userName}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.declinedAt}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.auditTitle}>Comment</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.declineComment}</span>
                                                </Paper>
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Paper>

                    {((isAdmin || isQHSE) && (state.status === "submitted")) ? (
                        <Grid container justify="center">
                            <Grid item>
                                <div className={classes.centered}>
                                    <Paper className={classes.paperBlack}>
                                        <HelpOutlineIcon fontSize="large" />
                                        <Typography placeholder="Info" className={classes.info2}>
                                            The verification of the project completion is to be done by the QHSE quality assurance coordinator or designate.
                                        </Typography>
                                    </Paper>
                                
                                    <ButtonGroup>
                                        <Button
                                            className={classes.saveBtn}
                                            color="primary"
                                            variant="contained"
                                            disabled={loading || verifyCheck() }
                                            onClick={handleVerify}
                                            style={{width: 260}}
                                        >
                                            {loading ? "Loading..." : "Save QHSE Verification"}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Grid>
                        </Grid>
                    ) : null}

                    {((isAdmin || isQHSE) && (state.status === "verified")) ? (
                        <Grid container justify="center">
                            <Grid item>
                                <div className={classes.centered}>
                                    <Paper className={classes.paperBlack}>
                                        <HelpOutlineIcon fontSize="large" />
                                        <Typography placeholder="Info" className={classes.info2}>
                                            The review of the work completion record is to be done by the QHSE quality assurance coordinator or designate.
                                        </Typography>
                                    </Paper>
                                
                                    <ButtonGroup>
                                        <Button
                                            className={classes.saveBtn2}
                                            color="primary"
                                            variant="contained"
                                            disabled={loading}
                                            onClick={handleOpenReview}
                                        >
                                            {loading ? "Loading..." : "Review"}
                                        </Button>
                                        <Button
                                            type="submit"
                                            className={classes.saveBtn}
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleOpenDecline}
                                            disabled={loading}
                                        >
                                            {loading ? "Loading..." : "Decline"}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Grid>
                        </Grid>
                    ) : null}

                    {((isAdmin || isQHSEHead || isQHSE) && (state.status === "reviewed")) ? (
                        <Grid container justify="center">
                            <Grid item>
                                <div className={classes.centered}>
                                    <Paper className={classes.paperBlack}>
                                        <HelpOutlineIcon fontSize="large" />
                                        <Typography placeholder="Info" className={classes.info2}>
                                            The approval of the work completion record is to be done primarily by the QHSE division manager or the Quality Assurance Coordinator.
                                        </Typography>
                                    </Paper>
                                
                                    <ButtonGroup>
                                        <Button
                                            className={classes.saveBtn2}
                                            color="primary"
                                            variant="contained"
                                            disabled={loading}
                                            onClick={handleOpenApprove}
                                        >
                                            {loading ? "Loading..." : "Approve"}
                                        </Button>
                                        <Button
                                            type="submit"
                                            className={classes.saveBtn}
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleOpenDisapprove}
                                            disabled={loading}
                                        >
                                            {loading ? "Loading..." : "Disapprove"}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Grid>
                        </Grid>
                    ) : null}

                </div>
                
            </MuiPickersUtilsProvider>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default ViewWorkCompletion;
