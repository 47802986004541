import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  Grid,
  Button,
  DialogTitle,
  IconButton,
  DialogActions,
  ButtonGroup,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../Feedback";
import {
  getAllSequences,
  deleteSequence,
} from "../../../actions/sequenceAction";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  centered: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 20,
  },
}));

const DeleteSequenceModal = ({
  openDelete,
  handleCloseDelete,
  deleteSequence,
  getAllSequences,
  id,

  error,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleDelete = () => {
    setLoading((prev) => !prev);

    deleteSequence(id)
      .then(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          // message: `Sequence Created Successfully`,
          success: true,
        });
        dispatch(getAllSequences());
      })
      .catch(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          // message: `Sequence Created Successfully`,
          success: false,
        });
      })
      .finally(() => {
        handleCloseDelete();
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={(error && error.error) || feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message || "Sequence Deleted Successfully."}
        />
      )}
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
        // fullScreen={fullScreen}
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Delete Sequence
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseDelete}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography variant="overline" className={classes.title3}>
            Are you sure you want to delete this sequence?
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button onClick={handleCloseDelete} className={classes.saveBtn}>
              Cancel
            </Button>
            <Button
              color="secondary"
              onClick={handleDelete}
              disabled={loading}
              className={classes.saveBtn}
              variant="contained"
            >
              {loading ? "Loading..." : "Delete"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.sequence.error,
});

const mapDispatchToProps = {
  deleteSequence,
  getAllSequences,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteSequenceModal);
