import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Typography,
    Dialog,
    DialogContent,
    Grid,
    DialogTitle,
    TextField,
    Button,
    IconButton,
    Backdrop,
    CircularProgress,
    FormControl,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { updateInventoryItem, getAllInventoryItems } from "../../../../../actions/inventoryActions";
import Feedback from "../../../../../Feedback";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 18,
        fontWeight: 600,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
    },
    btn: {
        margin: "30px 0",
        width: 300,
        letterSpacing: 2.5,
        padding: theme.spacing(2),
        paddingLeft: 20,
        paddingRight: 20,
        [theme.breakpoints.down("sm")]: {
            width: 200,
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 10000,
        color: "#fff",
    },
}));

const INIT_STATE = {
    item: "",
    quantity: "",
    quantityCounted: "",
    quantityOnProject: "",
    quantityMissing: "",
    variance: "",
};

const AddCountModal = ({ openAddCount, handleCloseAddCount }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [state, setState] = useState(INIT_STATE);
    const { invItems, error, loading } = useSelector((state) => state.inventoryItem);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        let abortController = new AbortController();

        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
        return () => {
            abortController.abort();
            dispatch({
                type: "RESET_ITEM",
            });
        };
    }, [error, dispatch]);

    useEffect(() => {
        dispatch(getAllInventoryItems());
    }, [dispatch]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    useEffect(() => {
        if (state.item) {
            invItems &&
                invItems.map(
                    (item) =>
                        item.name === state.item.name &&
                        setState((prev) => ({
                            ...prev,
                            ...state.item,
                            id: item && item.id,
                            quantity: item && item.totalQuantity,
                            unit: item && item.unit,
                        }))
                );
        }
    }, [state.item, invItems]);

    useEffect(() => {
        const variance =
            +state.quantityCounted +
            +state.quantityOnProject;
        const varianc = variance <= state.quantity ? (state.quantity - variance) : null;
        setState((prev) => ({
            ...prev,
            variance: varianc,
        }));
    }, [state.quantityCounted, state.quantityOnProject, state.quantity]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state.item,
            id: state.item._id,
            totalQuantity: state.quantity,
            totalValue: state.quantity * state.cost
        };
        const res = await new Promise((resolve, reject) => {
            resolve(dispatch(updateInventoryItem(data)));
        });
        if (res) {
            await dispatch(getAllInventoryItems());
            handleCloseAddCount();
            setState(INIT_STATE);
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Inventory Item Count Saved  Successfully.",
                success: true,
            }));
        }
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isValid = () =>
        !state.item ||
        !state.unit ||
        !state.quantity ||
        !state.quantityCounted ||
        !state.quantityOnProject || !state.quantityMissing;

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                <Dialog
                    open={openAddCount}
                    onClose={handleCloseAddCount}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="md"
                >
                    <DialogTitle id="form-dialog-title">
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="overline" className={classes.title}>
                                    Add Inventory Count
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={handleCloseAddCount}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent dividers>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3} justify="center" alignItems="center">
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        id="item"
                                        options={invItems}
                                        value={state.item}
                                        onChange={(event, newValue) => {
                                            setState((prev) => ({
                                                ...prev,
                                                item: newValue,
                                            }));
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        renderOption={(option) => (
                                            <React.Fragment>{option.name}</React.Fragment>
                                        )}
                                        style={{ marginTop: -10 }}
                                        autoComplete
                                        autoHighlight
                                        renderInput={(params) => (
                                            <>
                                                <label style={{ fontWeight: "bold" }}>Item Name</label>
                                                <TextField
                                                    {...params}
                                                    margin="normal"
                                                    fullWidth
                                                    required
                                                    variant="outlined"
                                                    value={(state.item && state.item.name) || ""}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "disabled",
                                                        name: "item",
                                                    }}
                                                />
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <label style={{ fontWeight: "bold" }}>Current Quantity</label>
                                        <TextField
                                            type="number"
                                            variant="outlined"
                                            value={(state.item && state.item.totalQuantity) || ""}
                                            onChange={handleChange}
                                            fullWidth
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <label style={{ fontWeight: "bold" }}>Unit of Measurement</label>
                                        <TextField
                                            type="text"
                                            variant="outlined"
                                            value={state.unit || "None"}
                                            fullWidth
                                            disabled
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <label style={{ fontWeight: "bold" }}>Quantity Counted</label>
                                        <TextField
                                            name="quantityCounted"
                                            type="number"
                                            variant="outlined"
                                            value={state.quantityCounted || ""}
                                            onChange={handleChange}
                                            fullWidth
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <label style={{ fontWeight: "bold" }}>Quantity on Project(s)</label>
                                        <TextField
                                            name="quantityOnProject"
                                            type="number"
                                            variant="outlined"
                                            value={state.quantityOnProject || ""}
                                            onChange={handleChange}
                                            fullWidth
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <label style={{ fontWeight: "bold" }}>Quarantined Damaged or Missing</label>
                                        <TextField
                                            name="quantityMissing"
                                            type="number"
                                            variant="outlined"
                                            value={state.quantityMissing || ""}
                                            onChange={handleChange}
                                            fullWidth
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={8}></Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <label style={{ fontWeight: "bold" }}>Variance</label>
                                        <TextField
                                            name="variance"
                                            type="number"
                                            variant="outlined"
                                            value={state.variance || ""}
                                            onChange={handleChange}
                                            fullWidth disabled
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container justify="center" alignItems="center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    className={classes.btn}
                                    disabled={isValid() || loading}
                                    endIcon={
                                        loading ? (
                                            <CircularProgress size={20} color="primary" />
                                        ) : null
                                    }
                                >
                                    {loading ? "Loading..." : "Save"}
                                </Button>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </MuiPickersUtilsProvider>
        </div>
    );
}

export default AddCountModal;
