import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import procurement from '../../../../procurement.jpeg';

const styles = StyleSheet.create({
    section: {
        margin: 10,
        marginTop: 20,
        paddingBottom: 20,
        paddingHorizontal: 30,
        textAlign: "justify",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.5,
    },
    text: {
        fontSize: 8.9,
        textAlign: "justify",
        fontWeight: 300,
        color: "#222",
        lineHeight: 1.5,
        marginBottom: 10,
    },
    boldText: {
        fontSize: 10,
        textAlign: "left",
        fontWeight: "bold",
        color: "#222",
        lineHeight: 2.0,
        marginBottom: 5,
    },

    footerTextLeft: {
        textAlign: "left",
        fontSize: 8.9,
    },
    textRight: {
        textAlign: "right",
        fontSize: 8.9,
        marginTop: -15,
    },
    textUnderline: {
        textDecoration: "underline",
        width: "100%",
        display:"block",
    },
});

export const PageTwo = () => {
    return (
        <>
            <View break>
                <Image
                    src={procurement}
                    style={{
                        height: 120,
                        width: "100%",
                        marginTop: 20,
                        paddingRight: 30,
                        paddingLeft: 30,
                        marginBottom: 0,
                    }} 
                />
                <View style={styles.section}>
                    <Text style={styles.boldText}><strong> 1. SERVICES & DELIVERABLES.</strong></Text>
                    <Text style={styles.text}>
                        Seller agrees to provide to Company (or its subsidiaries, if such subsidiaries are designated as the contracting parties in the purchase order) (hereinafter
                        referred to as Compan ) the services ("Services") and/or goods (Good), described in any purchase order, in accordance with these Terms and Conditions
                        ("Agreement"). Upon acceptance of a purchase order, shipment of Goods or commencement of a Service, Seller shall be bound by the provisions of this
                        Agreement, including all provisions set forth on the face of any applicable purchase order, whether Seller acknowledges or otherwise signs this Agreement or
                        the purchase order, unless Seller objects to such terms in writing prior to shipping Goods or commencing Services. This writing does not constitute a firm
                        offer,and may be revoked at any time prior to acceptance. This Agreement may not be added to, modified, superseded, or otherwise altered, except by writing
                        signed by an authorized COMPANY representative. Any terms or conditions contained in any acknowledgment, invoice, or other communication of Seller,
                        which are inconsistent with the terms and conditions herein, are hereby rejected. To the extent that this Agreement might be treated as an acceptance of
                        Sellers prior offer, such acceptance is expressly made on condition of assent by Seller to the terms hereof and shipment of the Goods or beginning
                        performance of any Services by Seller shall constitute such assent. COMPANY hereby reserves the right to reschedule any delivery or cancel any purchase
                        order issued at any time prior to shipment of the Goods or prior to commencement of any Services. COMPANY shall not be subject to any charges or other
                        fees as a result of such cancellation.</Text>

                    <Text style={styles.boldText}>2. DELIVERY</Text>
                    <Text style={styles.text}>
                        Time is of the essence. Delivery of Goods and Services shall be made pursuant to the schedule, via the carrier, and to the place specified on the face of the
                        applicable purchase order. COMPANY reserves the right to return, shipping charges collect, all Goods received in advance of the delivery schedule. If no
                        delivery schedule is specified, the order shall be filled promptly and delivery will be made by the most expeditious form of land transportation. If no method of
                        shipment is specified in the purchase order, Seller shall use the least expensive carrier. In the event Seller fails to deliver the Goods or Services within the
                        time specified, COMPANY may, at its option, decline to accept performance and terminate the Agreement or may demand its allocable fair share of Seller's
                        available Goods and terminate the balance of the Agreement. Seller shall package all items in suitable containers to permit safe transportation and handling.
                        Each delivered container must be labeled and marked to identify contents without opening and all boxes and packages must contain packing sheets listing
                        contents. COMPANYs purchase order number must appear on all shipping containers, packing sheets, delivery tickets, and bills of lading.</Text>

                    <Text style={styles.boldText}>3. IDENTIFICATION, RISK OF LOSS, & DESTRUCTION OF GOODS.</Text>
                    <Text style={styles.text}>
                        Seller assumes all risk of loss until receipt by COMPANY. Title to Goods shall pass to COMPANY upon receipt by it of the Goods at the designated
                        destination. If the Goods ordered are destroyed prior to title passing to COMPANY, COMPANY may at its option cancel the Agreement or require delivery of
                        substitute Goods of equal quantity and quality. Such delivery will be made as soon as commercially practicable. If loss of Goods is partial, COMPANY shall
                        have the right to require delivery of the Goods not destroyed.</Text>
                    
                    <Text style={styles.boldText}>4. PAYMENT.</Text>
                    <Text style={styles.text}>
                        As full consideration for the performance of the Services, delivery of the Goods and the assignment of rights to COMPANY as provided herein, COMPANY
                        shall pay Seller (i) the amount agreed upon and specified in the applicable purchase order, or (ii) Seller's quoted price on date of shipment (for Goods), or the
                        date Services were started (for Services), whichever is lower. Applicable taxes and other charges such as shipping costs, duties, customs, tariffs, imposts, and
                        government-imposed surcharges shall be stated separately on Seller's invoice. Payment is made when COMPANY's check is mailed. Payment shall not
                        constitute acceptance. All personal property taxes assessable upon the Goods prior to receipt by COMPANY of Goods conforming to the purchase order shall
                        be borne by Seller. Seller shall invoice COMPANY for all Goods delivered and all Services actually performed. Each invoice submitted by Seller must be
                        </Text>

                        <Text style={[styles.textUnderline]}>
                            {"________________________________________________________________________________"}
                        </Text>
                        <Text style={[styles.footerTextLeft]}>RS-CPD-PMG-PUR-P-10106 Procurement</Text>
                        <Text style={[styles.textRight]}>Unauthorized Printing Is Not Permitted</Text>
                </View>
            </View>
        </>
    )
}

export default PageTwo;
