import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Typography,
    Paper,
    Grid,
    Button,
    Backdrop,
    Chip,
    IconButton,
    Icon,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import SearchComponent from "../../SearchComponent";
import { TablePaginationActions } from "../../TablePaginationActions";
import StartCountModal from "../Modals/Inventory/CountModals/StartCountModal";
import DeleteCountModal from "../Modals/Inventory/CountModals/DeleteCountModal";
import { getInventoryCounts } from "../../../actions/inventoryCountActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Feedback from "../../../Feedback";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold"
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
    },

    action: {
        marginBottom: 30,
    },
    btn: {
        marginTop: 30,
        marginBottom: 30,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    table: {
        minWidth: 500,
    },
    chip: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    marginBottom: {
        marginBottom: 30,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    createBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
}));

const InventoryCount = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [openCreate, setOpenCreate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [getId, setGetId] = useState("");
    const [search, setSearch] = useState("");
    const { counts, loading, error } = useSelector((state) => state.inventoryCount);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    const navigateById = (path, id) => {
        history.push(`${path}/${id}`);
    };

    useEffect(() => {
        dispatch(getInventoryCounts());
    }, [dispatch]);

    const updateSearch = (e) => {
        setSearch(e.target.value.substr(0, 20));
    };

    const ResetSearch = (e) => {
        e.preventDefault();
        setSearch("");
    };

    let filteredRows = () =>
        counts === undefined
            ? []
            : counts &&
            counts.filter((row) => {
                if (search !== "") {
                    return (
                        row.employee
                            .toString()
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) !== -1
                    );
                } else {
                    return row;
                }
            });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpenCreate = () => {
        setOpenCreate(true);
    };
    const handleCloseCreate = () => {
        setOpenCreate(false);
    };
    const handleDeleteOpen = (id) => {
        setGetId(id);
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const emptyRows =
        rowsPerPage -
        Math.min(
            rowsPerPage,
            (Array(1000).length || filteredRows().length) - page * rowsPerPage
        );

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}
            <StartCountModal {...{ openCreate, handleCloseCreate }} />
            <DeleteCountModal {...{ openDelete, handleCloseDelete, id: getId }} />

            <Typography variant="overline" gutterBottom className={classes.title}>
                Inventory Count
            </Typography>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={3}
                className={classes.marginBottom}
            >
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.createBtn}
                        onClick={handleOpenCreate}
                    >
                        Start
                    </Button>
                </Grid>
                <Grid item>
                    <SearchComponent
                        updateSearch={updateSearch}
                        placeholder="Search..."
                        search={search}
                        ResetSearch={ResetSearch}
                    />
                </Grid>
            </Grid>

            <Paper>
                <TableContainer>
                    <Table className={classes.table} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Counted By</TableCell>
                                <TableCell>Store</TableCell>
                                <TableCell>Store Location</TableCell>
                                <TableCell>Period Ending</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Count Scope</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Action</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRows().slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                : filteredRows()
                            ).map((row, i) => {
                                return (
                                    <TableRow hover key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {row.employee && row.employee}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.store && row.store?.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.location && row.location?.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.createdAt && row.createdAt}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.createdAt && row.createdAt}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.documentNo && row.documentNo}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Chip
                                                style={{ padding: 10 }}
                                                label={row.status}
                                                variant="outlined"
                                                className={clsx(
                                                    row.status === "draft"
                                                        ? classes.statusYellow
                                                        : row.status === "submitted" || row.status === "rejected"
                                                            ? classes.statusOrange
                                                            : row.status === "accepted"
                                                                ? classes.statusGreen
                                                                : row.status === "received" || row.status === "approved"
                                                                    ? classes.statusDarkGreen
                                                                    : classes.statusRed,
                                                    classes.chip
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {(row.status === "draft" || row.status === "rejected") ? (
                                                <IconButton
                                                    onClick={() => navigateById("/inventory/count/edit", row._id)}
                                                    disabled={(row.status === "submitted" || row.status === "accepted" || row.status === "approved" || row.status === "received")}
                                                    variant="extended"
                                                >
                                                    <Icon className="fas fa-pencil-alt" color="primary" />
                                                </IconButton>
                                            ) : ((row.status === "submitted" || row.status === "accepted" || row.status === "approved" || row.status === "received") ? (
                                                <IconButton
                                                    color="inherit"
                                                    onClick={() => navigateById("/inventory/count/view", row._id)}
                                                    disabled={row.status === "draft" || row.status === "rejected"}
                                                    variant="extended"
                                                >
                                                    <Icon className="fas fa-eye" color="action" />
                                                </IconButton>
                                            ) : null)}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <IconButton
                                                variant="extended"
                                                color="secondary"
                                                onClick={() => handleDeleteOpen(row._id)}
                                            >
                                                <Icon className="fas fa-trash" color="secondary" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}

                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                            {filteredRows().length === 0 && (
                                <TableRow hover>
                                    <TableCell
                                        colSpan={10}
                                        align="center"
                                        style={{ color: "#616161" }}
                                        component="th"
                                        scope="row"
                                    >
                                        No Records To Display
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                    colSpan={3}
                    count={filteredRows().length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </Paper>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div >
    );
};

export default InventoryCount;
