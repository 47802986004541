import React from "react";
import {
  Grid,
  makeStyles,
  Typography,
  AppBar,
  Tabs,
  Tab,
  Box,
  Card,
  CardContent,
  CardActions,
  Avatar,
  CardHeader,
  Divider,
  Button,
  TextField,
  Badge,
  withStyles,
  IconButton,
  Tooltip,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Chip,
  Backdrop,
  CircularProgress,
  ButtonGroup,
  Icon,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import SettingsIcon from "@material-ui/icons/Settings";
import { useDispatch, useSelector } from "react-redux";

import Feedback from "../../../Feedback";
import clsx from "clsx";
import {
  uploadSignature,
  removeSignature,
  removeProfilePhoto,
  updateUserPassword,
} from "../../../actions/userActions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box paddingTop={3} paddingLeft={1} paddingRight={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  large: {
    width: 100,
    height: 100,
    margin: "auto",
    cursor: "pointer",
  },
  card: {
    display: "grid",
    justifyItems: "center",
    alignContent: "center",
    textAlign: "center",
    padding: theme.spacing(3),
  },
  btn: {
    marginLeft: "auto",
    padding: 13,
    letterSpacing: 2.5,
    fontSize: 12,
  },
  colorDisabled: {
    color: "#9e9e9e",
  },
  header: {
    color: "#9e9e9e",

    // color: "#6d6d6d",
    fontFamily: "'Rubik', Roboto, 'Quicksand'",
    textTransform: "uppercase",
  },
  input: {
    display: "none",
  },
  label: {
    cursor: "pointer",
    display: "inline-block",
  },
  label2: {
    cursor: "pointer",
    display: "inline-block",
    marginLeft: 6,
    marginTop: 2,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: "#fff",
  },
  authActive: {
    width: 100,
    padding: 10,
    marginTop: 10,
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
    border: "1px solid #ffffff",
    borderRadius: 50,
    background: "linear-gradient(145deg, #ffffff, #f6f6f6)",
    boxShadow: "-0.5px 0.5px 3px #d9d9d9, 0.5px -0.5px 3px #d9d9d9",
  },
  activeText: {
    // color: "#44b70099",
    color: "transparent",
    background: "#44b70099",
    marginLeft: 10,
    fontFamily: "Rubik",
    fontWeight: 900,
    fontSize: 11.5,
    textTransform: "uppercase",
    textShadow: "2px 2px 5px rgba(255,255,255,0.5)",
    backgroundClip: "text",
  },
  inActiveText: {
    background: "#9e9e9e",
    color: "transparent",
    marginLeft: 10,
    fontFamily: "Rubik",
    fontWeight: 900,
    fontSize: 11.5,
    textTransform: "uppercase",
    textShadow: "2px 2px 5px rgba(255,255,255,0.5)",
    backgroundClip: "text",
  },
  chip: {
    borderWidth: 2,
    fontSize: 12,
    fontWeight: 900,
    color: "#9e9e9e",
    fontFamily: "Rubik",
  },
  blurred: {
    position: "relative",
    background: "inherit",
    overflow: "hidden",
    "&:after": {
      position: "absolute",
      content: "''",
      boxShadow: "inset 0 0 0 200px rgba(255,255,255,0.05)",
      filter: "blur(10px)",
      background: "inherit",
    },
  },
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    // marginBottom: 20,
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 13,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  signature: {
    fontFamily: "'Montez', cursive",
    fontSize: 24,

    fontWeight: 400,
  },
  signatureImg: {
    width: 70,
    imageRendering: "pixelated",
  },
  signatureRule: {
    width: 150,
    margin: "auto",
  },
  saveBtn: {
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    margin: "30px 0",
    padding: theme.spacing(1.2),
  },
  avatarBtn: {
    position: "relative",
    "&:hover $middle": {
      opacity: 1,
    },
    "&:hover $avatarProfile": {
      opacity: 0.2,
    },
    textAlign: "center",
    zIndex: 11,
    "&:after": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: -1,
      padding: 2,
      borderRadius: "50%",
      border: `1px dashed ${
        theme.palette.type === "light" ? "rgb(75 75 76 / 32%)" : "#ccc"
      }`,
      // animation: "200ms cubic-bezier(.72,-0.1,0,1.57) both infinite $rotation",
    },
  },

  "@keyframes rotation": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },

  photoIcon: {
    display: "block",
    margin: "auto",
    marginBottom: 5,
  },
  middle: {
    fontSize: 12,
    opacity: 0,
    position: "absolute",
    width: "100%",
    // height: "100%",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",

    // transition: "all 300ms ease",
  },
  avatarProfile: {
    opacity: 1,
    display: "block",
    backfaceVisibility: "hidden",
    // transition: "opacity 100ms ease-in-out",
  },
}));
/*
 * Victoria  ########
 */

const RippleEffect = withStyles((theme) => ({
  root: {
    backgroundColor: "#44b700",

    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    marginRight: -10,

    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const DisableRippleEffect = withStyles((theme) => ({
  root: {
    backgroundColor: "#9e9e9e",

    color: "#9e9e9e",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    marginRight: -10,

    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const PencilIcon = withStyles((theme) => ({
  root: {
    width: 30,
    height: 30,
    // border: `3px solid ${theme.palette.background.paper}`,
    border: `3px solid #d9d9d9`,
    boxShadow: theme.shadows[10],
    padding: 3,
    borderRadius: 50,
    background: theme.palette.background.paper,
    transform: "rotate(270deg)",
    "&:hover": {
      boxShadow: theme.shadows[12],
    },
  },
}))(EditIcon);

const UploadIcon = withStyles((theme) => ({
  root: {
    position: "relative",
    width: 30,
    height: 30,
    border: `3px solid ${
      theme.palette.type === "light"
        ? theme.palette.background.paper
        : "#d9d9d9"
    }`,

    boxShadow: theme.shadows[2],
    padding: 3,
    borderRadius: 50,
    background: theme.palette.background.paper,
    transform: "rotate(270deg)",
    "&:hover": {
      boxShadow: theme.shadows[3],
    },
  },
}))(AddIcon);

const RemoveIcon = withStyles((theme) => ({
  root: {
    position: "relative",
    width: 30,
    height: 30,
    border: `3px solid ${
      theme.palette.type === "light"
        ? theme.palette.background.paper
        : "#d9d9d9"
    }`,

    boxShadow: theme.shadows[2],
    padding: 3,
    borderRadius: 50,
    background: theme.palette.background.paper,
    transform: "rotate(270deg)",
    marginTop: -3,
    "&:hover": {
      boxShadow: theme.shadows[3],
    },
  },
}))(CloseIcon);

export const UsersView = ({
  profile,
  uploadPhoto,
  getProfile,
  updateProfile,
  message,
  // updateUserPassword,
  error,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const [helper, showHelper] = React.useState(false);
  // const [twoFactor, setTwoFactor] = React.useState(false);
  // const isFirstRun = React.useRef(true);

  const [file, setFile] = React.useState(undefined);
  const [state, setState] = React.useState({
    firstName: "",
    lastName: "",
    phone: "",
    signature: "",
  });

  const [pass, setPass] = React.useState({
    currentPassword: "",
    newPassword: "",
  });
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  const [feed2, setFeed2] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  const [visibility, showVisibility] = React.useState({
    password1: false,
    password2: false,
  });
  const dispatch = useDispatch();

  const {
    loading: loadingSignature,
    loading: loadingDeleteSignature,
    loading: loadingDeleteProfile,
    loading: loadingPassword,
    error: signatureError,
    error: updatePassError,
    signature,
    message: deleteMessage,
    message: passwordSuccessMessage,
  } = useSelector((state) => state.user);

  React.useEffect(() => {
    if (signature !== "") {
      getProfile();
    }
  }, [getProfile, signature]);

  React.useEffect(() => {
    if (deleteMessage !== "") {
      getProfile();
      setFeed((prev) => ({
        ...prev,
        loading: false,
        open: !prev.open,
        message: deleteMessage,
        success: true,
      }));
    }
  }, [getProfile, deleteMessage]);

  React.useEffect(() => {
    if (passwordSuccessMessage !== "") {
      getProfile();
      setFeed((prev) => ({
        ...prev,
        loading: false,
        open: !prev.open,
        message: passwordSuccessMessage,
        success: true,
      }));
      setPass((prev) => ({
        ...prev,
        currentPassword: "",
        newPassword: "",
      }));
    }
  }, [getProfile, passwordSuccessMessage]);

  // const handleTwoFactorCallback = React.useCallback(() => {
  //   setOpenBackdrop((prev) => !prev);
  //   const data = {
  //     isAuth: twoFactor,
  //   };
  //   updateTwoFactor(data)
  //     .then(() => {
  //       setOpenBackdrop(false);
  //       dispatch(getProfile);
  //     })
  //     .catch(() => console.error("Failed to update"));
  // }, [updateTwoFactor, twoFactor, dispatch, getProfile]);

  // React.useEffect(() => {
  //   if (isFirstRun.current) {
  //     isFirstRun.current = false;
  //     return;
  //   }
  //   handleTwoFactorCallback();
  // }, [handleTwoFactorCallback]);

  React.useEffect(() => {
    if (updatePassError !== null) {
      setFeed2((prev) => ({
        ...prev,
        loading: false,
        open: !prev.open,
        message: updatePassError,
        success: false,
      }));
    }
  }, [updatePassError]);

  React.useEffect(() => {
    if (signatureError && signatureError !== null) {
      setFeed((prev) => ({
        ...prev,
        loading: false,
        open: !prev.open,
        message: signatureError,
        success: false,
      }));
    }
  }, [signatureError]);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      firstName: profile && profile.firstName,
      lastName: profile && profile.lastName,
      phone: profile && profile.phone,
      signature: profile && profile.signature,
    }));
  }, [profile]);

  React.useEffect(() => {
    const upload = async () => {
      if (file) {
        const formData = new FormData();
        formData.append("img", file);

        dispatch(uploadSignature(formData));
        getProfile();
      }
    };
    upload();
  }, [file, dispatch, getProfile]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeSignature = (e) => {
    setFile(e.target.files[0]);
  };

  const handleRemoveSignature = () => {
    dispatch(removeSignature());
  };

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handleChangePass = (e) => {
    e.persist();
    setPass((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleVisibility = (key) => {
    showVisibility((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // const handleToggleTwoFA = (e) => {
  //   setTwoFactor(e.target.checked);
  // };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    dispatch(updateUserPassword(pass));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    updateProfile(state)
      .then(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,

          success: true,
        });
        dispatch(getProfile());
      })
      .catch(() => {
        setLoading(false);
        // setFeed({
        //   loading: false,
        //   open: !feed.open,
        //   // message: "Failed to update profile",
        //   success: false,
        // });
      });
  };

  const handleFocus = () => {
    showHelper((prev) => !prev);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };
  const handleCloseFeed2 = () => {
    setFeed2((prevState) => ({ ...prevState, open: false }));
  };

  const validCheck = () => !state.firstName || !state.lastName || !state.phone;

  return (
    <div>
      {updatePassError && (
        <Feedback
          handleCloseFeed={handleCloseFeed2}
          open={feed2.open}
          severity="error"
          message={feed2.message}
        />
      )}
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message || message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <UserPhotoModal
        open={open}
        handleClose={handleClose}
        profile={profile}
        uploadPhoto={uploadPhoto}
        getProfile={getProfile}
      />
      <Typography
        variant="overline"
        gutterBottom
        style={{ display: "flex", alignItems: "center" }}
        className={classes.colorDisabled}
      >
        Settings <SettingsIcon color="disabled" style={{ marginLeft: 5 }} />
      </Typography>
      <AppBar
        position="static"
        style={{ borderBottom: "1px solid #e8e8e8" }}
        color="transparent"
        elevation={0}
      >
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="simple tabs example"
          indicatorColor="secondary"
          //   textColor="secondary"
          variant="fullWidth"
        >
          <Tab
            label={
              <Typography variant="overline" className={classes.title3}>
                General
              </Typography>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <Typography variant="overline" className={classes.title3}>
                Security
              </Typography>
            }
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card className={clsx(classes.card)}>
              <CardContent>
                <Tooltip
                  title="Click Icon to Select Photo"
                  arrow
                  placement="right"
                >
                  <IconButton
                    onClick={handleClickOpen}
                    className={classes.avatarBtn}
                  >
                    <Badge
                      overlap="circle"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      badgeContent={<PencilIcon fontSize="large" />}
                    >
                      <Avatar
                        sizes="30"
                        className={clsx(classes.large, classes.avatarProfile)}
                        src={(profile && profile.photo) || "/img/user.svg"}
                      />
                    </Badge>
                    <span className={classes.middle}>
                      <AddAPhotoIcon className={classes.photoIcon} />
                      Update Photo
                    </span>
                  </IconButton>
                </Tooltip>

                <Typography
                  variant="h5"
                  color="inherit"
                  className={classes.colorDisabled}
                >
                  {(profile && profile.userName) || "Loading..."}
                </Typography>
                <Typography variant="overline">
                  {(profile && profile?.jobTitle?.name) || "Loading..."}
                </Typography>
                <Grid container justify="center" alignItems="center">
                  {state.signature ? (
                    <img
                      src={state.signature}
                      alt="signature"
                      className={classes.signatureImg}
                    />
                  ) : (
                    <Typography className={classes.signature}>
                      signature
                    </Typography>
                  )}

                  <label htmlFor="signature" className={classes.label2}>
                    <Tooltip
                      arrow
                      title="Click Icon to Upload Signature"
                      placement="bottom"
                    >
                      <UploadIcon />
                    </Tooltip>
                  </label>

                  <IconButton
                    onClick={handleRemoveSignature}
                    disabled={state.signature === undefined}
                  >
                    <Tooltip
                      arrow
                      title="Click Icon to Remove Signature"
                      placement="bottom-start"
                    >
                      <RemoveIcon color="error" />
                    </Tooltip>
                  </IconButton>

                  <input
                    type="file"
                    name="signature"
                    id="signature"
                    accept="image/*"
                    className={classes.input}
                    onChange={handleChangeSignature}
                  />
                </Grid>
                <Divider variant="middle" className={classes.signatureRule} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card>
              <CardHeader
                title={
                  <Typography
                    variant="h6"
                    style={{ fontSize: 18, fontWeight: 900 }}
                    className={classes.header}
                  >
                    Profile
                  </Typography>
                }
              />
              <Divider light />
              <form onSubmit={handleSubmit}>
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        label="First Name"
                        variant="outlined"
                        fullWidth
                        name="firstName"
                        value={state.firstName || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        label="Last Name"
                        variant="outlined"
                        fullWidth
                        name="lastName"
                        value={state.lastName || ""}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        label="Phone Number"
                        variant="outlined"
                        fullWidth
                        name="phone"
                        value={state.phone || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider light />
                <CardActions style={{ padding: 16 }}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    className={classes.btn}
                    disabled={loading || validCheck()}
                    endIcon={loading ? <CircularProgress size={20} /> : null}
                  >
                    {loading ? "Loading..." : "Save Changes"}
                  </Button>
                </CardActions>
              </form>
            </Card>
          </Grid>
        </Grid>
      </TabPanel>

      {/* Change Password */}

      <TabPanel value={value} index={1}>
        <Card className={classes.marginBottom}>
          <CardHeader
            title={
              <Typography
                variant="h6"
                style={{ fontSize: 18, fontWeight: 900 }}
                className={classes.header}
              >
                Two-Factor Authentication
              </Typography>
            }
          />
          <Divider light />
          <CardContent>
            <FormHelperText style={{ marginBottom: 10 }}>
              Two-factor authentication is enabled by default on your Account to
              Keep your data secure and prevent malicious activities on this
              application.
            </FormHelperText>
            <>
              {profile && profile.isAuth ? (
                <RippleEffect variant="dot" overlap="circle" />
              ) : (
                <DisableRippleEffect variant="dot" overlap="circle" />
              )}{" "}
              <Chip
                label={profile && profile.isAuth ? "ENABLED" : "DISABLED"}
                variant="outlined"
                className={classes.chip}
              />
            </>
          </CardContent>
        </Card>
        <Card>
          <CardHeader
            title={
              <Typography
                variant="h6"
                style={{ fontSize: 18, fontWeight: 900 }}
                className={classes.header}
              >
                Change Password
              </Typography>
            }
          />
          <Divider light />
          <form onSubmit={handleChangePassword}>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Old Password"
                    type={visibility.password1 ? "text" : "password"}
                    variant="outlined"
                    name="currentPassword"
                    value={pass.currentPassword || ""}
                    onChange={handleChangePass}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleVisibility("password1")}
                          >
                            {visibility.password1 ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="New Password"
                    type={visibility.password2 ? "text" : "password"}
                    name="newPassword"
                    value={pass.newPassword}
                    onChange={handleChangePass}
                    onFocus={handleFocus}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleVisibility("password2")}
                          >
                            {visibility.password2 ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      helper
                        ? "Password needs to have at least one lower case, one uppercase, one number, one special character, and must be at least 8 characters but no more than 35."
                        : null
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider light />
            <CardActions
              style={{
                padding: 16,
              }}
            >
              <Button
                variant="contained"
                type="submit"
                color="primary"
                className={classes.btn}
                disabled={
                  !pass.currentPassword || !pass.newPassword || loadingPassword
                }
                endIcon={
                  loadingPassword ? (
                    <CircularProgress color="primary" size={15} />
                  ) : null
                }
              >
                {loadingPassword ? "Loading..." : "Change Password"}
              </Button>
            </CardActions>
          </form>
        </Card>
      </TabPanel>

      <Backdrop
        className={classes.backdrop}
        open={
          loadingSignature || loadingDeleteSignature || loadingDeleteProfile
        }
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

/* --------------------------- User Photo Modal ------------------------------- */

const UserPhotoModal = ({
  open,
  handleClose,
  profile,
  uploadPhoto,
  getProfile,
}) => {
  const classes = useStyles();
  const [mediaPreview, setMediaPreview] = React.useState("");
  const [photo, setPhoto] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  const dispatch = useDispatch();
  const { message: deletePhotoMessage } = useSelector((state) => state.user);

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };
  React.useEffect(() => {
    if (deletePhotoMessage !== "") {
      getProfile();
      setFeed((prev) => ({
        ...prev,
        loading: false,
        open: !prev.open,
        message: deletePhotoMessage,
        success: true,
      }));
    }
  }, [getProfile, deletePhotoMessage]);

  React.useEffect(() => {
    if (deletePhotoMessage !== "") {
      setTimeout(() => {
        dispatch({
          type: "RESET_PROFILE_PICTURE_MESSAGE",
        });
      }, 3000);
    }
  }, [deletePhotoMessage, dispatch]);

  const handleChange = (e) => {
    e.persist();
    const { files } = e.target;

    setPhoto(files[0]);
    setMediaPreview(window.URL.createObjectURL(files[0]));
  };

  const handleRemovePhoto = () => {
    dispatch(removeProfilePhoto());
    setTimeout(() => {
      handleClose();
    }, 3000);
  };

  const handleUploadPhoto = () => {
    setLoading((prev) => !prev);
    const formData = new FormData();
    formData.append("img", photo);

    uploadPhoto(formData)
      .then((doc) => {
        if (doc.success === true) {
          setLoading(false);
          handleClose();
          setFeed({
            loading: false,
            open: !feed.open,
            message: `Profile Photo uploaded successfully`,
            success: true,
          });
          setMediaPreview("");
          setPhoto(null);
          getProfile();
        } else {
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            // message: `Invalid Image format.`,
            success: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
        // setFeed({
        //   loading: false,
        //   open: !feed.open,
        //   // message: `Invalid Image format.`,
        //   success: false,
        // });
      });
  };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message || `Profile Photo uploaded successfully`}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || `Invalid Image format.`}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ textAlign: "center", fontSize: 18 }}
        >
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Change Avatar
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider light />
        <DialogContent>
          <label htmlFor="img">
            <Tooltip title="Click to Select Avatar" placement="right" arrow>
              <Avatar
                sizes="30"
                className={classes.large}
                src={
                  mediaPreview || (profile && profile.photo) || "/img/user.svg"
                }
              />
            </Tooltip>
          </label>

          <input
            type="file"
            name="img"
            id="img"
            accept="image/*"
            className={classes.input}
            onChange={handleChange}
          />
          {photo && photo.name}
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <ButtonGroup>
            <Button
              color="secondary"
              variant="contained"
              disabled={!(profile && profile.photo)}
              className={classes.saveBtn}
              onClick={handleRemovePhoto}
            >
              <DeleteIcon />
            </Button>
            <Button
              variant="contained"
              color="primary"
              endIcon={<Icon className="fas fa-upload" />}
              disabled={!photo || !mediaPreview || loading}
              onClick={handleUploadPhoto}
              className={classes.saveBtn}
            >
              {loading ? "Loading..." : "Upload"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};
