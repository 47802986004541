import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    AppBar,
    IconButton,
    Dialog,
    Toolbar,
    Tooltip,
    DialogContent,
    Backdrop,
    Radio,
    MenuItem,
    Select,
    RadioGroup,
    FormLabel,
    FormControlLabel,
    FormControl,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import { connect, useDispatch, useSelector } from "react-redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory } from "react-router-dom";
import Feedback from "../../../../Feedback";
import { getProfile } from "../../../../actions/userActions";
import {
    createPurchaseOrder,
    getPurchaseOrders,
    getAcceptedRequestForQuotes
} from "../../../../actions/purchasingActions";
import { getCurrencies } from "../../../../actions/currencyActions";
// import { formatCurrency } from "../../../../utils";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#fff",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 10,
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        paddingBottom: 0
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000000,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },
    btnWrapper: {
        textAlign: "center",
    },
    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 300,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const INIT_STATE = {
    employee: "",
    employeeId:"",
    division: "",
    items: [],
    rfq: null,
    rfqId: "",
    totalQuantityNeeded: null,
    totalAmount: null,
    discount: null,
    serviceCharge: null,
    freightCharge: null,
    paymentTerms: "",
    currency: "",
    creditTerms: "",
    totalVat: null,
    finalAmount: null,
};

const CreatePurchaseOrderModal = ({ openCreate, handleCloseCreate, createPurchaseOrder }) => {
    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });
    const [month] = useState(moment().format("MMMM"));
    const [year] = useState(moment().format("YYYY"));
    const [state, setState] = useState(INIT_STATE);
    const { profile } = useSelector((state) => state.user);
    const { rfqs_accepted, loading, error } = useSelector((state) => state.rfq);
    const { currencies } = useSelector((state) => state.currency);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getCurrencies());
        dispatch(getAcceptedRequestForQuotes());
    }, [dispatch]);

    useEffect(() => {
        if (state.rfq) {
            setState((prev) => ({
                ...prev,
                employee: profile && profile._id,
                month: month,
                year: year,
                rfqId: state.rfq && state.rfq._id,
                purchaseReq: state.rfq && state.rfq.purchaseRequisition?._id,
                purpose: state.rfq && state.rfq.purchaseRequisition?.purpose,
                requisitionType: state.rfq && state.rfq.purchaseRequisition?.requisitionType,
                purchaseType: state.rfq && state.rfq.purchaseRequisition?.purchaseType,
                shippingType: state.rfq && state.rfq.purchaseRequisition?.shippingType,
                vendorType: state.rfq && state.rfq.purchaseRequisition?.vendorType,
                vendorName: state.rfq && state.rfq.vendor?.companyName,
                vendor: state.rfq && state.rfq.vendor?._id,
                rfqDivision: state.rfq && state.rfq.division?.name,
                division: state.rfq && state.rfq.division?._id,
                items: state.rfq && state.rfq.items,
                dateNeeded: (state.rfq && moment(state.rfq.dateNeeded).format("l")) || "",
            }))
        }
    }, [profile, state.rfq, year, month]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    // capture item changes
    const handleItemChange = (i) => (e) => {
        e.persist();
        const newItems = state.items.map((item, index) => {
            if (i !== index) {
                return item;
            }
            
            if(item.vatApplicable !== "Yes"){
                let vat = (item.extendedCost * 0.075);
                return {
                    ...item,
                    vat: vat,
                    vatExtendedCost: (item.extendedCost + vat),
                    [e.target.name]: e.target.value,
                };
            } else if(item.vatApplicable !== "No") {
                return {
                    ...item,
                    vat: null,
                    vatExtendedCost: item.extendedCost,
                    [e.target.name]: e.target.value,
                };
            } else {
                return {
                    ...item,
                };
            }
        });
        setState((prev) => ({
            ...prev,
            items: newItems,
        }));
    };

    useEffect(() => {
        if (state.items && state.items.length > 0) {
            let total1 = state.items.reduce((acc, cur) => { return acc + +cur.quantityNeeded; }, 0);
            let total2 = state.items.reduce((acc, cur) => { return acc + +cur.extendedCost; }, 0);
            
            setState((prev) => ({
                ...prev,
                totalQuantityNeeded: total1,
                totalAmount: total2,
            }));
        }
    }, [state.items]);

    useEffect(() => {
        if(state.items && state.items.length > 0){
            let remItems = state.items.filter((item) => ((item.vatApplicable === "Yes") && (item.vat !== null)));
            
            if(remItems && remItems.length > 0){
                let totalVat = remItems.reduce((acc, cur) => { return acc + +cur.vat; }, 0);
                setState((prev) => ({
                    ...prev,
                    totalVat: totalVat,
                }));
            }else {
                setState((prev) => ({
                    ...prev,
                    totalVat: null,
                }));
            }
        }
    }, [state.items]);

    useEffect(() => {
        if(state.totalAmount && state.discount && state.freightCharge && state.serviceCharge){
            let part1 = (+state.serviceCharge + +state.freightCharge);

            let finalAmount = parseFloat((+state.totalAmount + (+part1)) - (+state.discount));

            if((state.totalVat !== null) || (state.totalVat !== "")){
                setState((prev) => ({
                    ...prev,
                    finalAmount: (+finalAmount + +state.totalVat),
                }));
            }else {
                setState((prev) => ({
                    ...prev,
                    finalAmount: finalAmount,
                }));
            }
        }
    }, [state.totalAmount, state.discount, state.freightCharge, state.serviceCharge, state.totalVat]);

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            rfq: state.rfqId,
            items: state.items,
        };
        const res = await new Promise((resolve, reject) => {
            resolve(createPurchaseOrder(data));
        });
        
        if (res) {
            dispatch(getPurchaseOrders());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Purchase Order Created Successfully.",
                success: true,
            }));
            setTimeout(() => {
                history.push(`/purchasing/purchaseorder`);
                handleCloseCreate();
            }, 2000);
        }
    };

    const validCheck = () =>
        !state.division ||
        !state.employee ||
        !state.rfq ||
        !state.currency ||
        !state.creditTerms ||
        (state.items.length === 0) ||
        (state.totalQuantityNeeded === 0) ||
        (state.totalAmount === 0);

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <Dialog
                    open={openCreate}
                    onClose={handleCloseCreate}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    fullScreen
                    maxWidth="md"
                >
                    <AppBar
                        className={classes.appBar}
                        variant="elevation"
                        position="fixed"
                        color="inherit"
                    >
                        <Toolbar>
                            <Tooltip title="close" arrow>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseCreate}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.container}>
                                <Typography variant="overline" className={classes.title}>
                                    Create Purchase Order
                                </Typography>

                                <Typography variant="overline" className={classes.title2}>
                                    RFQ Details
                                </Typography>

                                <Paper variant="outlined" square className={classes.paper}>
                                    <Grid
                                        container
                                        justify="center"
                                        alignItems="center"
                                        spacing={2}
                                        className={classes.grid}
                                    >
                                        <Grid item xs={12} md={4}>
                                            <label style={{ fontWeight: "bold" }}>Select Accepted RFQs</label>
                                            <Autocomplete
                                                id="rfq"
                                                options={rfqs_accepted}
                                                value={state.rfq}
                                                debug
                                                onChange={(event, newValue) => {
                                                    setState((prev) => ({
                                                        ...prev,
                                                        rfq: newValue,
                                                    }));
                                                }}
                                                getOptionLabel={(option) => option.documentNo || ""}
                                                renderOption={(option) => (
                                                    <React.Fragment>{option.vendor?.companyName}</React.Fragment>
                                                )}
                                                renderInput={(params) => (
                                                    <>
                                                        <TextField
                                                            {...params}
                                                            placeholder="Type something..."
                                                            fullWidth
                                                            required
                                                            variant="outlined"
                                                            value={(state.rfq && state.rfq) || ""}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                autoComplete: "disabled",
                                                                name: "rfq",
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label style={{ fontWeight: "bold" }}>Currency</label>
                                            <FormControl fullWidth variant="outlined" required>
                                                <Select
                                                    inputProps={{
                                                        id: "currency",
                                                        name: "currency",
                                                    }}
                                                    value={state.currency || ""}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem disabled>Select Currency</MenuItem>
                                                    {currencies.map((currency) => (
                                                        <MenuItem key={currency._id} value={currency.code}>
                                                            {currency.code}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label style={{ fontWeight: "bold" }}>Credit Terms</label>
                                            <FormControl fullWidth variant="outlined" required>
                                                <Select
                                                    inputProps={{
                                                        id: "creditTerms",
                                                        name: "creditTerms",
                                                    }}
                                                    value={state.creditTerms || ""}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem disabled>Select Terms</MenuItem>
                                                    <MenuItem value="Advance">Advance</MenuItem>
                                                    <MenuItem value="14 Days">14 Days</MenuItem>
                                                    <MenuItem value="30 Days">30 Days</MenuItem>
                                                    <MenuItem value="45 Days">45 Days</MenuItem>
                                                    <MenuItem value="60 Days">60 Days</MenuItem>
                                                    <MenuItem value="See additional payment terms">See Additional Payment Terms</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {(state.creditTerms === "See additional payment terms") ? 
                                        <Grid item xs={12} md={4}>
                                            <label style={{ fontWeight: "bold" }}>Additional Payment Terms</label>
                                            <TextField
                                                id="paymentTerms"
                                                name="paymentTerms"
                                                placeholder="Additional Payment Terms"
                                                value={state.paymentTerms || ""}
                                                onChange={handleChange}
                                                variant="outlined"
                                                fullWidth
                                                />
                                        </Grid> : null}
                                        <Grid item xs={12} md={4}>
                                            <label >Vendor Company</label>
                                            <TextField
                                                id="vendorCompany"
                                                name="vendorCompany"
                                                placeholder="Vendor Company"
                                                value={state.vendorName || ""}
                                                onChange={handleChange}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label >Requisition Type</label>
                                            <TextField
                                                id="requisitionType"
                                                name="requisitionType"
                                                placeholder="Requisition Type"
                                                value={state.requisitionType || ""}
                                                onChange={handleChange}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label >Purchase Type</label>
                                            <TextField
                                                id="purchaseType"
                                                name="purchaseType"
                                                placeholder="Purchase Type"
                                                value={state.purchaseType || ""}
                                                onChange={handleChange}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label >Vendor Type</label>
                                            <TextField
                                                id="vendorType"
                                                name="vendorType"
                                                placeholder="Vendor Type"
                                                value={state.vendorType || ""}
                                                onChange={handleChange}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label >Shipping Type</label>
                                            <TextField
                                                id="shippingType"
                                                name="shippingType"
                                                placeholder="Shipping Type"
                                                value={state.shippingType || ""}
                                                onChange={handleChange}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled
                                            />
                                        </Grid>
                                        
                                        <Grid item xs={12} md={4}>
                                            <label >RFQ Division</label>
                                            <TextField
                                                id="rfqDivision"
                                                name="rfqDivision"
                                                placeholder="RFQ Division"
                                                value={state.rfqDivision || ""}
                                                onChange={handleChange}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label >Date Needed</label>
                                            <TextField
                                                id="dateNeeded"
                                                name="dateNeeded"
                                                placeholder="Date Needed"
                                                value={state.dateNeeded || ""}
                                                onChange={handleChange}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <label >Purpose</label>
                                            <TextField
                                                id="Purpose"
                                                name="purpose"
                                                placeholder="Purpose"
                                                value={state.purpose || ""}
                                                onChange={handleChange}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                disabled
                                            />
                                        </Grid>
                                        {((state.creditTerms !== "See additional payment terms") || (state.creditTerms !== "See additional terms")) ? <Grid item xs={12} md={4}></Grid> : null}
                                    </Grid>
                                </Paper>


                                {/* Items requested */}
                                <>
                                    <Typography variant="overline" className={classes.title2}>
                                        Requested Items
                                    </Typography>

                                    <Paper variant="outlined" square className={classes.paper}>
                                        {state.items ? state.items.map((sitem, i) => (
                                            <Paper variant="outlined" square className={classes.paper} key={i}>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    className={classes.grid}
                                                >
                                                    <Grid item xs={12} md={4}>
                                                        <label style={{ fontWeight: "bold" }}>Product / Service Description</label>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            name="description"
                                                            value={sitem.description || ""}
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <label style={{ fontWeight: "bold" }}>Quantity (units)</label>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            type="text"
                                                            name="quantityNeeded"
                                                            value={`${sitem.quantityNeeded + ' ' + sitem.unit}` || ""}
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <label style={{ fontWeight: "bold" }}>Budget Amount (NGN)</label>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            type="text"
                                                            name="budgetAmount"
                                                            value={(sitem && sitem.budgetAmount) || ""}
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <label style={{ fontWeight: "bold" }}>Quoted Amount (NGN)</label>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            type="text"
                                                            name="quotedAmount"
                                                            value={(sitem && sitem.quotedAmount) || ""}
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={2}>
                                                        <label style={{ fontWeight: "bold" }}>Extended Cost (NGN)</label>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            required
                                                            type="text"
                                                            name="extendedCost"
                                                            value={(sitem && sitem.extendedCost) || ""}
                                                            disabled
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    className={classes.grid}
                                                >
                                                    <Grid item xs={12} md={4}>
                                                        <FormControl
                                                            fullWidth
                                                            required
                                                            variant="outlined"
                                                            component="fieldset"
                                                        >
                                                            <FormLabel>Is VAT applicable?</FormLabel>
                                                            <RadioGroup row defaultValue="No" required>
                                                                <FormControlLabel
                                                                    value="Yes"
                                                                    control={<Radio checked={(sitem && sitem.vatApplicable === "Yes") ? true : false} />}
                                                                    label="Yes"
                                                                    name="vatApplicable"
                                                                    onChange={handleItemChange(i)}
                                                                />
                                                                <FormControlLabel
                                                                    value="No"
                                                                    control={<Radio checked={(sitem && sitem.vatApplicable === "No") ? true : false} />}
                                                                    label="No"
                                                                    name="vatApplicable"
                                                                    onChange={handleItemChange(i)}
                                                                />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    {(sitem && sitem.vatApplicable === "Yes") ? (
                                                        <>
                                                            <Grid item xs={12} md={2}>
                                                                <label style={{ fontWeight: "bold" }}>VAT Applicable (7.5%):</label>
                                                                <TextField
                                                                    placeholder="Applicable VAT"
                                                                    name="vat"
                                                                    value={(sitem && sitem.vat) || 0}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    disabled
                                                                    />
                                                            </Grid>
                                                            <Grid item xs={12} md={2}>
                                                                <label style={{ fontWeight: "bold" }}>Extended Cost + VAT (NGN):</label>
                                                                <TextField
                                                                    placeholder="Extended Cost + VAT"
                                                                    name="vatExtendedCost"
                                                                    value={(sitem && sitem.vatExtendedCost) || 0}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    disabled
                                                                    />
                                                            </Grid>
                                                        </>
                                                    ) : null}
                                                </Grid>
                                            </Paper>
                                        )) : <>
                                            <Typography variant="overline" className={classes.title2}>
                                                No Requested Items
                                            </Typography>
                                        </>}
                                        {state.items && state.items.length > 0 ?
                                            <Grid
                                                container
                                                spacing={2}
                                                className={classes.grid}
                                            >
                                                <Grid item xs={12} md={2}>
                                                    <label style={{ fontWeight: "bold" }}>Discount:</label>
                                                    <TextField
                                                        placeholder="Discount"
                                                        name="discount"
                                                        value={(state && state.discount) || ""}
                                                        variant="outlined"
                                                        fullWidth
                                                        onChange={handleChange}
                                                        type="number"
                                                        required
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <label style={{ fontWeight: "bold" }}>Service Charge:</label>
                                                    <TextField
                                                        placeholder="Service Charge"
                                                        name="serviceCharge"
                                                        value={(state && state.serviceCharge) || ""}
                                                        variant="outlined"
                                                        fullWidth
                                                        onChange={handleChange}
                                                        type="number"
                                                        required
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={2}>
                                                    <label style={{ fontWeight: "bold" }}>Freight Charge:</label>
                                                    <TextField
                                                        placeholder="Freight Charge"
                                                        name="freightCharge"
                                                        value={(state && state.freightCharge) || ""}
                                                        variant="outlined"
                                                        fullWidth
                                                        onChange={handleChange}
                                                        type="number"
                                                        required
                                                    />
                                                </Grid>
                                            </Grid>
                                        : null}
                                    </Paper>
                                </>

                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={8} />
                                    <Grid item xs={12} md={2}>
                                        <label style={{ fontWeight: "bold" }}>Total Items Quantity</label>
                                        <TextField
                                            placeholder="Total Quantity Needed"
                                            name="totalQuantityNeeded"
                                            value={(state && state.totalQuantityNeeded) || "0"}
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <label style={{ fontWeight: "bold" }}>Total Amount</label>
                                        <TextField
                                            placeholder="Total Amount"
                                            name="totalAmount"
                                            value={(state && state.totalAmount) || "0"}
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                </Grid>

                                <div className={classes.btnWrapper}>
                                    <Button
                                        type="submit"
                                        className={classes.saveBtn}
                                        disabled={loading || validCheck()}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {loading ? "Loading..." : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </form>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </DialogContent>
                </Dialog>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default connect(null, { createPurchaseOrder,  getAcceptedRequestForQuotes })(CreatePurchaseOrderModal);
