import React, { useState, useEffect } from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    Icon,
    MenuItem,
    Button,
    Divider,
    darken,
    Backdrop,
    ButtonGroup,
    Hidden,
    FormLabel,
    FormControlLabel,
    IconButton,
    Badge,
    Checkbox,
    RadioGroup,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import InvoiceViewModal from "../Modals/Quality/InvoiceModal";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../../Feedback";
import {
    verifyReceivingInspection,
    getReceivingInspection,
    getReceivingInspections,
} from "../../../actions/qualityActions";

import ApproveRAIModal from "../Modals/Quality/ApproveRAIModal";
import DisapproveRAIModal from "../Modals/Quality/DisapproveRAIModal";

import AcceptRAIModal from "../Modals/Quality/AcceptRAIModal";
import RejectRAIModal from "../Modals/Quality/RejectRAIModal";

import ReviewRAIModal from "../Modals/Quality/ReviewRAIModal";
import DeclineRAIModal from "../Modals/Quality/DeclineRAIModal";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight:'bold',
        borderBottomColor: '#444',
        borderBottomWidth: 1,
        borderBottomStyle:'solid',
        paddingBottom: 10,
        marginBottom: 15,
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    paper3: {
        padding: theme.spacing(3),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
        marginTop: 20, 
        background: darken("#fff", 0.008),
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 400,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    employee: "",
    division: "",
    date: "",
    items: [{
        description: "",
        quantityOrdered: "",
        unit: ""
    }],
};


const INIT_PARAMS = {
    inspectionParams: [
        { type: "Identification", value: "",},
        { type: "Quantity", value: "",},
        { type: "Damages", value: "", },
        { type: "Required Markings", value: "",},
        { type: "Cleanliness/Good condition", value: "",},
        { type: "Documentation", value: "",},
    ],
    specDiscrepancies: [
        { type: "Incorrect Specification", value: "",},
        { type: "Expired Goods from Inspection", value: "",},
        { type: "Number of Damaged Goods/Parts", value: "", },
        { type: "Goods with Poor Finishing", value: "",},
        { type: "Operability/Functionality", value: "",},
        { type: "Percentage Supply Compliance", value: "",},
    ],
};


const ViewReceiving = () => {    
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    const [myparams, setMyParams] = useState(INIT_PARAMS);
    const [theUrl, setTheUrl] = useState("");
    const [openDoc, setOpenDoc] = useState(false);

    const [openAccept, setOpenAccept] = useState(false);
    const [openDecline, setOpenDecline] = useState(false);
    const [openApprove, setOpenApprove] = useState(false);
    const [openDisapprove, setOpenDisapprove] = useState(false);
    const [openReject, setOpenReject] = useState(false);
    const [openReview, setOpenReview] = useState(false);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { error, loading, receiving } = useSelector((state) => state.receiving);
    const { profile } = useSelector((state) => state.user);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getReceivingInspections());
        dispatch(getReceivingInspection(id));
    }, [dispatch, id]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            inspectionParams: (receiving && receiving.inspectionParams) || (myparams.inspectionParams),
            specDiscrepancies: (receiving && receiving.specDiscrepancies) || (myparams.specDiscrepancies),
        }));
    }, [receiving, myparams]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: (receiving && receiving.employee) || "",
            division: (receiving && receiving.division) || "",
            costType: (receiving && receiving.costType) || "",
            costCenter: (receiving && receiving.costCenter) || "",
            vendor: (receiving && receiving.vendor) || "",
            supplierId: (receiving && receiving.supplierId) || "",
            deliveryTime: (receiving && receiving.deliveryTime) || "",
            deliveryLocation: (receiving && receiving.deliveryLocation) || "",
            rejectionReason: (receiving && receiving.rejectionReason) || "",
            paymentTerm: (receiving && receiving.paymentTerm) || "",
            date: (receiving && moment(receiving.date).format("l")) || "",
            
            items: (receiving && receiving.items) || [],
            invoices: (receiving && receiving.invoices) || [],
            status: (receiving && receiving.status) || "",
            documentNo: (receiving && receiving.documentNo) || "",
            purchaseOrderNo: (receiving && receiving.purchaseOrderNo) || "",

            createdBy: (receiving && receiving.createdBy) || "",
            createdAt: (receiving && receiving.createdAt) || "",

            submittedBy: (receiving && receiving.submittedBy) || "",
            submittedAt: (receiving && receiving.submittedAt) || "",

            verifiedBy: (receiving && receiving.verifiedBy) || "",
            verifiedAt: (receiving && receiving.verifiedAt) || "",

            reviewedBy: (receiving && receiving.reviewedBy) || "",
            reviewedAt: (receiving && receiving.reviewedAt) || "",
            reviewComment: (receiving && receiving.reviewComment) || "",

            approvedBy: (receiving && receiving.approvedBy) || "",
            approvedAt: (receiving && receiving.approvedAt) || "",
            approveComment: (receiving && receiving.approveComment) || "",
            
            rejectedBy: (receiving && receiving.rejectedBy) || "",
            rejectedAt: (receiving && receiving.rejectedAt) || "",
            rejectComment: (receiving && receiving.rejectComment) || "",

            acceptedBy: (receiving && receiving.acceptedBy) || "",
            acceptedAt: (receiving && receiving.acceptedAt) || "",
            acceptComment: (receiving && receiving.acceptComment) || "",

            declinedBy: (receiving && receiving.declinedBy) || "",
            declinedAt: (receiving && receiving.declinedAt) || "",
            declineComment: (receiving && receiving.declineComment) || "",

        }));
    }, [receiving, myparams]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    // capture item changes
    const handleVendorItemChange = (i) => (e) => {
        e.persist();
        const newItems = state.items.map((item, index) => {
            if (i !== index) {
                return item;
            }
            if((e.target.name === "quantityInspected") || (e.target.name === "quantityAccepted") || (e.target.name === "quantityRejected")){
                return {
                    ...item,
                    [e.target.name]: e.target.value,
                };
            } else {
                return {
                    ...item,
                };
            }
        });
        setState((prev) => ({
            ...prev,
            items: newItems,
        }));
    };

    const handleInspectionParamsChange = (i) => (e) => {
        e.persist();
        const newItems = myparams.inspectionParams.map((item, index) => {
            if (i !== index) {
                return item;
            }            
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setMyParams((prev) => ({
            ...prev,
            inspectionParams: newItems,
        }));
    };
    
    const handleDiscrepanciesChange = (i) => (e) => {
        e.persist();
        const newItems = myparams.specDiscrepancies.map((item, index) => {
            if (i !== index) {
                return item;
            }            
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setMyParams((prev) => ({
            ...prev,
            specDiscrepancies: newItems,
        }));
    };

    // qhse head
    const handleOpenApprove = () => {
        setOpenApprove(true);
    };
    const handleCloseApprove = () => {
        setOpenApprove(false);
    };
    const handleOpenDisapprove = () => {
        setOpenDisapprove(true);
    };
    const handleCloseDisapprove = () => {
        setOpenDisapprove(false);
    };

    // qhse
    const handleOpenReview = () => {
        setOpenReview(true);
    };
    const handleCloseReview = () => {
        setOpenReview(false);
    };
    const handleOpenDecline = () => {
        setOpenDecline(true);
    };
    const handleCloseDecline = () => {
        setOpenDecline(false);
    };

    // requester
    const handleOpenAccept = () => {
        setOpenAccept(true);
    };
    const handleCloseAccept = () => {
        setOpenAccept(false);
    };
    const handleOpenReject = () => {
        setOpenReject(true);
    };
    const handleCloseReject = () => {
        setOpenReject(false);
    };

    const handleVerify = async (e) => {
        e.preventDefault();
        const data = {
            paymentTerm: state.paymentTerm,
            rejectionReason: state.rejectionReason,
            items: state.items,
            inspectionParams: myparams.inspectionParams,
            specDiscrepancies: myparams.specDiscrepancies,
            id
        }
        await dispatch(verifyReceivingInspection(data));
        await dispatch(getReceivingInspection(id));
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Receiving and Inspection Record Verified Successfully.",
            success: true,
        }));
        setTimeout(() => {
            history.push("/quality/receivinginspection");
        }, 1500);
    };

    const handleOpenDoc = (url) => {
        setTheUrl(null);
        if(url){
            setTheUrl(url);
            setOpenDoc(true);
        }
    };
    
    const handleCloseDoc = () => {
        setOpenDoc(false);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isAdmin = profile
        ? profile &&
        profile.groups &&
        profile.groups.some((grp) => grp.name === "admin") : false;

    const isQHSE = profile
        ? profile &&
        profile.division &&
        profile?.division?.code === "QHS"
        : false;
    
    const isPMG = profile
        ? profile &&
        profile.division &&
        profile?.division?.code === "PMG"
        : false;

    const isQHSEHead = profile
        ? profile &&
        profile.division &&
        ((profile?.division?.code === "QHS") && (profile?.division?.manager === profile._id))
        : false;

    // console.log(state.costCenter, profile.division)

    const isRequester = (profile && state)
        ? profile &&
        profile.division &&
        (state?.createdBy?.id === profile._id)
        : false;

    const isCostCenter = (profile && state)
        ? profile &&
        profile.division &&
        (state?.costCenter?.id === profile?.division?._id)
        : false;

    const verifyCheck = () =>
        !state.paymentTerm ||
        !state.rejectionReason ||
        !myparams.specDiscrepancies ||
        !myparams.inspectionParams;

    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                <InvoiceViewModal {...{ openDoc, handleCloseDoc, docs: theUrl }} />

                <AcceptRAIModal {...{ openAccept, handleCloseAccept, id }} />
                <RejectRAIModal {...{ openReject, handleCloseReject, id }} />
                
                <ReviewRAIModal {...{ openReview, handleCloseReview, id }} />
                <DeclineRAIModal {...{ openDecline, handleCloseDecline, id }} />
                
                <ApproveRAIModal {...{ openApprove, handleCloseApprove, id }} />
                <DisapproveRAIModal {...{ openDisapprove, handleCloseDisapprove, id }}  />

                <Typography variant="overline" className={classes.title}>
                    Receiving and Inspection
                </Typography>
                <Grid container justify="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.backBtn}
                            startIcon={<ArrowBackIosIcon />}
                            onClick={() => history.push("/quality/receivinginspection")}
                        >
                            Back
                        </Button>

                        {((isQHSE || isAdmin || isPMG) && (state.status === "verified" || state.status === "approved")) ? (
                        <Button
                            color="secondary"
                            variant="contained"
                            style={{ marginLeft: 10 }}
                            className={classes.backBtn}
                            onClick={() => history.push(`/quality/receiving/export/${id}`)}
                        >
                            View Document
                        </Button>
                    ) : null}

                    </Grid>
                    <Grid item>
                        <Paper variant="outlined" className={classes.status}>
                            <Typography variant="overline" className={classes.statusTitle}>
                                Document No.
                            </Typography>
                            <Divider className={classes.divider2} orientation="vertical" />
                            {loading ? "Loading..." : state.documentNo}
                        </Paper>
                    </Grid>
                </Grid>

                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                    style={{ marginBottom: 20 }}
                >
                    <Hidden smDown>
                        <Grid item></Grid>
                    </Hidden>
                    <Grid item>
                        <Paper
                            variant="outlined"
                            className={clsx(
                                state.status === "draft"
                                    ? classes.statusYellow
                                    : state.status === "submitted"
                                        ? classes.statusOrange
                                        : (state.status === "verified" || state.status === "reviewed" || state.status === "accepted")
                                            ? classes.statusGreen
                                            : (state.status === "approved" || state.status === "sent")
                                                ? classes.statusDarkGreen
                                                : classes.statusRed,
                                classes.status
                            )}
                        >
                            <Typography variant="overline" className={classes.statusTitle}>
                                Status
                            </Typography>
                            <Divider className={classes.divider2} orientation="vertical" />
                            <Typography variant="overline" className={classes.statusTitle}>
                                {loading ? "Loading..." : state.status}
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <div>

                    <Grid container spacing={2} className={classes.grid}>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Employee Name"
                                name="employeeName"
                                variant="outlined"
                                fullWidth
                                value={(state.createdBy && state.createdBy.userName) || ""}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Division/Subdivision"
                                name="division"
                                variant="outlined"
                                fullWidth
                                value={(state.division && state.division.name) || ""}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Document No"
                                name="documentNo"
                                variant="outlined"
                                fullWidth
                                value={state.documentNo || ""}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Purchase Order Number"
                                name="purchaseOrderNo"
                                variant="outlined"
                                fullWidth
                                value={(state.purchaseOrderNo && state.purchaseOrderNo) || ""}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Vendor"
                                name="vendor"
                                variant="outlined"
                                fullWidth
                                value={((state.vendor && state.vendor?.companyName) || (state.vendorName && state.vendorName)) || ""}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                label="Supplier ID"
                                name="supplierId"
                                value={(state.supplierId && state.supplierId) || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <DatePicker
                                className={classes.marginBottom}
                                autoOk
                                format="DD/MM/yyyy"
                                views={["year", "month", "date"]}
                                label="Date"
                                fullWidth
                                disabled
                                required
                                inputVariant="outlined"
                                value={(state.date && state.date) || ""}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                label="Delivery Location"
                                name="deliveryLocation"
                                value={(state.deliveryLocation && state.deliveryLocation) || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                label="Cost Type"
                                name="costType"
                                value={(state.costType && state.costType) || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                required
                                label="Cost Center"
                                name="costCenter"
                                value={(state.costCenter && state.costCenter?.name) || ""}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <FormControl fullWidth variant="outlined" required>
                                <InputLabel id="deliveryTime">Was The Product/Service Delivered On Time?</InputLabel>
                                <Select
                                    inputProps={{
                                        id: "deliveryTime",
                                        name: "deliveryTime",
                                    }}
                                    value={state.deliveryTime || ""}
                                    disabled
                                >
                                    <MenuItem disabled>Select</MenuItem>
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {(state.invoices && state.invoices.length > 0) && <>
                        
                    <Typography variant="overline" className={classes.title2}>
                        Uploaded Invoices
                    </Typography>

                    <Paper variant="outlined" square className={classes.paper} style={{ paddingBottom:0 }}>
                        {state.invoices ? state.invoices.map((sinvoice, i) => (
                            <Grid
                                container
                                spacing={2}
                                className={classes.grid}
                                key={i}
                            >
                                <Grid item xs={12} md={11}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        label="Description of Invoice"
                                        name="description"
                                        value={sinvoice.description || ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <IconButton
                                        disabled={sinvoice.invoice === null}
                                        onClick={() => handleOpenDoc(sinvoice.invoice)}
                                    >
                                        <Badge
                                            color="secondary"
                                            badgeContent={sinvoice.invoice ? 1 : null}
                                        >
                                            <AttachFileIcon />
                                        </Badge>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )) : <>
                        <Typography variant="overline" className={classes.title2}>
                            No Invoice Uploaded
                        </Typography>
                        </>}
                    </Paper> </> }

                    <Paper variant="outlined" square className={classes.paper}>
                        <Typography variant="overline" className={classes.title2}>
                            --- Please complete the Vendor Payment Terms and Reason for rejection or any comment below. Enter NA if not applicable. <br />
                            --- Also fill in the quantity inspected, accepted and rejected for each purchase order items. <br />
                            --- Kindly enter 0 if not applicable..
                        </Typography>
                    </Paper>

                    <>
                        <Grid container spacing={2} className={classes.grid}>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Vendor Payment Term"
                                    name="paymentTerm"
                                    value={(state.paymentTerm && state.paymentTerm) || ""}
                                    onChange={handleChange}
                                    disabled={state.status !== "submitted"}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    label="Reasons for Rejection/Comment"
                                    multiline
                                    rows={3}
                                    name="rejectionReason"
                                    value={(state.rejectionReason && state.rejectionReason) || ""}
                                    onChange={handleChange}
                                    disabled={state.status !== "submitted"}
                                />
                            </Grid>
                        </Grid>

                        {state.status === "submitted" ? 
                        <Grid container spacing={2} className={classes.grid}>
                            <Grid item xs={12} md={6}>
                                <Paper variant="outlined" square className={classes.paper3}>
                                    <Typography variant="overline" className={classes.title3}>
                                        Inspection Parameters (Check if acceptable):
                                    </Typography>

                                    <Grid container spacing={2} className={classes.grid}>
                                        <Grid item xs={12} md={12}>
                                            <table style={{width:'100%'}}>
                                            {myparams.inspectionParams && myparams.inspectionParams.map((inspecItem, i) => (
                                                <tbody key={i}>
                                                    <tr>
                                                        <td><FormLabel>{inspecItem.type}</FormLabel></td>
                                                        <td>
                                                            <FormControl fullWidth variant="outlined" component="fieldset">
                                                                <RadioGroup row value={inspecItem.type || ""} >
                                                                    <FormControlLabel
                                                                        value="Yes"
                                                                        control={<Checkbox checked={inspecItem.value === "Yes"}/>}
                                                                        name="value"
                                                                        onChange={handleInspectionParamsChange(i)}
                                                                    />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ))}
                                            </table>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid> 

                            <Grid item xs={12} md={6}>
                                <Paper variant="outlined" square className={classes.paper3}>
                                    <Typography variant="overline" className={classes.title3}>
                                        Specification Discrepancies (State number rejected):
                                    </Typography>

                                    <Grid container spacing={2} className={classes.grid}>
                                        <Grid item xs={12} md={12}>
                                            <table style={{width:'100%'}}>
                                            {myparams.specDiscrepancies && myparams.specDiscrepancies.map((specItem, i) => (
                                                <tbody key={i}>
                                                    <tr>
                                                        <td><FormLabel>{specItem.type}</FormLabel></td>
                                                        <td>
                                                            <FormControl fullWidth variant="outlined" component="fieldset">
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    label="Enter number"
                                                                    name="value"
                                                                    value={specItem.value || ""}
                                                                    onChange={handleDiscrepanciesChange(i)}
                                                                    disabled={state.status !== "submitted"}
                                                                    />
                                                            </FormControl>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ))}
                                            </table>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>                          
                        </Grid> : null}

                        <Typography variant="overline" className={classes.title2}>
                            Receiving and Inspection Items
                        </Typography>

                        <Paper variant="outlined" square className={classes.paper}>
                            {state.items.map((sitem, i) => (
                                <Paper variant="outlined" square className={classes.paper} key={i}>
                                    <Grid
                                        container
                                        spacing={2}
                                        className={classes.grid}
                                    >
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Description of Item"
                                                name="description"
                                                value={sitem.description || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Quantity Ordered"
                                                name="quantityNeeded"
                                                type="number"
                                                value={sitem.quantityNeeded || ""}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Quantity Delivered"
                                                type="number"
                                                name="quantityDelivered"
                                                value={sitem.quantityDelivered || ""}
                                                disabled
                                            />
                                        </Grid> 
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                type="number"
                                                label="Quantity Received"
                                                name="quantityReceived"
                                                value={sitem.quantityReceived || ""}
                                                disabled
                                            />
                                        </Grid>   
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required={state.status !== "submitted"}
                                                type="number"
                                                label="Quantity Inspected"
                                                placeholder="Quantity Inspected"
                                                name="quantityInspected"
                                                value={sitem.quantityInspected || ""}
                                                onChange={handleVendorItemChange(i)}
                                                disabled={state.status !== "submitted"}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required={state.status !== "submitted"}
                                                type="number"
                                                label="Quantity Accepted"
                                                placeholder="Quantity Accepted"
                                                name="quantityAccepted"
                                                value={sitem.quantityAccepted || ""}
                                                onChange={handleVendorItemChange(i)}
                                                disabled={state.status !== "submitted"}
                                            />
                                        </Grid> 
                                        <Grid item xs={12} md={2}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required={state.status !== "submitted"}
                                                type="number"
                                                label="Quantity Rejected"
                                                placeholder="Quantity Rejected"
                                                name="quantityRejected"
                                                value={sitem.quantityRejected || ""}
                                                onChange={handleVendorItemChange(i)}
                                                disabled={state.status !== "submitted"}
                                            />
                                        </Grid>                          
                                    </Grid>
                                </Paper>
                            ))}
                        </Paper>

                        

                        {/* Verified Data */}
                        {state.status !== "submitted" ? 
                        <Grid container spacing={2} className={classes.grid}>
                            <Grid item xs={12} md={6}>
                                <Paper variant="outlined" square className={classes.paper3}>
                                    <Typography variant="overline" className={classes.title3}>
                                        Inspection Parameters (Checked if deemed acceptable):
                                    </Typography>

                                    <Grid container spacing={2} className={classes.grid}>
                                        <Grid item xs={12} md={12}>
                                            <table style={{width:'100%'}}>
                                            {state.inspectionParams && state.inspectionParams.map((inspecItem, i) => (
                                                <tbody key={i}>
                                                    <tr>
                                                        <td><FormLabel>{inspecItem.type}</FormLabel></td>
                                                        <td>
                                                            <FormControl fullWidth variant="outlined" component="fieldset">
                                                                <RadioGroup row value={inspecItem.type || ""} disabled>
                                                                    <FormControlLabel
                                                                        value="Yes"
                                                                        control={<Checkbox checked={inspecItem.value === "Yes"}/>}
                                                                        name="value"
                                                                    />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ))}
                                            </table>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid> 

                            <Grid item xs={12} md={6}>
                                <Paper variant="outlined" square className={classes.paper3}>
                                    <Typography variant="overline" className={classes.title3}>
                                        Specification Discrepancies (Number rejected):
                                    </Typography>

                                    <Grid container spacing={2} className={classes.grid}>
                                        <Grid item xs={12} md={12}>
                                            <table style={{width:'100%'}}>
                                            {state.specDiscrepancies && state.specDiscrepancies.map((specItem, i) => (
                                                <tbody key={i}>
                                                    <tr>
                                                        <td><FormLabel>{specItem.type}</FormLabel></td>
                                                        <td>
                                                            <FormControl fullWidth variant="outlined" component="fieldset">
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    required
                                                                    label="Enter number"
                                                                    name="value"
                                                                    value={specItem.value || ""}
                                                                    disabled={state.status !== "submitted"}
                                                                />
                                                            </FormControl>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ))}
                                            </table>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>                          
                        </Grid> : null}
                    </>

                    <Grid
                        container
                        alignItems="center"
                        wrap="nowrap"
                        spacing={1}
                        style={{ marginBottom: 5 }}
                    >
                        <Grid item>
                            <Typography variant="overline" className={classes.title2}>
                                Audit Trail
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Icon className="fas fa-clipboard-list" color="disabled" />
                        </Grid>
                    </Grid>
                    <Paper variant="outlined" square className={classes.paper}>
                        <Grid container justify="space-between" alignItems="center" spacing={2}>
                            {state.status === "draft" && (
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Created By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.createdBy && state.createdBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.createdAt}</span>
                                    </Paper>
                                </Grid>
                            )}
                            {state.status !== "draft" && (
                                <>
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Requester</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.submittedBy && state.submittedBy.userName}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.submittedAt}</span>
                                        </Paper>
                                    </Grid>
                                    
                                    {(state.verifiedAt && state.verifiedBy) && (
                                    <Grid item xs={12} md={12}>
                                        <Paper
                                            className={classes.auditContainer}
                                            component="div"
                                            elevation={1}
                                            variant="outlined"
                                            square
                                        >
                                            <span className={classes.auditTitle}>Inspected By</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.verifiedBy && state.verifiedBy.userName}</span>
                                            <Divider orientation="vertical" flexItem />
                                            <span className={classes.audit}>{state.verifiedAt}</span>
                                        </Paper>
                                    </Grid>
                                    )}
                                    {(state.acceptedAt && state.acceptedBy) && (
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <Paper
                                                    className={classes.auditContainer}
                                                    component="div"
                                                    elevation={1}
                                                    variant="outlined"
                                                    square
                                                >
                                                    <span className={classes.auditTitle}>Requester Signs</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.acceptedBy && state.acceptedBy.userName}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.acceptedAt}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.auditTitle}>Comment</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.acceptComment}</span>
                                                </Paper>
                                            </Grid>
                                        </>
                                    )}
                                    {(state.reviewedAt && state.reviewedBy) && (
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <Paper
                                                    className={classes.auditContainer}
                                                    component="div"
                                                    elevation={1}
                                                    variant="outlined"
                                                    square
                                                >
                                                    <span className={classes.auditTitle}>Reviewed By</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.reviewedBy && state.reviewedBy.userName}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.reviewedAt}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.auditTitle}>Comment</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.reviewComment}</span>
                                                </Paper>
                                            </Grid>
                                        </>
                                    )}
                                    {(state.approvedBy && state.approvedAt) && (
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <Paper
                                                    className={classes.auditContainer}
                                                    component="div"
                                                    elevation={1}
                                                    variant="outlined"
                                                    square
                                                >
                                                    <span className={classes.auditTitle}>Approved By</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.approvedBy && state.approvedBy.userName}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.approvedAt}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.auditTitle}>Comment</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.approveComment}</span>
                                                </Paper>
                                            </Grid>
                                        </>
                                    )}
                                    {(state.rejectedAt && state.rejectedBy) && (
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <Paper
                                                    className={classes.auditContainer}
                                                    component="div"
                                                    elevation={1}
                                                    variant="outlined"
                                                    square
                                                >
                                                    <span className={classes.auditTitle}>Rejected By</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.rejectedBy && state.rejectedBy.userName}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.rejectedAt}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.auditTitle}>Comment</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.rejectComment}</span>
                                                </Paper>
                                            </Grid>
                                        </>
                                    )}
                                    {(state.declinedAt && state.declinedBy) && (
                                        <>
                                            <Grid item xs={12} md={12}>
                                                <Paper
                                                    className={classes.auditContainer}
                                                    component="div"
                                                    elevation={1}
                                                    variant="outlined"
                                                    square
                                                >
                                                    <span className={classes.auditTitle}>Declined By</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.declinedBy && state.declinedBy.userName}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.declinedAt}</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.auditTitle}>Comment</span>
                                                    <Divider orientation="vertical" flexItem />
                                                    <span className={classes.audit}>{state.declineComment}</span>
                                                </Paper>
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Paper>

                    {((isAdmin || isQHSE) && (state.status === "submitted")) ? (
                        <Grid container justify="center">
                            <Grid item>
                                <div className={classes.centered}>
                                    <Paper className={classes.paperBlack}>
                                        <HelpOutlineIcon fontSize="large" />
                                        <Typography placeholder="Info" className={classes.info2}>
                                            The verification of the receiving and inspection record and completion of the project is to be done by the QHSE personnnel or designate.
                                        </Typography>
                                    </Paper>
                                
                                    <ButtonGroup>
                                        <Button
                                            className={classes.saveBtn}
                                            color="primary"
                                            variant="contained"
                                            disabled={loading || verifyCheck() }
                                            onClick={handleVerify}
                                        >
                                            {loading ? "Loading..." : "Save Inspection/Verification"}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Grid>
                        </Grid>
                    ) : null}

                    {((isAdmin || isRequester || isCostCenter) && (state.status === "verified")) ? (
                        <Grid container justify="center">
                            <Grid item>
                                <div className={classes.centered}>
                                    <Paper className={classes.paperBlack}>
                                        <HelpOutlineIcon fontSize="large" />
                                        <Typography placeholder="Info" className={classes.info2}>
                                            The signing off (acceptance) of the receiving and inspection record is to be done by the Requester or any member of the Cost Center division.
                                        </Typography>
                                    </Paper>
                                
                                    <ButtonGroup>
                                        <Button
                                            className={classes.saveBtn2}
                                            color="primary"
                                            variant="contained"
                                            disabled={loading}
                                            onClick={handleOpenAccept}
                                        >
                                            {loading ? "Loading..." : "Accept"}
                                        </Button>
                                        <Button
                                            type="submit"
                                            className={classes.saveBtn}
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleOpenReject}
                                            disabled={loading}
                                        >
                                            {loading ? "Loading..." : "Reject"}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Grid>
                        </Grid>
                    ) : null}

                    {((isAdmin || isQHSE) && (state.status === "accepted")) ? (
                        <Grid container justify="center">
                            <Grid item>
                                <div className={classes.centered}>
                                    <Paper className={classes.paperBlack}>
                                        <HelpOutlineIcon fontSize="large" />
                                        <Typography placeholder="Info" className={classes.info2}>
                                            The review of the receiving and inspection record is to be done any member of the QHSE division.
                                        </Typography>
                                    </Paper>
                                
                                    <ButtonGroup>
                                        <Button
                                            className={classes.saveBtn2}
                                            color="primary"
                                            variant="contained"
                                            disabled={loading}
                                            onClick={handleOpenReview}
                                        >
                                            {loading ? "Loading..." : "Review"}
                                        </Button>
                                        <Button
                                            type="submit"
                                            className={classes.saveBtn}
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleOpenDecline}
                                            disabled={loading}
                                        >
                                            {loading ? "Loading..." : "Decline"}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Grid>
                        </Grid>
                    ) : null}

                    {((isAdmin || isQHSEHead || isQHSE) && (state.status === "reviewed")) ? (
                        <Grid container justify="center">
                            <Grid item>
                                <div className={classes.centered}>
                                    <Paper className={classes.paperBlack}>
                                        <HelpOutlineIcon fontSize="large" />
                                        <Typography placeholder="Info" className={classes.info2}>
                                            The approval of the receiving and inspection record is to be done by the QHSE division Head.
                                        </Typography>
                                    </Paper>
                                
                                    <ButtonGroup>
                                        <Button
                                            className={classes.saveBtn2}
                                            color="primary"
                                            variant="contained"
                                            disabled={loading}
                                            onClick={handleOpenApprove}
                                        >
                                            {loading ? "Loading..." : "Approve"}
                                        </Button>
                                        <Button
                                            type="submit"
                                            className={classes.saveBtn}
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleOpenDisapprove}
                                            disabled={loading}
                                        >
                                            {loading ? "Loading..." : "Disapprove"}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Grid>
                        </Grid>
                    ) : null}

                </div>
                
            </MuiPickersUtilsProvider>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default ViewReceiving;
