import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { connect, useDispatch } from "react-redux";

import Feedback from "../../../../Feedback";
import {
  getAllBudgetSubheads,
  createBudgetSubhead,
} from "../../../../actions/budgetSubheadAction";
import { getAllBudgetHeads } from "../../../../actions/budgetHeadsActions";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },
  btn: {
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    margin: "30px 0",
    padding: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
  backBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const INIT_STATE = {
  name: "",
  head: "",
  code: "",
  heads: [],
};

const BudgetSubHeadModal = ({
  open,
  handleClose,
  getAllBudgetSubheads,
  createBudgetSubhead,
  getAllBudgetHeads,
  error,
  heads,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  useEffect(() => {
    setLoad((prev) => !prev);
    getAllBudgetHeads().then(() => {
      setLoad(false);
    });
  }, [getAllBudgetHeads]);

  useEffect(() => {
    if (heads) {
      setState((prev) => ({
        ...prev,
        heads,
      }));
    }
  }, [heads]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    const data = {
      name: state.name,
      head: state.head,
      code: state.code && state.code.toUpperCase(),
    };
    createBudgetSubhead(data)
      .then(() => {
        setLoading(false);
        setState(INIT_STATE);
        setFeed({
          loading: false,
          open: !feed.open,
          message: `Budget SubHead created successfully`,
          success: true,
        });
        dispatch(getAllBudgetSubheads);
        handleClose();
      })
      .catch(() => {
        setLoading(false);
        handleClose();
        setFeed({
          loading: false,
          open: !feed.open,
          message: error && error.error,
          success: false,
        });
      });
  };
  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const validCheck = () => {
    return !state.name || !state.head;
  };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Create SubHead
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item xs={12} md={6}>
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  value={state.name}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel id="head-label-id">Head</InputLabel>
                  <Select
                    labelId="head-label-id"
                    id="head"
                    label="Head"
                    inputProps={{
                      id: "head",
                      name: "head",
                    }}
                    onChange={handleChange}
                    value={state.head || ""}
                    name="head"
                  >
                    <MenuItem value="" disabled>
                      --- Select Head ---
                    </MenuItem>
                    {load ? (
                      <MenuItem>Loading</MenuItem>
                    ) : (
                      state.heads &&
                      state.heads.map((head) => (
                        <MenuItem key={head._id} value={head._id}>
                          {head.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} md={6}></Grid> */}
              <Grid item xs={12} md={12}>
                <TextField
                  id="code"
                  name="code"
                  label="Code"
                  value={state.code}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  className={classes.saveBtn}
                  disabled={validCheck() || loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.error.error,
  heads: state.budgetHeads.heads.data,
});

export default connect(mapStateToProps, {
  getAllBudgetSubheads,
  createBudgetSubhead,
  getAllBudgetHeads,
})(BudgetSubHeadModal);
