import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  Tabs,
  Tab,
  TextField,
  ButtonGroup,
  Backdrop,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormLabel,
  FormControl,
  MenuItem,
  Select,
  Badge,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { connect, useDispatch } from "react-redux";
import { Tooltip } from "@material-ui/core";
import {
  getVendorById,
  approveVendor,
  rejectVendor,
  resendVerificationEmail,
  blacklistVendor,
  reactivateVendor,
} from "../../../actions/vendorsActions";
import Feedback from "../../../Feedback";
import { TabPanel } from "../../Utils";
import { standards } from "../../../utils/standards";
import VendorDocModal from "./Quality/VendorDocModal";

const useStyles = makeStyles((theme) => ({
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },

  container: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 500,
  },
  tab: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  gridForm: {
    padding: theme.spacing(3),
    backgroundImage:
      "radial-gradient(circle at 13% 47%, rgba(140, 140, 140,0.03) 0%, rgba(140, 140, 140,0.03) 25%,transparent 25%, transparent 100%),radial-gradient(circle at 28% 63%, rgba(143, 143, 143,0.03) 0%, rgba(143, 143, 143,0.03) 16%,transparent 16%, transparent 100%),radial-gradient(circle at 81% 56%, rgba(65, 65, 65,0.03) 0%, rgba(65, 65, 65,0.03) 12%,transparent 12%, transparent 100%),radial-gradient(circle at 26% 48%, rgba(60, 60, 60,0.03) 0%, rgba(60, 60, 60,0.03) 6%,transparent 6%, transparent 100%),radial-gradient(circle at 97% 17%, rgba(150, 150, 150,0.03) 0%, rgba(150, 150, 150,0.03) 56%,transparent 56%, transparent 100%),radial-gradient(circle at 50% 100%, rgba(25, 25, 25,0.03) 0%, rgba(25, 25, 25,0.03) 36%,transparent 36%, transparent 100%),radial-gradient(circle at 55% 52%, rgba(69, 69, 69,0.03) 0%, rgba(69, 69, 69,0.03) 6%,transparent 6%, transparent 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255))",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  gridCarousel: {
    display: "block",
    background: "#263238",
    width: "100%",
    height: "100vh",
    position: "fixed",
    right: 0,
    backgroundImage:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(4, 3, 9, 0.25)),url(/img/oil-1.jpg);",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    boxShadow: "0px 5px 10px #888888",
  },
  paper: {
    padding: theme.spacing(6),
    // marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
  },
  carousel1: {
    backgroundImage:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(3, 2, 9, 0.25)),url(/img/oil-1.jpg);",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    // minHeight: "100vh",
    height: "100%",
    overflow: "scroll",

    // objectFit: "cover",
  },
  btnGroup: {
    marginTop: 40,
  },
  footerBottom: {
    marginTop: "2.5rem",
    textAlign: "center",
  },
  btn2: {
    marginTop: 20,
  },
  footerText: {
    color: "#bdbdbd",
  },
  ref: {
    fontWeight: 800,
    color: "#444",
    fontSize: 14,
    display: "block",
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  bg: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100vh",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    // willChange: "opacity",
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.32), rgba(3, 2, 9, 0.25))",
  },
  label: {
    fontSize: 14,
    marginBottom: 10,
  },
  tabs: {
    fontSize: 12,
    display: "block",
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  saveBtn2: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const INIT_STATE = {
  companyName: "",
  supplierId:"",
  officeAddress: "",
  registrationNumber: "",
  email: "",
  city: "",
  state: "",
  country: "",
  companyTelephone: "",
  website: "",
  contactPerson: "",
  contactDesignation: "",
  contactTelephone: "",
  contactEmail: "",
  businessType: "",
  yearEstablished: "",
  numberOfEmployee: "",
  relatedProduct: "",
  relatedService: "",

  BQMS: "",
  BQMSDocumentNo: "",
  BQMSDocumentUrl:"",
  standardCompliance: "",
  standard: "",
  otherStandard: "",
  QHSPolicy: "",
  policyDocumentName: "",
  QHSRisks: "",
  QHSDocumentUrl:"",
  riskDocumentName: "",
  riskDocumentUrl:"",
  QHSEPolicyAderence: "",
  QHSIMS: "",
  conformedStandard: "",
  QHSTraining: "",
  safetyRep: "",

  tin: "",
  vat: "",
  accountName: "",
  accountNumber: "",
  bank: "",
  sortCode: "",
  currency: "",
  branch: "",
  bankContactPhone: "",
  workRefCompanyName: "",
  workRefCompanyAddress: "",
  workRefContactPerson: "",
  workRefContactDesignation: "",
  workRefContactEmail: "",
  workRefContactPhone: "",

  individualRefName: "",
  individualRefAddress: "",
  individualRefEmail: "",
  individualRefPhone: "",
};

const ViewVendorModal = ({
  id,
  open,
  handleClose,
  getVendorById,
  vendor,
  approveVendor,
  rejectVendor,
  getAllVendors,
  blacklistVendor,
  reactivateVendor,
  profile,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tabPage, setTabPage] = useState(0);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [openResend, setOpenResend] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [openReactivate, setOpenReactivate] = useState(false);
  const [openBlacklist, setOpenBlacklist] = useState(false);
  const [loading, setLoading] = useState(false);

  const [theUrl, setTheUrl] = useState("");
  const [openDoc, setOpenDoc] = useState(false);

  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const getVendorByIdCallback = React.useCallback(() => {
    setOpenBackdrop((prev) => !prev);
    getVendorById(id).then(() => {
      setOpenBackdrop(false);
    });
  }, [id, getVendorById]);

  React.useEffect(() => {
    getVendorByIdCallback();
  }, [getVendorByIdCallback]);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      companyName: vendor && vendor.companyName,
      supplierId: vendor && vendor.supplierId,
      officeAddress: vendor && vendor.officeAddress,
      registrationNumber: vendor && vendor.registrationNumber,
      email: vendor && vendor.email,
      city: vendor && vendor.city,
      state: vendor && vendor.state,
      country: vendor && vendor.country,
      companyTelephone: vendor && vendor.companyTelephone,
      website: vendor && vendor.website,
      contactPerson: vendor && vendor.contactPerson,
      contactDesignation: vendor && vendor.contactDesignation,
      contactTelephone: vendor && vendor.contactTelephone,
      contactEmail: vendor && vendor.contactEmail,
      businessType: vendor && vendor.businessType,
      yearEstablished: vendor && vendor.yearEstablished,
      numberOfEmployee: vendor && vendor.numberOfEmployee,
      relatedProduct: vendor && vendor.relatedProduct,
      relatedService: vendor && vendor.relatedService,

      BQMS: vendor && vendor.BQMS,
      BQMSDocumentNo: vendor && vendor.BQMSDocumentNo,
      BQMSDocumentUrl: (vendor && vendor.BQMSDocumentUrl) || null,
      standardCompliance: vendor && vendor.standardCompliance,
      standard: vendor && vendor.standard,
      otherStandard: vendor && vendor.otherStandard,
      QHSPolicy: vendor && vendor.QHSPolicy,
      policyDocumentName: vendor && vendor.policyDocumentName,
      QHSDocumentUrl: (vendor && vendor.QHSDocumentUrl) || null,
      QHSRisks: vendor && vendor.QHSRisks,
      riskDocumentName: vendor && vendor.riskDocumentName,
      riskDocumentUrl: (vendor && vendor.riskDocumentUrl) || null,
      QHSEPolicyAderence: vendor && vendor.QHSEPolicyAderence,
      QHSIMS: vendor && vendor.QHSIMS,
      conformedStandard: vendor && vendor.conformedStandard,
      QHSTraining: vendor && vendor.QHSTraining,
      safetyRep: vendor && vendor.safetyRep,

      tin: vendor && vendor.tin,
      vat: vendor && vendor.vat,
      accountName: vendor && vendor.accountName,
      accountNumber: vendor && vendor.accountNumber,
      bank: vendor && vendor.bank,
      sortCode: vendor && vendor.sortCode,
      currency: vendor && vendor.currency,
      branch: vendor && vendor.branch,
      bankContactPhone: vendor && vendor.bankContactPhone,
      workRefCompanyName: vendor && vendor.workRefCompanyName,
      workRefCompanyAddress: vendor && vendor.workRefCompanyAddress,
      workRefContactPerson: vendor && vendor.workRefContactPerson,
      workRefContactDesignation: vendor && vendor.workRefContactDesignation,
      workRefContactEmail: vendor && vendor.workRefContactEmail,
      workRefContactPhone: vendor && vendor.workRefContactPhone,

      individualRefName: vendor && vendor.individualRefName,
      individualRefAddress: vendor && vendor.individualRefAddress,
      individualRefEmail: vendor && vendor.individualRefEmail,
      individualRefPhone: vendor && vendor.individualRefPhone,
    }));
  }, [vendor]);

  const handleTabChange = (e, newValue) => {
    setTabPage(newValue);
  };

  const handleNextTab = () => {
    setTabPage((prev) => prev + 1);
  };

  const handlePrevTab = () => {
    setTabPage((prev) => prev - 1);
  };
  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleOpenResend = () => {
    setOpenResend(true);
  };

  const handleCloseResend = () => {
    setOpenResend(false);
  };

  const handleOpenReject = () => {
    setOpenReject(true);
  };

  const handleCloseReject = () => {
    setOpenReject(false);
  };

  const handleOpenReactivate = () => {
    setOpenReactivate(true);
  };

  const handleCloseReactivate = () => {
    setOpenReactivate(false);
  };

  const handleOpenApprove = () => {
    setOpenApprove(true);
  };

  const handleCloseApprove = () => {
    setOpenApprove(false);
  };

  const handleOpenBlacklist = () => {
    setOpenBlacklist(true);
  };

  const handleCloseBlacklist = () => {
    setOpenBlacklist(false);
  };

  const handleApproveVendor = () => {
    setLoading((prev) => !prev);
    const data = {
      id,
      path: "",
    };
    approveVendor(data)
      .then((doc) => {
        if (doc.success === false) {
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            message:
              "An Unexpected Error has occurred. Contact Admin to fix issue",
            success: false,
          });
          handleCloseApprove();
        } else {
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            message: "Vendor Approved Successfully.",
            success: true,
          });
          //   dispatch(getVendorById(id));
          dispatch(getAllVendors());
          handleCloseApprove();
        }
      })
      .catch(() => {
        setLoading(false);
        // setFeed({
        //   loading: false,
        //   open: !feed.open,
        //   message:
        //     "An Unexpected Error has occurred. Contact Admin to fix issue",
        //   success: false,
        // });
        handleCloseApprove();
      })
      .finally(() => {
        setLoading(false);
        getVendorById(id);
      });
  };
  
  const handleResendVendor = async () => {
    setLoading((prev) => !prev);
    const data = {
      token: vendor.completeRegToken,
      id,
      path: "vendor/completereg",
    };
    const res = await new Promise((resolve, reject) => {
      resolve(dispatch(resendVerificationEmail(data)));
    });
    if(res){
      if (res.success === false) {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message:
            "An Unexpected Error has occurred. Contact Admin to fix issue",
          success: false,
        });
        handleCloseResend();
      } else {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message:res.message,
          success: true,
        });
        handleCloseResend();
        //   dispatch(getVendorById(id));
        dispatch(getAllVendors());
        window.location.reload();
      }
    }
  };


  const handleRejectVendor = (comment) => {
    setLoading((prev) => !prev);
    const data = {
      id,
      comment,
      path: "vendor/completereg",
    };
    rejectVendor(data)
      .then((doc) => {
        if (doc.success === false) {
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            message:
              "An Unexpected Error has occurred. Contact Admin to fix issue",
            success: false,
          });
          handleCloseApprove();
        } else {
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            message:
              "Vendor Rejected Successfully, and an email has been sent to the Vendor to that effect",
            success: true,
          });
          //   dispatch(getVendorById(id));
          dispatch(getAllVendors());
          handleCloseReject();
        }
      })
      .catch(() => {
        setLoading(false);
        // setFeed({
        //   loading: false,
        //   open: !feed.open,
        //   message:
        //     "An Unexpected Error has occurred. Contact Admin to fix issue",
        //   success: false,
        // });
        handleCloseReject();
      })
      .finally(() => {
        setLoading(false);
        getVendorById(id);
      });
  };

  const handleBlacklistVendor = () => {
    setLoading((prev) => !prev);
    blacklistVendor(id)
      .then((doc) => {
        if (doc.success === false) {
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            message:
              "An Unexpected Error has occurred. Contact Admin to fix issue",
            success: false,
          });
          handleCloseBlacklist();
        } else {
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            message: "Vendor has been Blacklisted Successfully.",
            success: true,
          });
          //   dispatch(getVendorById(id));
          dispatch(getAllVendors());
          handleCloseBlacklist();
        }
      })
      .catch(() => {
        setLoading(false);
        // setFeed({
        //   loading: false,
        //   open: !feed.open,
        //   message:
        //     "An Unexpected Error has occurred. Contact Admin to fix issue",
        //   success: false,
        // });
        handleCloseBlacklist();
      })
      .finally(() => {
        setLoading(false);
        getVendorById(id);
      });
  };

  const handleReactivateVendor = () => {
    setLoading((prev) => !prev);
    let data = {
      path: "",
      id,
    };
    reactivateVendor(data)
      .then((doc) => {
        if (doc.success === false) {
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            message:
              "An Unexpected Error has occurred. Contact Admin to fix issue",
            success: false,
          });
          handleCloseReactivate();
        } else {
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            message: "Vendor has been Reactivated Successfully.",
            success: true,
          });
          //   dispatch(getVendorById(id));
          dispatch(getAllVendors());
          handleCloseReactivate();
        }
      })
      .catch(() => {
        setLoading(false);
        // setFeed({
        //   loading: false,
        //   open: !feed.open,
        //   message:
        //     "An Unexpected Error has occurred. Contact Admin to fix issue",
        //   success: false,
        // });
        handleCloseReactivate();
      })
      .finally(() => {
        setLoading(false);
        getVendorById(id);
      });
  };

  const handleOpenDoc = (url) => {
    setTheUrl(null);
    if(url){
      setTheUrl(url);
      setOpenDoc(true);
    }
  };

  const handleCloseDoc = () => {
    setOpenDoc(false);
  };
  
  let isQualityManager = profile
    ? profile &&
      profile.groups &&
      profile.groups.some(
        (grp) => grp.name === "manager" && grp.module === "quality"
      )
    : false;

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}

    <VendorDocModal
        {...{ openDoc, handleCloseDoc, docs: theUrl }}
      />

    <ResendModal
        openResend={openResend}
        handleCloseResend={handleCloseResend}
        handleResendVendor={handleResendVendor}
        loading={loading}
      />
      <RejectModal
        openReject={openReject}
        handleCloseReject={handleCloseReject}
        handleRejectVendor={handleRejectVendor}
        loading={loading}
      />
      <ApproveModal
        openApprove={openApprove}
        handleCloseApprove={handleCloseApprove}
        handleApproveVendor={handleApproveVendor}
        loading={loading}
      />
      <BlacklistModal
        loading={loading}
        handleCloseBlacklist={handleCloseBlacklist}
        handleBlacklistVendor={handleBlacklistVendor}
        openBlacklist={openBlacklist}
      />
      <ReactivateModal
        {...{
          openReactivate,
          handleCloseReactivate,
          handleReactivateVendor,
          loading,
        }}
      />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          className={classes.appBar}
          variant="elevation"
          position="fixed"
          color="default"
        >
          <Toolbar>
            <Tooltip title="close" arrow>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <div className={classes.container}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography
                className={classes.title}
                variant="overline"
                gutterBottom
              >
                Vendor Information
              </Typography>
            </Grid>
            {isQualityManager && (
              <Grid item>
                {openBackdrop ? (
                  <Button
                    disabled
                    variant="contained"
                    color="default"
                    style={{
                      marginBottom: 30,
                    }}
                    className={classes.saveBtn2}
                  >
                    Loading...
                  </Button>
                ) : vendor.status === "new" ? (
                  <Button
                    onClick={handleOpenResend}
                    variant="contained"
                    color="primary"
                    style={{
                      marginBottom: 30,
                      width:300
                    }}
                    className={classes.saveBtn2}
                  >
                    Resend Verification Email
                  </Button>
                ) : vendor.status ===
                    "pending" || vendor.status === "declined" ? (
                  <ButtonGroup
                    // variant="contained"
                    // disableElevation
                    style={{
                      marginLeft: "auto",
                      marginBottom: 30,
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      onClick={handleOpenReject}
                      color="secondary"
                      variant="contained"
                      className={classes.saveBtn}
                    >
                      Reject
                    </Button>
                    <Button
                      onClick={handleOpenApprove}
                      color="primary"
                      variant="contained"
                      className={classes.saveBtn}
                    >
                      Approve
                    </Button>
                  </ButtonGroup>
                ) : vendor.status === "approved" ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    style={
                      {
                        // marginBottom: 30,
                      }
                    }
                    onClick={handleOpenBlacklist}
                    disabled={vendor.status === "blacklisted"}
                    className={classes.saveBtn2}
                  >
                    {vendor.status === "blacklisted"
                      ? "Blacklisted"
                      : "Blacklist"}
                  </Button>
                ) : vendor.status === "blacklisted" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={
                      {
                        // marginBottom: 30,
                      }
                    }
                    onClick={handleOpenReactivate}
                    className={classes.saveBtn2}
                  >
                    Reactivate
                  </Button>
                ) : null}
              </Grid>
            )}
          </Grid>

          <AppBar position="static" color="transparent">
            <Tabs
              value={tabPage}
              onChange={handleTabChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="fullWidth"
              // centered
            >
              <Tab
                label={
                  <Typography variant="overline" className={classes.tabs}>
                    General Info.
                  </Typography>
                }
                wrapped
                {...a11yProps(0)}
                className={classes.tabs}
                fullWidth
              />
              <Tab
                label={
                  <Typography variant="overline" className={classes.tabs}>
                    Business Info
                  </Typography>
                }
                wrapped
                {...a11yProps(1)}
                className={classes.tabs}
                fullWidth
              />
              <Tab
                label={
                  <Typography variant="overline" className={classes.tabs}>
                    QHSE Info.
                  </Typography>
                }
                wrapped
                {...a11yProps(2)}
                className={classes.tabs}
                fullWidth
              />
              <Tab
                label={
                  <Typography variant="overline" className={classes.tabs}>
                    Bank Details
                  </Typography>
                }
                wrapped
                {...a11yProps(3)}
                className={classes.tabs}
                fullWidth
              />
              <Tab
                label={
                  <Typography variant="overline" className={classes.tabs}>
                    Work Ref.
                  </Typography>
                }
                wrapped
                {...a11yProps(4)}
                className={classes.tabs}
                fullWidth
              />
            </Tabs>
          </AppBar>
          <Paper className={classes.paper} variant="outlined">
            <TabPanel value={tabPage} index={0}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="email"
                    type="email"
                    name="email"
                    label="Email"
                    value={state.email || ""}
                    disabled
                    fullWidth
                    required
                    placeholder="Email"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="companyName"
                    name="companyName"
                    label="Company Name"
                    value={state.companyName || ""}
                    disabled
                    fullWidth
                    required
                    placeholder="Company Name"
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <TextField
                    variant="outlined"
                    id="officeAddress"
                    name="officeAddress"
                    label="Office Address"
                    value={state.officeAddress || ""}
                    fullWidth
                    required
                    placeholder="Office Address"
                    disabled
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="registrationNumber"
                    name="registrationNumber"
                    label="Registration Number"
                    value={state.registrationNumber || ""}
                    fullWidth
                    required
                    placeholder="Registration Number"
                    disabled
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="city"
                    name="city"
                    label="City"
                    value={state.city || ""}
                    fullWidth
                    required
                    placeholder="City"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="state"
                    name="state"
                    label="State"
                    value={state.state || ""}
                    fullWidth
                    required
                    placeholder="State"
                    disabled
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="country"
                    name="country"
                    label="Country"
                    fullWidth
                    required
                    value={state.country || ""}
                    placeholder="Country"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="companyTelephone"
                    name="companyTelephone"
                    label="Company Telephone"
                    fullWidth
                    required
                    value={state.companyTelephone || ""}
                    placeholder="Company Telephone"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="website"
                    name="website"
                    label="Website"
                    fullWidth
                    required
                    value={state.website || ""}
                    placeholder="Website"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="contactPerson"
                    name="contactPerson"
                    label="Contact Person"
                    fullWidth
                    required
                    value={state.contactPerson || ""}
                    placeholder="Contact Person"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="contactDesignation"
                    name="contactDesignation"
                    label="Designation"
                    fullWidth
                    required
                    placeholder="Designation"
                    value={state.contactDesignation || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="contactTelephone"
                    name="contactTelephone"
                    label="Contact Telephone"
                    fullWidth
                    required
                    placeholder="Contact Telephone"
                    value={state.contactTelephone || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    type="email"
                    id="contactEmail"
                    name="contactEmail"
                    label="Contact Email"
                    fullWidth
                    required
                    placeholder="Contact Email"
                    value={state.contactEmail || ""}
                    disabled
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabPage} index={1}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="businessType"
                    name="businessType"
                    label="Business Type"
                    fullWidth
                    required
                    placeholder="Business Type"
                    value={state.businessType || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="yearEstablished"
                    name="yearEstablished"
                    label="Year Established"
                    fullWidth
                    required
                    placeholder="Year Established"
                    value={state.yearEstablished || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="numberOfEmployee"
                    name="numberOfEmployee"
                    label="No. of Employee"
                    fullWidth
                    required
                    placeholder="No. of Employee"
                    value={state.numberOfEmployee || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="relatedProduct"
                    name="relatedProduct"
                    label="Related Product"
                    fullWidth
                    required
                    value={state.relatedProduct || ""}
                    placeholder="Related Product"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="relatedService"
                    name="relatedService"
                    label=" Related Service"
                    fullWidth
                    required
                    value={state.relatedService || ""}
                    placeholder="Related Service"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="tin"
                    name="tin"
                    label="Tax Identification No."
                    fullWidth
                    required
                    placeholder="Tax Identification No."
                    value={state.tin || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="vat"
                    name="vat"
                    label="VAT Registration No."
                    fullWidth
                    required
                    placeholder="VAT Registration No."
                    value={state.vat || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      id="supplierId"
                      name="supplierId"
                      label="Supplier Id"
                      disabled
                      value={state.supplierId || ""}
                      fullWidth
                      placeholder="Supplier Id"
                    />
                  </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabPage} index={2}>
              <Grid container justify="center" alignItems="center" spacing={2}>
                <Grid container alignItems="center" wrap="nowrap">
                  <Grid item>
                    <Typography variant="overline" gutterBottom className={classes.ref}>
                    Quality Management System Requirements
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      disabled={state.BQMSDocumentUrl === null}
                      onClick={() => handleOpenDoc(state.BQMSDocumentUrl)}
                    >
                      <Badge
                        color="secondary"
                        badgeContent={state.BQMSDocumentUrl ? 1 : null}
                      >
                        <AttachFileIcon />
                      </Badge>
                    </IconButton>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    required
                    component="fieldset"
                    disabled
                  >
                    <FormLabel component="legend" className={classes.label}>
                      <strong>Q.</strong> Does your company have a Quality
                      Management System?
                    </FormLabel>

                    <Select
                      labelId="BQMS"
                      id="BQMS"
                      name="BQMS"
                      inputProps={{
                        id: "BQMS",
                        name: "BQMS",
                      }}
                      value={state.BQMS}
                      disabled
                    >
                      <MenuItem value={false} disabled>
                        <em>----Select Option----</em>
                      </MenuItem>

                      <MenuItem value={false}>No</MenuItem>
                      <MenuItem value={true}>Yes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {state.BQMS ? ( <>
                  <Grid item xs={12} md={6}>
                    {state.BQMS && (
                      <>
                        <FormLabel component="legend" className={classes.label}>
                          <strong>Q.</strong> If Yes? Enter Your Business Quality
                          Management Document Number
                        </FormLabel>
                        <TextField
                          variant="outlined"
                          name="BQMSDocumentNo"
                          fullWidth
                          value={state.BQMSDocumentNo || ""}
                          disabled
                        />
                      </>
                    )}
                  </Grid>
                
                  </> ) : <Grid item xs={12} md={6}></Grid> }
                
                <Grid item xs={12} md={12}>
                  {state.BQMS && <Divider light />}
                </Grid>

                <Grid item xs={12} md={4}>
                  {state.BQMS && (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      component="fieldset"
                    >
                      <FormLabel component="legend" className={classes.label}>
                        <strong>Q.</strong> Can you confirm that your systems
                        comply with the requirements?
                      </FormLabel>

                      <Select
                        labelId="standardCompliance"
                        id="standardCompliance"
                        name="standardCompliance"
                        inputProps={{
                          id: "standardCompliance",
                          name: "standardCompliance",
                        }}
                        value={state.standardCompliance}
                        disabled
                      >
                        <MenuItem value={false} disabled>
                          <em>----Select Option----</em>
                        </MenuItem>

                        <MenuItem value={false}>No</MenuItem>
                        <MenuItem value={true}>Yes</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} md={4}>
                  {state.standardCompliance && (
                    <>
                      <FormControl fullWidth variant="outlined">
                        <FormLabel component="legend" className={classes.label}>
                          <strong>Q.</strong> If Yes? Please state the standard
                          {""}
                          {""}
                        </FormLabel>

                        <Select
                          id="standard"
                          name="standard"
                          inputProps={{
                            id: "standard",
                            name: "standard",
                          }}
                          value={state.standard}
                          disabled
                        >
                          <MenuItem value={false} disabled>
                            <em>----Select Option----</em>
                          </MenuItem>

                          {standards.map((standard, i) => (
                            <MenuItem key={i} value={standard}>
                              {standard}
                            </MenuItem>
                          ))}
                          <MenuItem value="others">Others</MenuItem>
                        </Select>
                      </FormControl>
                    </>
                  )}
                </Grid>
                {(state.standard === "others" && state.standardCompliance) ? (
                  <Grid item xs={12} md={4}>
                    <FormLabel component="legend" className={classes.label}>
                      Please indicate the other standard your company conforms
                      to
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      name="otherStandard"
                      fullWidth
                      disabled
                      value={state.otherStandard}
                    />
                  </Grid>
                ) : <Grid item xs={12} md={4}></Grid> }
                

                <Grid container alignItems="center" wrap="nowrap">
                  <Grid item>
                    <Typography variant="overline" gutterBottom className={classes.ref}>
                    Health, Safety & Environment Requirements
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      disabled={state.QHSDocumentUrl === null}
                      onClick={() => handleOpenDoc(state.QHSDocumentUrl)}
                    >
                      <Badge
                        color="secondary"
                        badgeContent={state.QHSDocumentUrl ? 1 : null}
                      >
                        <AttachFileIcon />
                      </Badge>
                    </IconButton>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    required
                    component="fieldset"
                    disabled
                  >
                    <FormLabel component="legend" className={classes.label}>
                      <strong>Q.</strong> Does your company have a Quality, Health & Safety Policy?
                    </FormLabel>

                    <Select
                      labelId="QHSPolicy"
                      id="QHSPolicy"
                      name="QHSPolicy"
                      inputProps={{
                        id: "QHSPolicy",
                        name: "QHSPolicy",
                      }}
                      value={state.QHSPolicy}
                      disabled
                    >
                      <MenuItem value={false} disabled>
                        <em>----Select Option----</em>
                      </MenuItem>

                      <MenuItem value={false}>No</MenuItem>
                      <MenuItem value={true}>Yes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {state.QHSPolicy ? ( <>
                  <Grid item xs={12} md={6}>
                    <FormLabel component="legend" className={classes.label}>
                      <strong>Q.</strong> If Yes? Please indicate Document
                      Name
                      {""}
                      {""}
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      name="policyDocumentName"
                      fullWidth
                      value={state.policyDocumentName || ""}
                      disabled
                    />
                  </Grid>
                </> ) : <Grid item xs={12} md={6}></Grid> }


                <Grid container alignItems="center" wrap="nowrap">
                  <Grid item>
                    <Typography variant="overline" gutterBottom className={classes.ref}>
                      Quality, Health & Safety Risks Compliance
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      disabled={state.riskDocumentUrl === null}
                      onClick={() => handleOpenDoc(state.riskDocumentUrl)}
                    >
                      <Badge
                        color="secondary"
                        badgeContent={state.riskDocumentUrl ? 1 : null}
                      >
                        <AttachFileIcon />
                      </Badge>
                    </IconButton>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    required
                    component="fieldset"
                    disabled
                  >
                    <FormLabel component="legend" className={classes.label}>
                      <strong>Q.</strong> Does your company have Systems to
                      Identify Significant Quality, Health & Safety Risks & How
                      They're Controlled?
                    </FormLabel>

                    <Select
                      labelId="QHSRisks"
                      id="QHSRisks"
                      name="QHSRisks"
                      inputProps={{
                        id: "QHSRisks",
                        name: "QHSRisks",
                      }}
                      value={state.QHSRisks}
                      disabled
                    >
                      <MenuItem value={false} disabled>
                        <em>----Select Option----</em>
                      </MenuItem>

                      <MenuItem value={false}>No</MenuItem>
                      <MenuItem value={true}>Yes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>


                {state.QHSRisks ? ( <>
                  <Grid item xs={12} md={6}>
                    <FormLabel component="legend" className={classes.label}>
                      <strong>Q.</strong> If Yes? Please indicate Document
                      Name
                      {""}
                      {""}
                    </FormLabel>
                    <TextField
                      variant="outlined"
                      name="riskDocumentName"
                      fullWidth
                      value={state.riskDocumentName || ""}
                      disabled
                    />
                  </Grid>
                </> ) : <Grid item xs={12} md={6}></Grid>}

                <Grid item xs={12} md={4}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    required
                    component="fieldset"
                    style={{ marginTop: 20 }}
                  >
                    <FormLabel component="legend" className={classes.label}>
                      <strong>Q.</strong> Please Confirm that you'll adhere to
                      both RusselSmith's QHSE Policies and Where applicable,
                      comply with any project specific requirements requested by
                      Our Site Management to ensure the Safety of everyone on
                      Site is Maintained?
                    </FormLabel>

                    <Select
                      labelId="QHSEPolicyAdherence"
                      id="QHSEPolicyAdherence"
                      name="QHSEPolicyAdherence"
                      inputProps={{
                        id: "QHSEPolicyAdherence",
                        name: "QHSEPolicyAdherence",
                      }}
                      value={state.QHSEPolicyAderence}
                      disabled
                    >
                      <MenuItem value={false}>No</MenuItem>
                      <MenuItem value={true}>Yes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    required
                    component="fieldset"
                    style={{ marginTop: 20 }}
                  >
                    <FormLabel component="legend" className={classes.label}>
                      <strong>Q.</strong> Does your company encourage and
                      provide proactive health and safety training?
                    </FormLabel>

                    <Select
                      labelId="QHSTraining"
                      id="QHSTraining"
                      name="QHSTraining"
                      inputProps={{
                        id: "QHSTraining",
                        name: "QHSTraining",
                      }}
                      value={state.QHSTraining}
                      disabled
                    >
                      <MenuItem value={false} disabled>
                        <em>----Select Option----</em>
                      </MenuItem>

                      <MenuItem value={false}>No</MenuItem>
                      <MenuItem value={true}>Yes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    required
                    component="fieldset"
                    style={{ marginTop: 20 }}
                  >
                    <FormLabel component="legend" className={classes.label}>
                      <strong>Q.</strong> Do you have appointed safety
                      representatives within your work force?
                    </FormLabel>

                    <Select
                      labelId="safetyRep"
                      id="safetyRep"
                      name="safetyRep"
                      inputProps={{
                        id: "safetyRep",
                        name: "safetyRep",
                      }}
                      value={state.safetyRep}
                      disabled
                    >
                      <MenuItem value={false} disabled>
                        <em>----Select Option----</em>
                      </MenuItem>

                      <MenuItem value={false}>No</MenuItem>
                      <MenuItem value={true}>Yes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </TabPanel>

            
            <TabPanel value={tabPage} index={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="accountName"
                    name="accountName"
                    label="Account Name"
                    fullWidth
                    required
                    placeholder="Account Name"
                    value={state.accountName || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="accountNumber"
                    name="accountNumber"
                    label="Account Number"
                    fullWidth
                    required
                    placeholder="Account Number"
                    value={state.accountNumber || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="bank"
                    name="bank"
                    label="Bank"
                    fullWidth
                    required
                    placeholder="Bank"
                    value={state.bank || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="sortCode"
                    name="sortCode"
                    label="Sort Code"
                    fullWidth
                    required
                    placeholder="Sort Code"
                    value={state.sortCode || ""}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    label="Currency"
                    fullWidth
                    value={state.currency || ""}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="branch"
                    name="branch"
                    label="Branch"
                    fullWidth
                    required
                    placeholder="Branch"
                    value={state.branch || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="bankContactPhone"
                    name="bankContactPhone"
                    label="Contact Phone"
                    fullWidth
                    required
                    placeholder="Contact Phone"
                    value={state.bankContactPhone || ""}
                    disabled
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabPage} index={4}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="workRefCompanyName"
                    name="workRefCompanyName"
                    label="Company Name"
                    fullWidth
                    required
                    placeholder="Company Name"
                    value={state.workRefCompanyName || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="workRefCompanyAddress"
                    name="workRefCompanyAddress"
                    label="Company Address"
                    fullWidth
                    required
                    placeholder="Company Address"
                    value={state.workRefCompanyAddress || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="workRefContactPerson"
                    name="workRefContactPerson"
                    label="Contact Person"
                    fullWidth
                    required
                    placeholder="Contact Person"
                    value={state.workRefContactPerson || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="workRefContactDesignation"
                    name="workRefContactDesignation"
                    label="Contact Designation"
                    fullWidth
                    required
                    placeholder="Contact Designation"
                    value={state.workRefContactDesignation || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    type="email"
                    id="workRefContactEmail"
                    name="workRefContactEmail"
                    label="Contact Email"
                    fullWidth
                    required
                    placeholder="Contact Email"
                    value={state.workRefContactEmail || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="workRefContactPhone"
                    name="workRefContactPhone"
                    label="Contact Phone"
                    fullWidth
                    required
                    placeholder="Contact Phone"
                    value={state.workRefContactPhone || ""}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <Typography variant="overline" className={classes.ref}>
                    Individual Reference
                  </Typography>

                  <Divider light />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="individualRefName"
                    name="individualRefName"
                    label="Name"
                    fullWidth
                    required
                    placeholder="Name"
                    value={state.individualRefName || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="individualRefAddress"
                    name="individualRefAddress"
                    label="Address"
                    fullWidth
                    required
                    placeholder="Address"
                    value={state.individualRefAddress || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    type="email"
                    id="individualRefEmail"
                    name="individualRefEmail"
                    label="Email"
                    fullWidth
                    required
                    placeholder="Email"
                    value={state.individualRefEmail || ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    id="individualRefPhone"
                    name="individualRefPhone"
                    label="Phone"
                    fullWidth
                    required
                    placeholder="Phone"
                    value={state.individualRefPhone || ""}
                    disabled
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <ButtonGroup>
              <Button
                onClick={handlePrevTab}
                color="secondary"
                disabled={tabPage === 0}
                startIcon={<ArrowBackIosIcon />}
                variant="contained"
                className={classes.saveBtn}
              >
                Prev
              </Button>
              <Button
                onClick={handleNextTab}
                color="primary"
                disabled={tabPage === 4}
                endIcon={<ArrowForwardIosIcon />}
                variant="contained"
                className={classes.saveBtn}
              >
                Next
              </Button>
            </ButtonGroup>
          </Paper>
        </div>
      </Dialog>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const RejectModal = ({
  openReject,
  handleCloseReject,
  loading,
  handleRejectVendor,
}) => {
  const [comment, setComment] = React.useState("");
  const classes = useStyles();

  const handleChange = (e) => {
    setComment(e.target.value);
  };
  return (
    <div>
      <Dialog
        open={openReject}
        onClose={handleCloseReject}
        TransitionComponent={Transition}
        fullWidth
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Reject Vendor?
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseReject}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            label="Comment"
            variant="outlined"
            name="comment"
            value={comment}
            onChange={handleChange}
            fullWidth
            multiline
            rows={4}
          />
        </DialogContent>

        <DialogActions>
          <ButtonGroup>
            <Button onClick={handleCloseReject} className={classes.saveBtn}>
              Close
            </Button>
            <Button
              color="secondary"
              disabled={!comment || loading}
              onClick={() => handleRejectVendor(comment)}
              variant="contained"
              className={classes.saveBtn}
              endIcon={
                loading ? (
                  <CircularProgress size={20} color="secondary" />
                ) : null
              }
            >
              {loading ? "Loading..." : "Reject"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ApproveModal = ({
  openApprove,
  handleCloseApprove,
  handleApproveVendor,
  loading,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={openApprove}
        onClose={handleCloseApprove}
        TransitionComponent={Transition}
        fullWidth
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Approve Vendor?
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseApprove}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="overline" className={classes.title3}>
            Note: By Clicking "Approve", it means you're satisfied with the
            information provided by this vendor?
          </Typography>
        </DialogContent>

        <DialogActions>
          <ButtonGroup>
            <Button onClick={handleCloseApprove} className={classes.saveBtn}>
              Close
            </Button>
            <Button
              type="button"
              color="primary"
              variant="contained"
              disabled={loading}
              onClick={handleApproveVendor}
              className={classes.saveBtn}
              endIcon={
                loading ? <CircularProgress size={20} color="primary" /> : null
              }
            >
              {loading ? "Loading..." : "Approve"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const BlacklistModal = ({
  openBlacklist,
  handleCloseBlacklist,
  handleBlacklistVendor,
  loading,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={openBlacklist}
        onClose={handleCloseBlacklist}
        TransitionComponent={Transition}
        fullWidth
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Blacklist Vendor?
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseBlacklist}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="overline" className={classes.title3}>
            Note: By Clicking "Blacklist", it means you no longer want to do
            business with this vendor?
          </Typography>
        </DialogContent>

        <DialogActions>
          <ButtonGroup>
            <Button onClick={handleCloseBlacklist} className={classes.saveBtn}>
              Close
            </Button>
            <Button
              type="button"
              color="secondary"
              variant="contained"
              disabled={loading}
              onClick={handleBlacklistVendor}
              className={classes.saveBtn}
              endIcon={
                loading ? (
                  <CircularProgress size={20} color="secondary" />
                ) : null
              }
            >
              {loading ? "Loading..." : "Blacklist"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ReactivateModal = ({
  openReactivate,
  handleCloseReactivate,
  handleReactivateVendor,
  loading,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={openReactivate}
        onClose={handleCloseReactivate}
        TransitionComponent={Transition}
        fullWidth
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Reactivate Vendor?
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseReactivate}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="overline" className={classes.title3}>
            Note: By Clicking "Reactivate", it means you're ready to reactivate
            this vendor?
          </Typography>
        </DialogContent>

        <DialogActions>
          <ButtonGroup>
            <Button onClick={handleCloseReactivate} className={classes.saveBtn}>
              Close
            </Button>
            <Button
              type="button"
              color="primary"
              disabled={loading}
              variant="contained"
              onClick={handleReactivateVendor}
              className={classes.saveBtn}
              endIcon={
                loading ? <CircularProgress size={20} color="primary" /> : null
              }
            >
              {loading ? "Loading..." : "Reactivate"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};


const ResendModal = ({
  openResend,
  handleCloseResend,
  handleResendVendor,
  loading,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={openResend}
        onClose={handleCloseResend}
        TransitionComponent={Transition}
        fullWidth
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Resend Vendor Verification Email
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseResend}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="overline" className={classes.title3}>
            Note: By Clicking "Resend", it means you want to resend a Verification email to this vendor?
          </Typography>
        </DialogContent>

        <DialogActions>
          <ButtonGroup>
            <Button onClick={handleCloseResend} className={classes.saveBtn}>
              Close
            </Button>
            <Button
              type="button"
              color="primary"
              variant="contained"
              disabled={loading}
              onClick={handleResendVendor}
              className={classes.saveBtn}
              endIcon={
                loading ? <CircularProgress size={20} color="primary" /> : null
              }
            >
              {loading ? "Loading..." : "Resend"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  vendor: state.vendors.vendor,
  profile: state.user.profile,
});

const mapDispatchToProps = {
  getVendorById,
  approveVendor,
  rejectVendor,
  blacklistVendor,
  reactivateVendor,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewVendorModal);
