import React, { useState, useEffect } from "react";
import {
  Paper,
  makeStyles,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  InputAdornment,
  Divider,
  IconButton,
  Dialog,
  Tooltip,
  Backdrop,
  AppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { getSymbol } from "../../../../../utils/currencyconvert";
import moment from 'moment';

import { useDispatch, useSelector } from "react-redux";
import { getDivisionsByService } from "../../../../../actions/divisionActions";
import { getCurrencies } from "../../../../../actions/currencyActions";
import {
  createEquipmentPriceList,
  getAllEquipmentPriceList,
} from "../../../../../actions/masterListActions/equipmentAction";
import Feedback from "../../../../../Feedback";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: "bold",
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  appBar: {
    // position: "relative",
    zIndex: 2,
  },
  container: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  total: {
    marginTop: 40,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  warningInfo: {
    color: "#f5f5f5",
    background: "#f44336",
    padding: 20,
    marginBottom: 20,
    marginRight: 10,
    "& > *": {
      fontWeight: 900,
    },
  },
  form: {
    marginTop: 20,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 300,
  },
}));

const initialState = {
  description: "",
  division: "",
  category: "",
  provider: "",
  condition: "",
  currency: "",
  elife: "",
  code: "",
  cost: "",
  depRate: "",
  dateOfPurchase: moment(new Date()).format('Y/MM/DD'),
  wearRate: null,
  margin: "",
  currentPeriod: moment(new Date()).format('Y/MM/DD'),
  usedLife: "",
  accumDep: "",
  netBookValue: "",
  usefulLife: "",
  dayRate: "",
  wear: "",
  withholdingRate: null,
  withholdingTax: "",
  totalDayRate: "",
  display: "",
};

const EquipmentModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [state, setState] = useState(initialState);
  const [selectedDate, setSelectedDate] = useState(null);

  const [wearRate, setWearRate] = useState("");
  const [depRate, setDepRate] = useState(null);
  const [withholdingRate, setWithholdingRate] = useState("");

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { divisions } = useSelector((state) => state.division);
  const { currencies } = useSelector((state) => state.currency);
  const { loading, success, error } = useSelector((state) => state.equipment);


  useEffect(() => {
    const abortController = new AbortController();

    if (error && error !== null) {
      setFeed((prev) => ({
        ...prev,
        loading: false,
        open: !prev.open,
        message: error,
        success: false,
      }));
      setTimeout(() => {
        setFeed((prev) => ({
          ...prev,
          loading: false,
          open: false,
          message: "",
          success: false,
        }));
        dispatch({
          type: "RESET_EQUIPMENT_PRICE_LIST",
        });
      }, 6000);
    }
    return () => {
      abortController.abort();
    };
  }, [error, dispatch]);

  useEffect(() => {
    const abortController = new AbortController();

    if (success) {
      dispatch(getAllEquipmentPriceList());
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: "Equipment price list created successfully.",
        success: true,
      }));
      setState(initialState);
      dispatch({
        type: "RESET_EQUIPMENT_PRICE_LIST",
      });

      setTimeout(() => {
        handleClose();
      }, 2000);
    }

    return () => {
      abortController.abort();
    };
  }, [success, dispatch, handleClose]);

  useEffect(() => {
    dispatch(getCurrencies());
    dispatch(getDivisionsByService());
  }, [dispatch]);

  // dayRate is baseRate
  useEffect(() => {
    setWithholdingRate(5); setWearRate(15); setDepRate(10);
    const netBookValue =
      state.cost - state.accumDep;
    if (wearRate && withholdingRate && depRate) {
      const withholdingTax = (+state.dayRate * (+withholdingRate / 100)).toFixed(2);
      const wear = (+state.dayRate * (wearRate / 100)).toFixed(2);
      setState((prev) => ({
        ...prev, withholdingRate, wearRate, wear, depRate, netBookValue, withholdingTax
      }));
    }
  }, [dispatch, withholdingRate, wearRate, depRate, state.dayRate, state.cost, state.accumDep]);

  useEffect(() => {
    let usefulLife, accumDep;
    let usedLife = Math.abs((moment(state.dateOfPurchase).diff(state.currentPeriod, 'days') / 365) * 1).toFixed(2);
    if (usedLife <= 10) {
      usefulLife = (5 - usedLife).toFixed(2);
      accumDep = (+state.cost * (state.depRate / 100) * usedLife).toFixed(2);
    } else {
      usefulLife = (10 - usedLife).toFixed(2);
      accumDep = (+state.cost - (state.depRate)).toFixed(2);
    }
    setState((prev) => ({
      ...prev, usedLife, usefulLife, accumDep
    }));
  }, [dispatch, state.division, state.cost, state.depRate, state.dateOfPurchase, state.currentPeriod]);

  useEffect(() => {
    const totalDayRate = ((+state.dayRate + +state.withholdingTax) * +state.elife).toFixed(2);
    setState((prev) => ({
      ...prev, totalDayRate
    }));
  }, [state.dayRate, state.withholdingTax, state.elife]);

  const handleChangeDate = (date) => {
    setSelectedDate(date);
    let dateOfPurchase = moment(selectedDate);
    setState((prev) => ({
      ...prev, dateOfPurchase
    }));
  };

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(createEquipmentPriceList(state));
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const validCheck = () =>
    !state.description ||
    !state.division ||
    !state.provider ||
    !state.condition ||
    !state.elife ||
    !state.code ||
    !state.netBookValue ||
    !state.accumDep ||
    !state.depRate ||
    !selectedDate ||
    !state.dateOfPurchase ||
    !state.currentPeriod ||
    !state.withholdingTax ||
    !state.withholdingRate ||
    !state.currency ||
    !state.wearRate ||
    !state.wear ||
    !state.dayRate || 
    !state.margin ||
    !state.usefulLife ||
    !state.usedLife ||
    !state.totalDayRate;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        fullScreen
        maxWidth="md"
      >
        {feed.success ? (
          <Feedback
            handleCloseFeed={handleCloseFeed}
            open={feed.open}
            severity="success"
            message={feed.message}
          />
        ) : (
          <Feedback
            handleCloseFeed={handleCloseFeed}
            open={feed.open}
            severity="error"
            message={feed.message}
          />
        )}
        <AppBar
          className={classes.appBar}
          variant="elevation"
          position="fixed"
          color="inherit"
          elevation={1}
        >
          <Toolbar>
            <Tooltip title="close" arrow>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <div className={classes.container}>
          <Typography className={classes.title} variant="overline">
            Create Equipment Price List
          </Typography>
          <div>
            <form
              component={Paper}
              className={classes.form}
              onSubmit={handleSubmit}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Equipment Description"
                    name="description"
                    variant="outlined"
                    value={state.description}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Asset Code"
                    name="code"
                    variant="outlined"
                    value={state.code}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel>Division</InputLabel>
                    <Select
                      inputProps={{
                        id: "division",
                        name: "division",
                      }}
                      value={state.division}
                      onChange={handleChange}
                    >
                      <MenuItem disabled>Select Division</MenuItem>
                      {divisions.map((division) => (
                        <MenuItem key={division._id} value={division._id}>
                          {division.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel>Service Provider</InputLabel>
                    <Select
                      inputProps={{
                        id: "provider",
                        name: "provider",
                      }}
                      value={state.provider}
                      onChange={handleChange}
                    >
                      <MenuItem disabled>Select Provider</MenuItem>
                      <MenuItem value="In House">In House</MenuItem>
                      <MenuItem value="Vendor">Vendor</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel>Currency</InputLabel>
                    <Select
                      inputProps={{
                        id: "currency",
                        name: "currency",
                      }}
                      value={state.currency}
                      onChange={handleChange}
                    >
                      <MenuItem disabled>Select Currency</MenuItem>
                      {currencies.map((currency) => (
                        <MenuItem key={currency._id} value={currency.code}>
                          {currency.code}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Cost of Equipment"
                    name="cost"
                    type="number"
                    variant="outlined"
                    value={state.cost}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Equipment life"
                    name="elife"
                    type="text"
                    variant="outlined"
                    value={state.elife}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DatePicker
                    autoOk
                    format="DD/MM/yyyy"
                    label="Date of Purchase"
                    value={selectedDate}
                    name="dateOfPurchase"
                    maxDate={new Date()}
                    onChange={handleChangeDate}
                    fullWidth
                    inputVariant="outlined"
                    animateYearScrolling={true}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <DatePicker
                    format="DD/MM/yyyy"
                    label="Current Period"
                    name="currentPeriod"
                    value={state.currentPeriod}
                    fullWidth todayLabel="Today"
                    required disabled
                    inputVariant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth variant="outlined" required>
                    <InputLabel>Condition of Equipment</InputLabel>
                    <Select
                      inputProps={{
                        id: "condition",
                        name: "condition",
                      }}
                      value={state.condition}
                      onChange={handleChange}
                    >
                      <MenuItem disabled>Select Condition</MenuItem>
                      <MenuItem value="New">New</MenuItem>
                      <MenuItem value="Good">Good</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Used Life"
                    name="usedLife"
                    type="number"
                    variant="outlined"
                    value={state.usedLife || 0}
                    onChange={handleChange}
                    fullWidth disabled
                    required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Useful Life (years)"
                    name="usefulLife"
                    type="number"
                    variant="outlined"
                    value={state.usefulLife || 0}
                    onChange={handleChange}
                    fullWidth disabled
                    required
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <TextField
                    label="Depreciation Rate (%)"
                    name="depRate"
                    type="text"
                    variant="outlined"
                    value={state.depRate + "%" || 0}
                    disabled
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label={`Accumulated Depreciation (${getSymbol(
                      state.currency || "NGN",
                      0
                    )})`}
                    name="accumDep"
                    type="text"
                    variant="outlined"
                    value={state.accumDep}
                    fullWidth disabled
                    required
                    InputProps={{
                      startAdornment: (
                        <>
                          <InputAdornment position="start">
                            {getSymbol(state.currency || "NGN", 0)}
                          </InputAdornment>
                          <Divider
                            className={classes.divider2}
                            orientation="vertical"
                          />
                        </>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label={`Net Book Value (${getSymbol(
                      state.currency || "NGN",
                      0
                    )})`}
                    name="netBookValue"
                    type="text"
                    variant="outlined"
                    value={state.netBookValue}
                    fullWidth disabled
                    required
                    InputProps={{
                      startAdornment: (
                        <>
                          <InputAdornment position="start">
                            {getSymbol(state.currency || "NGN", 0)}
                          </InputAdornment>
                          <Divider
                            className={classes.divider2}
                            orientation="vertical"
                          />
                        </>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Base Rate"
                    name="dayRate"
                    type="text"
                    variant="outlined"
                    value={state.dayRate || ""}
                    fullWidth
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Wear And Tear (%)"
                    name="wearRate"
                    type="text"
                    variant="outlined"
                    value={state.wearRate + "%" || 0}
                    disabled
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Wear"
                    name="wear"
                    type="text"
                    variant="outlined"
                    value={state.wear || 0}
                    onChange={handleChange}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Withholding Rate (%)"
                    name="withholdingRate"
                    type="text"
                    variant="outlined"
                    value={state.withholdingRate + "%" || 0}
                    disabled
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Withholding Tax"
                    name="withholdingTax"
                    type="number"
                    variant="outlined"
                    value={state.withholdingTax || 0}
                    disabled
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Profit Margin (%)"
                    name="margin"
                    type="number"
                    variant="outlined"
                    value={state.margin}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Day Rate"
                    name="totalDayRate"
                    type="number"
                    variant="outlined"
                    value={state.totalDayRate}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>

              <Grid container justify="center" alignItems="center" spacing={3}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.saveBtn}
                    type="submit"
                    disabled={validCheck() || loading}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
};

export default EquipmentModal;
