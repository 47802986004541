import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Grid,
  Backdrop,
  IconButton,
  Chip,
  Icon,
  Tooltip,
  useTheme,
  Button,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import clsx from "clsx";
import { TablePaginationActions } from "../../TablePaginationActions";
import SearchComponent from "../../SearchComponent";
import { formatCurrency } from "../../../utils";
import CreateReimbursementModal from "../Modals/CreateReimbursementModal";
import { getAllReimbursements } from "../../../actions/reimburseActions";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 500,
  },
  chip: {
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: "0.65rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    // background: "#00e676",

    borderColor: "#1de9b6",
    // color: "#fff",
  },
  statusYellow: {
    // background: "#fbc02d",

    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  statusRed: {
    // background: "#ef5350",

    borderColor: "#ef5350",
  },
  marginBottom: {
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  createBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  backBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const Reimbursement = ({ getAllReimbursements, reimbursements, profile }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [search, setSearch] = useState("");
  const [openCreate, setOpenCreate] = useState(false);

  useEffect(() => {
    setOpenBackdrop((prev) => !prev);
    getAllReimbursements().then(() => {
      setOpenBackdrop(false);
    });
  }, [getAllReimbursements]);

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };

  let filteredRows = () =>
    reimbursements === undefined
      ? []
      : reimbursements.filter((row) => {
          if (search !== "") {
            return (
              row.documentNo
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1 ||
              row.employee.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              (row.total &&
                row.total
                  .toString()
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1) ||
              row.month.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              row.status.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              (row.division &&
                row.division.name
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1)
            );
          } else {
            return row;
          }
        });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const navigateById = (path, id) => {
    history.push(`${path}/${id}`);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (Array(1000).length || filteredRows().length) - page * rowsPerPage
    );

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleCreateClose = () => {
    setOpenCreate(false);
  };

  return (
    <div>
      <CreateReimbursementModal {...{ openCreate, handleCreateClose }} />
      <Typography variant="overline" className={classes.title}>
        Reimbursement
      </Typography>

      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={3}
        className={classes.marginBottom}
      >
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            style={{ marginRight: 5 }}
            onClick={handleOpenCreate}
            className={classes.createBtn}
          >
            Create
          </Button>
        </Grid>
        <Grid item>
          <SearchComponent
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
      </Grid>

      <Paper>
        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>Document No.</TableCell>
                <TableCell>Employee</TableCell>
                <TableCell>Div./Subdiv.</TableCell>
                <TableCell>Month</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows()
              ).map((row, i) => {
                return (
                  <TableRow hover key={row._id}>
                    <TableCell component="th" scope="row">
                      <Chip label={row.documentNo} variant="outlined" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.employee}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.division && row.division.name}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {row.month}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        color:
                          row.total <= 200000
                            ? theme.palette.secondary.light
                            : theme.palette.success.main,
                      }}
                    >
                      {row.amountToReimburse
                        ? formatCurrency(
                            row.amountToReimburse,
                            row.rate === 1 ? "en-NG" : "en-US",
                            row.currency ? row.currency : "NGN"
                          )
                        : formatCurrency(0, "en-NG", "NGN")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Chip
                        label={row.status.toUpperCase()}
                        className={clsx(
                          row.status === "draft"
                            ? classes.statusYellow
                            : row.status === "submitted" ||
                              row.status === "to be reimbursed" ||
                              row.status === "to reimburse" ||
                              row.status === "reimburse"
                            ? classes.statusOrange
                            : row.status === "approved" ||
                              row.status === "reviewed" ||
                              row.status === "confirmed"
                            ? classes.statusGreen
                            : row.status === "completed" ||
                              row.status === "reviewed" ||
                              row.status === "paid" ||
                              row.status === "reimbursed"
                            ? classes.statusDarkGreen
                            : classes.statusRed,
                          classes.chip
                        )}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell align="center">
                      {row.status === "draft" || row.status === "rejected" ? (
                        <Tooltip title="Edit Reimbursement" arrow>
                          <span>
                            <IconButton
                              color="primary"
                              onClick={() =>
                                navigateById("/reimbursement", row._id)
                              }
                              disabled={
                                profile._id !==
                                (row && row.createdBy && row.createdBy._id)
                              }
                            >
                              <Icon
                                className="fas fa-pencil-alt"
                                color={
                                  profile._id !==
                                  (row && row.createdBy && row.createdBy._id)
                                    ? "disabled"
                                    : "primary"
                                }
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      ) : (
                        <Tooltip arrow title="View Reimbursement">
                          <IconButton
                            onClick={() =>
                              navigateById("/reimbursement/view", row._id)
                            }
                          >
                            <Icon className="fas fa-eye" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
              {filteredRows().length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={10}
                    align="center"
                    style={{ color: "#616161" }}
                    component="th"
                    scope="row"
                  >
                    No Records To Display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>

      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const mapStateToProps = (state) => ({
  reimbursements: state.reimburse.reimbursements,
  profile: state.user.profile,
});

const mapDispatchToProps = {
  getAllReimbursements,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reimbursement);
