import React, { useState } from "react";

import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  DialogTitle,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  darken,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../Feedback";
import { useParams, useHistory } from "react-router-dom";

import { financePayReimbursement } from "../../../actions/reimburseActions";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },

  centered: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  submitBtn: {
    marginTop: 30,
    marginLeft: 10,
    marginBottom: 30,
    padding: theme.spacing(1.2),
    letterSpacing: 2.5,
    width: 130,
    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
  },
}));

const INIT_STATE = {
  amount: 0,
  method: "",
  comment: "",
};

const FinanceReimbursementPaymentModal = ({
  openPay,
  handleClosePay,

  financePayReimbursement,
  currency,
  error,
}) => {
  const classes = useStyles();

  const history = useHistory();
  const [state, setState] = useState(INIT_STATE);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePayment = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    const data = {
      ...state,
      path: "reimbursement/view",
      id,
    };
    financePayReimbursement(data)
      .then(() => {
        setLoading(false);
        setState(INIT_STATE);

        setFeed({
          loading: false,
          open: !feed.open,
          message: `Amount paid successfully, an email has been sent to the requestor.`,
          success: true,
        });
      })
      .catch(() => {
        setLoading(false);
        setState(INIT_STATE);

        setFeed({
          loading: false,
          open: !feed.open,
          success: false,
        });
      })
      .finally(() => {
        setTimeout(() => {
          history.push("/cash/payment");
        }, 1500);
        handleClosePay();
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const validCheck = () => !state.amount || !state.method || !state.comment;

  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={
            feed.message ||
            "Amount paid successfully, an email has been sent to the requestor."
          }
        />
      )}

      <Dialog
        open={openPay}
        onClose={handleClosePay}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
        // fullScreen={fullScreen}
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Payment
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClosePay}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handlePayment}>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  type="number"
                  name="amount"
                  label="Amount"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  value={state.amount}
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Currency"
                  variant="outlined"
                  fullWidth
                  value={currency}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel>Payment Method</InputLabel>
                  <Select
                    name="method"
                    id="method"
                    inputProps={{
                      id: "method",
                    }}
                    onChange={handleChange}
                    value={state.method}
                  >
                    <MenuItem disabled>
                      <em>---SELECT PAYMENT METHOD---</em>
                    </MenuItem>
                    <MenuItem value="check">Check</MenuItem>
                    <MenuItem value="transfer">Transfer</MenuItem>
                    <MenuItem value="cash">Cash</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label="Comment"
                  multiline
                  rows={4}
                  fullWidth
                  variant="outlined"
                  name="comment"
                  onChange={handleChange}
                  value={state.comment}
                  required
                />
              </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.submitBtn}
                  disabled={validCheck() || loading}
                >
                  {loading ? "Loading..." : "Refund"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.reimburse.reimburseError,
});

const mapDispatchToProps = {
  financePayReimbursement,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinanceReimbursementPaymentModal);
