import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { connect, useDispatch } from "react-redux";
import {
    ButtonGroup,
    Typography,
    makeStyles,
    TextField,
    Grid,
    IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../../Feedback";
import {
    getCashAdvanceById,
    acceptFinCashRetirement,
} from "../../../../actions/cashAdvanceAction";
import { useHistory } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold"
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 20,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    saveBtn: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    textField: {
        marginBottom: 20,
    },
}));

const AcceptFinCashRetirement = ({
    openFinAccept,
    handleCloseFinAccept,
    id,
    acceptFinCashRetirement,
    getCashAdvanceById,
}) => {
    const dispatch = useDispatch();
    const [reason, setReason] = React.useState("");
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const handleChange = (e) => {
        setReason(e.target.value);
    };

    const handleFinAccept = () => {
        setLoading((prev) => !prev);
        const data = {
            comment: reason,
            id,
            path: "cashadvance/view",
        };

        acceptFinCashRetirement(data)
            .then((doc) => {
                setLoading(false);
                setFeed({
                    loading: false,
                    open: !feed.open,
                    message: `You have successfully accepted this cash retirement.`,
                    success: true,
                });
                dispatch(getCashAdvanceById(id));
                handleCloseFinAccept();
            })
            .catch(() => {
                setLoading(false);
                handleCloseFinAccept();
                console.log(`An error has occured, kindly contact admin.`);
            })
            .finally(() => {
                setTimeout(() => {
                    history.push("/cashadvance");
                }, 1500);
            });
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}
            <Dialog
                open={openFinAccept}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseFinAccept}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="overline" className={classes.title}>
                                {" "}
                                {"Accept Cash Retirement?"}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCloseFinAccept}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography variant="overline" className={classes.title3}>
                            By Clicking Accept, it means you're okay with this cash retirement.
                        </Typography>
                        <TextField
                            label="Comment"
                            className={classes.textField}
                            variant="outlined"
                            fullWidth
                            value={reason}
                            onChange={handleChange}
                            helperText="Please add a comment"
                            multiline
                            rows={4}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup>
                        <Button
                            onClick={handleCloseFinAccept}
                            color="secondary"
                            className={classes.saveBtn}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleFinAccept}
                            color="primary"
                            disabled={!reason || loading}
                            className={classes.saveBtn}
                            variant="contained"
                        >
                            {loading ? "Loading..." : "Accept"}
                        </Button>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const mapDispatchToProps = {
    acceptFinCashRetirement,
    getCashAdvanceById,
};

export default connect(null, mapDispatchToProps)(AcceptFinCashRetirement);
