import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
    Dialog,
    DialogContent,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    DialogTitle,
    IconButton,
    Fab,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import Feedback from "../../../../Feedback";
import { getLesson, createQuiz } from "../../../../actions/ppmActions/lmsActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight:'bold',
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    centered: {
        textAlign: "center",
        marginTop: 10,
        marginBottom: 20,
    },
    btn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    createBtn: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
    },
    saveBtn: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        width: 200,
        [theme.breakpoints.down("sm")]: {
        width: "100%",
        },
    },
    saveBtn2: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        width: 200,
        // [theme.breakpoints.down("sm")]: {
        //   width: "100%",
        // },
    },
}));

const INIT_STATE = {
    question: "",
    answerType:"",
    options: [{
        option:"",
        isCorrect: false,
    }],
};

const CreateQuizModal = ({ openAddQuiz, handleCloseAddQuiz, id, getLesson, createQuiz, error }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [state, setState] = useState(INIT_STATE);
    const [loading, setLoading] = useState(false);
    const [feed, setFeed] = React.useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleAddItem = () => {
        const data = {
            option: "",
            isCorrect: false,
        };
        setState((prev) => ({
            ...prev,
            options: prev.options.concat([data]),
        }));
    };

    const handleChangeItem = (i) => (e) => {
        e.persist();
        const newOptions = state.options.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: e.target.value,
            };
        });
        setState((prev) => ({
            ...prev,
            options: newOptions,
        }));
    };

    const handleRemoveItem = (i) => {
        setState((prev) => ({
            ...prev,
            options: prev.options.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading((prev) => !prev);
        let data = {
            lesson: id,
            question: state.question,
            answerType: state.answerType,
            options: state.options,
        };
        
        if(data.lesson && data.question && data.answerType && data.options.length > 0){
            createQuiz(data)
                .then(() => {
                    setLoading(false);
                    setState(INIT_STATE);
                    
                    setFeed({
                        loading: false,
                        open: !feed.open,
                        // message: `Sequence Created Successfully`,
                        success: true,
                    });
                    dispatch(getLesson(id));
                })
                .catch(() => {
                    setLoading(false);
                    
                    setFeed({
                        loading: false,
                        open: !feed.open,
                        // message: `Sequence Created Successfully`,
                        success: false,
                    });
                })
                .finally(() => {
                    handleCloseAddQuiz();
                });
        }
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isValid = () => !state.question || !state.answerType || (state.options.length === 0);

    return (
        <div>
            {error && error.error ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={(error && error.error) || feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message || "Module Created Successfully."}
                />
            )}
            <Dialog
                open={openAddQuiz}
                onClose={handleCloseAddQuiz}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
                // fullScreen={fullScreen}
            >
                <DialogTitle>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="overline" className={classes.title}>
                                Add Quiz/Test To Lesson
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCloseAddQuiz}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handleSubmit}>
                        <Grid container justify="center" alignItems="center" spacing={2}>
                            <Grid item xs={12} md={8}>
                                <TextField
                                    fullWidth
                                    label="Question"
                                    name="question"
                                    value={state.question || ""}
                                    onChange={handleChange}
                                    required
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth required variant="outlined">
                                    <InputLabel id="answerType">
                                        Answer Type
                                    </InputLabel>
                                    <Select
                                        labelId="answerType"
                                        required
                                        value={state.answerType || ""}
                                        name="answerType"
                                        onChange={handleChange}
                                    >
                                        <MenuItem disabled>Select</MenuItem>
                                        <MenuItem value="multipleChoice">Multiple Choice</MenuItem>
                                        <MenuItem value="truthy" disabled>True or False</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Paper variant="outlined" square className={classes.paper}>
                                    {state.options && state.options.map((sitem, i) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            className={classes.grid}
                                            key={i}
                                            style={{marginBottom: 15}}
                                        >
                                            <Grid item xs={12} md={8}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    label="Option Answer"
                                                    name="option"
                                                    value={sitem.option || ""}
                                                    onChange={handleChangeItem(i)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <FormControl fullWidth required variant="outlined">
                                                    <InputLabel id="isCorrect">
                                                        Is Correct Answer?
                                                    </InputLabel>
                                                    <Select
                                                        labelId="isCorrect"
                                                        required
                                                        value={sitem.isCorrect || false}
                                                        name="isCorrect"
                                                        onChange={handleChangeItem(i)}
                                                    >
                                                        <MenuItem disabled>Select</MenuItem>
                                                        <MenuItem value={true}>True</MenuItem>
                                                        <MenuItem value={false}>False</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <Fab
                                                    color="secondary"
                                                    onClick={() => handleRemoveItem(i)}
                                                    >
                                                    <CloseIcon />
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    ))}

                                    <Button
                                        variant="outlined"
                                        color="default"
                                        onClick={() =>
                                            handleAddItem(state.options.length + 1)
                                        }
                                        endIcon={<AddIcon fontSize="large" />}
                                    >
                                        Insert Option
                                    </Button>
                                </Paper>
                            </Grid>
                        </Grid>

                        <Grid container justify="center">
                            <Button
                                color="primary"
                                type="submit"
                                variant="contained"
                                fullWidth
                                size="large"
                                disabled={loading || isValid()}
                                className={classes.saveBtn}
                            >
                                {loading ? "Loading..." : "Submit"}
                            </Button>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state) => ({
    error: state.lms.error,
});

const mapDispatchToProps = { getLesson, createQuiz };

export default connect( mapStateToProps, mapDispatchToProps )(CreateQuizModal);
