import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
    Dialog,
    DialogContent,
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    DialogTitle,
    IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../../Feedback";
import {
    getCourses,
    createCourse,
} from "../../../../actions/ppmActions/lmsActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },

    centered: {
        textAlign: "center",
        marginTop: 10,
        marginBottom: 20,
    },
    btn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    createBtn: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
    },
    saveBtn: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        width: 200,
        [theme.breakpoints.down("sm")]: {
        width: "100%",
        },
    },
    saveBtn2: {
        margin: "30px 0",
        padding: theme.spacing(2),
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        width: 200,
        // [theme.breakpoints.down("sm")]: {
        //   width: "100%",
        // },
    },
}));

const INIT_STATE = {
    name: "",
    code: "",
    description:"",
    nextNumber: 1,
};

const CreateCourseModal = ({ openCreate, handleCloseCreate, getCourses, createCourse, error }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [state, setState] = useState(INIT_STATE);
    const [loading, setLoading] = useState(false);
    const [feed, setFeed] = React.useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading((prev) => !prev);
        let data = {
            name: state.name,
            description: state.description,
            nextNumber: state.nextNumber,
            code: state.code,
        };
        createCourse(data)
            .then(() => {
                setLoading(false);
                setState(INIT_STATE);

                setFeed({
                    loading: false,
                    open: !feed.open,
                    // message: `Sequence Created Successfully`,
                    success: true,
                });
                dispatch(getCourses());
            })
            .catch(() => {
                setLoading(false);

                setFeed({
                    loading: false,
                    open: !feed.open,
                    // message: `Sequence Created Successfully`,
                    success: false,
                });
            })
            .finally(() => {
                handleCloseCreate();
            });
    };
    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isValid = () => !state.name || !state.code || !state.description;

    return (
        <div>
            {error && error.error ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={(error && error.error) || feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message || "Course Created Successfully."}
                />
            )}
            <Dialog
                open={openCreate}
                onClose={handleCloseCreate}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
                // fullScreen={fullScreen}
            >
                <DialogTitle>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="overline" className={classes.title}>
                                Create Course
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCloseCreate}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handleSubmit}>
                        <Grid container justify="center" alignItems="center" spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    name="name"
                                    value={state.name || ""}
                                    onChange={handleChange}
                                    required
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    label="Code"
                                    name="code"
                                    value={state.code || ""}
                                    onChange={handleChange}
                                    required
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    fullWidth
                                    label="Description"
                                    multiline
                                    rows={3}
                                    name="description"
                                    value={state.description || ""}
                                    onChange={handleChange}
                                    required
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Grid container justify="center">
                            <Button
                                color="primary"
                                type="submit"
                                variant="contained"
                                fullWidth
                                size="large"
                                disabled={loading || isValid()}
                                className={classes.saveBtn}
                            >
                                {loading ? "Loading..." : "Submit"}
                            </Button>
                        </Grid>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state) => ({
  error: state.lms.error,
});

const mapDispatchToProps = {
  getCourses,
  createCourse,
};

export default connect( mapStateToProps, mapDispatchToProps )(CreateCourseModal);
