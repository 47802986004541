import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  withStyles,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Fab,
  Divider,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Icon,
  darken,
  Backdrop,
  ButtonGroup,
  Hidden,
  IconButton,
  Badge,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../../Feedback";
import { getCustomers } from "../../../actions/customerActions";
import { getContactPersonByCustomer } from "../../../actions/contactPersonActions";
import { getDivisionsByService } from "../../../actions/divisionActions";
import { getCwsrById, updateCwsr, } from "../../../actions/cwsrActions";
// BD Head
import ReviewCwsrModal from "../Modals/Sales/ReviewCwsrModal";
import DeclineCwsrModal from "../Modals/Sales/DeclineCwsrModal";
// Services Division
import ApproveCwsrModal from "../Modals/Sales/ApproveCwsrModal";
import RejectCwsrModal from "../Modals/Sales/RejectCwsrModal";
// Commercials Division
import AcceptCwsrModal from "../Modals/Sales/AcceptCwsrModal";
import ToModifyCwsrModal from "../Modals/Sales/ToModifyCwsrModal";

import ModifiedDataModal from "../Modals/Sales/ModifiedDataModal";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    // marginBottom: 20,
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontWeight: "bold",
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },
  container: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  divider: {
    marginBottom: 10,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  total: {
    marginTop: 40,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  warningInfo: {
    color: "#f5f5f5",
    background: "#f44336",
    padding: 20,
    marginBottom: 20,
    marginRight: 10,
    "& > *": {
      fontWeight: 900,
    },
  },
  form: {
    marginTop: 20,
  },
  grid: {
    marginBottom: 20,
  },
  input: {
    border: "1px solid #bdbdbd",
    padding: theme.spacing(2),
    borderRadius: 3,
  },

  btnWrapper: {
    textAlign: "center",
  },

  saveBtn: {
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    margin: "30px 0",
    padding: theme.spacing(2),
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  saveBtn2: {
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    margin: "30px 0",
    padding: theme.spacing(2),
    width: 200,
    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  backBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  status: {
    width: "fit-content",
    color: "#979292",
    padding: 6,
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusTitle: {
    color: "#b5b5b5",
    fontSize: "0.75rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusRed: {
    borderColor: "#ef5350",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  embed: {
    width: "100%",
    height: 100,
    objectFit: "contain",
    objectPosition: "center",
    marginRight: 5,
    cursor: "pointer",
    transition: "all 300ms ease-in-out",
    zIndex: 1,
    "&:hover": {
      transform: "scale(2.5)",
      zIndex: 1000000000000,
    },
  },
  audit: {
    padding: 10,
    fontFamily: "Rubik",
    fontSize: 10,
    width: "fit-content",
    textTransform: "uppercase",
    letterSpacing: 1.5,
    // fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
  auditTitle: {
    padding: 10,
    // background: theme.palette.grey[600],
    color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
    fontFamily: "Rubik",
    fontSize: 10,
    width: "fit-content",
    textTransform: "uppercase",
    letterSpacing: 1.5,
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
  auditContainer: {
    width: 500,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  small: {
    fontSize: 11,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    display: "block",
    color: theme.palette.type === "dark" && "#cccccc",
  },
  link: {
    display: "block",
    color: theme.palette.type === "dark" && "#cccccc",
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    // backgroundColor: "#44b700",
    backgroundColor: "#ff9800",
    // color: "#44b700",
    color: "#ff9800",

    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: 50,
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const INIT_STATE = {
  employee: "",
  division: "",
  jobTitle: "",
  employeeId: "",
  customer: "",
  contactPerson: "",
  contactPersonEmail: "",
  contactPersonJob: "",
  contactPersonPhone: "",
  serviceDivision: "",
  serviceType: "",
  thirdParty: "",
  serviceRequirement: "",
  manpowerRequirement: [
    {
      qualification: "",
      quantity: 1,
      source: "",
    },
  ],
  equipmentRequirement: [
    {
      equipment: "",
      quantity: 1,
      specification: "",
    },
  ],
  consumableRequirement: [
    {
      consumable: "",
      quantity: 1,
      specification: "",
      requiredPPE: "",
    },
  ],

  serviceLocation: "",
  serviceDuration: 1,
  servicePeriod: "",
  serviceMethod: "",
  customerPrice: "",
  regulatoryRequirement: "",
  otherRequirement: "",
  mobilizationDate: "",
  dateReceived: "",
  document: [],
  drawingSpecification: [],
  status: "",
  approvedBy: "",
  approvedAt: "",
  approverComment: "",
  reviewedBy: "",
  reviewedAt: "",
  modifiedBy: "",
  modifiedAt: "",
  reviewerComment: "",
  submittedBy: "",
  submittedAt: "",
  documentNo: "",
  modificationReason: "",
  modificationDoc: ""
};

const ViewCWSR = () => {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState(INIT_STATE);

  const [openReview, setOpenReview] = useState(false);
  const [openDecline, setOpenDecline] = useState(false);

  const [openApprove, setOpenApprove] = useState(false);
  const [openReject, setOpenReject] = useState(false);

  const [openAccept, setOpenAccept] = useState(false);
  const [openToModify, setOpenToModify] = useState(false);

  const [openModify, setOpenModify] = useState(false);


  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { customers } = useSelector((state) => state.customer);
  const { contactPersons } = useSelector((state) => state.contactPerson);
  const { divisions } = useSelector((state) => state.division);
  const { profile } = useSelector((state) => state.user);
  const { error, loading, cwsr } = useSelector((state) => state.cwsr);

  useEffect(() => {
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: error,
        success: false,
      }));
    }
  }, [error]);

  useEffect(() => {
    dispatch(getCwsrById(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDivisionsByService());
  }, [dispatch]);

  useEffect(() => {
    if (state.contactPerson) {
      contactPersons &&
        contactPersons.map(
          (item) =>
            item.name === state.contactPerson &&
            setState((prev) => ({
              ...prev,
              contactPersonEmail: item && item.email,
              contactPersonJob: item && item.designation,
              contactPersonPhone: item && item.phone,
            }))
        );
    }
  }, [state.contactPerson, contactPersons]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      employee: (cwsr && cwsr.employee) || "",
      division: (cwsr && cwsr.division && cwsr.division.name) || "",
      jobTitle: (cwsr && cwsr.jobTitle) || "",
      employeeId: (cwsr && cwsr.employeeId) || "",
      customer: (cwsr && cwsr.customer && cwsr.customer._id) || "",
      contactPerson: (cwsr && cwsr.contactPerson) || "",
      serviceDivision: (cwsr && cwsr.serviceDivision) || "",
      serviceType: (cwsr && cwsr.serviceType) || "",
      serviceRequirement: (cwsr && cwsr.serviceRequirement) || "",
      thirdParty: cwsr && (cwsr.thirdParty === true ? "Yes" : "No"),
      manpowerRequirement: cwsr && cwsr.manpowerRequirement,
      equipmentRequirement: cwsr && cwsr.equipmentRequirement,
      consumableRequirement: cwsr && cwsr.consumableRequirement,
      serviceLocation: (cwsr && cwsr.serviceLocation) || "",
      serviceDuration: (cwsr && cwsr.serviceDuration) || "",
      servicePeriod: (cwsr && cwsr.servicePeriod) || "",
      serviceMethod: (cwsr && cwsr.serviceMethod) || "",
      customerPrice: (cwsr && cwsr.customerPrice) || "",
      regulatoryRequirement: (cwsr && cwsr.regulatoryRequirement) || "",
      otherRequirement: (cwsr && cwsr.otherRequirement) || "",
      dateReceived: (cwsr && moment(cwsr.dateReceived).format("l")) || "",
      mobilizationDate:
        (cwsr && moment(cwsr.mobilizationDate).format("l")) || "",
      drawingSpecification: (cwsr && cwsr.drawingSpecification) || [],
      modificationReason: (cwsr && cwsr.modificationReason) || "",
      modificationDoc: (cwsr && cwsr.modificationDoc) || "",
      document: (cwsr && cwsr.document) || [],
      status: (cwsr && cwsr.status) || "",
      approvedBy: (cwsr && cwsr.approvedBy && cwsr.approvedBy.userName) || "",
      approvedAt: (cwsr && cwsr.approvedAt) || "",
      approverComment: (cwsr && cwsr.approverComment) || "",
      
      declinedBy: (cwsr && cwsr.declinedBy && cwsr.declinedBy.userName) || "",
      declinedAt: (cwsr && cwsr.declinedAt) || "",
      declineComment: (cwsr && cwsr.declineComment) || "",

      rejectedBy: (cwsr && cwsr.rejectedBy && cwsr.rejectedBy.userName) || "",
      rejectedAt: (cwsr && cwsr.rejectedAt) || "",
      rejectComment: (cwsr && cwsr.rejectComment) || "",

      reviewedBy: (cwsr && cwsr.reviewedBy && cwsr.reviewedBy.userName) || "",
      reviewedAt: (cwsr && cwsr.reviewedAt) || "",
      modifiedBy: (cwsr && cwsr.modifiedBy && cwsr.modifiedBy.userName) || "",
      modifiedAt: (cwsr && cwsr.modifiedAt) || "",
      reviewerComment: (cwsr && cwsr.reviewerComment) || "",
      submittedBy:
        (cwsr && cwsr.submittedBy && cwsr.submittedBy.userName) || "",
      submittedAt: (cwsr && cwsr.submittedAt) || "",
      documentNo: (cwsr && cwsr.documentNo) || "",
    }));
  }, [cwsr]);

  useEffect(() => {
    if (state.customer !== "") {
      dispatch(getContactPersonByCustomer(state.customer));
    }
  }, [dispatch, state.customer]);

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  // BD Head
  const handleOpenReview = () => {
    setOpenReview(true);
  };
  const handleCloseReview = () => {
    setOpenReview(false);
  };
  const handleOpenDecline = () => {
    setOpenDecline(true);
  };
  const handleCloseDecline = () => {
    setOpenDecline(false);
  };

  // Services Division
  const handleOpenApprove = () => {
    setOpenApprove(true);
  };
  const handleCloseApprove = () => {
    setOpenApprove(false);
  };
  const handleOpenReject = () => {
    setOpenReject(true);
  };
  const handleCloseReject = () => {
    setOpenReject(false);
  };

  // Commercials Division
  const handleOpenAccept = () => {
    setOpenAccept(true);
  };
  const handleCloseAccept = () => {
    setOpenAccept(false);
  };
  const handleOpenToModify = () => {
    setOpenToModify(true);
  };
  const handleCloseToModify = () => {
    setOpenToModify(false);
  };

  const handleOpenModify = () => {
    setOpenModify(true);
  };
  const handleCloseModify = () => {
    setOpenModify(false);
  };

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
    }));
    };

    const handleChangeManpower = (i) => (e) => {
      e.persist();
      const newManpower = state.manpowerRequirement.map((item, index) => {
          if (i !== index) {
              return item;
          }
          return {
              ...item,
              [e.target.name]: e.target.value,
          };
      });
      setState((prev) => ({
          ...prev,
          manpowerRequirement: newManpower,
      }));
    };

    const handleChangeEquipment = (i) => (e) => {
      e.persist();
      const newEquipment = state.equipmentRequirement.map((item, index) => {
          if (i !== index) {
              return item;
          }
          return {
              ...item,
              [e.target.name]: e.target.value,
          };
      });
      setState((prev) => ({
          ...prev,
          equipmentRequirement: newEquipment,
      }));
    };

    const handleChangeConsumable = (i) => (e) => {
      e.persist();

      const newConsumable = state.consumableRequirement.map((item, index) => {
          if (i !== index) {
              return item;
          }
          return {
              ...item,
              [e.target.name]: e.target.value,
          };
      });
      setState((prev) => ({
          ...prev,
          consumableRequirement: newConsumable,
      }));

    };
    const handleAddManpower = () => {
      const data = {
          qualification: "",
          quantity: 1,
          source: "",
      };
      setState((prev) => ({
          ...prev,
          manpowerRequirement: prev.manpowerRequirement.concat([data]),
      }));
    };

    const handleRemoveManpower = (i) => {
        setState((prev) => ({
            ...prev,
            manpowerRequirement: prev.manpowerRequirement.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleAddEquipment = () => {
        const data = {
            equipment: "",
            quantity: 1,
            specification: "",
        };
        setState((prev) => ({
            ...prev,
            equipmentRequirement: prev.equipmentRequirement.concat([data]),
        }));
    };

    const handleRemoveEquipment = (i) => {
        setState((prev) => ({
            ...prev,
            equipmentRequirement: prev.equipmentRequirement.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleAddConsumables = () => {
        const data = {
            consumable: "",
            requiredPPE: "",
            quantity: 1,
            specification: "",
        };
        setState((prev) => ({
            ...prev,
            consumableRequirement: prev.consumableRequirement.concat([data]),
        }));
    };

    const handleRemoveConsumable = (i) => {
        setState((prev) => ({
            ...prev,
            consumableRequirement: prev.consumableRequirement.filter(
                (_, index) => index !== i
            ),
        }));
    };

    const handleSave = async (e) => {
      e.preventDefault();
  
      let data = {
        manpowerRequirement: state.manpowerRequirement,
        consumableRequirement: state.consumableRequirement,
        serviceMethod: state.serviceMethod,
        regulatoryRequirement: state.regulatoryRequirement,
        otherRequirement: state && state.otherRequirement,
        equipmentRequirement: state.equipmentRequirement,
        id,
      };
      
    
      const res = await new Promise((resolve, reject) => {
        resolve(dispatch(updateCwsr(data)));
      });
      
      if (res) {
        dispatch(getCwsrById(id));
        setFeed((prev) => ({
          loading: false,
          open: !prev.open,
          message: "CWSR Updated Successfully.",
          success: true,
        }));
      }
    };

  const isBDHead = profile
    ? profile &&
    profile.groups &&
    profile.groups.some(
      (grp) => grp.name === "manager" && grp.module === "sales"
    )
    : false;

  const isServices = profile
        ? profile &&
        profile.division &&
        (
            profile?.division?.code === "SMD" || 
            profile?.division?.code === "OPD" || 
            profile?.division?.code === "TSD" || 
            profile?.division?.code === "SSD" || 
            profile?.division?.code === "UAD"
        )
        : false;

  const isCommercials = profile
    ? profile &&
    profile.groups &&
    profile.groups.some(
      (grp) => grp.name === "reviewer" && grp.module === "sales"
    )
    : false;

  let modifiedData = cwsr && cwsr.modifiedData;

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      {/* BD head */}
      <ReviewCwsrModal {...{ openReview, handleCloseReview, id }} />
      <DeclineCwsrModal {...{ openDecline, handleCloseDecline, id }} />

      {/* Service division head */}
      <ApproveCwsrModal {...{ openApprove, handleCloseApprove, id }} />
      <RejectCwsrModal {...{ openReject, handleCloseReject, id }} />

      {/* Commercial division head */}
      <AcceptCwsrModal {...{ openAccept, handleCloseAccept, id }} />
      <ToModifyCwsrModal {...{ openToModify, handleCloseToModify, id }} />

      <ModifiedDataModal
        {...{
          openModify,
          handleCloseModify,
          modifiedData,
        }}
      />
      <Typography variant="overline" className={classes.title}>
        View CWSR
      </Typography>
      <Grid container justify="space-between" alignItems="center" spacing={2}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            className={classes.backBtn}
            startIcon={<ArrowBackIosIcon />}
            onClick={() => history.push("/sales/cwsr")}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Paper variant="outlined" className={classes.status}>
            <Typography variant="overline" className={classes.statusTitle}>
              Document No.
            </Typography>
            <Divider className={classes.divider2} orientation="vertical" />
            {loading ? "Loading..." : state.documentNo}
          </Paper>
        </Grid>
      </Grid>

      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={2}
        style={{ marginBottom: 20 }}
      >
        <Hidden smDown>
          <Grid item></Grid>
        </Hidden>
        <Grid item>
          <Paper
            variant="outlined"
            className={clsx(
              state.status === "draft"
                ? classes.statusYellow
                : state.status === "submitted"
                  ? classes.statusOrange
                  : state.status === "approved"
                    ? classes.statusGreen
                    : state.status === "reviewed"
                      ? classes.statusDarkGreen
                      : classes.statusRed,
              classes.status
            )}
          >
            <Typography variant="overline" className={classes.statusTitle}>
              Status
            </Typography>
            <Divider className={classes.divider2} orientation="vertical" />
            {loading
              ? "Loading..."
              : state.status && state.status === "tomodify" ? state.status.substring(2).toUpperCase() : state.status.toUpperCase()}
          </Paper>
        </Grid>
      </Grid>

      <form onSubmit={handleSave}>
      <Typography variant="overline" className={classes.title2}>
        A. Basic Information
      </Typography>

      <Paper variant="outlined" square className={classes.paper}>
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={2}
          className={classes.grid}
        >
          <Grid item xs={12} md={5}>
            <TextField
              variant="outlined"
              fullWidth
              label="Prepared By"
              name="employee"
              value={state.employee || ""}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              fullWidth
              label="Division/Subdivision"
              name="division"
              value={state.division || ""}
              disabled
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              variant="outlined"
              fullWidth
              label="Date Received"
              name="division"
              value={state.dateReceived || ""}
              disabled
            />
          </Grid>
        </Grid>
      </Paper>

      <Typography variant="overline" className={classes.title2}>
        B. Customer Information
      </Typography>

      <Paper variant="outlined" square className={classes.paper}>
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={2}
          className={classes.grid}
        >
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined" disabled>
              <InputLabel id="customerName">Customer Name</InputLabel>
              <Select
                labelId="customerName"
                label="Customer Name"
                name="customer"
                value={state.customer || ""}
              >
                <MenuItem disabled>Select</MenuItem>
                {customers &&
                  customers.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined" disabled>
              <InputLabel id="contactPerson">Contact Person</InputLabel>
              <Select
                labelId="contactPerson"
                label="Contact Person"
                name="contactPerson"
                value={state.contactPerson}
              >
                <MenuItem disabled value="">
                  Select
                </MenuItem>
                {contactPersons &&
                  contactPersons.map((item) => (
                    <MenuItem key={item._id} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              fullWidth
              type="email"
              label="Contact Person Email"
              name="contactPersonEmail"
              value={state.contactPersonEmail || ""}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              fullWidth
              label="Job Title"
              name="contactPersonJob"
              value={state.contactPersonJob || ""}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="outlined"
              fullWidth
              label="Phone Number"
              type="tel"
              name="contactPersonPhone"
              value={state.contactPersonPhone || ""}
              disabled
            />
          </Grid>
        </Grid>
      </Paper>

      <Typography variant="overline" className={classes.title2}>
        C. Service Information
      </Typography>

      <Paper variant="outlined" square className={classes.paper}>
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={2}
          className={classes.grid}
        >
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined" disabled>
              <InputLabel id="serviceDivision">Service Division</InputLabel>
              <Select
                labelId="serviceDivision"
                label="Service Division"
                name="serviceDivision"
                value={state.serviceDivision._id || ""}
              >
                <MenuItem disabled>Select</MenuItem>
                {divisions &&
                  divisions.map((item) => (
                    <MenuItem key={item._id} value={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Service Type"
              type="text"
              name="serviceType"
              value={state.serviceType || ""}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              variant="outlined"
              component="fieldset"
              disabled
            >
              <FormLabel>Is this a third party service?</FormLabel>
              <RadioGroup row value={state.thirdParty}>
                <FormControlLabel
                  value="Yes"
                  control={<Radio />}
                  label="Yes"
                  name="thirdParty"
                />
                <FormControlLabel
                  value="No"
                  control={<Radio />}
                  label="No"
                  name="thirdParty"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
      </Paper>

      <Typography variant="overline" className={classes.title2}>
        D. Work Scope Requirements
      </Typography>
      <Paper variant="outlined" square className={classes.paper}>
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={2}
          className={classes.grid}
        >
          <Grid item xs={12} md={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Service Requirements"
              multiline
              rows={4}
              name="serviceRequirement"
              value={state.serviceRequirement || ""}
              disabled
            />
          </Grid>
        </Grid>
      </Paper>

      <Typography variant="overline" className={classes.title2}>
        E. Other Requirements
      </Typography>

      <Paper variant="outlined" square className={classes.paper}>
        <Grid
          container
          justify="center"
          alignItems="flex-start"
          spacing={2}
          className={classes.grid}
        >
          <Grid item xs={12} md={6}>
            <label>Service Location(s) offshore/onshore</label>
            <TextField
              variant="outlined"
              fullWidth
              value={state.serviceLocation || ""}
              name="serviceLocation"
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <label>Customer Price & Cost Target</label>
            <TextField
              variant="outlined"
              fullWidth
              name="customerPrice"
              value={state.customerPrice}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <label>Service Duration</label>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Number Only"
                  type="number"
                  value={state.serviceDuration}
                  name="serviceDuration"
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="typeOfPeriod">Type Of Period</InputLabel>
                  <Select
                    labelId="typeOfPeriod"
                    value={state.servicePeriod}
                    name="servicePeriod"
                    disabled
                  >
                    <MenuItem disabled>Select</MenuItem>
                    <MenuItem value="Days">Days</MenuItem>
                    <MenuItem value="Weeks">Weeks</MenuItem>
                    <MenuItem value="Months">Months</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <label>Expected Mobilization Date</label>
            <TextField
              variant="outlined"
              fullWidth
              value={state.mobilizationDate}
              name="mobilizationDate"
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <label>Service Method(s)</label>
            <TextField
              variant="outlined"
              fullWidth
              name="serviceMethod"
              value={state.serviceMethod}
              disabled={(!isServices && state.status === "reviewed") || (isServices && state.status === "approved")}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <label htmlFor="regulatoryRequirement">Regulatory Requirements</label>
            <TextField
              variant="outlined"
              fullWidth
              name="regulatoryRequirement"
              value={state.regulatoryRequirement}
              disabled={(!isServices && state.status === "reviewed") || (isServices && state.status === "approved")}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <label htmlFor="otherRequirement">
              Other Specific requirement necessary to meet customer's
              expectations
            </label>
            <TextField
              variant="outlined"
              fullWidth
              name="otherRequirement"
              multiline
              rows={4}
              value={state.otherRequirement}
              disabled={(!isServices && state.status === "reviewed") || (isServices && state.status === "approved")}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Paper>

      <Grid
        container
        alignItems="center"
        wrap="nowrap"
        spacing={1}
        style={{ marginBottom: 5 }}
      >
        <Grid item>
          <Typography variant="overline" className={classes.title2}>
            Drawing Specification(s)
          </Typography>
        </Grid>
        <Grid item>
          <AttachFileIcon color="disabled" />
        </Grid>
      </Grid>
      <Paper variant="outlined" square className={classes.paper}>
        <Grid container spacing={3} justify="center" alignItems="center">
          {state.drawingSpecification &&
            state.drawingSpecification.map((item, i) => (
              <Grid item key={i} xs={12} md={2}>
                <a
                  // href={
                  //   item || (item && item.includes(".pdf"))
                  //     ? item.replace(".pdf", ".png")
                  //     : item
                  // }
                  href={item}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                  download={item && item.includes(".pdf")}
                >
                  <object
                    // data={
                    //   item || (item && item.includes(".pdf"))
                    //     ? item.replace(".pdf", ".png")
                    //     : item
                    // }
                    data={item}
                    // type={item && item.includes(".pdf") && "application/pdf"}
                    className={classes.embed}
                  >
                    <Typography component="small" className={classes.small}>
                      Your Browser doesn't have a PDF Plugin. Instead you can{" "}
                      <Typography
                        component="a"
                        href={item}
                        target="_blank"
                        rel="noopener"
                        className={classes.small}
                        download={item && item.includes(".pdf")}
                      >
                        Click here to download the PDF file.
                      </Typography>
                    </Typography>
                  </object>
                </a>
              </Grid>
            ))}
        </Grid>
      </Paper>


      <Typography variant="overline" className={classes.title2}>
        F. Manpower Requirements
      </Typography>

      <Paper variant="outlined" square className={classes.paper}>
        {state.manpowerRequirement &&
          state.manpowerRequirement.map((item, i) => (
            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={2}
              className={classes.grid}
              key={i}
            >
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Qualification"
                  name="qualification"
                  value={item.qualification || ""}
                  disabled={(!isServices && state.status === "reviewed") || (isServices && state.status === "approved")}
                  onChange={handleChangeManpower(i)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Quantity"
                  type="number"
                  name="quantity"
                  value={item.quantity}
                  disabled={(!isServices && state.status === "reviewed") || (isServices && state.status === "approved")}
                  onChange={handleChangeManpower(i)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth variant="outlined"
                  disabled={(!isServices && state.status === "reviewed") || (isServices && state.status === "approved")}>
                  <InputLabel id="nationalExpat">National / Expat</InputLabel>
                  <Select
                    labelId="nationalExpat"
                    value={item.source || ""}
                    name="source"
                    onChange={handleChangeManpower(i)}
                  >
                    <MenuItem
                      disabled={(!isServices && state.status === "reviewed") || (isServices && state.status === "approved")}>Select</MenuItem>
                    <MenuItem value="National">National</MenuItem>
                    <MenuItem value="Expat">Expat</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={1}>
                  <Fab
                      color="secondary"
                      onClick={() => handleRemoveManpower(i)}
                  >
                      <CloseIcon />
                  </Fab>
              </Grid>
            </Grid>
          ))}
          <Button
              variant="outlined"
              color="default"
              onClick={() =>
                  handleAddManpower(state.manpowerRequirement.length + 1)
              }
              endIcon={<AddIcon fontSize="large" />}
          >
              Insert Item
          </Button>
      </Paper>

      <Typography variant="overline" className={classes.title2}>
        G. Equipment/Material/Power Requirements
      </Typography>

      <Paper variant="outlined" square className={classes.paper}>
        {state.equipmentRequirement &&
          state.equipmentRequirement.map((item, i) => (
            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={2}
              className={classes.grid}
              key={i}
            >
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Equipment"
                  name="equipment"
                  value={item.equipment || ""}
                  disabled={(!isServices && state.status === "reviewed") || (isServices && state.status === "approved")}
                  onChange={handleChangeEquipment(i)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Quantity"
                  type="number"
                  value={item.quantity}
                  name="quantity"
                  disabled={(!isServices && state.status === "reviewed") || (isServices && state.status === "approved")}
                  onChange={handleChangeEquipment(i)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="specification"
                    label="Specification"
                    value={item.specification}
                    disabled={(!isServices && state.status === "reviewed") || (isServices && state.status === "approved")}
                    onChange={handleChangeEquipment(i)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={1}>
                <Fab
                    color="secondary"
                    onClick={() => handleRemoveEquipment(i)}
                >
                    <CloseIcon />
                </Fab>
            </Grid>
            </Grid>
          ))}
          <Button
              variant="outlined"
              color="default"
              onClick={() =>
                  handleAddEquipment(state.equipmentRequirement.length + 1)
              }
              endIcon={<AddIcon fontSize="large" />}
          >
              Insert Item
          </Button>
      </Paper>

      <Typography variant="overline" className={classes.title2}>
        H. Required Consumables
      </Typography>

      <Paper variant="outlined" square className={classes.paper}>
        {state.consumableRequirement &&
          state.consumableRequirement.map((item, i) => (
            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={2}
              className={classes.grid}
              key={i}
            >
              <Grid item xs={12} md={3}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Consumable"
                  value={item.consumable}
                  name="consumable"
                  disabled={(!isServices && state.status === "reviewed") || (isServices && state.status === "approved")}
                  onChange={handleChangeConsumable(i)}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Quantity"
                  type="number"
                  value={item.quantity}
                  name="quantity"
                  disabled={(!isServices && state.status === "reviewed") || (isServices && state.status === "approved")}
                  onChange={handleChangeConsumable(i)}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="specification"
                    label="Specification"
                    value={item.specification}
                    disabled={(!isServices && state.status === "reviewed") || (isServices && state.status === "approved")}
                    onChange={handleChangeConsumable(i)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="requiredPPE"
                  value={item.requiredPPE}
                  label="Required PPE"
                  disabled={(!isServices && state.status === "reviewed") || (isServices && state.status === "approved")}
                  onChange={handleChangeConsumable(i)}
                />
              </Grid>
              <Grid item xs={12} md={1}>
                  <Fab
                      color="secondary"
                      onClick={() => handleRemoveConsumable(i)}
                  >
                      <CloseIcon />
                  </Fab>
              </Grid>
            </Grid>
          ))}
          <Button
            variant="outlined"
            color="default"
            onClick={() =>
                handleAddConsumables(
                    state.consumableRequirement.length + 1
                )
            }
            endIcon={<AddIcon fontSize="large" />}
        >
            Insert Item
        </Button>
      </Paper>

      {/* Required Consumables Attachments */}
      <Grid container alignItems="center" wrap="nowrap" spacing={1} style={{ marginBottom: 5 }}>
        <Grid item>
          <Typography variant="overline" className={classes.title2}>Attachment(s)</Typography>
        </Grid>
        <Grid item>
          <AttachFileIcon color="disabled" />
        </Grid>
      </Grid>
      <Paper variant="outlined" square className={classes.paper}>
        <Grid container spacing={3} justify="center" alignItems="center">
          {state.document && state.document.length > 0 ? (
            state.document.map((item, i) => (
              <Grid item key={i} xs={12} md={2}>
                <a
                  href={item}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                  download={item && item.includes(".pdf")}
                >
                  <object
                    data={item}
                    className={classes.embed}
                  >
                    <Typography component="small" className={classes.small}>
                      Your Browser doesn't have a PDF Plugin. Instead you can{" "}
                      <Typography
                        component="a"
                        href={item}
                        target="_blank"
                        rel="noopener"
                        className={classes.small}
                        download={item && item.includes(".pdf")}
                      >
                        Click here to download the PDF file.
                      </Typography>
                    </Typography>
                  </object>
                </a>
              </Grid>
            ))
          ) : (
            <Typography
              variant="overline"
              className={classes.title3}
              style={{ padding: 20, fontSize: 11 }}
            >
              No Attachments
            </Typography>
          )}
        </Grid>
      </Paper>

      {/* Modification Request // available to everyone if filled */}
      {(cwsr && cwsr.status !== "accepted") && (state.modificationDoc !== "" && state.modificationReason !== "") ? (
        <>
          <Grid container alignItems="center" wrap="nowrap" style={{ marginBottom: 10 }}>
            <Grid item>
              <Typography variant="overline" className={classes.title2}>Modification Requested To CWSR</Typography>
            </Grid>
          </Grid>
          <Paper variant="outlined" square className={classes.paper} style={{ padding: 15 }}>
            <Grid container alignItems="center" wrap="nowrap" spacing={1} style={{ marginBottom: 5 }}>
              <Grid item>
                <Typography variant="overline" className={classes.title2}>Attachment(s)</Typography>
              </Grid>
            </Grid>
            <Paper variant="outlined" square className={classes.paper}>
              <Grid container spacing={3} justify="center" alignItems="center">
                {state.modificationDoc !== "" ? (
                  <Grid item xs={12} md={2}>
                    <a
                      href={state.modificationDoc}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.link}
                      download={state.modificationDoc}
                    >
                      <object
                        data={state.modificationDoc}
                        className={classes.embed}
                      >
                        <Typography component="small" className={classes.small}>
                          Your Browser doesn't have a PDF Plugin. Instead you can{" "}
                          <Typography
                            component="a"
                            href={state.modificationDoc}
                            target="_blank"
                            rel="noopener"
                            className={classes.small}
                            download={state.modificationDoc}
                          >
                            Click here to download the PDF file.
                          </Typography>
                        </Typography>
                      </object>
                    </a>
                  </Grid>
                ) : (
                  <Typography
                    variant="overline"
                    className={classes.title3}
                    style={{ padding: 20, fontSize: 11 }}
                  >
                    No Attachments
                  </Typography>
                )}
              </Grid>
            </Paper>

            <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
              <Grid item>
                <Typography variant="overline" className={classes.title2}>Reason for Change(s)</Typography>
              </Grid>
              <TextField variant="outlined" fullWidth multiline rows={3} disabled value={state.modificationReason} />
            </Grid>
          </Paper>
        </>
      ) : null}

      <Grid
        container
        alignItems="center"
        wrap="nowrap"
        spacing={1}
        style={{ marginBottom: 5 }}
      >
        <Grid item>
          <Typography variant="overline" className={classes.title2}>
            Audit Trail
          </Typography>
        </Grid>
        <Grid item>
          <Icon className="fas fa-clipboard-list" color="disabled" />
        </Grid>
      </Grid>
      <Paper variant="outlined" square className={classes.paper}>
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper
              className={classes.auditContainer}
              component="div"
              elevation={1}
              variant="outlined"
              square
            >
              <span className={classes.auditTitle}>Submitted By</span>
              <Divider orientation="vertical" flexItem />
              <span className={classes.audit}>{state.submittedBy}</span>
              <Divider orientation="vertical" flexItem />
              <span className={classes.audit}>{state.submittedAt}</span>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          {state.reviewedBy && (
            <>
              <Grid item xs={12} md={6}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Reviewed By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.reviewedBy}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.reviewedAt}</span>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Reviewer Comment</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.reviewerComment}</span>
                </Paper>
              </Grid>
            </>
          )}

          {state.declinedBy && (
            <>
              <Grid item xs={12} md={6}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Declined By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.declinedBy}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.declinedAt}</span>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Comment</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.declineComment}</span>
                </Paper>
              </Grid>
            </>
          )}

          {state.rejectedBy && (
            <>
              <Grid item xs={12} md={6}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Rejected By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.rejectedBy}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.rejectedAt}</span>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Comment</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.rejectComment}</span>
                </Paper>
              </Grid>
            </>
          )}

          {state.approvedBy && (
            <>
              <Grid item xs={12} md={6}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Approved By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.approvedBy}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.approvedAt}</span>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Approver Comment</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.approverComment}</span>
                </Paper>
              </Grid>
            </>
          )}
          {state.modifiedBy && (
            <>
              <Grid item xs={12} md={6}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Modified By</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.modifiedBy}</span>
                  <Divider orientation="vertical" flexItem />
                  <span className={classes.audit}>{state.modifiedAt}</span>
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper
                  className={classes.auditContainer}
                  component="div"
                  elevation={1}
                  variant="outlined"
                  square
                >
                  <span className={classes.auditTitle}>Modified Data</span>
                  <Divider orientation="vertical" flexItem />

                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span className={classes.auditTitle}>
                      Click Icon to view Modified data
                    </span>

                    <IconButton size="small" onClick={handleOpenModify}>
                      <StyledBadge
                        variant="dot"
                        overlap="circle"
                        color="primary"
                      >
                        <Icon className="far fa-file-alt" fontSize="small" />
                      </StyledBadge>
                    </IconButton>
                  </span>
                </Paper>
              </Grid>
            </>
          )}

        </Grid>
      </Paper>

      {isBDHead && state.status === "submitted" ? (
        <Grid container justify="center">
          <Grid item>
            <ButtonGroup>
              <Button
                onClick={handleOpenReview}
                className={classes.saveBtn2}
                variant="contained"
              >
                Review
              </Button>
              <Button
                onClick={handleOpenDecline}
                className={classes.saveBtn}
                color="secondary"
                variant="contained"
              >
                Decline
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      ) : (isServices && state.status === "reviewed" ? (
        <Grid container justify="center">
          <Grid item>
            
            <ButtonGroup>
              <Button
                  type="submit"
                  className={classes.saveBtn}
                  color="primary"
                  variant="contained"
                  disabled={loading}
                  // onClick={handleSave}
              >
                  {loading ? "Loading..." : "Saves Changes"}
              </Button>
              <Button
                onClick={handleOpenApprove}
                className={classes.saveBtn2}
                variant="contained"
              >
                Approve
              </Button>
              <Button
                onClick={handleOpenReject}
                className={classes.saveBtn}
                color="secondary"
                variant="contained"
              >
                Reject
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      ) : (isCommercials && state.status === "approved" ? (
        <Grid container justify="center">
          <Grid item>
            <ButtonGroup>
              <Button
                onClick={handleOpenAccept}
                className={classes.saveBtn2}
                variant="contained"
              >
                Accept
              </Button>
              <Button
                onClick={handleOpenToModify}
                className={classes.saveBtn}
                color="secondary"
                variant="contained"
              >
                Modify
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      ) : null))
      }
      </form>
      
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ViewCWSR;
