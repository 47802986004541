import React, { useState } from "react";
import { connect } from "react-redux";
import {
    makeStyles,
    Typography,
    Paper,
    Grid,
    Button,
    Backdrop,
    Icon,
    IconButton,
} from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useHistory } from "react-router-dom";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { TablePaginationActions } from "../../TablePaginationActions";
import SearchComponent from "../../SearchComponent";
import CreateCourseModal from "../Modals/LMS/CreateCourseModal";
import { getCourses } from "../../../actions/ppmActions/lmsActions";
import EditCourseModal from "../Modals/LMS/EditCourseModal";
import DeleteCourseModal from "../Modals/LMS/DeleteCourseModal";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  createBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 500,
  },
  marginBottom: {
    marginBottom: 30,
  },
}));

const Courses = ({ getCourses, courses }) => {
    const classes = useStyles();
    const history = useHistory();
    const [search, setSearch] = useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [getId, setGetId] = useState("");

    React.useEffect(() => {
        setOpenBackdrop((prev) => !prev);
        getCourses().then(() => {
            setOpenBackdrop(false);
        });
    }, [getCourses]);

    const updateSearch = (e) => {
        setSearch(e.target.value.substr(0, 20));
    };

    const ResetSearch = (e) => {
        e.preventDefault();
        setSearch("");
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    let filteredRows = () =>
        courses === undefined
        ? []
        : courses &&
        courses.map((row) => {
            if (search !== "") {
                return (
                row.name
                    .toString()
                    .toLowerCase()
                    .indexOf(search.toLowerCase()) !== -1 ||
                row.code
                    .toString()
                    .toLowerCase()
                    .indexOf(search.toLowerCase()) !== -1
                );
            } else {
                return row;
            }
        });

    const navigateById = (path, id) => {
        history.push(`${path}/${id}`);
    };

    const emptyRows =
        rowsPerPage -
        Math.min(
        rowsPerPage,
        (Array(1000).length || filteredRows().length) - page * rowsPerPage
        );

    const handleOpenCreate = () => {
        setOpenCreate(true);
    };

    const handleCloseCreate = () => {
        setOpenCreate(false);
    };

    const handleOpenEdit = (id) => {
        setGetId(id);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleOpenDelete = (id) => {
        setGetId(id);
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    return (
        <div>
            <CreateCourseModal {...{ openCreate, handleCloseCreate }} />
            <EditCourseModal {...{ openEdit, handleCloseEdit, id: getId }} />
            <DeleteCourseModal {...{ openDelete, handleCloseDelete, id: getId }} />

            <Typography variant="overline" className={classes.title}>
                Courses
            </Typography>

            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={3}
                className={classes.marginBottom}
            >
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        // style={{ marginRight: 5, marginBottom: 30 }}
                        onClick={handleOpenCreate}
                        className={classes.btn}
                    >
                        Create
                    </Button>
                </Grid>
                <Grid item>
                    <SearchComponent
                        updateSearch={updateSearch}
                        placeholder="Search..."
                        search={search}
                        ResetSearch={ResetSearch}
                    />
                </Grid>
            </Grid>
            <Paper>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item></Grid>
                    <Grid item>
                        <IconButton style={{ marginRight: 10, marginTop: 10 }}>
                            <FilterListIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <TableContainer>
                <Table className={classes.table} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Code</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Edit</TableCell>
                            <TableCell>View</TableCell>
                            <TableCell>Delete</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {(rowsPerPage > 0
                            ? filteredRows().slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            : filteredRows()
                        ).map((row) => {
                            return (
                            <TableRow hover key={row._id}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell>{row.code}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell>
                                    <IconButton
                                        color="primary"
                                        onClick={() => handleOpenEdit(row._id)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        color="inherit"
                                        onClick={() => navigateById("/lms/course", row._id)}
                                        variant="extended"
                                    >
                                        <Icon className="fas fa-eye" color="action" />
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        color="secondary"
                                        onClick={() => handleOpenDelete(row._id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            );
                        })}

                        {filteredRows().length === 0 && (
                            <TableRow hover>
                                <TableCell
                                    colSpan={5}
                                    align="center"
                                    style={{ color: "#616161" }}
                                >
                                    No Records To Display
                                </TableCell>
                            </TableRow>
                        )}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                </TableContainer>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                    colSpan={3}
                    count={filteredRows().length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </Paper>

            <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
        );
    };

    const mapStateToProps = (state) => ({
        courses: state.lms.courses,
    });

    const mapDispatchToProps = {
        getCourses,
    };

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
