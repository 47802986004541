import React, { useState, useEffect } from "react";
import { Document, Page, Text, StyleSheet, PDFViewer, } from "@react-pdf/renderer";
import { Paper, Typography, makeStyles, Grid, Button, ButtonGroup, darken, Backdrop, } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Feedback from "../../../../Feedback";
import { getProfile } from "../../../../actions/userActions";
import { getQuotation } from "../../../../actions/quotationActions";

import SendQuotationModal from "../../Modals/Sales/QuotationModals/SendQuotationModal";
import CloseQuotationModal from "../../Modals/Sales/QuotationModals/CloseQuotationModal";

import PageOne from './Doc/PageOne';
import PageTwo from './Doc/PageTwo';
import PageThree from './Doc/PageThree';

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        padding: theme.spacing(2),
        width: 200,
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
}));

const styles = StyleSheet.create({
    header: {
        fontSize: 10,
        marginBottom: 10,
        textAlign: 'center',
        color: 'grey',
        marginTop: 20,
        borderBottomWidth: 1,
        borderBottomColor: "#888",
        borderLeftStyle: "solid",
        textTransform: "capitalize"

    },
    page: {
        backgroundColor: "#fff",
        color: "black",
        fontSize: 12,
        textAlign: "left",
        fontWeight: 400,
        lineHeight: 1.5,
    },
    viewer: {
        width: "100%",
        height: window.innerHeight,
    },
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});

const QuotationDocument = ({ quote }) => {
    return (
        <PDFViewer style={styles.viewer}>
            <Document>
                <Page size="A4" style={styles.page} wrap>
                    <Text style={styles.header} fixed>
                        {quote && quote.title ? quote.title : null}
                    </Text>
                    <PageOne quote={quote} />
                    <PageTwo quote={quote} />
                    {quote && quote.noteToPricing ? <PageThree quote={quote} /> : null}
                </Page>
            </Document>
        </PDFViewer>
    );
}

const QuotationPDF = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [openSend, setOpenSend] = useState(false);
    const [openClose, setOpenClose] = useState(false);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { error, loading, quotation } = useSelector((state) => state.quotation);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getQuotation(id));
    }, [dispatch, id]);

    const handleOpenSend = () => {
        setOpenSend(true);
    };
    const handleCloseSend = () => {
        setOpenSend(false);
    };
    const handleOpenClose = () => {
        setOpenClose(true);
    };
    const handleCloseClose = () => {
        setOpenClose(false);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}

            <SendQuotationModal {...{ openSend, handleCloseSend, id }} />
            <CloseQuotationModal {...{ openClose, handleCloseClose, id }} />

            <Typography variant="overline" className={classes.title}>
                Preview Quotation Document
            </Typography>

            <Paper style={{ padding: 20, marginTop: 15, marginBottom: 15 }}>
                <Typography variant="overline" className={classes.title2}>
                    Please wait a little while for the PDF document to load.
                </Typography>
            </Paper>

            <Grid container justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.backBtn}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.push("/sales/quotation")}
                    >
                        Back
                    </Button>

                    {(quotation && quotation.status === "approved") ? (
                        <Button
                            style={{ marginLeft: 20 }}
                            color="secondary"
                            variant="contained"
                            className={classes.saveBtn2}
                            onClick={handleOpenSend}
                        >
                            Send Quotation
                        </Button>
                    ) : (quotation && (quotation.status === "sent" || quotation.status === "read")) ? (
                        <ButtonGroup>
                            <Button
                                style={{ marginLeft: 20 }}
                                color="secondary"
                                variant="contained"
                                className={classes.saveBtn2}
                                onClick={handleOpenSend}
                            >
                                Resend Quotation
                            </Button>
                            <Button
                                style={{ marginLeft: 20 }}
                                color="secondary"
                                variant="contained"
                                className={classes.backBtn}
                                onClick={handleOpenClose}
                            >
                                Close Quotation
                            </Button>
                        </ButtonGroup>
                    ) : null}

                </Grid>
            </Grid>

            <Grid item style={{ marginTop: 20 }}>
                <QuotationDocument quote={quotation} />
            </Grid>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default QuotationPDF;
