import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import procurement from '../../../../procurement.jpeg';

const styles = StyleSheet.create({
    section: {
        margin: 10,
        marginTop: 20,
        paddingBottom: 20,
        paddingHorizontal: 30,
        textAlign: "justify",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.5,
    },
    text: {
        fontSize: 8.9,
        textAlign: "justify",
        fontWeight: 300,
        color: "#222",
        lineHeight: 1.5,
        marginBottom: 10,
    },
    boldText: {
        fontSize: 10,
        textAlign: "left",
        fontWeight: "bold",
        color: "#222",
        lineHeight: 2.0,
        marginBottom: 5,
    },
    footerTextLeft: {
        textAlign: "left",
        fontSize: 8.9,
    },
    textRight: {
        textAlign: "right",
        fontSize: 8.9,
        marginTop: -15,
    },
    textUnderline: {
        textDecoration: "underline",
        width: "100%",
        display:"block",
    },
});

export const PageThree = () => {
    return (
        <>
            <View break>
                <Image
                    src={procurement}
                    style={{
                        height: 120,
                        width: "100%",
                        marginTop: 20,
                        paddingRight: 30,
                        paddingLeft: 30,
                        marginBottom: 0,
                    }} 
                />
                <View style={styles.section}>
                    <Text style={styles.text}>
                        provided to Company within ninety (90) days of completion of the Services or delivery of Goods and must reference the applicable purchase order, and
                        COMPANY reserves the right to return all incorrect invoices. COMPANY will receive a 5% discount of the invoiced amount for all invoices that are submitted
                        more than ninety (90) days after completion of the Services or delivery of the Goods. Unless otherwise specified on the face of a purchase order, COMPANY
                        shall pay the invoiced amount within fourteen (14) days after receipt of a correct invoice. Seller will receive no royalty or other remuneration on the production
                        or distribution of any products developed by COMPANY or Seller in connection with or based on the Goods or Services provided. There will be penalty for late delivery of goods. Furthermore, goods must be delivered in good conditions and signed off by RusselSmith Facilities and Services representative.
                    </Text>
                
                    <Text style={styles.boldText}>5. WARRANTIES.</Text>
                    <Text style={styles.text}>5.1 Services:</Text>
                    <Text style={styles.text}>
                        Seller represents and warrants that all Services shall be completed in a professional, workmanlike manner, with the degree of skill and care that is required by
                        current, good, and sound professional procedures. Further, Seller represents and warrants that the Services shall be completed in accordance with applicable
                        specifications and shall be correct and appropriate for the purposes contemplated in this Agreement. Seller represents and warrants that the performance of
                        Services under this Agreement will not conflict with, or be prohibited in any way by, any other agreement or statutory restriction to which Seller is bound.</Text>
                    
                    <Text style={styles.text}>5.2 Goods:</Text>
                    <Text style={styles.text}>
                        Seller warrants that all Goods provided will be new and will not be used or refurbished except otherwise stated. Seller warrants that all Goods delivered shall
                        be free from defects in materials and workmanship and shall conform to all applicable specifications for a period of twelve (12) months from the date of
                        delivery to COMPANY or for the period provided in Seller's standard warranty covering the Goods, whichever is longer. Additionally, Goods purchased shall be
                        subject to all written and oral express warranties made by Seller's agents. All warranties and Service guaranties shall run both to COMPANY and to its
                        customers. If COMPANY identifies a warranty problem with the Goods during the warranty period, COMPANY will promptly notify Seller of such problems and
                        will return the Goods to Seller, at Seller's expense. Within five (5) business days of receipt of the returned Goods, Seller shall, at COMPANY's option, either
                        repair or replace such Goods, or credit COMPANY's account for the same. Replacement and repaired Goods shall be warranted for the remainder of the
                        warranty period or six (6) months, whichever is longer.</Text>
                    
                    <Text style={styles.boldText}>6. INSPECTION.</Text>
                    <Text style={styles.text}>
                        COMPANY shall have a reasonable time after receipt of Goods or Service deliverables and before payment to inspect them for conformity hereto, and performance hereunder shall not be deemed accepted until COMPANY has run an adequate test to determine whether the Goods and Services conform to the specifications hereof. Use of a portion of the Goods for the purpose of testing shall not constitute an acceptance of the Goods. If performance tendered does not wholly conform with the provisions hereof, COMPANY shall have the right to reject such performance. Nonconforming Goods will be returned to Seller freight collect and risk of loss will pass to Seller upon COMPANY's delivery to the common carrier.</Text>

                    <Text style={styles.boldText}>7. INDEPENDENT CONTRACTOR.</Text>
                    <Text style={styles.text}>
                        COMPANY is interested only in the results obtained under this Agreement; the manner and means of achieving the results are subject to Seller's sole control. Seller is an independent contractor for all purposes, without express or implied authority to bind COMPANY by contract or otherwise. Neither Seller nor its employees, agents or subcontractors ("Seller's Assistants") are agents or employees of COMPANY, and therefore are not entitled to any employee benefits of COMPANY, including but not limited to, any type of insurance. Seller shall be responsible for all costs and expenses incident to performing its obligations under this Agreement and shall provide Seller's own supplies and equipment</Text>

                    <Text style={[styles.textUnderline]}>
                        {"________________________________________________________________________________"}
                    </Text>
                    <Text style={[styles.footerTextLeft]}>RS-CPD-PMG-PUR-P-10106 Procurement</Text>
                    <Text style={[styles.textRight]}>Unauthorized Printing Is Not Permitted</Text>

                </View>
            </View>
        </>
    )
}

export default PageThree;
