import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Typography,
    Dialog,
    DialogContent,
    Grid,
    DialogTitle,
    TextField,
    Button,
    IconButton,
    Backdrop,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import {
    DatePicker,
    MuiPickersUtilsProvider,
    TimePicker,
} from "@material-ui/pickers";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import { createVisitorAppointment, getVisitorAppointments } from "../../../../actions/visitorsActions";
import { getProfile } from "../../../../actions/userActions";
import Feedback from "../../../../Feedback";
import { useHistory } from "react-router-dom";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { QRCodeCanvas } from 'qrcode.react';

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 18,
        fontWeight: 600,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
    },
    btn: {
        margin: "30px 0",
        width: 300,
        letterSpacing: 2.5,
        padding: theme.spacing(2),
        paddingLeft: 20,
        paddingRight: 20,
        [theme.breakpoints.down("sm")]: {
            width: 200,
            // minWidth: "100%",
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 10000,
        color: "#fff",
    },
}));

const INIT_STATE = {
    visitorName: "",
    visitorEmail: "",
    visitorPhone: "",
    visitPurpose: "",
    visitDetails: "",
    visitDuration: "",
    visitTime: "",
    visitDate: "",
    visitCode: "",
    visitQRCode: "",
};

const CreateVisitorRequestModal = ({ openCreate, handleCloseCreate }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    const [time, setTime] = useState(new Date());
    const [date, setDate] = useState(new Date());
    const [code, setCode] = useState(Math.floor(Math.random() * 9000) + 1000);

    const { error, loading } = useSelector((state) => state.visitors);
    const { profile } = useSelector((state) => state.user);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    React.useEffect(() => {
        dispatch(getProfile())
    }, [dispatch]);

    useEffect(() => {
        let abortController = new AbortController();

        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
        return () => {
            abortController.abort();
            dispatch({
                type: "RESET_VISITOR_APPOINTMENT",
            });
        };
    }, [error, dispatch]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
        setCode(Math.round(Math.random() * 9000));
    };

    const handleChangeDate = (date) => {
        setDate(date);
    };

    const handleChangeTime = (time) => {
        setTime(time);
    };

    React.useEffect(() => {
        if (date !== "" && time !== "" && code !== "") {
            setState((prev) => ({
                ...prev,
                visitDate: moment(date._d).format('MMMM Do YYYY'),
                visitTime: moment(time._d).format('h:mm a'),
                visitCode: code,
            }));
        }
    }, [date, time, code]);

    React.useEffect(() => {
        if (state.visitCode !== "") {
            let url =`https://qrcode.tec-it.com/API/QRCode?data=${state.visitCode}&backcolor=%23ffffff&quietzone=20&quietunit=Px&dpi=600&size=Large`;
            setState((prev) => ({
                ...prev,
                visitQRCode: url,
            }));
        }
    }, [state.visitCode]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (state.visitCode && state.visitQRCode) {
            const data = {
                ...state,
                employee: profile.userName,
                employeeId: profile.employeeId,
                employeeEmail: profile.email,
                division: profile.division,
            }
            const res = await new Promise((resolve, reject) => {
                resolve(dispatch(createVisitorAppointment(data)));
            });
            if (res) {
                handleCloseCreate();
                dispatch(getVisitorAppointments());
                setState(INIT_STATE);
                setFeed((prev) => ({
                    loading: false,
                    open: !prev.open,
                    message: "Visitor Appointment Created Successfully.",
                    success: true,
                }));
                setTimeout(() => {
                    history.push(`/visitor/${res._id}`);
                }, 2000);
            }
        }
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const isValid = () =>
        !state.visitorName || !state.visitorEmail || !state.visitorPhone || !state.visitPurpose ||
        !state.visitTime || !state.visitDuration || !state.visitDate || !state.visitDetails || !state.visitCode;

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                <Dialog
                    open={openCreate}
                    onClose={handleCloseCreate}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    maxWidth="md"
                >
                    <DialogTitle id="form-dialog-title">
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="overline" className={classes.title}>
                                    Create Visitor Appointment
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={handleCloseCreate}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent dividers>
                        <QRCodeCanvas
                            id="canvas"
                            style={{ display: "none" }}
                            value={state.visitCode}
                        />
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3} justify="center" alignItems="center">
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="visitorName"
                                        name="visitorName"
                                        label="Visitor Name"
                                        value={state.visitorName || ""}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="visitorEmail"
                                        name="visitorEmail"
                                        label="Visitor Email"
                                        value={state.visitorEmail || ""}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="visitorPhone"
                                        name="visitorPhone"
                                        label="Visitor Phone"
                                        value={state.visitorPhone || ""}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Purpose of Visit</InputLabel>
                                        <Select
                                            name="visitPurpose"
                                            inputProps={{ id: "visitPurpose", }}
                                            value={state.visitPurpose || ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="" disabled><em>Select</em></MenuItem>
                                            <MenuItem value="Personal">Personal</MenuItem>
                                            <MenuItem value="Official">Official</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="visitDetails"
                                        name="visitDetails"
                                        label="Brief details about the Visit"
                                        value={state.visitDetails || ""}
                                        onChange={handleChange}
                                        variant="outlined"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="outlined" required>
                                        <InputLabel>Visit Duration</InputLabel>
                                        <Select
                                            name="visitDuration"
                                            inputProps={{ id: "visitDuration", }}
                                            value={state.visitDuration || ""}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="" disabled><em>Select</em></MenuItem>
                                            <MenuItem value="OneHour">Less than One Hour</MenuItem>
                                            <MenuItem value="TwoHours">Less than Two Hours</MenuItem>
                                            <MenuItem value="ThreeHours">Less than Three Hours</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DatePicker
                                        autoOk
                                        format="DD/MM/yyyy"
                                        views={["year", "month", "date"]}
                                        label="Expected Date"
                                        fullWidth
                                        inputVariant="outlined"
                                        value={date}
                                        onChange={handleChangeDate}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TimePicker
                                        autoOk
                                        fullWidth
                                        inputVariant="outlined"
                                        label="Expected Time"
                                        value={time}
                                        onChange={handleChangeTime}
                                        required
                                        className={classes.textField}
                                    />
                                </Grid>

                            </Grid>
                            <Grid container justify="center" alignItems="center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    className={classes.btn}
                                    disabled={isValid() || loading}
                                    endIcon={
                                        loading ? (
                                            <CircularProgress size={20} color="primary" />
                                        ) : null
                                    }
                                >
                                    {loading ? "Loading..." : "Create"}
                                </Button>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </MuiPickersUtilsProvider>
    );
}

export default CreateVisitorRequestModal;