import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import {
    makeStyles,
    Grid,
    Typography,
    Button,
    Paper,
    IconButton,
    Menu,
    Backdrop,
    MenuItem,
    Chip,
    Icon,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";
import FilterListIcon from "@material-ui/icons/FilterList";
import CreateSalesOrderModal from "../Modals/Sales/SalesOrderModals/CreateSalesOrderModal";
import DeleteRFQModal from "../Modals/Purchasing/DeleteRFQModal";
import { TablePaginationActions } from "../../TablePaginationActions";
import SearchComponent from "../../SearchComponent";
import { getRequestForQuotes } from "../../../actions/purchasingActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Feedback from "../../../Feedback";
import clsx from "clsx";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight:"bold"
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
    },

    action: {
        marginBottom: 30,
    },
    btn: {
        marginTop: 30,
        marginBottom: 30,
    },
    createBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    table: {
        minWidth: 500,
    },
    chip: {
        paddingLeft: 8,
        paddingRight: 8,
        fontSize: "0.65rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
        borderWidth: 2,
    },
    statusGreen: {
        // background: "#00e676",
        borderWidth: 2,

        borderColor: "#1de9b6",
        // color: "#fff",
    },
    statusYellow: {
        // background: "#fbc02d",
        borderWidth: 2,

        borderColor: "#fbc02d",
    },
    statusOrange: {
        borderColor: "#ff9800",
        borderWidth: 2,
    },
    statusRed: {
        // background: "#ef5350",
        borderWidth: 2,
        borderColor: "#ef5350",
    },
    marginBottom: {
        marginBottom: 30,
    },
}));

const SalesOrder = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [search, setSearch] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [filter, setFilter] = useState("");
    const [openCreate, setOpenCreate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [getId, setGetId] = useState("");

    const { rfqs, loading, error } = useSelector((state) => state.rfq);
    const { profile } = useSelector((state) => state.user);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        dispatch(getRequestForQuotes());
    }, [dispatch]);

    const updateSearch = (e) => {
        setSearch(e.target.value.substr(0, 20));
    };

    const navigateById = (path, id) => {
        history.push(`${path}/${id}`);
    };

    const ResetSearch = (e) => {
        e.preventDefault();
        setSearch("");
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpenCreate = () => {
        setOpenCreate(true);
    };
    const handleCloseCreate = () => {
        setOpenCreate(false);
    };

    const handleDeleteOpen = (id) => {
        setGetId(id);
        setOpenDelete(true);
    };
    const handleDeleteClose = () => {
        setOpenDelete(false);
    };

    const handleOpenFilter = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const filteredRows = () =>
        rfqs === undefined
        ? []
        : rfqs &&
        rfqs.filter((row) => {
            if (search !== "") {
                return (
                    row.year.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    row.month.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    row.status.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    row.createdAt.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    row.documentNo.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                    (row.division && row.division.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) || 
                    (row.vendor && row.vendor.companyName.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                );
            } else if (filter !== "") {
                return row.status.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
            } else {
                return row;
            }
        });

    const handleSelectFilter = (e, val) => {
        setFilter(val.substr(0, 20));
        handleCloseFilter();
    };

    const isAdmin = profile
        ? profile &&
        profile.groups &&
        profile.groups.some((grp) => grp.name === "admin") : false;

    const isDivManager = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "purchasing" && grp.module === "head"
        )
        : false;

    const isProcurement = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "purchasing" && grp.module === "manager"
        )
        : false;

    const dataLength = Array(1000).length || filteredRows().length;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, dataLength - page * rowsPerPage);
    
    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}

            <CreateSalesOrderModal {...{ openCreate, handleCloseCreate }} />
            <DeleteRFQModal {...{ openDelete, handleDeleteClose, id: getId }} />

            <Typography variant="overline" gutterBottom className={classes.title}>
                Sales Order
            </Typography>
            
            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={3}
                className={classes.marginBottom}
            >
                <Grid item>
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.createBtn}
                    onClick={handleOpenCreate}
                    // onClick={()=> {}}
                >
                    Create
                </Button>
                </Grid>
                <Grid item>
                <SearchComponent
                    updateSearch={updateSearch}
                    placeholder="Search..."
                    search={search}
                    ResetSearch={ResetSearch}
                />
                </Grid>
            </Grid>

            <Paper>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item></Grid>
                    <Grid item>
                        <IconButton
                            style={{ marginRight: 10, marginTop: 10 }}
                            onClick={handleOpenFilter}
                        >
                            <FilterListIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseFilter}
                            TransitionComponent={Fade}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                        >
                            <MenuItem onClick={(e) => handleSelectFilter(e, "")}>
                                All
                            </MenuItem>
                            <MenuItem onClick={(e) => handleSelectFilter(e, "Draft")}>
                                Draft
                            </MenuItem>
                            <MenuItem onClick={(e) => handleSelectFilter(e, "Accepted")}>
                                Accepted
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>

                <TableContainer>
                    <Table className={classes.table} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Document No.</TableCell>
                                <TableCell>Vendor</TableCell>
                                <TableCell>Date Needed</TableCell>
                                <TableCell>Div./SubDiv.</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Action</TableCell>
                                {(isAdmin) ? (<TableCell>Delete</TableCell>) : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {(rowsPerPage > 0
                                ? filteredRows().slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                : filteredRows()
                            ).map((row, i) => {
                                return (
                                    <TableRow hover key={row._id}>
                                        <TableCell component="th" scope="row">
                                            {row.documentNo && row.documentNo}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.vendor && row.vendor.companyName}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.dateNeeded && moment(row.dateNeeded).format("l")}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.division && row.division?.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Chip
                                                style={{ padding: 10 }}
                                                label={row.status}
                                                variant="outlined"
                                                className={clsx(
                                                    row.status === "draft" || row.status === "declined" || row.status === "rejected"
                                                        ? classes.statusYellow
                                                        : row.status === "submitted" 
                                                            ? classes.statusOrange
                                                            : row.status === "approved" || row.status === "accepted" 
                                                                ? classes.statusGreen
                                                                : row.status === "completed" || row.status === "reviewed"
                                                                    ? classes.statusDarkGreen
                                                                    : classes.statusRed,
                                                    classes.chip
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {(row.status === "draft" || row.status === "declined" || row.status === "rejected") ? (
                                                <IconButton
                                                    onClick={() => navigateById("/purchasing/rfq/view", row._id)}
                                                    disabled={(row.status === "submitted" || row.status === "accepted" || row.status === "approved")}
                                                    variant="extended"
                                                >
                                                    <Icon className="fas fa-pencil-alt" color="primary" />
                                                </IconButton>
                                            ) : ((isAdmin || isDivManager) && (row.status === "submitted") ? (
                                                // Division Head Approve / Reject Requisition
                                                <IconButton
                                                    color="inherit"
                                                    onClick={() => navigateById("/purchasing/rfq/view", row._id)}
                                                    disabled={row.status === "draft" || row.status === "declined" || row.status === "rejected"}
                                                    variant="extended"
                                                >
                                                    <Icon className="fas fa-eye" color="action" />
                                                </IconButton>
                                            ) : ((isAdmin || isProcurement) && (row.status === "approved")) ? (
                                                // Procurement Accept / Decline / Issue Requisition
                                                <IconButton
                                                    color="inherit"
                                                    onClick={() => navigateById("/purchasing/rfq/view", row._id)}
                                                    disabled={row.status === "draft" || row.status === "declined" || row.status === "rejected"}
                                                    variant="extended"
                                                >
                                                    <Icon className="fas fa-eye" color="action" />
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    color="inherit"
                                                    onClick={() => navigateById("/purchasing/rfq/view", row._id)}
                                                    //disabled={row.status !== "accepted" || row.status !== "reviewed"}
                                                    variant="extended"
                                                >
                                                    <Icon className="fas fa-eye" color="action" />
                                                </IconButton>
                                            )
                                            )}
                                        </TableCell>
                                        {(isAdmin) ? (<TableCell component="th" scope="row">
                                            <IconButton
                                                variant="extended"
                                                color="secondary"
                                                onClick={() => handleDeleteOpen(row._id)}
                                            >
                                                <Icon className="fas fa-trash" color="secondary" />
                                            </IconButton>
                                        </TableCell>) : null}
                                    </TableRow>
                                );
                            })}


                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                                </TableRow>
                            )}
                            {filteredRows().length === 0 && (
                                <TableRow hover>
                                <TableCell
                                    colSpan={10}
                                    align="center"
                                    style={{ color: "#616161" }}
                                    component="th"
                                    scope="row"
                                >
                                    No Records To Display
                                </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                    colSpan={3}
                    count={filteredRows().length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </Paper>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default SalesOrder;
