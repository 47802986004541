import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import colors from "../theme/colors";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Alfa Slab One",
    display: "inline-block",
    textDecoration: "none",
  },
  textOne: {
    color: theme.palette.type === "light" ? colors.deepblue : "#fff",
    marginRight: -5,
  },
  textTwo: {
    // color: theme.palette.type === "light" ? colors.lightblue : "#e2c0c0",
    color: theme.palette.type === "light" ? colors.lightblue : "#fff",
    borderLeft: `3px solid ${colors.lightred}`,
    paddingLeft: -4,
  },
}));
function Logo({ path, size = 15 }) {
  const classes = useStyles();

  return (
    <Typography
      className={classes.root}
      variant="overline"
      component={Link}
      to={path}
      style={{ fontSize: size }}
    >
      <span className={classes.textOne}>RS</span>{" "}
      <span className={classes.textTwo}>Edge</span>
    </Typography>
  );
}

export default Logo;
