import {
  GET_RESET_USER_DATA_BY_TOKEN,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
} from "../types/";
import axios from "axios";

export const getResetUserDataByToken = (token) => async (dispatch) => {
  try {
    const URL = `/api/v1/auth/user/reset/${token}`;
    const res = await axios.get(URL);
    dispatch({
      type: GET_RESET_USER_DATA_BY_TOKEN,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request.data;
    } else {
      return error.message;
    }
  }
};

export const resetPassword = ({ token, password }) => async (dispatch) => {
  try {
    const URL = `/api/v1/auth/resetPassword/${token}`;
    const res = await axios.put(URL, { password });
    dispatch({
      type: RESET_PASSWORD,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request.data;
    } else {
      return error.message;
    }
  }
};

export const forgotPassword = (data) => async (dispatch) => {
  try {
    const URL = `/api/v1/auth/forgotPassword`;
    const res = await axios.post(URL, data);
    dispatch({
      type: FORGOT_PASSWORD,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request.data;
    } else {
      return error.message;
    }
  }
};
