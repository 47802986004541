import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Dialog,
  makeStyles,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
} from "@material-ui/core";
import Feedback from "../../../Feedback";
import CloseIcon from "@material-ui/icons/Close";

import {
  getDivisionById,
  updateDivision,
  getDivisions,
} from "../../../actions/divisionActions";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  saveBtn2: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
}));

const INIT_STATE = {
  name: "",
  parent: "",
  code: "",
  manager: "",
  head: "",
  groupEmail: ""
};

const EditDivisionModal = ({
  openEdit,
  handleCloseEdit,
  users,
  divisions,
  division,
  getDivisionById,
  updateDivision,
  getDivisions,
  id,
}) => {
  const classes = useStyles();
  const [state, setState] = useState(INIT_STATE);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const getDivisionCallback = React.useCallback(() => {
    setLoading((prev) => !prev);
    getDivisionById(id).then(() => {
      setLoading(false);
    });
  }, [id, getDivisionById]);

  React.useEffect(() => {
    getDivisionCallback();
  }, [getDivisionCallback]);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      name: division && division.name,
      code: division && division.code,
      groupEmail: division && division.groupEmail,
      parent: division && division.parent && division.parent._id,
      manager: division && division.manager && division.manager._id,
      head: division && division.head && division.head._id,
    }));
  }, [division]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    const data = {
      ...state,
      id,
    };
    updateDivision(data)
      .then(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message: `Division updated Successfully`,
          success: true,
        });
        dispatch(getDivisions);
        handleCloseEdit();
      })
      .catch(() => {
        setLoading(false);

        setFeed({
          loading: false,
          open: !feed.open,
          message: `An Unexpected Error occured. Contact administration`,
          success: false,
        });
        handleCloseEdit();
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };
  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Edit Division
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseEdit}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item xs={12} md={6}>
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  value={state.name || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="division-label-id">Parent</InputLabel>
                  <Select
                    labelId="division-label-id"
                    id="parent"
                    inputProps={{
                      id: "parent",
                      name: "parent",
                    }}
                    label="Parent"
                    name="parent"
                    value={state.parent || ""}
                    onChange={handleChange}
                    disabled={loading}
                  >
                    <MenuItem disabled>---Select Division----</MenuItem>
                    {divisions &&
                      divisions.map((division) => (
                        <MenuItem key={division._id} value={division._id}>
                          {division.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="code"
                  name="code"
                  label="Code"
                  value={state.code || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="groupEmail"
                  name="groupEmail"
                  label="Group Email"
                  value={state.groupEmail || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="manager-id">Manager</InputLabel>
                  <Select
                    labelId="manager-id"
                    id="manager"
                    inputProps={{
                      id: "manager",
                      name: "manager",
                    }}
                    label="Manager"
                    name="mananger"
                    value={state.manager || ""}
                    onChange={handleChange}
                    disabled={loading}
                  >
                    <MenuItem disabled>---Select Manager----</MenuItem>
                    <MenuItem value={undefined}>None</MenuItem>

                    {users &&
                      users.map((user) => (
                        <MenuItem key={user._id} value={user._id}>
                          {user.userName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel id="head-id">Head</InputLabel>
                  <Select
                    labelId="head-id"
                    id="head"
                    inputProps={{
                      id: "head",
                      name: "head",
                    }}
                    label="Head"
                    name="head"
                    value={state.head || ""}
                    onChange={handleChange}
                    disabled={loading}
                  >
                    <MenuItem disabled>----Select Head----</MenuItem>
                    <MenuItem value={undefined}>None</MenuItem>

                    {users &&
                      users.map((user) => (
                        <MenuItem key={user._id} value={user._id}>
                          {user.userName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                className={classes.saveBtn}
                disabled={loading}
              >
                {loading ? "Loading..." : "Update"}
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  division: state.division.division,
});

const mapDispatchToProps = {
  getDivisionById,
  updateDivision,
  getDivisions,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDivisionModal);
