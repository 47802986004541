import React, { useState, useEffect, useCallback } from "react";
import {
    Typography,
    makeStyles,
    TextField,
    Grid,
    Button,
    AppBar,
    IconButton,
    Dialog,
    Toolbar,
    Tooltip,
    DialogContent,
    Backdrop,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { connect, useDispatch, useSelector } from "react-redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useHistory } from "react-router-dom";
import Feedback from "../../../../Feedback";
import { getProfile } from "../../../../actions/userActions";
import { getAllVendors } from "../../../../actions/vendorsActions";
import { getVendorPurchaseOrder } from "../../../../actions/purchasingActions";
import { createWorkCompletion, getWorkCompletions } from "../../../../actions/qualityActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 20,
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 12,
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#fff",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        marginBottom: 10,
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000000,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 300,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

const INIT_STATE = {
    employee: "",
    employeeId:"",
    division: "",
    type:"",
    projectDescription: "",
    vendorId:"",
    vendorName: "",
    vendorEmail:"",
    vendorPhone: "",
    vendorContactPerson: "",
    purchaseOrderNo: "",
};

const CreateWCModal = ({ openCreate, handleCloseCreate, createWorkCompletion }) => {
    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const [state, setState] = useState(INIT_STATE);

    const { profile } = useSelector((state) => state.user);
    const { loading, error } = useSelector((state) => state.workcompletion);
    const { vendors } = useSelector((state) => state.vendors);
    const { vendor_purchase_orders } = useSelector((state) => state.po);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: profile && profile._id,
            employeeName: profile && profile.userName,
            employeeId: profile && profile.employeeId,
            division: profile && profile.division && profile.division._id,
            divisionName: profile && profile.division && profile.division.name,
        }));
    }, [profile]);

    useEffect(() => {
        dispatch(getProfile());
        dispatch(getAllVendors());
    }, [dispatch]);

    const getVendorPOCallback = useCallback(() => {
        if (state.vendorId !== "") {
            dispatch(getVendorPurchaseOrder(state.vendorId));
        }
    }, [state, dispatch]);

    useEffect(() => {
        if (state.vendorId !== "") {
            getVendorPOCallback();
        }
    }, [state, getVendorPOCallback]);

    const handleChange = (e) => {
        e.persist();
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
        };

        if(data.vendorContactPerson && data.projectDescription && data.vendorPhone && data.vendorName){ 
            const res = await new Promise((resolve, reject) => {
                resolve(createWorkCompletion(data));
            });
            if (res) {
                dispatch(getWorkCompletions());
                setFeed((prev) => ({
                    loading: false,
                    open: !prev.open,
                    message: "Work Completion Created Successfully.",
                    success: true,
                }));
                setTimeout(() => {
                    history.push(`/quality/workcompletion`);
                    handleCloseCreate();
                }, 2000);
            }
        }
    };

    const validCheck = () =>
        !state.division ||
        !state.employee ||
        !state.projectDescription ||
        !state.vendorName ||
        !state.vendorPhone || 
        !state.vendorContactPerson || 
        !state.purchaseOrderNo;
    
    return (
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}
                <Dialog
                    open={openCreate}
                    onClose={handleCloseCreate}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                    fullScreen
                    maxWidth="md"
                >
                    <AppBar
                        className={classes.appBar}
                        variant="elevation"
                        position="fixed"
                        color="inherit"
                    >
                        <Toolbar>
                            <Tooltip title="close" arrow>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseCreate}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.container}>
                                <Typography variant="overline" className={classes.title}>
                                    Create Work Completion
                                </Typography>

                                <Grid container spacing={2} className={classes.grid}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                        label="Employee Name"
                                        name="employeeName"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleChange}
                                        value={state.employeeName || ""}
                                        disabled
                                        required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="EID"
                                            name="employeeId"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            value={state.employeeId || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            label="Division/Subdivision"
                                            name="division"
                                            variant="outlined"
                                            fullWidth
                                            onChange={handleChange}
                                            value={(state.division && state.divisionName) || ""}
                                            disabled
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label="Project Description"
                                            name="projectDescription"
                                            value={state.projectDescription || ""}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel id="type">Type</InputLabel>
                                            <Select
                                                labelId="type"
                                                label="Type"
                                                name="type"
                                                value={state.type || ""}
                                                onChange={handleChange}
                                            >
                                                <MenuItem disabled>Select</MenuItem>
                                                <MenuItem value="Vendor">Vendor</MenuItem>
                                                <MenuItem value="Open Market">Open Market</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    
                                    {state.type === "Vendor" && ( 
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <Autocomplete
                                                style={{marginTop: -15}}
                                                id="vendor"
                                                options={vendors}
                                                // value={(vendor && vendor.id) || ""}
                                                onChange={(event, newValue) => {
                                                    setState((prev) => ({
                                                        ...prev,
                                                        vendorId: newValue && newValue._id,
                                                        vendorEmail: newValue && newValue.email,
                                                        vendorPhone: (newValue && newValue.companyTelephone) || "",
                                                        vendorName: newValue && newValue.companyName,
                                                        vendorContactPerson: (newValue && newValue.contactPerson) || "",
                                                    }));
                                                }}
                                                getOptionLabel={(option) => option.companyName || ""}
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                renderOption={(option) => (
                                                    <React.Fragment>{option.companyName}</React.Fragment>
                                                )}
                                                autoComplete
                                                autoHighlight
                                                renderInput={(params) => (
                                                    <TextField
                                                    {...params}
                                                        label="Vendor"
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                        variant="outlined"
                                                        value={(state.vendorName && state.vendorName) || ""}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "disabled",
                                                            name: "vendor",
                                                        }}
                                                        />
                                                    )}
                                                    />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                label="Vendor Name"
                                                variant="outlined"
                                                fullWidth
                                                value={(state.vendorName && state.vendorName) || ""}
                                                disabled
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                label="Vendor Email"
                                                variant="outlined"
                                                fullWidth
                                                type="email"
                                                value={(state.vendorEmail && state.vendorEmail) || ""}
                                                disabled
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                label="Vendor Phone Number"
                                                variant="outlined"
                                                fullWidth
                                                value={(state.vendorPhone && state.vendorPhone) || ""}
                                                required
                                                name="vendorPhone"
                                                onChange={handleChange}
                                                // onChange={(event, newValue) => {
                                                //     setState((prev) => ({
                                                //         ...prev,
                                                //         vendorPhone: newValue && newValue.target.value,
                                                //     }));
                                                // }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Autocomplete
                                                style={{marginTop: -15}}
                                                id="vendorPO"
                                                options={vendor_purchase_orders}
                                                // value={(vendorPO && vendorPO.id) || ""}
                                                onChange={(event, newValue) => {
                                                    setState((prev) => ({
                                                        ...prev,
                                                        purchaseOrderNo: newValue && newValue.documentNo,
                                                    }));
                                                }}
                                                getOptionLabel={(option) => option.vendor?.companyName || ""}
                                                getOptionSelected={(option, value) => option.id === value.id}
                                                renderOption={(option) => (
                                                    <React.Fragment>{option.vendor?.companyName} - {option.documentNo}</React.Fragment>
                                                )}
                                                autoComplete
                                                autoHighlight
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Purchase Order Number"
                                                        margin="normal"
                                                        fullWidth
                                                        required
                                                        variant="outlined"
                                                        value={(state.purchaseOrderNo && state.purchaseOrderNo) || ""}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: "disabled",
                                                            name: "purchaseOrderNo",
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                label="Purchase Order Number"
                                                variant="outlined"
                                                fullWidth
                                                disabled
                                                value={(state.purchaseOrderNo && state.purchaseOrderNo) || ""}
                                                required
                                                name="purchaseOrderNo"
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </> )}


                                    {state.type === "Open Market" && ( 
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                label="Vendor Name"
                                                name="vendorName"
                                                variant="outlined"
                                                fullWidth
                                                onChange={handleChange}
                                                value={(state.vendorName && state.vendorName) || ""}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Vendor Contact Person"
                                                name="vendorContactPerson"
                                                value={state.vendorContactPerson || ""}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                label="Vendor Email"
                                                variant="outlined"
                                                fullWidth
                                                type="email"
                                                name="vendorEmail"
                                                value={(state.vendorEmail && state.vendorEmail) || ""}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Vendor Phone Number"
                                                name="vendorPhone"
                                                value={state.vendorPhone || ""}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                required
                                                label="Purchase Order Number"
                                                name="purchaseOrderNo"
                                                value={state.purchaseOrderNo || ""}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </> )}
                                </Grid>

                                <div className={classes.btnWrapper}>
                                    <Button
                                        type="submit"
                                        className={classes.saveBtn}
                                        disabled={loading || validCheck()}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {loading ? "Loading..." : "Save"}
                                    </Button>
                                </div>
                            </div>
                        </form>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </DialogContent>
                </Dialog>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default connect(null, { createWorkCompletion })(CreateWCModal);
