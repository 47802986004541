import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  ButtonGroup,
  Button,
  DialogActions,
  makeStyles,
  Grid,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import Feedback from "../../../../Feedback";
import { useHistory } from "react-router-dom";
import {
  getMonthlyBudgetById,
  getMonthlyBudgets,
  modifyMonthlyBudget,
} from "../../../../actions/monthlyBudgetAction";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const SubmitMonthlyModifyModal = ({
  openModify,
  handleCloseModify,
  id,
  monthlyBudget,
  // getMonthlyBudgetById,
  // getMonthlyBudgets,
  modifyMonthlyBudget,
  error,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const submitBudget = async () => {
    setLoading((prev) => !prev);

    const data = {
      id,
      path: "budget/monthly/view",
    };

    new Promise((resolve, reject) => {
      resolve(modifyMonthlyBudget(data));
    })
      .then((doc) => {
        if (doc) {
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            message: `Monthly Budget has been modified successfully, it now awaits CEO's action.`,
            success: true,
          });
        } else {
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            message: "An unexpected error has occured. Contact Administration.",
            success: false,
          });
        }
      })
      .finally(() => {
        dispatch(getMonthlyBudgets());
        dispatch(getMonthlyBudgetById(id));
        setTimeout(() => {
          history.push("/budget/monthly");
        }, 2000);
        handleCloseModify();
      });
    // .catch(() => {
    //   setLoading(false);
    //   setFeed({
    //     loading: false,
    //     open: !feed.open,

    //     success: false,
    //   });
    // })
    // .finally(() => {
    //   setTimeout(() => {
    //     history.push("/budget/monthly");
    //   }, 2000);
    //   handleCloseModify();
    // });
  };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={(error && error) || feed.message}
        />
      )}
      <Dialog
        open={openModify}
        onClose={handleCloseModify}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Submit Budget?
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseModify}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography variant="overline" className={classes.title3}>
            Note: Once submitted, it's assumed you're done preparing this budget
            and won't be available for modification except if rejected.
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button
              className={classes.saveBtn}
              color="secondary"
              onClick={handleCloseModify}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={loading}
              onClick={submitBudget}
              className={classes.saveBtn}
              variant="contained"
              endIcon={
                loading ? <CircularProgress size={16} color="primary" /> : null
              }
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  monthlyBudget:
    state.monthlyBudget.monthlyBudget && state.monthlyBudget.monthlyBudget.data,
  error: state.monthlyBudget.error,
});

const mapDispatchToProps = {
  // getMonthlyBudgetById,
  // getMonthlyBudgets,
  modifyMonthlyBudget,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitMonthlyModifyModal);
