import { combineReducers } from "redux";
import usersReducer from "./usersReducer";
import jobTitleReducer from "./jobTitleReducer";
import divisionReducer from "./divisionReducer";
import groupsReducer from "./groupsReducer";
import accessRightsReducer from "./accessRightsReducer";
import resetPasswordReducer from "./resetPasswordReducer";
import errorReducer from "./errorReducer";
import budgetHeadReducer from "./budgets/budgetHeadReducer";
import budgetSubheadReducer from "./budgets/budgetSubheadReducer";
import ItemReducer from "./budgets/ItemReducer";

import annualReducer from "./budgets/annualReducer";
import vendorsReducer from "./vendorsReducer";
import currencyReducer from "./currencyReducer";
import costCodeReducer from "./costCodeReducer";
import monthlyReducer from "./budgets/monthlyReducer";
import sequenceReducer from "./sequenceReducer";
import modulesReducer from "./modulesReducer";
import cashAdvanceReducer from "./budgets/cashAdvanceReducer";
import reimburseReducer from "./budgets/reimburseReducer";
import cashRecoveryReducer from "./budgets/cashRecoveryReducer";
import peoplesReducer from "./peoplesReducer";
import customerReducer from "./sales/customerReducer";
import quotationReducer from "./sales/quotationReducer";
import serviceTypeReducer from "./sales/serviceTypeReducer";
import contactPersonReducer from "./sales/contactPersonReducer";
import cwsrReducer from "./sales/cwsrReducer";
import analyticsReducer from "./analytics";
import countReducer from "./count";
import notificationReducer from "./notificationReducer";
import leadReducer from "./crm/leadReducer";
import salesCallReducer from "./crm/selesCallReducer";
import personnelReducer from "./sales/priceList/personnelReducer";
import equipmentReducer from "./sales/priceList/equipmentReducer";
import mobilizationReducer from "./sales/priceList/mobilizationReducer";
import materialReducer from "./sales/priceList/materialReducer";

import storeReducer from "./inventory/storeReducer";
import inventoryItemReducer from "./inventory/inventoryItemReducer";
import locationReducer from "./inventory/locationReducer";
import requisitionReducer from "./inventory/requisitionReducer";
import receiptReducer from "./inventory/receiptReducer";
import grnReducer from "./inventory/grnReducer";

import gatepassReducer from "./inventory/gatepassReducer";
import inventoryCountReducer from "./inventory/inventoryCountReducer";

import projectMobReducer from "./projectMob/projectMobReducer";

import officeReducer from "./officeReducer";
import visitorsReducer from "./visitorsReducer";
import purchaseRequisitionReducer from "./purchasing/purchaseRequisitionReducer";
import rfqReducer from "./purchasing/rfqReducer";
import openMarketReducer from "./purchasing/openMarketReducer";
import purchaseOrderReducer from "./purchasing/purchaseOrderReducer";
import assetAcquisitionReducer from "./assets/assetAcquisitionReducer";
import assetRequisitionReducer from "./assets/assetRequisitionReducer";

import salesOrderReducer from "./sales/salesOrderReducer";
import receivingReducer from "./quality/receivingReducer";
import workcompletionReducer from "./quality/workcompletionReducer";
import lmsReducer from "./ppm/lmsReducer";

const appReducer = combineReducers({
  user: usersReducer,
  jobTitle: jobTitleReducer,
  division: divisionReducer,
  groups: groupsReducer,
  accessRights: accessRightsReducer,
  resetPassword: resetPasswordReducer,
  error: errorReducer,
  budgetHeads: budgetHeadReducer,
  budgetSubheads: budgetSubheadReducer,
  budgetItems: ItemReducer,
  annualBudget: annualReducer,
  monthlyBudget: monthlyReducer,
  vendors: vendorsReducer,
  currency: currencyReducer,
  costcode: costCodeReducer,
  sequence: sequenceReducer,
  module: modulesReducer,
  cashAdvance: cashAdvanceReducer,
  reimburse: reimburseReducer,
  cashRecovery: cashRecoveryReducer,
  peoples: peoplesReducer,
  customer: customerReducer,
  service: serviceTypeReducer,
  contactPerson: contactPersonReducer,
  analytics: analyticsReducer,
  count: countReducer,
  notification: notificationReducer,
  leads: leadReducer,
  salesCall: salesCallReducer,
  personnel: personnelReducer,
  equipment: equipmentReducer,
  mob: mobilizationReducer,
  material: materialReducer,
  cwsr: cwsrReducer,
  quotation: quotationReducer,
  stores: storeReducer,
  inventoryItem: inventoryItemReducer,
  grn: grnReducer,
  requisition: requisitionReducer,
  receipt: receiptReducer,
  location: locationReducer,
  office: officeReducer,
  gatepass: gatepassReducer,
  inventoryCount: inventoryCountReducer,
  projectMob: projectMobReducer,
  visitors: visitorsReducer,
  purchaseRequisition: purchaseRequisitionReducer,
  assetAcquisition: assetAcquisitionReducer,
  assetRequisition: assetRequisitionReducer,
  rfq: rfqReducer,
  openmarket: openMarketReducer,
  po: purchaseOrderReducer,
  salesorder: salesOrderReducer,
  receiving: receivingReducer,
  workcompletion: workcompletionReducer,
  lms: lmsReducer,

});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    // localStorage.removeItem("token:key");
    // localStorage.removeItem("rememberme");
    // localStorage.removeItem("welcome");
    // localStorage.removeItem("agreedPolicy");
    // dispatch({ type: "LOGOUT" });

    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
