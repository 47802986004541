import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Grid,
  Button,
  Backdrop,
  IconButton,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
  ButtonGroup,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import FilterListIcon from "@material-ui/icons/FilterList";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import CloseIcon from "@material-ui/icons/Close";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchComponent from "../../SearchComponent";

import { connect, useDispatch } from "react-redux";

import Feedback from "../../../Feedback";
// import BudgetHeadEditModal from "../Modals/Budget/BudgetHeadEditModal";

import {
  getAllBudgetItems,
  deleteBudgetItem,
} from "../../../actions/budgetItemActions";
import ItemModal from "../Modals/Budget/ItemModal";
import EditItemModal from "../Modals/Budget/EditItemModal";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 500,
  },
  createBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  saveBtn: {
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    margin: "30px 0",
    padding: theme.spacing(2),
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  marginBottom: {
    marginBottom: 30,
  },
}));

const BudgetItem = ({ getAllBudgetItems, items, error, deleteBudgetItem }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [open, setOpen] = useState(false);
  const [getId, setGetId] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [loading, setLoading] = useState(false);

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: error,
        success: false,
      }));
    }
  }, [error]);

  useEffect(() => {
    setOpenBackdrop((prev) => !prev);
    getAllBudgetItems().then(() => {
      setOpenBackdrop(false);
    });
  }, [getAllBudgetItems]);

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };

  const handleOpenDelete = (id) => {
    setGetId(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };
  const handleEditOpen = (id) => {
    setGetId(id);

    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const filteredRows = () =>
    items === undefined
      ? []
      : items.filter((row) => {
          if (search !== "") {
            return (
              row.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              (row.subHead &&
                row.subHead.name &&
                row.subHead.name.toLowerCase().indexOf(search.toLowerCase()) !==
                  -1) ||
              (row.division &&
                row.division &&
                row.division.name
                  .toLowerCase()
                  .indexOf(search.toLowerCase()) !== -1)
            );
          } else {
            return row;
          }
        });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleDeleteItem = () => {
    setLoading((prev) => !prev);

    deleteBudgetItem(getId)
      .then(() => {
        if (error) {
          setLoading(false);

          setFeed({
            loading: false,
            open: !feed.open,
            message: error && error.error,
            success: false,
          });
          handleCloseDelete();
        } else {
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            message: `Budget Item with ID: ${getId} Deleted.`,
            success: true,
          });
          handleCloseDelete();
          dispatch(getAllBudgetItems);
        }
      })
      .catch(() => {
        setFeed({
          loading: false,
          open: !feed.open,
          message: error && error.error,
          success: false,
        });
        handleCloseDelete();
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (Array(1000).length || filteredRows().length) - page * rowsPerPage
    );

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      )}
      {/* Modals Starts here */}
      <ItemModal open={open} handleClose={handleClose} />

      <EditItemModal
        openEdit={openEdit}
        handleCloseEdit={handleCloseEdit}
        id={getId}
      />
      <DeleteItemModal
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        loading={loading}
        handleDeleteItem={handleDeleteItem}
      />

      {/* Modals Ends here */}
      <Typography variant="overline" gutterBottom className={classes.title}>
        Budget Category
      </Typography>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={3}
        className={classes.marginBottom}
      >
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            style={{ marginRight: 5 }}
            onClick={handleOpenModal}
            className={classes.createBtn}
          >
            Create
          </Button>
        </Grid>
        <Grid item>
          <SearchComponent
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
      </Grid>

      <Paper>
        <Grid container justify="space-between" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <IconButton style={{ marginRight: 10, marginTop: 10 }}>
              <FilterListIcon />
            </IconButton>
          </Grid>
        </Grid>

        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>SubHead</TableCell>
                <TableCell>Division</TableCell>

                <TableCell>Edit</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows()
              ).map((row) => {
                return (
                  <TableRow hover key={row._id}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.subHead && row.subHead.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.division ? (
                        row.division.name
                      ) : (
                        <Chip label="All" disabled />
                      )}
                    </TableCell>

                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleEditOpen(row._id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => handleOpenDelete(row._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}

              {filteredRows().length === 0 && (
                <TableRow hover>
                  <TableCell
                    colSpan={5}
                    align="center"
                    style={{ color: "#616161" }}
                  >
                    No Records To Display
                  </TableCell>
                </TableRow>
              )}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const DeleteItemModal = ({
  openDelete,
  handleCloseDelete,
  loading,
  handleDeleteItem,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Typography variant="overline" className={classes.title3}>
                Delete
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseDelete}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="overline" className={classes.title3}>
              Warning: By clicking Delete, the data will be removed and won't be
              restored except recreated.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button
              autoFocus
              onClick={handleCloseDelete}
              className={classes.saveBtn}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteItem}
              color="secondary"
              autoFocus
              variant="contained"
              disabled={loading}
              className={classes.saveBtn}
              endIcon={
                loading ? (
                  <CircularProgress color="secondary" size={20} />
                ) : null
              }
            >
              {loading ? "Loading..." : "Delete"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  items: state.budgetItems.items.data,
  error: state.budgetItems.error,
});

export default connect(mapStateToProps, {
  getAllBudgetItems,
  deleteBudgetItem,
})(BudgetItem);
