import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  makeStyles,
  darken,
  Grid,
  Paper,
  Divider,
  Backdrop,
  CircularProgress,
  ButtonGroup,
  TextField,
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AssignmentIcon from "@material-ui/icons/Assignment";
// import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useHistory, useParams } from "react-router-dom";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  getLead,
  updateLead,
  // updateLeadStage,
} from "../../../actions/leadsAction";
import Feedback from "../../../Feedback";
import { getCustomers } from "../../../actions/customerActions";
import { getContactPersonByCustomer } from "../../../actions/contactPersonActions";
// import CreateSalesCallModal from "../Modals/Crm/CreateSalesCallModal";
import LeadHistoryModal from "../Modals/Crm/LeadHistoryModal";
import CreateContactModal from "../Modals/Sales/CreateContactModal";
import UpdateLeadStageModal from "../Modals/Crm/UpdateLeadStageModal";
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },

  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  paper3: {
    padding: 20,
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  divider: {
    marginTop: 30,
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  divider3: {
    marginTop: 10,
    marginBottom: 10,
  },
  submitBtn: {
    marginLeft: 10,
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,

    // padding: theme.spacing(2),
    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  status: {
    width: "fit-content",
    color: "#979292",
    padding: 6,
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  danger: {
    color: "#ef5350",
    fontSize: 13,
    // marginTop: -25,
    display: "block",
  },
  info: {
    fontWeight: 500,
    fontSize: 13,
  },
  textGreen: {
    color: "#388e3c",
    fontSize: 15,
  },
  docs: {
    padding: theme.spacing(3),
    overflow: "hidden",
  },
  link: {},
  createBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  backBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  statusGreen: {
    // background: "#00e676",

    borderColor: "#1de9b6",
    // color: "#fff",
  },
  statusYellow: {
    // background: "#fbc02d",

    borderColor: "#fbc02d",
  },
  statusOrange: {
    borderColor: darken("#ff9800", 0.1),
  },
  statusLemon: {
    borderColor: "#ffd600",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusRed: {
    // background: "#ef5350",

    borderColor: "#ef5350",
  },
  mt20: {
    marginTop: 10,
  },
  btnSuspect: {
    "&:hover": {
      // background: darken("#bdbdbd", 0.2),
      background: darken("#ff9800", 0.2),
    },
  },
  btnSuspectActive: {
    // background: "#bdbdbd",
    background: "#ff9800",
  },
  btnProspect: {
    "&:hover": {
      background: darken("#fbc02d", 0.2),
    },
  },
  btnProspectActive: {
    background: "#fbc02d",
  },
  btnOpportunity: {
    "&:hover": {
      background: darken("#ffd600", 0.2),
    },
  },
  btnOpportunityActive: {
    background: "#ffd600",
  },
  btnWon: {
    "&:hover": {
      background: darken("#4caf50", 0.2),
    },
  },
  btnWonActive: {
    background: "#4caf50",
  },
  btnLost: {
    "&:hover": {
      background: darken("#ef5350", 0.2),
    },
  },
  btnLostActive: {
    background: "#ef5350",
  },
  small: {
    display: "block",
    fontSize: 10,
    letterSpacing: 1.5,
  },
}));

const INIT_STATE = {
  title: "",
  address: "",
  website: "",
  leadType: "",
  email: "",
  designation: "",
  phone: "",
  lineOfBusiness: "",
  relationship: "",
  yearsInBusiness: "",
  lastContactDate: "",
  nextContactDate: "",
  comment:"",
};

function ViewLead() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const theme = useTheme();
  const [openUpdate, setOpenUpdate] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  // const [open, setOpen] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [openCreateContact, setOpenCreateContact] = useState(false);
  const [state, setState] = useState(INIT_STATE);
  const [newCustomer, setNewCustomer] = useState(null);
  const [newContactPerson, setNewContactPerson] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [inputValueTwo, setInputValueTwo] = useState("");
  const [value, setValue] = useState(null);

  const { loading, error, lead, success } = useSelector((state) => state.leads);
  const { profile } = useSelector((state) => state.user);
  const { customers } = useSelector((state) => state.customer);
  const { contactPersons, loading: load } = useSelector(
    (state) => state.contactPerson
  );

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  useEffect(() => {
    const customer = customers
      ?.map((customer) => customer)
      .filter((item) => item._id === lead?.customer?._id);
    const contact = contactPersons.filter(
      (item) => item._id === lead?.contactPerson?._id
    );
    setState((prev) => ({
      ...prev,
      title: lead?.title,
      lineOfBusiness: lead?.lineOfBusiness,
      relationship: lead?.relationship,
      yearsInBusiness: lead?.yearsInBusiness,
      lastContactDate: lead?.lastContactDate || "",
      nextContactDate: lead?.nextContactDate || "",
      comment: lead?.comment || "",
    }));

    setNewCustomer((prev) => prev || customer[0]);
    setNewContactPerson((prev) => prev || contact[0]);
  }, [lead, customers, contactPersons]);

  useEffect(() => {
    let abortController = new AbortController();

    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
      dispatch({
        type: "RESET_LEADS",
      });
    };
  }, [error, dispatch]);

  useEffect(() => {
    let abortController = new AbortController();

    if (success) {
      dispatch(getLead(id));

      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: "Lead Updated Successfully.",
        success: true,
      }));
    }
    return () => {
      abortController.abort();
      dispatch({
        type: "RESET_LEADS",
      });
    };
  }, [success, dispatch, id]);

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getLead(id));
  }, [dispatch, id]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      website: inputValue ? newCustomer?.website : "",
      address: inputValue ? newCustomer?.address : "",
    }));
  }, [newCustomer, inputValue]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      email: inputValueTwo ? newContactPerson?.email : "",
      designation: inputValueTwo ? newContactPerson?.designation : "",
      phone: inputValueTwo ? newContactPerson?.phone : "",
    }));
  }, [newContactPerson, inputValueTwo]);

  useEffect(() => {
    if (newCustomer) {
      dispatch(getContactPersonByCustomer(newCustomer?._id));
    }
  }, [dispatch, newCustomer]);

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  
  const handleChangeLastContactDate = (date) => {
    setState((prev) => ({
      ...prev,
      lastContactDate: date
    }));
  };

  const handleChangeNextContactDate = (date) => {
    setState((prev) => ({
      ...prev,
      nextContactDate: date
    }));
  };

  // const handleUpdateLeadStage = (stage) => {
  //   let data = {
  //     stage,
  //     id,
  //     date: new Date(),
  //   };
  //   dispatch(updateLeadStage(data));
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      title: state.title,
      lineOfBusiness: state.lineOfBusiness,
      relationship: state.relationship,
      yearsInBusiness: state.yearsInBusiness,
      lastContactDate: state.lastContactDate,
      nextContactDate: state.nextContactDate,
      comment: state.comment,
      id,
      contactPerson: newContactPerson?._id,
      date: new Date(),
    };
    dispatch(updateLead(data));
  };

  // const handleOpenModal = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleOpenHistoryModal = () => {
    setOpenHistory(true);
  };

  const handleCloseHistory = () => {
    setOpenHistory(false);
  };

  const handleCreateOpenContact = () => {
    setOpenCreateContact(true);
  };
  const handleCreateCloseContact = () => {
    setOpenCreateContact(false);
  };

  const handleOpenUpdate = (value) => {
    setValue(value);
    setOpenUpdate(true);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  return (
    <div>
      {/* <CreateSalesCallModal {...{ open, handleClose }} /> */}
      <LeadHistoryModal {...{ openHistory, handleCloseHistory }} />
      <UpdateLeadStageModal
        {...{
          open: openUpdate,
          handleClose: handleCloseUpdate,
          value,
          id,
          loading,
        }}
      />
      <CreateContactModal
        {...{
          openCreate: openCreateContact,
          handleCreateClose: handleCreateCloseContact,
        }}
      />
      <MuiPickersUtilsProvider utils={MomentUtils}>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Typography variant="overline" className={classes.title} gutterBottom>
        {lead?.leadType?.toLowerCase() === "won" ||
          lead?.leadType?.toLowerCase() === "lost"
          ? "View"
          : "Update"}{" "}
        Lead
      </Typography>
      <Grid container justify="space-between" alignItems="center" spacing={3}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.goBack()}
            startIcon={<ArrowBackIosIcon />}
            className={classes.createBtn}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Paper
            variant="outlined"
            className={clsx(
              {
                [classes.statusOrange]:
                  lead?.leadType?.toLowerCase() === "suspect",
                [classes.statusYellow]:
                  lead?.leadType?.toLowerCase() === "prospect",
                [classes.statusLemon]:
                  lead?.leadType?.toLowerCase() === "opportunity",
                [classes.statusDarkGreen]:
                  lead?.leadType?.toLowerCase() === "won",
                [classes.statusRed]: lead?.leadType?.toLowerCase() === "lost",
              },
              classes.status
            )}
          >
            <Typography variant="overline" className={classes.statusTitle}>
              Stage
            </Typography>
            <Divider className={classes.divider2} orientation="vertical" />
            {!lead ? "Loading..." : lead?.leadType?.toUpperCase()}
          </Paper>
        </Grid>
      </Grid>

      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.mt20}
      >
        <Grid item></Grid>
        <Grid item>
          <Paper variant="outlined" className={classes.status}>
            <Typography variant="overline" className={classes.statusTitle}>
              Doc. No.
            </Typography>
            <Divider className={classes.divider2} orientation="vertical" />
            {!lead ? "Loading..." : lead?.documentNo}
          </Paper>
        </Grid>
      </Grid>

      <Paper className={clsx(classes.paper, classes.mt20)}>
        <Grid
          container
          justify="space-between"
          alignItems="flex-start"
          style={{ marginBottom: 30 }}
        >
          <Grid item>
            <>
              <Typography className={classes.small} variant="overline">
                Lead Stages
              </Typography>
              <ButtonGroup
                orientation={matches ? "vertical" : "horizontal"}
                fullWidth={matches ? true : false}
              >
                <Button
                  className={clsx(
                    {
                      [classes.btnSuspectActive]:
                        lead?.leadType?.toLowerCase() === "suspect",
                    },
                    classes.btnSuspect
                  )}
                  // onClick={() => handleUpdateLeadStage("Suspect")}
                  onClick={() => handleOpenUpdate("Suspect")}
                >
                  Suspect
                </Button>
                <Button
                  className={clsx(
                    {
                      [classes.btnProspectActive]:
                        lead?.leadType?.toLowerCase() === "prospect",
                    },
                    classes.btnProspect
                  )}
                  // onClick={() => handleUpdateLeadStage("Prospect")}
                  onClick={() => handleOpenUpdate("Prospect")}
                >
                  Prospect
                </Button>
                <Button
                  className={clsx(
                    {
                      [classes.btnOpportunityActive]:
                        lead?.leadType?.toLowerCase() === "opportunity",
                    },
                    classes.btnOpportunity
                  )}
                  // onClick={() => handleUpdateLeadStage("Opportunity")}
                  onClick={() => handleOpenUpdate("Opportunity")}
                >
                  Opportunity
                </Button>
                <Button
                  className={clsx(
                    {
                      [classes.btnWonActive]:
                        lead?.leadType?.toLowerCase() === "won",
                    },
                    classes.btnWon
                  )}
                  // onClick={() => handleUpdateLeadStage("Won")}
                  onClick={() => handleOpenUpdate("Won")}
                >
                  Won
                </Button>
                <Button
                  className={clsx(
                    {
                      [classes.btnLostActive]:
                        lead?.leadType?.toLowerCase() === "lost",
                    },
                    classes.btnLost
                  )}
                  // onClick={() => handleUpdateLeadStage("Lost")}
                  onClick={() => handleOpenUpdate("Lost")}
                >
                  Lost
                </Button>
              </ButtonGroup>
            </>
          </Grid>
          <Grid item>
            <>
              <Typography className={classes.small} variant="overline">
                {/* &nbsp;&nbsp;Calls &nbsp;| History */}
                &nbsp;&nbsp; History
              </Typography>
              <ButtonGroup
                orientation={matches ? "vertical" : "horizontal"}
                fullWidth={matches ? true : false}
              >
                {/* <Button
                  disabled={
                    lead?.leadType?.toLowerCase() === "won" ||
                    lead?.leadType?.toLowerCase() === "lost"
                  }
                  onClick={handleOpenModal}
                >
                  <PhoneInTalkIcon />
                </Button> */}

                <Button onClick={handleOpenHistoryModal}>
                  <AssignmentIcon />
                </Button>
              </ButtonGroup>
            </>
          </Grid>
        </Grid>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} justify="center" alignItems="center">
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined" required>
                  <InputLabel>Lead Source</InputLabel>
                  <Select
                    name="title"
                    inputProps={{
                      id: "title",
                    }}
                    value={state.title || ""}
                    onChange={handleChange}
                  >
                    <MenuItem value="" disabled={lead?.leadType?.toLowerCase() === ("won" || "lost")}>
                      <em>--- Select ---</em>
                    </MenuItem>
                    <MenuItem value="Return Customer">Return Customer</MenuItem>
                    <MenuItem value="Customer Inquiry">Customer Inquiry</MenuItem>
                    <MenuItem value="Website Traffic">Website Traffic</MenuItem>
                    <MenuItem value="Referrals">Referrals</MenuItem>
                    <MenuItem value="Sales Call">Sales Call</MenuItem>
                    <MenuItem value="Event Networking">Event Networking</MenuItem>
                  </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                disabled
                value={newCustomer || null}
                debug
                onChange={(event, newValue) => {
                  setNewCustomer(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                getOptionLabel={(option) => option.name}
                options={customers}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                renderOption={(option) => (
                  <React.Fragment>{option.name}</React.Fragment>
                )}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      placeholder="Type something..."
                      fullWidth
                      required
                      label="Select Customer"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-customer", // disable autocomplete and autofill
                      }}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="lineOfBusiness"
                name="lineOfBusiness"
                label="Line of Business"
                value={state.lineOfBusiness || ""}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                required
                disabled={lead?.leadType?.toLowerCase() === ("won" || "lost")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined" required>
                  <InputLabel>Relationship Demographic</InputLabel>
                  <Select
                    name="relationship"
                    inputProps={{
                      id: "relationship",
                    }}
                    value={state.relationship || ""}
                    onChange={handleChange}
                  >
                    <MenuItem value="" disabled={lead?.leadType?.toLowerCase() === ("won" || "lost")}>
                      <em>--- Select ---</em>
                    </MenuItem>
                    <MenuItem value="Topsides Services">Topsides Services</MenuItem>
                    <MenuItem value="Subsea Services">Subsea Services</MenuItem>
                    <MenuItem value="Smart Manufacturing Services">Smart Manufacturing Services</MenuItem>
                    <MenuItem value="Operations and Production Services">Operations and Production Services</MenuItem>
                    <MenuItem value="UAV Services">UAV Services</MenuItem>
                    <MenuItem value="Sustainable Energy Services">Sustainable Energy Services</MenuItem>
                  </Select>
                </FormControl>
              {/* <TextField
                id="relationship"
                name="relationship"
                label="Relationship Demographic"
                value={state.relationship || ""}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                required
                disabled={lead?.leadType?.toLowerCase() === ("won" || "lost")}
              /> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel>Years In Business</InputLabel>
                <Select
                  name="yearsInBusiness"
                  inputProps={{
                    id: "yearsInBusiness",
                  }}
                  value={state.yearsInBusiness || ""}
                  onChange={handleChange}
                  disabled={lead?.leadType?.toLowerCase() === ("won" || "lost")}
                >
                  <MenuItem value="" disabled>
                    <em>--- Select ---</em>
                  </MenuItem>
                  <MenuItem value="0-5 Years">0-5 Years</MenuItem>
                  <MenuItem value="5-10 Years">5-10 Years</MenuItem>
                  <MenuItem value="Above 10 Years">Above 10 Years</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="website"
                name="website"
                label="Website"
                value={state.website || ""}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                required
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="address"
                name="address"
                label="Customer Address"
                value={state.address || ""}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                required
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                value={newContactPerson || null}
                debug
                onChange={(event, newValue) => {
                  setNewContactPerson(newValue);
                }}
                inputValue={inputValueTwo}
                onInputChange={(event, newInputValue) => {
                  setInputValueTwo(newInputValue);
                }}
                getOptionLabel={(option) => option.name}
                options={contactPersons}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                renderOption={(option) => (
                  <React.Fragment>{option.name}</React.Fragment>
                )}
                noOptionsText={
                  <>
                    {inputValueTwo.length > 0 ? (
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={handleCreateOpenContact}
                        style={{ zIndex: 9999 }}
                      >
                        Create Contact Person
                      </Button>
                    ) : (
                      "No Result"
                    )}
                  </>
                }
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      fullWidth
                      placeholder="Type something..."
                      required
                      label="Select Contact Person"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-contact-person", // disable autocomplete and autofill
                      }}
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="email"
                type="email"
                name="email"
                label="Contact Email"
                value={state.email || ""}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                required
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="designation"
                name="designation"
                label="Designation"
                value={state.designation || ""}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                required
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="phone"
                type="tel"
                name="phone"
                label="Contact Phone"
                value={state.phone || ""}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                required
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
                <DatePicker
                  className={classes.marginBottom}
                  autoOk
                  format="DD/MM/yyyy"
                  views={["year", "month", "date"]}
                  label="Last Contact Date"
                  fullWidth
                  required
                  inputVariant="outlined"
                  value={state.lastContactDate || ""}
                  onChange={handleChangeLastContactDate}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  format="DD/MM/yyyy"
                  views={["year", "month", "date"]}
                  label="Next Contact Date"
                  fullWidth
                  required
                  inputVariant="outlined"
                  value={state.nextContactDate || ""}
                  onChange={handleChangeNextContactDate}
                />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="comment"
                  type="text"
                  multiline
                  rows={3}
                  name="comment"
                  label="Comment and Notes"
                  value={state.comment || ""}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
          </Grid>
          {lead?.leadType?.toLowerCase() === "won" ||
            lead?.leadType?.toLowerCase() === "lost" ||
            profile?.id !== lead?.createdBy?._id ? null : (
            <Grid container justify="center" alignItems="center">
              <Button
                type="submit"
                variant="contained"
                fullWidth
                color="primary"
                className={classes.saveBtn}
                disabled={loading || load || !state.title}
                endIcon={
                  loading ? (
                    <CircularProgress size={20} color="primary" />
                  ) : null
                }
              >
                {loading ? "Loading..." : "Update"}
              </Button>
            </Grid>
          )}
        </form>
      </Paper>
      </MuiPickersUtilsProvider>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default ViewLead;
