import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { connect, useDispatch } from "react-redux";
import {
  ButtonGroup,
  TextField,
  makeStyles,
  Typography,
  Grid,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../../Feedback";
import {
  rejectCashAdvance,
  getCashAdvanceById,
} from "../../../../actions/cashAdvanceAction";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textField: {
    marginBottom: 20,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RejectCashAdvance = ({
  openReject,
  handleCloseReject,
  id,
  rejectCashAdvance,
  getCashAdvanceById,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [reason, setReason] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleChange = (e) => {
    setReason(e.target.value);
  };

  const handleDecline = () => {
    setLoading((prev) => !prev);
    const data = {
      comment: reason,
      id,
      path: "cashadvance",
    };
    rejectCashAdvance(data)
      .then((doc) => {
        if (!doc.success) {
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            message: `An error has occurred, please contact admin. Failed to reject budget.`,
            success: false,
          });
        } else {
          setLoading(false);
          setFeed({
            loading: false,
            open: !feed.open,
            message: `You have successfully rejected this cash advance, an email has been sent to the initiator for modification.`,
            success: true,
          });
          dispatch(getCashAdvanceById(id));
          handleCloseReject();
        }
      })
      .catch(() => {
        console.error(`An error has occurred. Contact admin`);
        // setLoading(false);
        // setFeed({
        //   loading: false,
        //   open: !feed.open,
        //   message: `An error has occurred, please contact admin.`,
        //   success: false,
        // });
        // handleCloseReject();
      })
      .finally(() => {
        handleCloseReject();
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openReject}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseReject}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                {"Reject Cash Advance?"}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseReject}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          {/* <DialogContentText id="alert-dialog-slide-description" color="error"> */}
          <Typography variant="overline" className={classes.title3}>
            Note: By Clicking Reject, it means you're unsatisfied with this Cash
            Advance.
          </Typography>
          {/* </DialogContentText> */}
          <TextField
            label="Comment"
            className={classes.textField}
            variant="outlined"
            fullWidth
            value={reason}
            onChange={handleChange}
            helperText="State your reason for Rejecting."
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button
              onClick={handleCloseReject}
              color="secondary"
              className={classes.saveBtn}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDecline}
              color="primary"
              disabled={!reason || loading}
              className={classes.saveBtn}
              variant="contained"
              endIcon={
                loading ? <CircularProgress color="primary" size={20} /> : null
              }
            >
              {loading ? "Loading..." : "Reject"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapDispatchToProps = {
  rejectCashAdvance,
  getCashAdvanceById,
};

export default connect(null, mapDispatchToProps)(RejectCashAdvance);
