import React from "react";
import { connect, useDispatch } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import FilterListIcon from "@material-ui/icons/FilterList";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SearchComponent from "../../SearchComponent";
import {
  makeStyles,
  Paper,
  IconButton,
  Grid,
  InputAdornment,
  TextField,
  Divider,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  useTheme,
  Chip,
  Typography,
  ButtonGroup,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import CircularProgress from "@material-ui/core/CircularProgress";

import { formatAmount, formatCurrency } from "../../../utils";

import {
  getAllAnnualBudgetLineByBudgetId,
  deleteAnnualBudgetLine,
} from "../../../actions/annualAction";
import { useParams } from "react-router-dom";
import Feedback from "../../../Feedback";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { TablePaginationActions } from "../../TablePaginationActions";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  textField: {
    marginTop: 30,
    marginBottom: 30,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  marginBottom: {
    marginBottom: 30,
  },
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    // marginBottom: 20,
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    margin: "30px 0",
    padding: theme.spacing(2),
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const AnnualBudgetLineTable = ({
  getAllAnnualBudgetLineByBudgetId,
  annualBudgetLines,
  deleteAnnualBudgetLine,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [search, setSearch] = React.useState("");
  const [total, setTotal] = React.useState(0);
  const [lines, setLines] = React.useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [getId, setGetId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const { id } = useParams();

  const budgetLineCallback = React.useCallback(() => {
    setOpenBackdrop((prev) => !prev);
    getAllAnnualBudgetLineByBudgetId(id).then(() => {
      // if (!mountedRef.current) return null;
      setOpenBackdrop(false);
    });
  }, [id, getAllAnnualBudgetLineByBudgetId]);

  React.useEffect(() => {
    budgetLineCallback();
    return () => {
      // mountedRef.current = false;
      setOpenBackdrop(false);
    };
  }, [budgetLineCallback]);

  React.useEffect(() => {
    const abortController = new AbortController();
    let total = lines ? lines.reduce((acc, cur) => acc + +cur.subTotal, 0) : 0;
    total = formatAmount(total);
    setTotal(total);
    return () => {
      return abortController.abort();
    };
  }, [lines]);

  React.useEffect(() => {
    const abortController = new AbortController();
    setLines(annualBudgetLines === undefined ? [] : annualBudgetLines);
    return () => {
      abortController.abort();
    };
  }, [annualBudgetLines]);

  const updateSearch = (e) => {
    setSearch(e.target.value.substr(0, 20));
  };

  const ResetSearch = (e) => {
    e.preventDefault();
    setSearch("");
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDelete = (id) => {
    setGetId(id);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const navigateById = (path, id) => {
    history.push(`${path}/${id}`);
  };

  console.log(lines)

  const filteredRows = () =>
    lines === undefined
      ? lines
      : lines &&
        lines.filter((row) => {
          if (search !== "") {
            return (
              row.year
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1 ||
              row.employee.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
              (row.item &&
                row.item.name.toLowerCase().indexOf(search.toLowerCase()) !==
                  -1) ||
              (row.head &&
                row.head.name.toLowerCase().indexOf(search.toLowerCase()) !==
                  -1) ||
              (row.subHead &&
                row.subHead.name.toLowerCase().indexOf(search.toLowerCase()) !==
                  -1) ||
              row.subTotal
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1 ||
              row.description.toLowerCase().indexOf(search.toLowerCase()) !== -1
            );
          } else {
            return row;
          }
        });
  const handleDeleteBudgetLine = () => {
    setLoading((prev) => !prev);
    deleteAnnualBudgetLine(getId)
      .then(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message: `Budget Line Deleted Succesfully`,
          success: true,
        });
        dispatch(getAllAnnualBudgetLineByBudgetId(id));
        handleCloseDelete();
      })
      .catch((err) => {
        setLoading(false);
        handleCloseDelete();

        // setFeed({
        //   loading: false,
        //   open: !feed.open,
        //   message: `Failed to delete Budget Line`,
        //   success: false,
        // });
        // console.error("Failed to Load Budget Lines");
        // handleCloseDelete();
      });
  };

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      (Array(1000).length || filteredRows().length) - page * rowsPerPage
    );

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Grid
        container
        justify="space-between"
        alignItems="center"
        spacing={3}
        className={classes.marginBottom}
      >
        <Grid item></Grid>
        <Grid item>
          <SearchComponent
            updateSearch={updateSearch}
            placeholder="Search..."
            search={search}
            ResetSearch={ResetSearch}
          />
        </Grid>
      </Grid>
      <Paper>
        <Grid container justify="space-between" alignItems="center">
          <Grid item></Grid>
          <Grid item>
            <IconButton style={{ marginRight: 10, marginTop: 10 }}>
              <FilterListIcon />
            </IconButton>
          </Grid>
        </Grid>

        <TableContainer>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell>Expense Head</TableCell>
                <TableCell>Expense SubHead</TableCell>
                <TableCell>Budget Category</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>SubType</TableCell>

                <TableCell>SubTotal</TableCell>
                <TableCell>Edit</TableCell>

                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows().slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows()
              ).map((row) => {
                return (
                  <TableRow hover key={row._id}>
                    <TableCell component="th" scope="row">
                      {row.head && row.head.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.subHead && row.subHead.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.item ? row.item.name : <Chip label="none" />}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.description}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.subType && row.subType} 
                      {/* //: <Chip label="default" /> */}
                    </TableCell>

                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        color:
                          row.subTotal <= 200000
                            ? theme.palette.warning.main
                            : theme.palette.success.main,
                      }}
                    >
                      {row.subTotal
                        ? formatCurrency(row.subTotal, "en-NG", "NGN")
                        : formatCurrency(0, "en-NG", "NGN")}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          navigateById(
                            "/budget/annual/budgetline/edit/form",
                            row._id
                          )
                        }
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>

                    <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => handleOpenDelete(row._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={filteredRows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      <Grid container justify="space-between" alignItems="center">
        <Grid item></Grid>
        <Grid item>
          <TextField
            label="Total"
            variant="outlined"
            disabled
            className={classes.textField}
            value={total}
            InputProps={{
              startAdornment: (
                <>
                  <InputAdornment position="start">&#8358;</InputAdornment>
                  <Divider className={classes.divider} orientation="vertical" />
                </>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DeleteBugdetLineModal
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        handleDeleteBudgetLine={handleDeleteBudgetLine}
        loading={loading}
      />
    </div>
  );
};

const DeleteBugdetLineModal = ({
  openDelete,
  handleCloseDelete,
  loading,
  handleDeleteBudgetLine,
}) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Delete
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseDelete}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="overline" className={classes.title3}>
              Warning: By clicking Delete, the data will be removed and won't be
              restored except recreated.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button
              autoFocus
              onClick={handleCloseDelete}
              className={classes.saveBtn}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteBudgetLine}
              color="secondary"
              autoFocus
              variant="contained"
              disabled={loading}
              className={classes.saveBtn}
              endIcon={
                loading ? (
                  <CircularProgress size={20} color="secondary" />
                ) : null
              }
            >
              {loading ? "Loading..." : "Delete"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  annualBudgetLines: state.annualBudget.annualBudgetLines,
});

const mapDispatchToProps = {
  getAllAnnualBudgetLineByBudgetId,
  deleteAnnualBudgetLine,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnualBudgetLineTable);
