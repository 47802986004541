import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { connect, useDispatch } from "react-redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";

import Feedback from "../../../../Feedback";

import moment from "moment";
import {
  createAnnualBudget,
  getAnnualBudgets,
} from "../../../../actions/annualAction";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },
  btn: {
    margin: "30px 0",
    width: 300,
    letterSpacing: 2.5,
    padding: theme.spacing(2),
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down("sm")]: {
      width: 200,
      // minWidth: "100%",
    },
  },
}));

const INIT_STATE = {
  employeeName: "",
  division: "",
  jobTitle: "",
  year: "",
};

const AnnualModal = ({
  open,
  handleClose,

  createAnnualBudget,

  getAnnualBudgets,

  error,

  profile,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState(INIT_STATE);
  const [loading, setLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  useEffect(() => {
    if (profile) {
      setState((prev) => ({
        ...prev,
        employeeName: profile.userName,
        division: profile.division && profile.division.name,
        jobTitle: profile.jobTitle?.name,
        year: new Date(),
      }));
    }
  }, [profile]);

  const handleChange = (e) => {
    // e.persist();
    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleChangeDate = (date) => {
    setSelectedDate(date);
  };

  const navigateById = (path, id) => {
    history.push(`${path}/${id}`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    const data = {
      year: +moment(selectedDate).format("YYYY"),
    };

    createAnnualBudget(data)
      .then((doc) => {
        if (doc) {
          setLoading(false);
          setState((prev) => ({
            ...prev,
            INIT_STATE,
          }));
          setFeed((prev) => ({
            ...prev,
            loading: false,
            open: !feed.open,
            message: `Budget Item created successfully`,
            success: true,
          }));

          dispatch(getAnnualBudgets());
          handleClose();
          setTimeout(() => {
            navigateById("/budget/annual/budgetline", doc.data._id);
          }, 1500);
        } else {
          setLoading(false);
          handleClose();
          setFeed((prev) => ({
            ...prev,
            loading: false,
            open: !feed.open,
            message: error && error.error,
            success: false,
          }));
        }
      })
      .catch(() => {
        setLoading(false);
        handleClose();
        setFeed((prev) => ({
          ...prev,
          loading: false,
          open: !feed.open,
          message: error && error.error,
          success: false,
        }));
      });
  };
  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const validCheck = () => {
    return !selectedDate || !state.division;
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div>
        {feed.success ? (
          <Feedback
            handleCloseFeed={handleCloseFeed}
            open={feed.open}
            severity="success"
            message={feed.message}
          />
        ) : (
          <Feedback
            handleCloseFeed={handleCloseFeed}
            open={feed.open}
            severity="error"
            message={feed.message || (error && error.error)}
          />
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography variant="overline" className={classes.title}>
                  Annual Budget
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3} justify="center" alignItems="center">
                <Grid item xs={12} md={6}>
                  <TextField
                    id="employeeName"
                    name="employeeName"
                    label="Employee Name"
                    value={state.employeeName || ""}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    required
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="division"
                    name="division"
                    label="Division"
                    value={state.division || ""}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    required
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="jobTitle"
                    name="jobTitle"
                    label="Job Title"
                    value={state.jobTitle || ""}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    required
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    autoOk
                    views={["year"]}
                    label="Year"
                    value={selectedDate}
                    name="year"
                    minDate={new Date()}
                    onChange={handleChangeDate}
                    fullWidth
                    inputVariant="outlined"
                    disablePast
                    animateYearScrolling={true}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    className={classes.btn}
                    disabled={validCheck() || loading}
                  >
                    {loading ? "Loading..." : "Create"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    </MuiPickersUtilsProvider>
  );
};

const mapStateToProps = (state) => ({
  error: state.annualBudget.error,
  subheads: state.budgetSubheads.subheads.data,
  divisions: state.division.divisions.data,
  profile: state.user.profile,
});

export default connect(mapStateToProps, {
  createAnnualBudget,

  getAnnualBudgets,
})(AnnualModal);
