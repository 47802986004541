import React, { useState } from "react";
import {
  Typography,
  Button,
  makeStyles,
  Grid,
  TextField,
  Divider,
  InputAdornment,
  Tooltip,
  withStyles,
  Badge,
  Icon,
  darken,
  Paper,
} from "@material-ui/core";

import { useHistory, useParams } from "react-router-dom";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AnnualBudgetLineTable from "./AnnualBudgetLineTable";
import { getAllBudgetHeads } from "../../../actions/budgetHeadsActions";
import { getBudgetSubheadByHead } from "../../../actions/budgetSubheadAction";
import {
  getAllBudgetItems,
  getBudgetItemBySubheadAndDivision,
} from "../../../actions/budgetItemActions";
import { connect } from "react-redux";
import clsx from "clsx";

import {
  createAnnualBudgetLine,
  getAllAnnualBudgetLineByBudgetId,
  getAnnualBudgetById,
} from "../../../actions/annualAction";
import SubmitBudgetLineModal from "../Modals/Budget/SubmitBudgetLineModal";
import CreateAnnualBudgetLineModal from "../Modals/Budget/CreateAnnualBudgetLineModal";
import SubmitDeclinedModal from "../Modals/Budget/SubmitDeclinedModal";
import SubmitModifyModal from "../Modals/Budget/SubmitModifyModal";
import SubmitRejectedModal from "../Modals/Budget/SubmitRejectedModal";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  textField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },

  action: {
    marginBottom: 30,
  },
  btn: {
    marginTop: 30,
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 300,
  },
  divider: {
    marginTop: 30,
    marginBottom: 30,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  floatingBtn: {
    transform: "translateY(0px)",
    animation: "$floating 1.2s infinite alternate",
  },

  "@keyframes floating": {
    "0%": {
      transform: "translateY(0px)",
    },

    "100%": {
      transform: "translateY(-10px)",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  paperText: {
    fontFamily: "Rubik",
    fontSize: 13,
    fontWeight: 500,
    color: "#fff",

    height: "fit-content",
    padding: theme.spacing(1) + 2,
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: "#0176ff",

    position: "relative",
    "&::after": {
      position: "absolute",
      content: "''",
      right: -5,
      top: "40%",
      width: 10,
      height: 10,

      backgroundColor: "#0176ff",

      transform: "rotate(45deg)",
    },
  },
  submitBtn: {
    margin: "30px 0",
    letterSpacing: 2.5,

    padding: theme.spacing(2),
    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
  },
  status: {
    width: 140,
    color: "#979292",
    padding: 6,
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusTitle: {
    color: "#b5b5b5",
    fontSize: "0.75rem",
  },
  statusDarkGreen: {
    borderColor: "#4caf50",
  },
  statusGreen: {
    borderColor: "#1de9b6",
  },
  statusYellow: {
    borderColor: "#fbc02d",
  },
  statusRed: {
    borderColor: "#ef5350",
  },
  statusOrange: {
    borderColor: "#ff9800",
  },
  textGreen: {
    color: "#388e3c",
    fontWeight: 900,
  },
  backBtn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const RippleEffect = withStyles((theme) => ({
  root: {
    backgroundColor: "#44b700",
    // backgroundColor: theme.palette.secondary.light,
    color: "#44b700",
    // color: theme.palette.secondary.light,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    marginLeft: -5,

    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const AnnualBudgetLine = ({
  getAnnualBudgetById,

  annualBudget,
  annualBudgetLines,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [openCreate, setOpenCreate] = useState(false);

  const { id } = useParams();

  const [openSubmit, setOpenSubmit] = React.useState(false);
  const [openDecline, setOpenDecline] = React.useState(false);
  const [openModify, setOpenModify] = React.useState(false);
  const [openRejected, setOpenRejected] = React.useState(false);

  const getAnnualBudgetByIdCallback = React.useCallback(() => {
    getAnnualBudgetById(id);
  }, [id, getAnnualBudgetById]);

  React.useEffect(() => {
    getAnnualBudgetByIdCallback();
  }, [getAnnualBudgetByIdCallback]);

  const handleCreateOpen = () => {
    setOpenCreate(true);
  };

  const handleCreateClose = () => {
    setOpenCreate(false);
  };

  const handleOpenSubmit = () => {
    setOpenSubmit(true);
  };

  const handleCloseSubmit = () => {
    setOpenSubmit(false);
  };

  const handleOpenDecline = () => {
    setOpenDecline(true);
  };

  const handleCloseDecline = () => {
    setOpenDecline(false);
  };

  const handleOpenModify = () => {
    setOpenModify(true);
  };

  const handleCloseModify = () => {
    setOpenModify(false);
  };

  const handleOpenRejected = () => {
    setOpenRejected(true);
  };

  const handleCloseRejected = () => {
    setOpenRejected(false);
  };

  return (
    <div>
      <SubmitDeclinedModal {...{ openDecline, handleCloseDecline, id }} />
      <SubmitModifyModal {...{ openModify, handleCloseModify, id }} />
      <SubmitRejectedModal {...{ openRejected, handleCloseRejected, id }} />
      <SubmitBudgetLineModal
        openSubmit={openSubmit}
        handleCloseSubmit={handleCloseSubmit}
        id={id}
      />
      <CreateAnnualBudgetLineModal
        openCreate={openCreate}
        handleCreateClose={handleCreateClose}
        id={id}
      />

      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 20 }}
        spacing={3}
      >
        <Grid item>
          <Typography variant="overline" className={classes.title}>
            Annual / Budget Line
          </Typography>
          <Button
            onClick={() => history.goBack()}
            variant="contained"
            color="primary"
            startIcon={<ArrowBackIosIcon />}
            id="scroll-to-top"
            className={classes.backBtn}
          >
            Back
          </Button>
        </Grid>

        <Grid item>
          <TextField
            variant="outlined"
            label="Document Number"
            value={(annualBudget && annualBudget.documentNo) || ""}
            disabled
          />
        </Grid>
      </Grid>

      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 20 }}
        spacing={3}
      >
        <Grid
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            variant="outlined"
            value={(annualBudget && annualBudget.year) || ""}
            disabled
            InputProps={{
              startAdornment: (
                <>
                  <InputAdornment position="start">Year</InputAdornment>
                  <Divider
                    className={classes.divider2}
                    orientation="vertical"
                  />
                </>
              ),
            }}
            style={{
              width: 150,
              // marginBottom: 10,
            }}
          />
        </Grid>
        <Grid item>
          <Paper
            variant="outlined"
            className={clsx(
              (annualBudget && annualBudget.status === "draft") ||
                (annualBudget && annualBudget.status === "toclarify") ||
                (annualBudget && annualBudget.status === "tomodify")
                ? classes.statusYellow
                : annualBudget && annualBudget.status === "submitted"
                ? classes.statusOrange
                : (annualBudget && annualBudget.status === "approved") ||
                  (annualBudget && annualBudget.status === "reviewed") ||
                  (annualBudget && annualBudget.status === "accepted")
                ? classes.statusGreen
                : annualBudget && annualBudget.status === "completed"
                ? classes.statusDarkGreen
                : classes.statusRed,
              classes.status
            )}
          >
            <Typography variant="overline" className={classes.statusTitle}>
              Status
            </Typography>
            <Divider className={classes.divider2} orientation="vertical" />
            {annualBudget &&
              annualBudget.status &&
              annualBudget.status.toUpperCase()}
          </Paper>
        </Grid>
      </Grid>
      <>
        <Tooltip
          arrow
          title="Click button to add a new budget line"
          placement="right"
        >
          <Button
            onClick={handleCreateOpen}
            variant="contained"
            color="primary"
            size="large"
            endIcon={<Icon className="fas fa-folder-plus" />}
          >
            Add Budget Line
          </Button>
        </Tooltip>
        <RippleEffect variant="dot" overlap="circle" />
      </>

      <div style={{ marginBottom: 30 }} />

      <AnnualBudgetLineTable />

      {annualBudget && annualBudget.status === "draft" && (
        <Grid container justify="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenSubmit}
              size="large"
              disabled={annualBudgetLines && annualBudgetLines.length < 1}
              className={classes.submitBtn}
            >
              Submit Budget
            </Button>
          </Grid>
        </Grid>
      )}

      {annualBudget && annualBudget.status === "declined" && (
        <Grid container justify="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenDecline}
              size="large"
              disabled={annualBudgetLines && annualBudgetLines.length < 1}
              className={classes.submitBtn}
            >
              Submit Budget
            </Button>
          </Grid>
        </Grid>
      )}

      {annualBudget && annualBudget.status === "rejected" && (
        <Grid container justify="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenRejected}
              size="large"
              disabled={annualBudgetLines && annualBudgetLines.length < 1}
              className={classes.submitBtn}
            >
              Submit Budget
            </Button>
          </Grid>
        </Grid>
      )}

      {annualBudget && annualBudget.status === "tomodify" && (
        <Grid container justify="center">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenModify}
              size="large"
              disabled={annualBudgetLines && annualBudgetLines.length < 1}
              className={classes.submitBtn}
            >
              Submit Budget
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  heads: state.budgetHeads.heads.data,
  subheads: state.budgetSubheads.subheads.data,
  items: state.budgetItems.items.data,
  profile: state.user.profile,
  // error: state.annualBudget.annualBudgetLineError,
  error: state.error.error,
  annualBudget: state.annualBudget.annualBudget.data,
  annualBudgetLines: state.annualBudget.annualBudgetLines,
});

export default connect(mapStateToProps, {
  getAllBudgetHeads,
  getBudgetItemBySubheadAndDivision,
  getBudgetSubheadByHead,
  getAllBudgetItems,
  createAnnualBudgetLine,
  getAllAnnualBudgetLineByBudgetId,
  getAnnualBudgetById,
})(AnnualBudgetLine);
