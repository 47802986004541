import React, { useEffect } from "react";
import { Document, Page, Text, StyleSheet, PDFViewer, } from "@react-pdf/renderer";
import { Typography, Paper, makeStyles, Grid, darken, Backdrop, } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerQuotation, markCustomerQuotation } from "../actions/quotationActions";

import PageOne from './Admin/Sales/Quotation/Doc/PageOne';
import PageTwo from './Admin/Sales/Quotation/Doc/PageTwo';
import PageThree from './Admin/Sales/Quotation/Doc/PageThree';

import logo from '../logo.png';

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 20,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold",
    },
    title2: {
        display: "block",
        fontSize: 16,
        fontFamily: "Rubik",
        textTransform: "none"
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(2),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    top_bg: {
        backgroundColor: "#001c41",
        padding: 20,

    },
    top_bg_img: {
        height: 40,
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    },
}));

const styles = StyleSheet.create({
    header: {
        fontSize: 10,
        marginBottom: 10,
        textAlign: 'center',
        color: 'grey',
        marginTop: 20,
        borderBottomWidth: 1,
        borderBottomColor: "#888",
        borderLeftStyle: "solid",

    },
    page: {
        backgroundColor: "#fff",
        color: "black",
        fontSize: 12,
        textAlign: "left",
        fontWeight: 400,
        lineHeight: 1.5,
    },
    viewer: {
        width: "100%",
        height: window.innerHeight,
    },
    logo: {
        width: 34,
        height: 36,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});

const QuotationDocument = ({ quote }) => {
    return (
        <PDFViewer style={styles.viewer}>
            <Document>
                <Page size="A4" style={styles.page} wrap>
                    <Text style={styles.header} fixed>
                        {quote && quote.title ? quote.title : null}
                    </Text>
                    <PageOne quote={quote} />
                    <PageTwo quote={quote} />
                    {quote && quote.noteToPricing ? <PageThree quote={quote} /> : null}
                </Page>
            </Document>
        </PDFViewer>
    );
}

const CustomerQuotation = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { error, loading, customerquotation } = useSelector((state) => state.quotation);

    useEffect(() => {
        dispatch(getCustomerQuotation(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (customerquotation && customerquotation.data.status !== "read") {
            dispatch(markCustomerQuotation(id));
        }
    }, [dispatch, id, customerquotation]);

    useEffect(() => {
        if (error && error !== null) {
            console.log(error)
        }
    }, [error]);

    return (
        <div>
            <>
                <Grid item className={classes.top_bg}>
                    <img src={logo} className={classes.top_bg_img} alt="RusselSmith Logo" />
                </Grid>
                <Paper style={{ padding: 40, margin: 25, marginLeft: 100, marginRight: 100 }}>

                    {(customerquotation && customerquotation.data !== null) ?
                        <>
                            <Typography variant="overline" className={classes.title}>
                                Dear <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
                                    {customerquotation.data.contactPerson.name}</span>{", "}
                            </Typography>

                            <Typography variant="overline" className={classes.title2}>
                                Please find below the quotation document for your attention.
                            </Typography>

                            {customerquotation && customerquotation.data.comment ?
                                <Typography variant="overline" className={classes.title2}>
                                    {customerquotation.data.comment}
                                </Typography> : null
                            }

                            <Grid item style={{ marginTop: 20 }}>
                                <QuotationDocument quote={customerquotation.data} />
                            </Grid>
                        </>
                        : <Typography variant="overline" className={classes.title2}>
                            {!loading ? "We cannot fetch the Quotation details at this moment. Kindly check back later." : "loading..."}
                        </Typography>
                    }

                    <Backdrop className={classes.backdrop} open={loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Paper>
            </>
        </div>
    );
};

export default CustomerQuotation;
