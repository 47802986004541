import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Typography,
    Paper,
    Grid,
    Backdrop,
    IconButton,
    Chip,
    Icon,
    Tooltip,
    Divider,
    InputAdornment,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import clsx from "clsx";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import SearchComponent from "../../SearchComponent";
import { TablePaginationActions } from "../../TablePaginationActions";
import { getTodaysVisitorAppointments } from "../../../actions/visitorsActions";
import { useDispatch, useSelector } from "react-redux";
import Feedback from "../../../Feedback";
import { useHistory } from "react-router-dom";
import { getAttendances } from "../../../actions/peoplesActions";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold"
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
    },

    action: {
        marginBottom: 30,
    },
    btn: {
        marginTop: 30,
        marginBottom: 30,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    table: {
        minWidth: 500,
    },
    chip: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    marginBottom: {
        marginBottom: 30,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    createBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
        alignItems: "left"
    },
}));

const EmergencyList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [search, setSearch] = useState("");
    const { attendances, loading, error } = useSelector((state) => state.peoples);
    const { todayvisitors } = useSelector((state) => state.visitors);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [page2, setPage2] = React.useState(0);
    const [rowsPerPage2, setRowsPerPage2] = React.useState(5);
    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getTodaysVisitorAppointments());
        dispatch(getAttendances());
    }, [dispatch]);

    const updateSearch = (e) => {
        setSearch(e.target.value.substr(0, 20));
    };
    const ResetSearch = (e) => {
        e.preventDefault();
        setSearch("");
    };

    let filteredRows = () =>
        todayvisitors === undefined
            ? []
            : todayvisitors &&
            todayvisitors.filter((row) => {
                if (search !== "") {
                    return (
                        (row.employee &&
                            row.employee.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                        (row.visitorName &&
                            row.visitorName.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                        (row.visitorPhone &&
                            row.visitorPhone.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                    );
                } else {
                    return row;
                }
            });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangeDate = (date) => {
        setSelectedDate(date);
    };
    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const emptyRows =
        rowsPerPage -
        Math.min(
            rowsPerPage,
            (Array(1000).length || filteredRows().length) - page * rowsPerPage
        );


    const navigateById = (path, id) => {
        history.push(`${path}/${id}`);
    };

    let filteredRows2 = () =>
        attendances === undefined
            ? []
            : attendances.filter((row) => {
                if (search !== "") {
                    return (
                        (row.employee &&
                            row.employee.userName
                                .toString()
                                .toLowerCase()
                                .indexOf(search.toLowerCase()) !== -1) ||
                        (row.division &&
                            row.division.name
                                .toString()
                                .toLowerCase()
                                .indexOf(search.toLowerCase()) !== -1) ||
                        (row.jobTitle &&
                            row.jobTitle?.name
                                ?.toLowerCase()
                                .indexOf(search.toLowerCase()) !== -1) ||
                        (row.location &&
                            row.location.toLowerCase().indexOf(search.toLowerCase()) !==
                            -1) ||
                        (row.date &&
                            row.date.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                        (row.week &&
                            row.week.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                        (row.timeIn &&
                            row.timeIn.toLowerCase().indexOf(search.toLowerCase()) !==
                            -1) ||
                        (row.timeOut &&
                            row.timeOut.toLowerCase().indexOf(search.toLowerCase()) !== -1)
                    );
                } else {
                    return row;
                }
            });

    let filteredDate2 = filteredRows2();

    filteredRows2 = () =>
        filteredDate2.filter((item) => {
            if (selectedDate !== null) {
                let date = moment(selectedDate, "LL").format("MMMM Do YYYY");
                return (
                    item.date.toString().toLowerCase().indexOf(date.toLowerCase()) !== -1
                );
            } else {
                return item;
            }
        });

    const handleChangePage2 = (event, newPage) => {
        setPage2(newPage);
    };

    const handleChangeRowsPerPage2 = (event) => {
        setRowsPerPage2(parseInt(event.target.value, 10));
        setPage2(0);
    };


    const emptyRows2 =
        rowsPerPage2 -
        Math.min(
            rowsPerPage2,
            (Array(10000).length || filteredRows2().length) - page2 * rowsPerPage2
        );

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div>
                {feed.success ? (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="success"
                        message={feed.message}
                    />
                ) : (
                    <Feedback
                        handleCloseFeed={handleCloseFeed}
                        open={feed.open}
                        severity="error"
                        message={feed.message}
                    />
                )}

                <Typography variant="overline" gutterBottom className={classes.title} style={{ marginBottom: 20 }}>
                    Emergency Head Count (Todays Visitors)
                </Typography>

                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    spacing={3}
                    className={classes.marginBottom}
                >
                    <Grid item>
                        <SearchComponent
                            updateSearch={updateSearch}
                            placeholder="Search..."
                            search={search}
                            ResetSearch={ResetSearch}
                        />
                    </Grid>

                    <Grid item>
                        <DatePicker
                            disableFuture
                            autoOk
                            format="MMMM Do yyyy"
                            views={["year", "month", "date"]}
                            label="Select Date"
                            // disablePast
                            // maxDate={new Date(moment().add(5, "years")._d)}
                            inputVariant="outlined"
                            value={selectedDate}
                            onChange={handleChangeDate}
                            helperText="Current date is set as default"
                            style={{ marginBottom: -20 }}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        <Divider
                                            className={classes.divider2}
                                            orientation="vertical"
                                        />
                                        <InputAdornment position="end">
                                            <IconButton
                                                disabled={selectedDate === null}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setSelectedDate(null);
                                                }}
                                            >
                                                {selectedDate ? <CloseIcon /> : <FilterListIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    </>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>

                <Paper style={{ marginBottom: 15 }}>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-label="custom pagination table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Div./Subdiv.</TableCell>
                                    <TableCell>Location</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Week</TableCell>
                                    <TableCell>TimeIn</TableCell>
                                    <TableCell align="center">TimeOut</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage2 > 0
                                    ? filteredRows2().slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    : filteredRows2()
                                ).map((row, i) => {
                                    return (
                                        <TableRow hover key={row._id}>
                                            <TableCell component="th" scope="row">
                                                <Chip
                                                    label={row.employee.userName}
                                                    variant="outlined"
                                                />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.division && row.division.name}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.location && row.location}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.date}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.week}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.timeIn}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                align="center"
                                                style={{
                                                    textAlign: "center",
                                                }}
                                            >
                                                {row.timeOut ? row.timeOut : "-- • --"}
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip arrow title="View Attendance">
                                                    <IconButton
                                                        onClick={() =>
                                                            navigateById("/hr/attendance", row._id)
                                                        }
                                                    >
                                                        <Icon className="fas fa-eye" />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                                {emptyRows2 > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows2 }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                                {filteredRows2().length === 0 && (
                                    <TableRow hover>
                                        <TableCell
                                            colSpan={10}
                                            align="center"
                                            style={{
                                                color: "#616161",
                                                textAlign: "center",
                                            }}
                                            component="th"
                                            scope="row"
                                        >
                                            No Records To Display
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                        colSpan={3}
                        count={filteredRows2().length}
                        rowsPerPage={rowsPerPage2}
                        page={page2}
                        SelectProps={{
                            inputProps: { "aria-label": "rows per page" },
                            native: true,
                        }}
                        onChangePage={handleChangePage2}
                        onChangeRowsPerPage={handleChangeRowsPerPage2}
                        ActionsComponent={TablePaginationActions}
                    />
                </Paper>

                <Paper>
                    <TableContainer>
                        <Table className={classes.table} aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Invited By</TableCell>
                                    <TableCell>Visitor Name</TableCell>
                                    <TableCell>Visitor Phone</TableCell>
                                    <TableCell>Purpose</TableCell>
                                    <TableCell>Visit Date</TableCell>
                                    <TableCell>Checked In At</TableCell>
                                    <TableCell>Checked Out At</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? filteredRows().slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    : filteredRows()
                                ).map((row, i) => {
                                    return (
                                        <TableRow hover key={row._id}>
                                            <TableCell component="th" scope="row">
                                                {row.employee && row.employee}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.visitorName && row.visitorName}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.visitorPhone && row.visitorPhone}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.visitPurpose && row.visitPurpose}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.visitDate && row.visitDate}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.checkedInOn && row.checkedInOn}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.checkedOutAt && row.checkedOutAt}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <Chip
                                                    style={{ padding: 10 }}
                                                    label={row.status}
                                                    variant="outlined"
                                                    className={clsx(
                                                        row.status === "draft"
                                                            ? classes.statusOrange
                                                            : row.status === "submitted"
                                                                ? classes.statusGreen
                                                                : row.status === "approved" || row.status === "checked_in" || row.status === "checked_out"
                                                                    ? classes.statusDarkGreen
                                                                    : classes.statusRed,
                                                        classes.chip
                                                    )}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}

                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                                {filteredRows().length === 0 && (
                                    <TableRow hover>
                                        <TableCell
                                            colSpan={10}
                                            align="center"
                                            style={{ color: "#616161" }}
                                            component="th"
                                            scope="row"
                                        >
                                            No Records To Display
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                        colSpan={3}
                        count={filteredRows().length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: { "aria-label": "rows per page" },
                            native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </Paper>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </MuiPickersUtilsProvider>
    );
};

export default EmergencyList;