import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginComponent from "./components/LoginComponent";
import ForgotPassword from "./components/ForgotPassword";
// import theme from "./theme";
import VendorRegister from "./components/VendorRegister";
import Dashboard from "./components/Admin/Dashboard";
import { Splash } from "./components/Splash";
import Cookies from "js-cookie";
import { Quotes } from "./components/Admin/Quotes";
import {
  createMuiTheme,
  CssBaseline,
  MuiThemeProvider,
} from "@material-ui/core";

import { Wrapper } from "./components/Admin/Wrapper";
import Layout from "./components/Admin/Layout";
import CreateGroups from "./components/Admin/CreateGroups";
import UserAccess from "./components/Admin/UserAccess";

// guards
import AuthGuard from "./components/AuthGuard";
import ProtectedGuard from "./components/Admin/ProtectedGuard";
import AdminFinanceGuard from "./components/Admin/AdminFinanceGuard";
import AdminQuotationGuard from "./components/Admin/AdminQuotationGuard";
import AdminProjectMobGuard from "./components/Admin/AdminProjectMobGuard";
import AdminCommercialGuard from "./components/Admin/AdminCommercialGuard";
import AdminInventoryGuard from "./components/Admin/AdminInventoryGuard";
import QHSEGuard from "./components/Admin/QHSEGuard";
import CUEGuard from "./components/Admin/CUEGuard";
import AdminQualityGuard from "./components/Admin/AdminQualityGuard";
import PeoplesGuard from "./components/Admin/PeoplesGuard";
import VendorGuard from "./components/Admin/VendorGuard";
import AllGuard from "./components/Admin/AllGuard";
import CashPaymentGuard from "./components/Admin/CashPaymentGuard";

import { Provider } from "react-redux";
import store from "./store";
import CreateJobTitle from "./components/Admin/CreateJobTitle";
import SetUserPassword from "./components/SetUserPassword";
import theme from "./theme";
import CreateDivision from "./components/Admin/CreateDivision";
import PasswordReset from "./components/PasswordReset";
import UsersList from "./components/Admin/UsersList";
import BudgetHead from "./components/Admin/Budgets/BudgetHead";
import BudgetSubhead from "./components/Admin/Budgets/BudgetSubhead";
import BudgetItem from "./components/Admin/Budgets/BudgetItem";
import Annual from "./components/Admin/Budgets/Annual";
import AnnualBudgetLine from "./components/Admin/Budgets/AnnualBudgetLine";
import ViewAnnualBudget from "./components/Admin/Budgets/ViewAnnualBudget";
import ViewAnnualBudgetLine from "./components/Admin/Budgets/ViewAnnualBudgetLine";
import EditBudgetLine from "./components/Admin/Budgets/EditBudgetLine";
import EditBudgetLineForm from "./components/Admin/Budgets/EditBudgetLineForm";
import VendorCompleteRegistration from "./components/VendorCompleteRegistration";
import { Terms } from "./components/Terms";
import { Privacy } from "./components/Privacy";
import VendorsList from "./components/Admin/VendorsList";
import SetVendorPassword from "./components/SetVendorPassword";
import { getWeather } from "./utils/weather";

import AssetAcquisition from "./components/Admin/AssetAcquisition/AssetAcquisition";
import EditAssetAcquisition from "./components/Admin/AssetAcquisition/EditAssetAcquisition";
import ViewAssetAcquisition from "./components/Admin/AssetAcquisition/ViewAssetAcquisition";

import AssetRequisition from "./components/Admin/AssetRequisition/AssetRequisition";
import EditAssetRequisition from "./components/Admin/AssetRequisition/EditAssetRequisition";
import ViewAssetRequisition from "./components/Admin/AssetRequisition/ViewAssetRequisition";

import PurchaseRequisition from "./components/Admin/Purchasing/PurchaseRequisition";
import EditPurchaseRequisition from "./components/Admin/Purchasing/EditPurchaseRequisition";
import ViewPurchaseRequisition from "./components/Admin/Purchasing/ViewPurchaseRequisition";
import ReviewPurchaseRequisition from "./components/Admin/Purchasing/ReviewPurchaseRequisition";
import PurchaseOrder from "./components/Admin/Purchasing/PurchaseOrder";
import RejectionLogs from "./components/Admin/Purchasing/RejectionLogs";
import OpenMarket from "./components/Admin/Purchasing/OpenMarket";
import ViewOpenMarket from "./components/Admin/Purchasing/ViewOpenMarket";
import RequestForQuote from "./components/Admin/Purchasing/RequestForQuote";
import ViewRFQ from "./components/Admin/Purchasing/ViewRFQ";
// import CreateRFQ from "./components/Admin/Purchasing/CreateRFQ";
import ViewPurchaseOrder from "./components/Admin/Purchasing/ViewPurchaseOrder";
import PurchaseOrderPDF from "./components/Admin/Purchasing/PurchaseOrderPDF";

import AllAnnual from "./components/Admin/Budgets/AllAnnual";
import Products from "./components/Admin/Quality/Products";
import ReceivingInspection from "./components/Admin/Quality/Receiving";
import WorkCompletion from "./components/Admin/Quality/WorkCompletion";

import TwoFactorAuth from "./components/TwoFactorAuth";
import AnnualBudgetReview from "./components/Admin/Budgets/AnnualBudgetReview";
import Currency from "./components/Admin/Currency";
import CostCenter from "./components/Admin/Budgets/CostCenter";
import Monthly from "./components/Admin/Budgets/Monthly";
import MonthlyBudgetLine from "./components/Admin/Budgets/MonthlyBudgetLine";
import ViewMonthlyBudgetLine from "./components/Admin/Budgets/ViewMonthlyBudgetLine";
import ViewMonthlyBudget from "./components/Admin/Budgets/ViewMonthlyBudget";
import AllMonthly from "./components/Admin/Budgets/AllMonthly";
import Sequence from "./components/Admin/Sequence";
import MyAccount from "./components/Admin/MyAccount";
import Modules from "./components/Admin/Modules";
import SubModule from "./components/Admin/SubModule";
import CashAdvance from "./components/Admin/Budgets/CashAdvance";
import CashAdvanceEdit from "./components/Admin/Budgets/CashAdvanceEdit";
import ViewCashAdvance from "./components/Admin/Budgets/ViewCashAdvance";
import Payment from "./components/Admin/Budgets/Payment";
import ViewPayment from "./components/Admin/Budgets/ViewPayment";
import Reimbursement from "./components/Admin/Budgets/Reimbursement";
import EditReimbursement from "./components/Admin/Budgets/EditReimbursement";
import ViewReimbursement from "./components/Admin/Budgets/ViewReimbursement";
import ViewReimbursePayment from "./components/Admin/Budgets/ViewReimbursePayment";

import RecoveryReview from "./components/Admin/Budgets/RecoveryReview";
import RecoveryLog from "./components/Admin/Budgets/RecoveryLog";
import ViewCashRecovery from "./components/Admin/Budgets/ViewCashRecovery";

import ViewRecoveryLog from "./components/Admin/Budgets/ViewRecoveryLog";
import EditMonthlyBudgetLineForm from "./components/Admin/Budgets/EditMonthlyBudgetLineForm";
import Attendance from "./components/Admin/HR/Attendance";
import ViewAttendance from "./components/Admin/HR/ViewAttendance";
import PPMGuard from "./components/Admin/PPMGuard";
import Courses from "./components/Admin/LMS/Courses";

import Customers from "./components/Admin/Sales/Customers";
import ServiceTypes from "./components/Admin/Sales/ServiceTypes";
import Contacts from "./components/Admin/Sales/Contacts";

import CWSR from "./components/Admin/Sales/CWSR";
import EditCWSR from "./components/Admin/Sales/EditCWSR";
import ReviewCWSR from "./components/Admin/Sales/ReviewCWSR";
import ViewCWSR from "./components/Admin/Sales/ViewCWSR";

import Quotation from "./components/Admin/Sales/Quotation";
import QuotationPDF from "./components/Admin/Sales/Quotation/QuotationPDF";
import EditQuotation from "./components/Admin/Sales/Quotation/EditQuotation";
import ViewQuotation from "./components/Admin/Sales/Quotation/ViewQuotation";
import CustomerQuotation from "./components/CustomerQuotation";

import Receipt from "./components/Admin/Inventory/Receipt/Receipt";
import EditReceipt from "./components/Admin/Inventory/Receipt/EditReceipt";
import ViewReceipt from "./components/Admin/Inventory/Receipt/ViewReceipt";

import InventoryRequisition from "./components/Admin/Inventory/Requisition/InventoryRequisition";
import EditRequisition from "./components/Admin/Inventory/Requisition/EditRequisition";
import ViewRequisition from "./components/Admin/Inventory/Requisition/ViewRequisition";
import RequisitionPDF from "./components/Admin/Inventory/Requisition/RequisitionPDF";
import InventoryEquipments from "./components/Admin/Inventory/InventoryEquipments";

import Stores from "./components/Admin/Inventory/Stores";
import Offices from "./components/Admin/Inventory/Offices";
import Locations from "./components/Admin/Inventory/Locations";
import LocationItems from "./components/Admin/Inventory/LocationItems";
import StoreItems from "./components/Admin/Inventory/StoreItems";
import InventoryCategories from "./components/Admin/Inventory/InventoryCategories";

import InventoryCount from "./components/Admin/Inventory/InventoryCount";
import ViewInventoryCount from "./components/Admin/Inventory/Count/ViewInventoryCount";
import AssetDatabase from "./components/Admin/Inventory/AssetDatabase";

import GatePass from './components/Admin/Inventory/GatePass/GatePass';
import EditGatePass from "./components/Admin/Inventory/GatePass/EditGatePass";
import ViewGatePass from "./components/Admin/Inventory/GatePass/ViewGatePass";

import ProjectMobDemob from './components/Admin/ProjectMob/ProjectMobDemob';
import ViewProjectMobDemob from './components/Admin/ProjectMob/ViewProjectMob';
import JobAttendance from './components/Admin/ProjectMob/JobAttendance';
import ViewJobAttendance from './components/Admin/ProjectMob/ViewProjectMob';
import WeeklyActivity from './components/Admin/ProjectMob/WeeklyActivity';
import ViewWeeklyActivity from './components/Admin/ProjectMob/ViewProjectMob';

import Leads from "./components/Admin/Crm/Leads";
import ViewLead from "./components/Admin/Crm/ViewLead";
import SalesCall from "./components/Admin/Crm/SalesCall";
import ViewSalesCall from "./components/Admin/Crm/ViewSalesCall";
import KPI from "./components/Admin/KPI";
import MasterList from "./components/Admin/Sales/MasterList";

import VisitorRequests from "./components/Admin/HR/VisitorRequests";
import VisitorLog from "./components/Admin/HR/VisitorLog";
import CheckInVisitor from "./components/Admin/HR/CheckInVisitor";
import CheckOutVisitor from "./components/Admin/HR/CheckOutVisitor";
import Visitor from "./components/Admin/HR/VisitorPage";
import CustomerFeedback from "./components/CustomerFeedback";
import EmergencyList from "./components/Admin/HR/EmergencyList";

import AllUsersList from "./components/Admin/PPM/AllUsersList";
import JobTitles from "./components/Admin/PPM/JobTitles";
import SalesOrder from "./components/Admin/Sales/SalesOrder";
import OrderDatabase from "./components/Admin/Sales/OrderDatabase";
import ViewOrderDatabase from "./components/Admin/Sales/ViewOrderDatabase";
import EditReceiving from "./components/Admin/Quality/EditReceiving";
import ViewReceiving from "./components/Admin/Quality/ViewReceiving";
import ReceivingPDF from "./components/Admin/Quality/ReceivingPDF";
import EditWorkCompletion from "./components/Admin/Quality/EditWorkCompletion";
import ViewWorkCompletion from "./components/Admin/Quality/ViewWorkCompletion";
import WorkCompletionPDF from "./components/Admin/Quality/WorkCompletionPDF";

import ViewCourse from "./components/Admin/LMS/ViewCourse";
import ViewLesson from "./components/Admin/LMS/ViewLesson";

import AllCourses from "./components/Admin/LMS/AllCourses";
import CourseView from "./components/Admin/LMS/CourseView";
import LessonView from "./components/Admin/LMS/LessonView";
import GoodsReceivedNote from "./components/Admin/Inventory/GoodsReceivedNote";
import EditGRN from "./components/Admin/Inventory/GRN/EditGRN";
import ViewGRN from "./components/Admin/Inventory/GRN/ViewGRN";
import PRTEST from "./components/Admin/Purchasing/PRTEST";


// import VendorLayout from "./components/Vendors/VendorLayout";
// import VendorDashboard from "./components/Vendors/VendorDashboard";
// import UsersDashboard from "./components/Admin/UsersDashboard";
// import VendorAccount from "./components/Vendors/VendorAccount";

function App() {
  const [loading, setLoading] = useState(true);
  const [theming, setTheme] = useState(theme);
  const [geolocation, setGeoLocation] = React.useState({
    lat: "",
    lon: "",
  });
  const [weather, setWeather] = React.useState(null);
  const [clicked, setClicked] = React.useState(false);

  const geoCallback = React.useCallback(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      setGeoLocation((prev) => ({
        ...prev,
        lon: pos.coords.longitude,
        lat: pos.coords.latitude,
      }));
    });
  }, []);

  React.useEffect(() => {
    geoCallback();
  }, [geoCallback]);

  const weatherCallback = React.useCallback(() => {
    getWeather(geolocation.lat, geolocation.lon)
      .then((doc) => {
        setWeather(doc);
      })
      .catch((err) => console.error(err));
  }, [geolocation]);

  React.useEffect(() => {
    weatherCallback();
  }, [weatherCallback]);

  // console.log(weather);

  useEffect(() => {
    Cookies.set("splash", String(Math.random()), {
      expires: 7,
      path: "/",
      sameSite: "strict",
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const toggleDarkTheme = () => {
    let newPaletteType = theming.palette.type === "light" ? "dark" : "light";
    setTheme({
      palette: {
        type: newPaletteType,
      },
      typography: {
        // fontFamily: "Quicksand",
        fontFamily: "Rubik",
      },
    });
    localStorage.setItem("theme", newPaletteType);

    setClicked((prev) => {
      localStorage.setItem("clicked", JSON.stringify(!prev));
      return !prev;
    });
  };

  const muiTheme = createMuiTheme(theming);

  return (
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline />
      <Provider store={store}>
        <Wrapper>
          <Router>
            <Switch>
              <Route
                exact
                path="/"
                component={
                  loading && !Cookies.get("splash") ? Splash : LoginComponent
                }
              />
              <Route path="/security/twofactor/:id" component={TwoFactorAuth} />
              <Route path="/login" component={LoginComponent} />
              <Route path="/terms" component={Terms} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/forgotpass" component={ForgotPassword} />
              <Route
                path="/setuserpassword/:token"
                component={SetUserPassword}
              />
              <Route
                path="/setvendorpassword/:token"
                component={SetVendorPassword}
              />
              <Route path="/resetpassword/:token" component={PasswordReset} />
              <Route exact path="/vendor/register" component={VendorRegister} />
              <Route
                path="/vendor/completereg/:token"
                component={VendorCompleteRegistration}
              />
              <Route path="/customerquotation/:id" component={CustomerQuotation} />
              <Route path="/customerfeedback/:id" component={CustomerFeedback} />

              <>
                {/* <VendorLayout
                  toggleDarkTheme={toggleDarkTheme}
                  weather={weather}
                >
                  <Route exact path="/vendors" component={VendorDashboard} />
                  <Route
                    exact
                    path="/vendors/account"
                    component={VendorAccount}
                  />
                </VendorLayout> */}

                <Layout
                  toggleDarkTheme={toggleDarkTheme}
                  clicked={clicked}
                  weather={weather}
                >
                  <AuthGuard path="/dashboard">
                    <Dashboard weather={weather} />
                    {/* <UsersDashboard weather={weather} /> */}
                  </AuthGuard>

                  <AuthGuard exact path="/visitors">
                    <VisitorRequests />
                  </AuthGuard>
                  <AuthGuard path="/visitor/:id">
                    <Visitor />
                  </AuthGuard>
                  <CUEGuard exact path="/visitorlog">
                    <VisitorLog />
                  </CUEGuard>
                  <AuthGuard exact path="/scanvisitorin">
                    <CheckInVisitor />
                  </AuthGuard>
                  <AuthGuard exact path="/scanvisitorout">
                    <CheckOutVisitor />
                  </AuthGuard>
                  <QHSEGuard exact path="/emergencylist">
                    <EmergencyList />
                  </QHSEGuard>

                  <AuthGuard path="/mycourses">
                    <AllCourses />
                  </AuthGuard>
                  <AuthGuard exact path="/course/:id">
                    <CourseView />
                  </AuthGuard>
                  <AuthGuard exact path="/lesson/:id">
                    <LessonView />
                  </AuthGuard>

                  <AuthGuard path="/kpi">
                    <KPI />
                  </AuthGuard>

                  <VendorGuard path="/vendors/quote">
                    <Quotes />
                  </VendorGuard>

                  <AllGuard path="/account">
                    <MyAccount />
                  </AllGuard>

                  <AdminQualityGuard exact path="/quality/vendors">
                    <VendorsList />
                  </AdminQualityGuard>
                  <AdminQualityGuard exact path="/quality/products">
                    <Products />
                  </AdminQualityGuard>
                  
                  <AuthGuard exact path="/quality/receivinginspection">
                    <ReceivingInspection />
                  </AuthGuard>
                  <AuthGuard exact path="/quality/receiving/edit/:id">
                    <EditReceiving />
                  </AuthGuard>
                  <AuthGuard exact path="/quality/receiving/view/:id">
                    <ViewReceiving />
                  </AuthGuard>
                  <AuthGuard exact path="/quality/receiving/export/:id">
                    <ReceivingPDF />
                  </AuthGuard>

                  <AuthGuard exact path="/quality/workcompletion">
                    <WorkCompletion />
                  </AuthGuard>
                  <AuthGuard exact path="/quality/workcompletion/edit/:id">
                    <EditWorkCompletion />
                  </AuthGuard>
                  <AuthGuard exact path="/quality/workcompletion/view/:id">
                    <ViewWorkCompletion />
                  </AuthGuard>
                  <AuthGuard exact path="/quality/workcompletion/export/:id">
                    <WorkCompletionPDF />
                  </AuthGuard>
                  

                  

                  <AdminFinanceGuard path="/budget/head">
                    <BudgetHead />
                  </AdminFinanceGuard>
                  <AdminFinanceGuard path="/budget/subhead">
                    <BudgetSubhead />
                  </AdminFinanceGuard>
                  <AdminFinanceGuard path="/budget/item">
                    <BudgetItem />
                  </AdminFinanceGuard>
                  <AdminFinanceGuard exact path="/budget/all/annual">
                    <AllAnnual />
                  </AdminFinanceGuard>
                  <AdminFinanceGuard exact path="/budget/all/monthly">
                    <AllMonthly />
                  </AdminFinanceGuard>
                  <AdminFinanceGuard path="/currency">
                    <Currency />
                  </AdminFinanceGuard>

                  <AdminFinanceGuard path="/costcenter">
                    <CostCenter />
                  </AdminFinanceGuard>

                  <AdminFinanceGuard path="/budget/all/review">
                    <AnnualBudgetReview />
                  </AdminFinanceGuard>

                  <AuthGuard exact path="/budget/annual">
                    <Annual />
                  </AuthGuard>
                  <AuthGuard exact path="/budget/annual/budgetline/:id">
                    <AnnualBudgetLine />
                  </AuthGuard>
                  <AuthGuard path="/budget/annual/budgetline/view/:id">
                    <ViewAnnualBudgetLine />
                  </AuthGuard>
                  <AuthGuard exact path="/budget/annual/budgetline/edit/:id">
                    <EditBudgetLine />
                  </AuthGuard>
                  <AuthGuard path="/budget/annual/budgetline/edit/form/:id">
                    <EditBudgetLineForm />
                  </AuthGuard>
                  <AuthGuard path="/budget/annual/view/:id">
                    <ViewAnnualBudget />
                  </AuthGuard>

                  <AuthGuard exact path="/budget/monthly">
                    <Monthly />
                  </AuthGuard>

                  <AuthGuard exact path="/budget/monthly/budgetline/:id">
                    <MonthlyBudgetLine />
                  </AuthGuard>
                  <AuthGuard exact path="/budget/monthly/budgetline/view/:id">
                    <ViewMonthlyBudgetLine />
                  </AuthGuard>

                  <AuthGuard path="/budget/monthly/budgetline/edit/:id">
                    <EditMonthlyBudgetLineForm />
                  </AuthGuard>

                  <AuthGuard path="/budget/monthly/view/:id">
                    <ViewMonthlyBudget />
                  </AuthGuard>

                  <AuthGuard exact path="/cashadvance">
                    <CashAdvance />
                  </AuthGuard>

                  <AuthGuard exact path="/cashadvance/:id">
                    <CashAdvanceEdit />
                  </AuthGuard>

                  <CashPaymentGuard exact path="/cash/payment">
                    <Payment />
                  </CashPaymentGuard>

                  <CashPaymentGuard exact path="/recovery/review">
                    <RecoveryReview />
                  </CashPaymentGuard>

                  <CashPaymentGuard path="/recovery/view/:id">
                    <ViewCashRecovery />
                  </CashPaymentGuard>

                  <PeoplesGuard exact path="/recovery/log">
                    <RecoveryLog />
                  </PeoplesGuard>

                  <PeoplesGuard path="/recovery/log/view/:id">
                    <ViewRecoveryLog />
                  </PeoplesGuard>

                  <CashPaymentGuard exact path="/cash/payment/:id">
                    <ViewPayment />
                  </CashPaymentGuard>

                  <CashPaymentGuard path="/cash/reimbursement/payment/:id">
                    <ViewReimbursePayment />
                  </CashPaymentGuard>

                  <AuthGuard path="/cashadvance/view/:id">
                    <ViewCashAdvance />
                  </AuthGuard>

                  <AuthGuard exact path="/reimbursement">
                    <Reimbursement />
                  </AuthGuard>
                  <AuthGuard exact path="/reimbursement/:id">
                    <EditReimbursement />
                  </AuthGuard>

                  <AuthGuard path="/reimbursement/view/:id">
                    <ViewReimbursement />
                  </AuthGuard>

                  <AuthGuard exact path="/asset/acquisition">
                    <AssetAcquisition />
                  </AuthGuard>
                  <AuthGuard path="/asset/acquisition/edit/:id">
                    <EditAssetAcquisition />
                  </AuthGuard>
                  <AuthGuard path="/asset/acquisition/view/:id">
                    <ViewAssetAcquisition />
                  </AuthGuard>

                  <AuthGuard exact path="/asset/requisition">
                    <AssetRequisition />
                  </AuthGuard>
                  <AuthGuard path="/asset/requisition/edit/:id">
                    <EditAssetRequisition />
                  </AuthGuard>
                  <AuthGuard path="/asset/requisition/view/:id">
                    <ViewAssetRequisition />
                  </AuthGuard>

                  

                  <AuthGuard exact path="/purchasing/requisition">
                    <PurchaseRequisition />
                  </AuthGuard>
                  <AuthGuard path="/purchasing/requisition/edit/:id">
                    <EditPurchaseRequisition />
                  </AuthGuard>
                  <AuthGuard exact path="/purchasing/requisition/view/:id">
                    <ViewPurchaseRequisition />
                  </AuthGuard>
                  <AuthGuard path="/purchasing/requisition/review/:id">
                    <ReviewPurchaseRequisition />
                  </AuthGuard>

                  <AuthGuard exact path="/purchasing/mypr">
                    <PRTEST />
                  </AuthGuard>

                  <AuthGuard path="/purchasing/requestforquote">
                    <RequestForQuote />
                  </AuthGuard>
                  {/* <AuthGuard path="/purchasing/rfqs">
                    <CreateRFQ />
                  </AuthGuard> */}
                  <AuthGuard exact path="/purchasing/rfq/view/:id">
                    <ViewRFQ />
                  </AuthGuard>
                  <AuthGuard exact path="/purchasing/openmarket/view/:id">
                    <ViewOpenMarket />
                  </AuthGuard>

                  <AuthGuard exact path="/purchasing/purchaseorder">
                    <PurchaseOrder />
                  </AuthGuard>
                  <AuthGuard exact path="/purchasing/purchaseorder/view/:id">
                    <ViewPurchaseOrder />
                  </AuthGuard>
                  <AuthGuard exact path="/purchasing/purchaseorder/export/:id">
                    <PurchaseOrderPDF />
                  </AuthGuard>
                  <AuthGuard exact path="/purchasing/rejectionlogs">
                    <RejectionLogs />
                  </AuthGuard>
                  <AuthGuard exact path="/purchasing/openmarket">
                    <OpenMarket />
                  </AuthGuard>

                  <ProtectedGuard path="/setup/access">
                    <UserAccess />
                  </ProtectedGuard>
                  <ProtectedGuard path="/setup/jobtitle">
                    <CreateJobTitle />
                  </ProtectedGuard>
                  <ProtectedGuard path="/setup/groups">
                    <CreateGroups />
                  </ProtectedGuard>
                  <ProtectedGuard path="/setup/division">
                    <CreateDivision />
                  </ProtectedGuard>

                  <ProtectedGuard exact path="/setup/users">
                    <UsersList />
                  </ProtectedGuard>
                  <ProtectedGuard path="/setup/sequence">
                    <Sequence />
                  </ProtectedGuard>
                  <ProtectedGuard path="/setup/modules">
                    <Modules />
                  </ProtectedGuard>
                  <ProtectedGuard path="/setup/module/submodule/:id">
                    <SubModule />
                  </ProtectedGuard>
                  <ProtectedGuard exact path="/setup/offices">
                    <Offices />
                  </ProtectedGuard>

                  <PPMGuard exact path="/hr/attendance">
                    <Attendance />
                  </PPMGuard>

                  <PPMGuard path="/hr/attendance/:id">
                    <ViewAttendance />
                  </PPMGuard>

                  <PPMGuard path="/ppm/jobtitles">
                    <JobTitles />
                  </PPMGuard>
                  <PPMGuard path="/ppm/employees">
                    <AllUsersList />
                  </PPMGuard>

                  <PPMGuard exact path="/lms/courses">
                    <Courses />
                  </PPMGuard>
                  <PPMGuard exact path="/lms/course/:id">
                    <ViewCourse />
                  </PPMGuard>
                  <PPMGuard exact path="/lms/lesson/:id">
                    <ViewLesson />
                  </PPMGuard>

                  {/* INVENTORY STARTS */}
                  <AdminInventoryGuard exact path="/inventory/stores">
                    <Stores />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard exact path="/inventory/locations">
                    <Locations />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard exact path="/inventory/locationitems/:id">
                    <LocationItems />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard exact path="/inventory/storeitems">
                    <StoreItems />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard exact path="/inventory/categories">
                    <InventoryCategories />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard exact path="/inventory/count">
                    <InventoryCount />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard path="/inventory/count/view/:id">
                    <ViewInventoryCount />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard exact path="/inventory/assetdatabase">
                    <AssetDatabase />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard exact path="/inventory/grn">
                    <GoodsReceivedNote />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard exact path="/inventory/grn/edit/:id">
                    <EditGRN />
                  </AdminInventoryGuard>
                  <AdminInventoryGuard exact path="/inventory/grn/view/:id">
                    <ViewGRN />
                  </AdminInventoryGuard>


                  <AuthGuard exact path="/inventory/requisition">
                    <InventoryRequisition />
                  </AuthGuard>
                  <AuthGuard exact path="/inventory/requisition/edit/:id">
                    <EditRequisition />
                  </AuthGuard>
                  <AuthGuard exact path="/inventory/requisition/view/:id">
                    <ViewRequisition />
                  </AuthGuard>
                  <AdminInventoryGuard exact path="/inventory/requisition/export/:id">
                    <RequisitionPDF />
                  </AdminInventoryGuard>
                  <AuthGuard exact path="/inventory/equipments">
                    <InventoryEquipments />
                  </AuthGuard>

                  <AuthGuard exact path="/inventory/receipt">
                    <Receipt />
                  </AuthGuard>
                  <AuthGuard exact path="/inventory/receipt/edit/:id">
                    <EditReceipt />
                  </AuthGuard>
                  <AuthGuard exact path="/inventory/receipt/view/:id">
                    <ViewReceipt />
                  </AuthGuard>

                  <AuthGuard exact path="/inventory/gatepass">
                    <GatePass />
                  </AuthGuard>
                  <AuthGuard exact path="/inventory/gatepass/edit/:id">
                    <EditGatePass />
                  </AuthGuard>
                  <AuthGuard exact path="/inventory/gatepass/view/:id">
                    <ViewGatePass />
                  </AuthGuard>

                  {/* INVENTORY ENDS */}

                  <AuthGuard exact path="/sales/cwsr">
                    <CWSR />
                  </AuthGuard>
                  <AuthGuard exact path="/sales/cwsr/:id">
                    <EditCWSR />
                  </AuthGuard>
                  <AuthGuard exact path="/sales/cwsr/review/:id">
                    <ReviewCWSR />
                  </AuthGuard>
                  <AuthGuard exact path="/sales/cwsr/view/:id">
                    <ViewCWSR />
                  </AuthGuard>

                  <AuthGuard exact path="/sales/pricelist">
                    <MasterList />
                  </AuthGuard>

                  <AdminCommercialGuard exact path="/sales/salesorder">
                    <SalesOrder />
                  </AdminCommercialGuard>

                  <AdminCommercialGuard exact path="/sales/orderdatabase">
                    <OrderDatabase />
                  </AdminCommercialGuard>

                  <AdminCommercialGuard exact path="/sales/orderdatabase/view/:id">
                    <ViewOrderDatabase />
                  </AdminCommercialGuard>

                  

                  <AdminQuotationGuard exact path="/sales/quotation">
                    <Quotation />
                  </AdminQuotationGuard>
                  <AdminQuotationGuard exact path="/sales/quotation/:id">
                    <QuotationPDF />
                  </AdminQuotationGuard>
                  <AdminQuotationGuard exact path="/sales/quotation/view/:id">
                    <ViewQuotation />
                  </AdminQuotationGuard>
                  <AdminQuotationGuard exact path="/sales/quotation/edit/:id">
                    <EditQuotation />
                  </AdminQuotationGuard>

                  <AuthGuard path="/crm/customers">
                    <Customers />
                  </AuthGuard>

                  <AuthGuard path="/sales/service">
                    <ServiceTypes />
                  </AuthGuard>

                  <AuthGuard path="/crm/contacts">
                    <Contacts />
                  </AuthGuard>

                  <AuthGuard exact path="/crm/leads">
                    <Leads />
                  </AuthGuard>
                  <AuthGuard path="/crm/leads/:id">
                    <ViewLead />
                  </AuthGuard>
                  <AuthGuard exact path="/crm/salescall">
                    <SalesCall />
                  </AuthGuard>
                  <AuthGuard path="/crm/salescall/:id">
                    <ViewSalesCall />
                  </AuthGuard>

                  <AdminProjectMobGuard exact path="/pml/mobdemob">
                    <ProjectMobDemob />
                  </AdminProjectMobGuard>
                  <AdminProjectMobGuard path="/pml/mobdemob/view/:id">
                    <ViewProjectMobDemob />
                  </AdminProjectMobGuard>
                  <AdminProjectMobGuard exact path="/pml/jobattendance">
                    <JobAttendance />
                  </AdminProjectMobGuard>
                  <AdminProjectMobGuard path="/pml/jobattendance/view/:id">
                    <ViewJobAttendance />
                  </AdminProjectMobGuard>
                  <AdminProjectMobGuard exact path="/pml/weeklyactivity">
                    <WeeklyActivity />
                  </AdminProjectMobGuard>
                  <AdminProjectMobGuard path="/pml/weeklyactivity/view/:id">
                    <ViewWeeklyActivity />
                  </AdminProjectMobGuard>


                </Layout>
              </>
            </Switch>
          </Router>
        </Wrapper>
      </Provider>
    </MuiThemeProvider>
  );
}

export default App;
