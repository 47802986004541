import React from "react";
import { Text, View, StyleSheet , Image } from "@react-pdf/renderer";
import moment from "moment";
import { formatAmount, amountToWords } from "../../../../utils";
import procurement from '../../../../procurement.jpeg';
import ebenezer from '../../../../ebenezer.png';
import reviewer from '../../../../reviewer.jpg';
import authorizer from '../../../../authorizer.jpg';

const styles = StyleSheet.create({
    section: {
        margin: 10,
        marginBottom: 5,
        paddingHorizontal: 20,
        fontSize: 13,
        textAlign: "left",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.5,
    },
    footer: {
        marginTop: 30,
        fontSize: 11,
        fontWeight: 300,
        color: "#222",
        lineHeight: 1.2,
        position:"absolute",
        bottom: 15,
        left: 20,
    },
    rightSection: {
        textAlign: "right",
    },
    text: {
        fontSize: 12,
        textAlign: "left",
        fontWeight: 400,
        color: "#222",
        lineHeight: 1.7,
        display:"block",
        width: "100%",
    },
    caps: {
        fontWeight: 800,
        fontSize: 11,
    },
    mb_10: {
        fontSize: 10,
        marginBottom: 5,
        fontWeight:"bold",
    },

    textLeft: {
        textAlign: "left",
        fontSize: 12,
        marginBottom: 0,
    },
    footerTextLeft: {
        textAlign: "left",
        fontSize: 12,
    },
    textRight: {
        textAlign: "right",
        fontSize: 9,
    },
    textBold: {
        fontWeight: "bold",
    },
    textUnderline: {
        textDecoration: "underline",
        width: "100%",
        display:"block",
    },
    shipTo: {
        textAlign: "right",
        fontSize: 10,
        marginTop: -60,
    },
    table: {
        padding: 0,
        margin: 0,
        fontSize: 10,
    },
    row: {
        alignItems: "center",
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #000',
        padding: 0,
        margin: 0,
    },
    bodyrow: {
        alignItems: "stretch",
        justifyContent: 'center', // align the children vertically
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #000',
        padding: 0,
        margin: 0,
    },
    th: {
        fontWeight: "bold",
        fontSize: 11,
        padding: 4,
        margin: 0,
        backgroundColor: "#000",
        color: "#fff"
    },
    thd: {
        fontWeight: "bold",
        fontSize: 9,
        padding: 4,
        margin: 0,
        color: "#444",
        textAlign:'right',
        borderRight: '1px solid #000',
    },
    thtrans: {
        fontWeight: "bold",
        fontSize: 9,
        padding: 4,
        margin: 0,
        backgroundColor: "#fff",
        borderLeft: '1px solid #000',
    },
    foot: {
        backgroundColor: "#fff",
        alignItems: "center",
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid #000',
        padding: 0,
        margin: 0,
    },
    td: {
        overflow: 'hidden',
        fontSize: 9,
        padding: 4,
        margin: 0,
        backgroundColor: "#fff",
        color: "#444",
        border: "1px solid #000",
        borderTopWidth: 0,
    },
    desc: {
        width: "20%",
    },
    desc10: {
        width: "10%",
    },
    desc25: {
        width: "25%",
    },
    desc2: {
        width: "30%",
    },
    desc3: {
        width: "80%",
    },
    desc60: {
        width: "60%",
    },
    desc20right: {
        width: "20%",
        textAlign: "right",
    },
    desc4: {
        width: "40%",
        textAlign: "right",
    },
    desc5: {
        width: "50%",
        textAlign: "right",
    },
    desc40: {
        width: "40%",
    },
    desc30: {
        width: "30%",
    },
    desc15: {
        width: "15%",
        textAlign:"center",
    },
    desc12: {
        width: "15%",
        textAlign:"right",
    },
});


const TableHead = () => {
    return (
        <>
            <View style={[styles.row]}>
                <Text style={[styles.th, styles.desc30]}>Product Description</Text>
                <Text style={[styles.th, styles.desc10]}>Ref No.</Text>
                <Text style={[styles.th, styles.desc10]}>Quantity</Text>
                <Text style={[styles.th, styles.desc12]}>VAT</Text>
                <Text style={[styles.th, styles.desc12]}>Unit Price</Text>
                <Text style={[styles.th, styles.desc]}>Extended Cost</Text>
            </View>
        </>
    )
}

const TableFoot = ({ value }) => {
    return (
        <>
            {(value.discount && value.discount) ? 
            <View style={[styles.foot]}>
                <Text style={[styles.thtrans, styles.desc60]}>&nbsp;</Text>
                <Text style={[styles.thd, styles.desc]}>Discount:</Text>
                <Text style={[styles.thd, styles.desc]}>
                    {value && value.currency ? value.currency : null}
                    {value.discount ? formatAmount(value.discount, "en-NG") : formatAmount(value.discount, "en-NG")}
                </Text>
            </View>
            : null}

            {(value.freightCharge && value.freightCharge)? 
            <View style={[styles.foot]}>
                <Text style={[styles.thtrans, styles.desc60]}>&nbsp;</Text>
                <Text style={[styles.thd, styles.desc]}>Freight Charges:</Text>
                <Text style={[styles.thd, styles.desc]}>
                    {value && value.currency ? value.currency : null}
                    {value.freightCharge ? formatAmount(value.freightCharge, "en-NG") : formatAmount(value.freightCharge, "en-NG")}
                </Text>
            </View>
            : null}
            
            {(value.serviceCharge && value.serviceCharge) ? 
            <View style={[styles.foot]}>
                <Text style={[styles.thtrans, styles.desc60]}>&nbsp;</Text>
                <Text style={[styles.thd, styles.desc]}>Service Charges:</Text>
                <Text style={[styles.thd, styles.desc]}>
                    {value && value.currency ? value.currency : null}
                    {value.serviceCharge ? formatAmount(value.serviceCharge, "en-NG") : formatAmount(value.serviceCharge, "en-NG")}
                </Text>
            </View>
            : null}

            {(value.totalVat && value.totalVat) ? 
            <View style={[styles.foot, {borderBottom: '1px solid #000'}]}>
                <Text style={[styles.thtrans, styles.desc60]}>&nbsp;</Text>
                <Text style={[styles.thd, styles.desc]}>Total VAT:</Text>
                <Text style={[styles.thd, styles.desc]}>
                    {value && value.currency ? value.currency : null}
                    {value.totalVat ? formatAmount(value.totalVat, "en-NG") : formatAmount(value.totalVat, "en-NG")}
                </Text>
            </View>
            : null}

            {(value.finalAmount && value.finalAmount) ? 
            <View style={[styles.foot, {borderBottom: '1px solid #000'}]}>
                <Text style={[styles.thtrans, styles.desc60]}>&nbsp;</Text>
                <Text style={[styles.thd, styles.desc]}>Grand Amount:</Text>
                <Text style={[styles.thd, styles.desc, {textAlign:'right'}]}>
                    {value && value.currency ? value.currency : null}
                    {value.finalAmount ? formatAmount(value.finalAmount, "en-NG") : formatAmount(value.finalAmount, "en-NG")}
                </Text>
            </View>
            : ((value.totalAmount && value.totalAmount) ? 
                <View style={[styles.foot, {borderBottom: '1px solid #000'}]}>
                <Text style={[styles.thtrans, styles.desc60]}>&nbsp;</Text>
                <Text style={[styles.thd, styles.desc]}>Total Amount:</Text>
                <Text style={[styles.thd, styles.desc, {textAlign:'right'}]}>
                    {value && value.currency ? value.currency : null}
                    {value.totalAmount ? formatAmount(value.totalAmount, "en-NG") : formatAmount(value.totalAmount, "en-NG")}
                </Text>
            </View> : null
            )}
        </>
    )
}

export const PageOne = ({ quote }) => {

    let amount = quote.finalAmount ? quote.finalAmount : (quote.totalAmount ? quote.totalAmount : 0);
    let roundFinal = Math.round(amount);

    return (
        <>
            <View style={{position: "relative"}}>
                <Image
                    src={procurement}
                    style={{
                        height: 130,
                        width: "100%",
                        marginTop: 20,
                        paddingRight: 20,
                        paddingLeft: 20,
                        marginBottom: 0,
                    }} 
                />

                <View style={[styles.section, styles.rightSection]}>
                    <Text style={styles.mb_10}>Purchase Order: {quote && quote.documentNo ? quote.documentNo : null}</Text>
                    <Text style={styles.mb_10}>Order Date: {quote && quote.orderDate ? moment(quote.orderDate).format("MMMM Do YYYY") : null}</Text>
                    <Text style={styles.mb_10}>Delivery Date: {quote && quote.dateNeeded ? moment(quote.dateNeeded).format("MMMM Do YYYY") : null}</Text>
                    <Text style={styles.mb_10}>Credit Terms: {quote && quote.creditTerms ? quote.creditTerms : null}</Text>
                </View>

                <View style={styles.section}>
                    <Text style={[styles.mb_10, styles.caps, styles.textBold]}>TO:</Text>
                    <Text style={styles.mb_10}>{quote && quote.vendor ? quote.vendor?.companyName : null}</Text>
                    <Text style={styles.mb_10}>{quote && quote.vendor ? quote.vendor?.officeAddress : null}</Text>
                </View>
                
                <View style={[styles.section,styles.shipTo]}>
                    <Text style={[styles.mb_10, styles.caps, styles.textBold]}>SHIP TO:</Text>
                    <Text style={styles.mb_10}>RusselSmith Nigeria Limited</Text>
                    <Text style={styles.mb_10}>18 Adeola Hopewell Street,</Text>
                    <Text style={styles.mb_10}>Victoria Island, Lagos Nigeria.</Text>
                </View>
                
                {/* Items */}
                {quote && quote.items && quote.items.length > 0 ? (
                    <View style={styles.section}>
                        <View style={styles.table}>
                            
                            <TableHead />

                            {(quote.items).map((item, i) => (
                                <View key={i}>
                                    <View  style={[styles.bodyrow]}>
                                        <Text style={[styles.td, styles.desc30]}>
                                            {item.description && item.description}
                                        </Text>
                                        <Text style={[styles.td, styles.desc10]}>
                                            {"N/A"}
                                        </Text>
                                        <Text style={[styles.td, styles.desc10]}>
                                            {item.quantityNeeded && item.quantityNeeded}
                                        </Text>
                                        <Text style={[styles.td, styles.desc15]}>
                                            {quote && quote.currency ? quote.currency : null}
                                            {(item.vat && item.vat) ? formatAmount(item.vat, "en-NG") : "0.00"}
                                        </Text>
                                        <Text style={[styles.td, styles.desc15]}>
                                            {quote && quote.currency ? quote.currency : null}
                                            {item.quotedAmount
                                                ? formatAmount(item.quotedAmount, "en-NG")
                                                : formatAmount(0, "en-NG")}
                                        </Text>
                                        <Text style={[styles.td, styles.desc, {textAlign:'right'}]}>
                                            {quote && quote.currency ? quote.currency : null}
                                            {item.extendedCost
                                                ? formatAmount(item.extendedCost, "en-NG")
                                                : formatAmount(0, "en-NG")}
                                        </Text>
                                    </View>
                                </View>
                            ))}

                            <TableFoot value={quote} />
                        </View>
                    </View>
                ) : null}

                <View style={[styles.section, {fontSize: 9}]}>
                    {roundFinal && roundFinal ? 
                    <Text style={[styles.textLeft, styles.textBold, {fontSize: 9}]}><strong>Amount In Words:</strong> {" "}
                        <Text style={[styles.text, {fontSize: 9}]}>
                            {roundFinal ? amountToWords(roundFinal || 0) : "N/A"}
                            {/* {"Seventy Two Thousand One Hundred and Thirty Two "} */}
                            {quote && (quote.currency === "NGN") ? " Naira" : null}
                            {quote && (quote.currency === "USD") ? " US Dollar" : null}
                            {quote && (quote.currency === "GBP") ? " British Pounds" : null}
                            {quote && (quote.currency === "INR") ? " Indian Rupee" : null}
                        </Text>
                    </Text> : null}

                    {quote && quote.paymentTerms ? (
                        <>
                            <Text style={[styles.textLeft, styles.textBold, {fontSize: 9}]}><strong>Additional Payment Terms:</strong> {" "}
                                <Text style={[styles.text, {fontSize: 9}]}>
                                    {quote && quote.paymentTerms ? quote.paymentTerms : null}
                                </Text>
                            </Text>
                        </>
                    ) : null}

                    <Text style={[styles.textLeft, styles.textBold, {fontSize: 9}]}><strong>Prepared By:</strong> {" "}
                        <Text style={[styles.text, {fontSize: 9}]}>
                            {quote && quote.createdBy ? quote.createdBy?.userName : null}
                        </Text>
                    </Text>

                    <Text style={[styles.textLeft, styles.textBold, {fontSize: 9}]}><strong>Reviewed By:</strong> {" "}
                        <Text style={[styles.text, {fontSize: 9}]}>
                            {quote && quote.submittedBy ? quote.submittedBy?.userName : null}
                        </Text>
                        {" "} 
                        <View style={[styles.text]}>
                            {quote && quote.submittedBy ? 
                            <Image 
                                src={ebenezer} 
                            /> : null}
                        </View> 
                        {"  "} 
                        <Text style={[styles.text, styles.textRight, {fontSize: 9}]}>
                            {quote && quote.submittedAt ? 
                            (quote.submittedAt).slice(0, -12) : null} 
                        </Text>
                    </Text>

                    <Text style={[styles.textLeft, styles.textBold, {fontSize: 9}]}><strong>Authorised By:</strong> {" "}
                        <Text style={[styles.text, {fontSize: 9}]}>
                            {quote && quote.acceptedBy ? quote.acceptedBy?.userName : null}
                        </Text> 
                        {"  "} 
                        <View style={[styles.text]}>
                            {quote && quote.acceptedBy ? 
                            <Image 
                                src={reviewer} 
                            /> : null}
                        </View> 
                        {"  "} 
                        <Text style={[styles.text, styles.textRight, {fontSize: 9}]}>
                            {quote && quote.acceptedAt ? (quote.acceptedAt).slice(0, -12) : null}
                        </Text>
                    </Text>

                    <Text style={[styles.textLeft, styles.textBold, {fontSize: 9}]}><strong>Approved By: </strong> {" "}
                        <Text style={[styles.text, {fontSize: 9}]}>
                            {quote && quote.approvedBy ? quote.approvedBy?.userName : null}
                        </Text> 
                        {"  "} 
                        <View style={[styles.text]}>
                            {quote && quote.approvedBy ? 
                            <Image 
                                src={authorizer} 
                            /> : null}
                        </View> 
                        {"  "} 
                        <Text style={[styles.text, {fontSize: 9}]}>
                            {quote && quote.approvedAt ? (quote.approvedAt).slice(0, -12) : null}
                        </Text>
                    </Text>

                    <Text style={[styles.textLeft, styles.textBold, {fontSize: 9}]}>Vendor Signature:
                        <Text style={[styles.text, {fontSize: 9}]}>{"_________________________________________________________________________________"} 
                        </Text>
                    </Text>

                    <Text style={[styles.textLeft, {fontSize: 9}]}>
                        Please, do not hesitate to either call us on 07069000900 or e-mail us at 
                        cue@russelsmithgroup.com to express your view of our business dealings with you.
                    </Text>
                </View>
                
            </View>
        </>
    )
}

export default PageOne;
