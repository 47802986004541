import React, { useEffect, useState, useCallback } from "react";
import {
    makeStyles,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
    Divider,
    IconButton,
    ButtonGroup,
    Button,
    DialogActions,
    TextField,
    darken,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { connect, useDispatch, useSelector } from "react-redux";
import Feedback from "../../../../Feedback";
import { approveOpenMarket } from "../../../../actions/purchasingActions";
import { useHistory } from "react-router-dom";
import { getCostCodesByType } from "../../../../actions/costCodeAction";
import { createCashAdvance, getAllCashAdvances } from "../../../../actions/cashAdvanceAction";


const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold"
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 20,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(4),
        marginBottom: 30,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        paddingBottom: 30,
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginBottom: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },
    btnWrapper: {
        textAlign: "center",
    },
    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
            // color: "#fff",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        "&:disabled": {
            background: "#ccc",
            color: "#333",
        },
        "&:disabled:hover": {
            background: "#cccccc",
        },
    },
    small: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        color: theme.palette.warning.main,
    },
}));


const INIT_STATE = {
    costType: "",
    costCenter: "",
    rate: 1,
    advanceType:"open market",
  };

  
const CashAdvanceOpenMarketModal = ({ openAdvance, handleCloseAdvance, id, state }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { error, loading } = useSelector((state) => state.openmarket);
    const { costcodes } = useSelector((state) => state.costcode);
    const [newstate, setState] = useState(INIT_STATE);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    const codeTypeCallback = useCallback(() => {
        if (newstate.costType !== "") {
          dispatch(getCostCodesByType(newstate.costType))
        }
      }, [newstate, dispatch]);

    useEffect(() => {
        codeTypeCallback();
    }, [codeTypeCallback]);
    
    const handleChange = (e) => {
        e.persist();
        setState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const handleSubmitAdvance = async () => {

        let total = state.items.reduce((acc, cur) => {
            return acc + +cur.purchaseAmount;
          }, 0);

        let items = state.items.map((item) => {
            return {
              item: item.budgetItem,
              amount: item.purchaseAmount,
              description: item.description,
            };
        });

        if(total && items && items.length > 0){
            const data = {
                year: state.year,
                month: state.month,
                purpose: state.purpose,
                costCenter: newstate.costCenter,
                costType: newstate.costType,
                total: total,
                advanceDivision: state.division || null,
                rate: newstate.rate,
                currency: "NGN",
                advanceType: newstate.advanceType,
                items,
                opm: id,
            };
            
            const res = await new Promise((resolve, reject) => {
                resolve(dispatch(createCashAdvance(data)));
            });    
            
            if (res.success) {
                setTimeout(() => {
                    handleCloseAdvance();
                }, 1500);
                dispatch(getAllCashAdvances());
                setFeed((prev) => ({
                    loading: false,
                    open: !prev.open,
                    message: "Cash Advance Created Successfully.",
                    success: true,
                }));
                setTimeout(() => {
                    history.push(`/cashadvance/${res.data._id}`);
                }, 2000);
            }
        }
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}
            <Dialog
                open={openAdvance}
                onClose={handleCloseAdvance}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="overline" className={classes.title}>
                                Create Cash Advance for this Open Market Purchase
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCloseAdvance}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container spacing={3} justify="center" alignItems="center">
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Employee Name"
                                name="employeeName"
                                variant="outlined"
                                fullWidth
                                value={state.submittedBy?.userName || ""}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Division/Subdivision"
                                name="division"
                                variant="outlined"
                                fullWidth
                                value={state.division?.name || ""}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Month"
                                name="month"
                                variant="outlined"
                                fullWidth
                                value={state.month || ""}
                                required
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Year"
                                name="year"
                                variant="outlined"
                                fullWidth
                                value={state.year || ""}
                                disabled
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Purpose of Advance"
                                name="purpose"
                                variant="outlined"
                                fullWidth
                                value={state.purpose || ""}
                                required
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth variant="outlined" required>
                            <InputLabel id="costTypeId">Cost Type</InputLabel>
                            <Select
                                labelId="costTypeId"
                                name="costType"
                                inputProps={{
                                    id: "costType",
                                    name: "costType",
                                }}
                                onChange={handleChange}
                                value={newstate.costType || ""}
                            >
                                <MenuItem value="project">Project</MenuItem>
                                <MenuItem value="division">Division/Subdivision</MenuItem>
                            </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth variant="outlined" required>
                            <InputLabel id="costCodeId">Cost Center</InputLabel>
                            <Select
                                labelId="costCodeId"
                                name="costCenter"
                                inputProps={{
                                    id: "costCenter",
                                    name: "costCenter",
                                }}
                                onChange={handleChange}
                                value={newstate.costCenter || ""}
                                disabled={!newstate.costType}
                            >
                                <MenuItem value="" disabled>
                                <em>---SELECT COST CENTER----</em>
                                </MenuItem>
                                {(costcodes && costcodes.data && costcodes.data.length > 0) &&
                                    costcodes.data.map((cost) => (
                                    <MenuItem key={cost._id} value={cost.name}>
                                        {cost.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth variant="outlined" required>
                                <InputLabel id="currencyId">Currency</InputLabel>
                                <Select
                                    labelId="currencyId"
                                    name="rate"
                                    inputProps={{
                                        id: "rate",
                                        name: "rate",
                                    }}
                                    value={newstate.rate || 1}
                                    disabled
                                >
                                    <MenuItem key="5f35132ea690ed2f6c909a48" value={newstate.rate}>
                                        NGN
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup>
                        <Button onClick={handleCloseAdvance} className={classes.saveBtn}>
                            Close
                        </Button>
                        <Button
                            className={classes.saveBtn2}
                            disabled={loading || !newstate.costType || !newstate.costCenter }
                            onClick={handleSubmitAdvance}
                        >
                            {loading ? "Loading..." : "Create"}
                        </Button>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default connect(null, { approveOpenMarket })(CashAdvanceOpenMarketModal);
