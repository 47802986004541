import React, { useState, useEffect } from "react";
import {
    makeStyles,
    Typography,
    Paper,
    Grid,
    Button,
    Chip,
    Backdrop,
    IconButton,
    useTheme,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import FilterListIcon from "@material-ui/icons/FilterList";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchComponent from "../../SearchComponent";

import { connect } from "react-redux";
import { getUsersForPPM } from "../../../actions/userActions";
import EditAllUserModal from "../Modals/EditAllUserModal";
import CreateUserModal from "../Modals/CreateUserModal";
import DisableUserModal from "../Modals/DisableUserModal";
import clsx from "clsx";

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    textField: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(3),
    },

    action: {
        marginBottom: 30,
    },
    btn: {
        marginTop: 30,
        marginBottom: 30,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    table: {
        minWidth: 500,
    },
    createBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    actionBtn: {
        // background: green[500],
        background: "#00695f",
        color: "#fff",
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
        "&:hover": {
            background: "#014e47",
        },
    },
    marginBottom: {
        marginBottom: 30,
    },
    chip: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
}));

const AllUsersList = ({ getUsersForPPM, users }) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [openDisable, setOpenDisable] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [getId, setGetId] = useState("");
    const [search, setSearch] = useState("");

    const handleOpenDisable = (id) => {
        setGetId(id);
        setOpenDisable(true);
    };

    const handleCloseDisable = () => {
        setOpenDisable(false);
    };

    const handleEditOpen = (id) => {
        setGetId(id);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    useEffect(() => {
        setOpenBackdrop((prev) => !prev);
        getUsersForPPM().then(() => {
            setOpenBackdrop(false);
        });
    }, [getUsersForPPM]);

    const handleCreateOpen = () => {
        setOpenCreate(true);
    };

    const handleCreateClose = () => {
        setOpenCreate(false);
    };

    const updateSearch = (e) => {
        setSearch(e.target.value.substr(0, 20));
    };

    const ResetSearch = (e) => {
        e.preventDefault();
        setSearch("");
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredRows = () =>
        users === undefined
            ? []
            : users.filter((row) => {
                if (search !== "") {
                    return (
                        row.firstName.toLowerCase().indexOf(search.toLowerCase()) !==
                        -1 ||
                        row.lastName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                        row.email.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                        (row.jobTitle &&
                            row.jobTitle?.name
                                ?.toLowerCase()
                                .indexOf(search.toLowerCase()) !== -1) ||
                        row.employeeId.toLowerCase().indexOf(search.toLowerCase()) !==
                        -1 ||
                        (row.division &&
                            row.division.name
                                .toLowerCase()
                                .indexOf(search.toLowerCase()) !== -1)
                    );
                } else {
                    return row;
                }
            });

    const dataLength = Array(1000).length || filteredRows().length;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, dataLength - page * rowsPerPage);

    return (
        <div>
            <CreateUserModal
                openCreate={openCreate}
                handleCreateClose={handleCreateClose}
            />
            <Typography variant="overline" className={classes.title}>
                All Employees List
            </Typography>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={3}
                className={classes.marginBottom}
            >
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        style={{ marginRight: 5 }}
                        onClick={handleCreateOpen}
                        className={classes.createBtn}
                    >
                        Create
                    </Button>
                </Grid>
                <Grid item>
                    <SearchComponent
                        updateSearch={updateSearch}
                        placeholder="Search..."
                        search={search}
                        ResetSearch={ResetSearch}
                    />
                </Grid>
            </Grid>

            <Paper>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item></Grid>
                    <Grid item>
                        <IconButton style={{ marginRight: 10, marginTop: 10 }}>
                            <FilterListIcon />
                        </IconButton>
                    </Grid>
                </Grid>

                <TableContainer>
                    <Table className={classes.table} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell>FirstName</TableCell>
                                <TableCell>LastName</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Division</TableCell>
                                <TableCell>Designation</TableCell>
                                <TableCell>EmployeeId</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Edit</TableCell>
                                <TableCell>Disable</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRows().slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                : filteredRows()
                            ).map((row) => {

                                return (
                                    <TableRow hover key={row._id} >
                                        <TableCell component="th" scope="row">
                                            {row.firstName}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.lastName}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.email}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.division && row.division.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.jobTitle?.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {row.employeeId}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Chip
                                                style={{ padding: 10 }}
                                                label={row.active ? "Active" : "Disabled"}
                                                variant="outlined"
                                                className={clsx(
                                                    row.active ? classes.statusDarkGreen : classes.statusRed,
                                                    classes.chip
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleEditOpen(row._id)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => handleOpenDisable(row._id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {filteredRows().length === 0 && (
                                <TableRow hover>
                                    <TableCell
                                        colSpan={20}
                                        align="center"
                                        style={{ color: "#616161" }}
                                    >
                                        No Records To Display
                                    </TableCell>
                                </TableRow>
                            )}

                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                    colSpan={3}
                    count={filteredRows().length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
            </Paper>
            <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Disable Dialog */}
            <DisableUserModal
                {...{ openDisable, handleCloseDisable, id: getId }}
            />

            {/* Edit User Dialog */}
            <EditAllUserModal
                openEdit={openEdit}
                handleCloseEdit={handleCloseEdit}
                id={getId}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    users: state.user.users,
});

const mapDispatchToProps = { getUsersForPPM };

export default connect(mapStateToProps, mapDispatchToProps)(AllUsersList);
