import React, { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  AppBar,
  IconButton,
  Dialog,
  Toolbar,
  Tooltip,
  Divider,
  Fab,
  InputAdornment,
  DialogContent,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Badge,
  Icon,
  Backdrop,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";

import FilterListIcon from "@material-ui/icons/FilterList";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

import { connect, useDispatch, useSelector } from "react-redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import Feedback from "../../../../Feedback";
import { getProfile } from "../../../../actions/userActions";
import DocRequirementModal from "./DocRequirementModal";
import AttachmentRequirementModal from "./AttachmentRequirementModal";
import { getCustomers } from "../../../../actions/customerActions";
import { getContactPersonByCustomer } from "../../../../actions/contactPersonActions";
import { getDivisionsByService } from "../../../../actions/divisionActions";
import { getServiceTypesByDivision } from "../../../../actions/serviceTypeActions";
import {
  createCwsr,
  getAllCwsrs,
  uploadDoc,
} from "../../../../actions/cwsrActions";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    marginBottom: 20,
  },
  title2: {
    display: "block",
    fontSize: 12,
    color: "#444",
    fontWeight: "bold",
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },
  container: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  divider: {
    marginBottom: 10,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  total: {
    marginTop: 40,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000000,
    color: "#fff",
  },
  warningInfo: {
    color: "#f5f5f5",
    background: "#f44336",
    padding: 20,
    marginBottom: 20,
    marginRight: 10,
    "& > *": {
      fontWeight: 900,
    },
  },
  form: {
    marginTop: 20,
  },
  grid: {
    marginBottom: 20,
  },
  input: {
    border: "1px solid #bdbdbd",
    padding: theme.spacing(2),
    borderRadius: 3,
  },

  btnWrapper: {
    textAlign: "center",
  },

  saveBtn: {
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    margin: "30px 0",
    padding: theme.spacing(2),
    width: 300,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const INIT_STATE = {
  employee: "",
  division: "",
  jobTitle: "",
  employeeId: "",
  customer: null,
  contactPerson: "",
  contactPersonEmail: "",
  contactPersonJob: "",
  contactPersonPhone: "",
  serviceDivision: "",
  serviceType: "",
  thirdParty: false,
  serviceRequirement: "",
  manpowerRequirement: [
    {
      qualification: "",
      quantity: 1,
      source: "",
    },
  ],
  equipmentRequirement: [
    {
      equipment: "",
      quantity: 1,
      specification: "",
    },
  ],
  consumableRequirement: [
    {
      consumable: "",
      quantity: 1,
      specification: "",
      requiredPPE: "",
    },
  ],

  serviceLocation: "",
  serviceDuration: 1,
  servicePeriod: "",
  serviceMethod: "",
  customerPrice: "",
  regulatoryRequirement: "",
  otherRequirement: "",
};

const CreateCWSRModal = ({
  openCreate,
  handleCreateClose,
  uploadDoc,
  createCwsr,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });
  const [dateReceived, setDateReceived] = useState(new Date());
  const [mobilizationDate, setMobilizationDate] = useState(new Date());
  const [document, setDocument] = useState([]);
  const [drawingSpecification, setDrawingSpecification] = useState([]);
  const [state, setState] = useState(INIT_STATE);
  const [file, setFile] = useState(undefined);
  const [file2, setFile2] = useState("");

  const [openDoc, setOpenDoc] = useState(false);
  const [openAttachment, setOpenAttachment] = useState(false);

  const { profile } = useSelector((state) => state.user);
  const { customers } = useSelector((state) => state.customer);
  const { contactPersons } = useSelector((state) => state.contactPerson);
  const { divisions } = useSelector((state) => state.division);
  const { services } = useSelector((state) => state.service);
  const { loading, error } = useSelector((state) => state.cwsr);

  useEffect(() => {
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: error,
        success: false,
      }));
    }
  }, [error]);

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDivisionsByService());
  }, [dispatch]);

  useEffect(() => {
    if (state.customer !== null) {
      dispatch(getContactPersonByCustomer(state.customer._id || ""));
    }
  }, [dispatch, state.customer]);

  useEffect(() => {
    if (state.serviceDivision !== "") {
      dispatch(getServiceTypesByDivision(state.serviceDivision));
    }
  }, [dispatch, state.serviceDivision]);

  useEffect(() => {
    if (state.contactPerson) {
      contactPersons &&
        contactPersons.map(
          (item) =>
            item.name === state.contactPerson &&
            setState((prev) => ({
              ...prev,
              contactPersonEmail: item && item.email,
              contactPersonJob: item && item.designation,
              contactPersonPhone: item && item.phone,
            }))
        );
    }
  }, [state.contactPerson, contactPersons]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      employee: profile && profile.userName,
      division: profile && profile.division && profile.division.name,
      jobTitle: profile && profile.jobTitle,
      employeeId: profile && profile.employeeId,
    }));
  }, [profile]);

  // const toBase64 = (file) =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     // reader.readAsText(file);
  //     reader.onload = () => resolve(reader.result);

  //     reader.onerror = (error) => reject(error);
  //   });

  const handleUploadDrawing = (e) => {
    setFile(e.target.files);
  };

  const handleUploadDocument = (e) => {
    setFile2(e.target.files);
  };

  useEffect(() => {
    const upload = async () => {
      if (file) {
        for (let i = 0; i < file.length; i++) {
          // const result = await toBase64(file[i]);

          // if (result instanceof Error) {
          //   // console.log(result.message);
          //   setFeed((prev) => ({
          //     loading: false,
          //     open: !prev.open,
          //     message: result.message,
          //     success: false,
          //   }));
          // } else {
          //   setDrawingSpecification((prev) => prev.concat(result));
          // }
          const formData = new FormData();

          formData.append("doc", file[i]);

          let doc = await new Promise((resolve, reject) => {
            resolve(uploadDoc(formData));
          });
          if (doc) {
            setDrawingSpecification((prev) => prev.concat(doc));
          }
        }
      }
    };
    upload();
  }, [file, uploadDoc]);

  // console.log(drawingSpecification);

  useEffect(() => {
    const upload = async () => {
      if (file2) {
        for (let i = 0; i < file2.length; i++) {
          // const result = await toBase64(file2[i]);

          // if (result instanceof Error) {
          //   // console.log(result.message);
          //   setFeed((prev) => ({
          //     loading: false,
          //     open: !prev.open,
          //     message: result.message,
          //     success: false,
          //   }));
          // } else {
          //   setDocument((prev) => prev.concat(result));
          // }
          const formData = new FormData();

          formData.append("doc", file2[i]);
          let doc = await new Promise((resolve, reject) => {
            resolve(uploadDoc(formData));
          });
          if (doc) {
            setDocument((prev) => prev.concat(doc));
          }
        }
      }
    };
    upload();
  }, [file2, uploadDoc]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeDate = (date) => {
    setDateReceived(date);
  };

  const handleChangeMobilizationDate = (date) => {
    setMobilizationDate(date);
  };

  const isServices = profile
    ? profile &&
    profile.groups &&
    profile.groups.some(
      (grp) => grp.name === "head" && grp.module === "sales"
    )
    : false;

  const handleChangeManpower = (i) => (e) => {
    e.persist();
    const newManpower = state.manpowerRequirement.map((item, index) => {
      if (i !== index) {
        return item;
      }
      return {
        ...item,
        [e.target.name]: e.target.value,
      };
    });
    setState((prev) => ({
      ...prev,
      manpowerRequirement: newManpower,
    }));
  };

  const handleChangeEquipment = (i) => (e) => {
    e.persist();
    const newEquipment = state.equipmentRequirement.map((item, index) => {
      if (i !== index) {
        return item;
      }
      return {
        ...item,
        [e.target.name]: e.target.value,
      };
    });
    setState((prev) => ({
      ...prev,
      equipmentRequirement: newEquipment,
    }));
  };

  const handleChangeConsumable = (i) => (e) => {
    e.persist();

    const newConsumable = state.consumableRequirement.map((item, index) => {
      if (i !== index) {
        return item;
      }
      return {
        ...item,
        [e.target.name]: e.target.value,
      };
    });
    setState((prev) => ({
      ...prev,
      consumableRequirement: newConsumable,
    }));
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      ...state,
      thirdParty: state.thirdParty === "Yes" ? true : false,
      document,
      drawingSpecification,
      division: profile && profile.division,
      dateReceived: moment(dateReceived).toDate(),
      mobilizationDate: moment(mobilizationDate).toDate(),
      customer: state.customer && state.customer._id,
    };

    const res = await new Promise((resolve, reject) => {
      resolve(createCwsr(data));
    });

    if (res) {
      handleCreateClose();

      dispatch(getAllCwsrs());

      setDocument([]);
      setDrawingSpecification([]);
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: "CWSR Created Successfully.",
        success: true,
      }));
      setTimeout(() => {
        history.push(`/sales/cwsr/${res._id}`);
      }, 2000);
    }
  };

  const handleAddManpower = () => {
    const data = {
      qualification: "",
      quantity: 1,
      source: "",
    };
    setState((prev) => ({
      ...prev,
      manpowerRequirement: prev.manpowerRequirement.concat([data]),
    }));
  };

  const handleRemoveManpower = (i) => {
    setState((prev) => ({
      ...prev,
      manpowerRequirement: prev.manpowerRequirement.filter(
        (_, index) => index !== i
      ),
    }));
  };

  const handleAddEquipment = () => {
    const data = {
      equipment: "",
      quantity: 1,
      specification: "",
    };
    setState((prev) => ({
      ...prev,
      equipmentRequirement: prev.equipmentRequirement.concat([data]),
    }));
  };

  const handleRemoveEquipment = (i) => {
    setState((prev) => ({
      ...prev,
      equipmentRequirement: prev.equipmentRequirement.filter(
        (_, index) => index !== i
      ),
    }));
  };

  const handleAddConsumables = () => {
    const data = {
      consumable: "",
      quantity: 1,
      specification: "",
      requiredPPE: "",
    };
    setState((prev) => ({
      ...prev,
      consumableRequirement: prev.consumableRequirement.concat([data]),
    }));
  };

  const handleRemoveConsumable = (i) => {
    setState((prev) => ({
      ...prev,
      consumableRequirement: prev.consumableRequirement.filter(
        (_, index) => index !== i
      ),
    }));
  };

  const handleOpenDoc = () => {
    setOpenDoc(true);
  };

  const handleCloseDoc = () => {
    setOpenDoc(false);
  };

  const handleOpenAttachment = () => {
    setOpenAttachment(true);
  };

  const handleCloseAttachment = () => {
    setOpenAttachment(false);
  };

  const validCheck = () =>
    !state.employee ||
    !state.division ||
    !state.jobTitle ||
    !state.employee ||
    !state.employeeId ||
    !state.customer ||
    !state.contactPerson ||
    !state.contactPersonEmail ||
    !state.contactPersonJob ||
    !state.contactPersonPhone ||
    !state.serviceDivision ||
    !state.serviceType ||
    !state.thirdParty ||
    !state.serviceRequirement ||
    !state.serviceLocation ||
    !state.serviceDuration ||
    !state.servicePeriod ||
    !state.customerPrice;

  return (
    <div>
      <DocRequirementModal
        {...{ openDoc, handleCloseDoc, docs: drawingSpecification }}
      />
      <AttachmentRequirementModal
        {...{ openAttachment, handleCloseAttachment, docs: document }}
      />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {feed.success ? (
          <Feedback
            handleCloseFeed={handleCloseFeed}
            open={feed.open}
            severity="success"
            message={feed.message}
          />
        ) : (
          <Feedback
            handleCloseFeed={handleCloseFeed}
            open={feed.open}
            severity="error"
            message={feed.message}
          />
        )}
        <Dialog
          open={openCreate}
          onClose={handleCreateClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          fullScreen
          maxWidth="md"
        >
          <AppBar
            className={classes.appBar}
            variant="elevation"
            position="fixed"
            color="inherit"
          >
            <Toolbar>
              <Tooltip title="close" arrow>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCreateClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <div className={classes.container}>
                <Typography variant="overline" className={classes.title}>
                  CWSR Form <Icon className="far fa-file-alt" />
                </Typography>
                <Typography variant="overline" className={classes.title2}>
                  A. Basic Information
                </Typography>

                <Paper variant="outlined" square className={classes.paper}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={2}
                    className={classes.grid}
                  >
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label="Prepared By"
                        name="employee"
                        value={state.employee || ""}
                        onChange={handleChange}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label="Division/Subdivision"
                        name="division"
                        value={state.division || ""}
                        onChange={handleChange}
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <DatePicker
                        className={classes.marginBottom}
                        autoOk
                        format="DD/MM/yyyy"
                        views={["year", "month", "date"]}
                        label="Date Received"
                        fullWidth
                        inputVariant="outlined"
                        value={dateReceived}
                        onChange={handleChangeDate}
                        InputProps={{
                          endAdornment: (
                            <>
                              <Divider
                                className={classes.divider2}
                                orientation="vertical"
                              />
                              <InputAdornment position="end">
                                <IconButton
                                  disabled={dateReceived === null}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setDateReceived(null);
                                  }}
                                >
                                  {dateReceived ? (
                                    <CloseIcon />
                                  ) : (
                                    <FilterListIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            </>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>
                  </Grid>
                </Paper>
                <Typography variant="overline" className={classes.title2}>
                  B. Customer Information
                </Typography>

                <Paper variant="outlined" square className={classes.paper}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={2}
                    className={classes.grid}
                  >
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        id="country-code"
                        options={customers}
                        value={state.customer}
                        onChange={(event, newValue) => {
                          setState((prev) => ({
                            ...prev,
                            customer: newValue,
                          }));
                        }}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                          <React.Fragment>{option.name}</React.Fragment>
                        )}
                        style={{ marginTop: -10 }}
                        autoComplete
                        autoHighlight
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Customer Name"
                            margin="normal"
                            fullWidth
                            required
                            variant="outlined"
                            // error={!code ? true : false}
                            value={state.customer}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "disabled",
                              name: "customer",
                            }}
                          />
                        )}
                      />
                      {/* <FormControl fullWidth required variant="outlined">
                        <InputLabel id="customerName">Customer Name</InputLabel>
                        <Select
                          labelId="customerName"
                          name="customer"
                          onChange={handleChange}
                          value={state.customer || ""}
                        >
                          <MenuItem disabled>Select</MenuItem>
                          {customers &&
                            customers.map((item) => (
                              <MenuItem key={item._id} value={item._id}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl> */}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth required variant="outlined">
                        <InputLabel id="contactPerson">
                          Contact Person
                        </InputLabel>
                        <Select
                          labelId="contactPerson"
                          name="contactPerson"
                          value={state.contactPerson || ""}
                          onChange={handleChange}
                        >
                          <MenuItem disabled value="">
                            Select
                          </MenuItem>
                          {contactPersons &&
                            contactPersons.map((item) => (
                              <MenuItem key={item._id} value={item.name}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        required
                        type="email"
                        label="Contact Person Email"
                        name="contactPersonEmail"
                        value={state.contactPersonEmail || ""}
                        onChange={handleChange}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label="Job Title"
                        name="contactPersonJob"
                        value={state.contactPersonJob || ""}
                        onChange={handleChange}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label="Phone Number"
                        type="tel"
                        name="contactPersonPhone"
                        value={state.contactPersonPhone || ""}
                        onChange={handleChange}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>
                  </Grid>
                </Paper>
                <Typography variant="overline" className={classes.title2}>
                  C. Service Information
                </Typography>

                <Paper variant="outlined" square className={classes.paper}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={2}
                    className={classes.grid}
                  >
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth required variant="outlined">
                        <InputLabel id="serviceDivision">
                          Service Division
                        </InputLabel>
                        <Select
                          labelId="serviceDivision"
                          name="serviceDivision"
                          value={state.serviceDivision || ""}
                          onChange={handleChange}
                        >
                          <MenuItem disabled>Select</MenuItem>
                          {divisions &&
                            divisions.map((item) => (
                              <MenuItem key={item._id} value={item._id}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth required variant="outlined">
                        <InputLabel id="serviceType">Service Type</InputLabel>
                        <Select
                          labelId="serviceType"
                          name="serviceType"
                          value={state.serviceType || ""}
                          onChange={handleChange}
                        >
                          <MenuItem disabled>Select</MenuItem>
                          {services &&
                            services.map((item) => (
                              <MenuItem key={item._id} value={item.name}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl
                        fullWidth
                        required
                        variant="outlined"
                        component="fieldset"
                      >
                        <FormLabel>Is this a third party service?</FormLabel>
                        <RadioGroup row>
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                            name="thirdParty"
                            onChange={handleChange}
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                            name="thirdParty"
                            onChange={handleChange}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>
                  </Grid>
                </Paper>
                <Typography variant="overline" className={classes.title2}>
                  D. Work Scope Requirements
                </Typography>

                <Paper variant="outlined" square className={classes.paper}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    spacing={2}
                    className={classes.grid}
                  >
                    <Grid item xs={12} md={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        required
                        label="Service Requirements"
                        multiline
                        rows={4}
                        name="serviceRequirement"
                        value={state.serviceRequirement || ""}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Paper>

                <Grid container alignItems="center" wrap="nowrap">
                  <Grid item>
                    <Typography variant="overline" className={classes.title2}>
                      E. Other Requirements
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      disabled={drawingSpecification.length === 0}
                      onClick={handleOpenDoc}
                    >
                      <Badge
                        color="secondary"
                        badgeContent={drawingSpecification.length}
                      >
                        <AttachFileIcon />
                      </Badge>
                    </IconButton>
                  </Grid>
                </Grid>

                <Paper variant="outlined" square className={classes.paper}>
                  <Grid
                    container
                    justify="center"
                    alignItems="flex-start"
                    spacing={2}
                    className={classes.grid}
                  >
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth variant="outlined" required>
                        <label htmlFor="file">Drawing Specification </label>
                        <input
                          type="file"
                          variant="outlined"
                          accept="image/*,.pdf"
                          multiple
                          id="file"
                          className={classes.input}
                          onChange={handleUploadDrawing}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <label>Service Location(s) offshore/onshore *</label>
                      <TextField
                        label="Offshore/onshore"
                        variant="outlined"
                        fullWidth
                        required
                        value={state.serviceLocation || ""}
                        name="serviceLocation"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <label htmlFor="customerPrice">
                        Customer Price & Cost Target *
                      </label>
                      <TextField
                        variant="outlined"
                        fullWidth
                        required
                        name="customerPrice"
                        label="Customer Price & Cost Target"
                        value={state.customerPrice}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <label>Service Duration</label>
                      <Grid container justify="center" alignItems="center">
                        <Grid item xs={12} md={6}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Number Only"
                            type="number"
                            value={state.serviceDuration}
                            name="serviceDuration"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth required variant="outlined">
                            <InputLabel id="typeOfPeriod">
                              Type Of Period
                            </InputLabel>
                            <Select
                              labelId="typeOfPeriod"
                              value={state.servicePeriod}
                              name="servicePeriod"
                              onChange={handleChange}
                            >
                              <MenuItem disabled>Select</MenuItem>
                              <MenuItem value="Days">Days</MenuItem>
                              <MenuItem value="Weeks">Weeks</MenuItem>
                              <MenuItem value="Months">Months</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <label>Expected Mobilization Date *</label>
                      <DatePicker
                        className={classes.marginBottom}
                        autoOk
                        format="DD/MM/yyyy"
                        views={["year", "month", "date"]}
                        // label="Date Received"
                        fullWidth
                        required
                        inputVariant="outlined"
                        value={mobilizationDate}
                        onChange={handleChangeMobilizationDate}
                        InputProps={{
                          endAdornment: (
                            <>
                              <Divider
                                className={classes.divider2}
                                orientation="vertical"
                              />
                              <InputAdornment position="end">
                                <IconButton
                                  disabled={mobilizationDate === null}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setMobilizationDate(null);
                                  }}
                                >
                                  {mobilizationDate ? (
                                    <CloseIcon />
                                  ) : (
                                    <FilterListIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            </>
                          ),
                        }}
                      />
                    </Grid>
                    {isServices ? (
                      <>
                        <Grid item xs={12} md={4}>
                          <label>Service Method(s)</label>
                          <TextField
                            variant="outlined"
                            fullWidth
                            required
                            name="serviceMethod"
                            label="Service Method(s)"
                            value={state.serviceMethod}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <label>
                            Regulatory Requirements *
                          </label>
                          <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Regulatory Requirements"
                            name="regulatoryRequirement"
                            value={state.regulatoryRequirement}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <label htmlFor="otherRequirement">
                            Other Specific requirement necessary to meet customer's
                            expectations *
                          </label>
                          <TextField
                            variant="outlined"
                            fullWidth
                            required
                            label="Other Specific requirements"
                            name="otherRequirement"
                            value={state.otherRequirement}
                            onChange={handleChange}
                          />
                        </Grid>
                      </>
                    ) : null}


                  </Grid>
                  <Grid container alignItems="center" wrap="nowrap">
                    <Grid item>
                      <Typography variant="overline" className={classes.title2}>
                        Kindly attach any supporting documents, if available
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        disabled={document.length === 0}
                        onClick={handleOpenAttachment}
                      >
                        <Badge color="secondary" badgeContent={document.length}>
                          <AttachFileIcon />
                        </Badge>
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                  <FormControl fullWidth variant="outlined">
                    <label htmlFor="file2">Attachment</label>
                    <input
                      type="file"
                      variant="outlined"
                      accept="image/*,.pdf"
                      multiple
                      id="file2"
                      className={classes.input}
                      onChange={handleUploadDocument}
                    />
                  </FormControl>
                </Paper>

                {/* To be filled by the service division head or designate */}
                {isServices ? (
                  <>
                    <Typography variant="overline" className={classes.title2}>
                      F. Manpower Requirements
                    </Typography>


                    <Paper variant="outlined" square className={classes.paper}>
                      {state.manpowerRequirement.map((item, i) => (
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          spacing={2}
                          className={classes.grid}
                          key={i}
                        >
                          <Grid item xs={12} md={4}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              required
                              label="Qualification"
                              name="qualification"
                              value={item.qualification || ""}
                              onChange={handleChangeManpower(i)}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              required
                              label="Quantity"
                              type="number"
                              name="quantity"
                              value={item.quantity}
                              onChange={handleChangeManpower(i)}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormControl fullWidth required variant="outlined">
                              <InputLabel id="nationalExpat">
                                National / Expat
                              </InputLabel>
                              <Select
                                labelId="nationalExpat"
                                value={item.source || ""}
                                name="source"
                                onChange={handleChangeManpower(i)}
                              >
                                <MenuItem disabled>Select</MenuItem>
                                <MenuItem value="National">National</MenuItem>
                                <MenuItem value="Expat">Expat</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={1}>
                            <Fab
                              color="secondary"
                              onClick={() => handleRemoveManpower(i)}
                            >
                              <CloseIcon />
                            </Fab>
                          </Grid>
                        </Grid>
                      ))}

                      <Button
                        variant="outlined"
                        color="default"
                        onClick={() =>
                          handleAddManpower(state.manpowerRequirement.length + 1)
                        }
                        endIcon={<AddIcon fontSize="large" />}
                      >
                        Insert Item
                      </Button>
                    </Paper>

                    <Typography variant="overline" className={classes.title2}>
                      G. Equipment/Material/Power Requirements
                    </Typography>

                    <Paper variant="outlined" square className={classes.paper}>
                      {state.equipmentRequirement.map((item, i) => (
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          spacing={2}
                          className={classes.grid}
                          key={i}
                        >
                          <Grid item xs={12} md={4}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              required
                              label="Equipment"
                              name="equipment"
                              value={item.equipment || ""}
                              onChange={handleChangeEquipment(i)}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              required
                              label="Quantity"
                              type="number"
                              value={item.quantity}
                              name="quantity"
                              onChange={handleChangeEquipment(i)}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormControl fullWidth required variant="outlined">
                              <TextField
                                variant="outlined"
                                fullWidth
                                required
                                name="specification"
                                label="Specification"
                                value={item.specification}
                                onChange={handleChangeEquipment(i)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={1}>
                            <Fab
                              color="secondary"
                              onClick={() => handleRemoveEquipment(i)}
                            >
                              <CloseIcon />
                            </Fab>
                          </Grid>
                        </Grid>
                      ))}

                      <Button
                        variant="outlined"
                        color="default"
                        onClick={() =>
                          handleAddEquipment(state.equipmentRequirement.length + 1)
                        }
                        endIcon={<AddIcon fontSize="large" />}
                      >
                        Insert Item
                      </Button>
                    </Paper>

                    <Typography variant="overline" className={classes.title2}>
                      H. Required Consumables
                    </Typography>

                    <Paper variant="outlined" square className={classes.paper}>
                      {state.consumableRequirement.map((item, i) => (
                        <Grid
                          container
                          justify="center"
                          alignItems="center"
                          spacing={2}
                          className={classes.grid}
                          key={i}
                        >
                          <Grid item xs={12} md={3}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              required
                              label="Consumable"
                              value={item.consumable}
                              name="consumable"
                              onChange={handleChangeConsumable(i)}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              required
                              label="Quantity"
                              type="number"
                              value={item.quantity}
                              name="quantity"
                              onChange={handleChangeConsumable(i)}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormControl fullWidth required variant="outlined">
                              <TextField
                                variant="outlined"
                                fullWidth
                                required
                                name="specification"
                                label="Specification"
                                value={item.specification}
                                onChange={handleChangeConsumable(i)}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              required
                              name="requiredPPE"
                              value={item.requiredPPE}
                              label="Required PPE"
                              onChange={handleChangeConsumable(i)}
                            />
                          </Grid>
                          <Grid item xs={12} md={1}>
                            <Fab
                              color="secondary"
                              onClick={() => handleRemoveConsumable(i)}
                            >
                              <CloseIcon />
                            </Fab>
                          </Grid>
                        </Grid>
                      ))}
                      <Button
                        variant="outlined"
                        color="default"
                        onClick={() =>
                          handleAddConsumables(
                            state.consumableRequirement.length + 1
                          )
                        }
                        endIcon={<AddIcon fontSize="large" />}
                      >
                        Insert Item
                      </Button>
                    </Paper>
                  </>
                ) : null
                }

                <div className={classes.btnWrapper}>
                  <Button
                    type="submit"
                    className={classes.saveBtn}
                    disabled={loading || validCheck()}
                    color="primary"
                    variant="contained"
                  >
                    {loading ? "Loading..." : "Save"}
                  </Button>
                </div>
              </div>
            </form>
            <Backdrop className={classes.backdrop} open={loading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </DialogContent>
        </Dialog>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default connect(null, { uploadDoc, createCwsr })(CreateCWSRModal);
