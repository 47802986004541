import React, { useState, useEffect } from "react";
import {
  Paper,
  makeStyles,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  Dialog,
  Tooltip,
  InputAdornment,
  Backdrop,
  Divider,
  AppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencies } from "../../../../../actions/currencyActions";
import { getDivisionsByService } from "../../../../../actions/divisionActions";
import { getServiceTypesByDivision } from "../../../../../actions/serviceTypeActions";
import { getSymbol } from "../../../../../utils/currencyconvert";
import {
  createPersonnelPriceList,
  getAllPersonnelPriceList,
} from "../../../../../actions/masterListActions/personnelActions";
import Feedback from "../../../../../Feedback";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    fontWeight: "bold",
  },
  title2: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    fontWeight: "bold",
  },
  title3: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  appBar: {
    // position: "relative",
    zIndex: 2,
  },
  container: {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  total: {
    marginTop: 40,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  warningInfo: {
    color: "#f5f5f5",
    background: "#f44336",
    padding: 20,
    marginBottom: 20,
    marginRight: 10,
    "& > *": {
      fontWeight: 900,
    },
  },
  form: {
    marginTop: 20,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 300,
  },
}));

const initialState = {
  description: "",
  division: "",
  classification: "",
  serviceProvider: "",
  class: "",
  code: "",
  dailyRate: "",
  tax: "",
  insurance: "",
  melanil: "",
  PPE: "",
  OSP: "",
  subCharge: "",
  baseRate: "",
  markup: "",
  charge: "",
  currency: "",
};

const PersonnelModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [state, setState] = useState(initialState);
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { currencies } = useSelector((state) => state.currency);
  const { divisions } = useSelector((state) => state.division);
  const { loading, success, error } = useSelector((state) => state.personnel);
  const { services } = useSelector((state) => state.service);

  useEffect(() => {
    if (state.division !== "") {
      dispatch(getServiceTypesByDivision(state.division));
    }
  }, [dispatch, state.division]);

  useEffect(() => {
    const abortController = new AbortController();

    if (error && error !== null) {
      setFeed((prev) => ({
        ...prev,
        loading: false,
        open: !prev.open,
        message: error,
        success: false,
      }));
      setTimeout(() => {
        setFeed((prev) => ({
          ...prev,
          loading: false,
          open: false,
          message: "",
          success: false,
        }));
        dispatch({
          type: "RESET_PERSONNEL_PRICE_LIST",
        });
      }, 6000);
    }
    return () => {
      abortController.abort();
    };
  }, [error, dispatch]);

  useEffect(() => {
    const abortController = new AbortController();

    if (success) {
      dispatch(getAllPersonnelPriceList());
      setFeed((prev) => ({
        loading: false,
        open: !prev.open,
        message: "Personnel price list created successfully.",
        success: true,
      }));
      setState(initialState);
      dispatch({
        type: "RESET_PERSONNEL_PRICE_LIST",
      });

      setTimeout(() => {
        handleClose();
      }, 2000);
    }

    return () => {
      abortController.abort();
    };
  }, [success, dispatch, handleClose]);

  useEffect(() => {
    dispatch(getCurrencies());
    dispatch(getDivisionsByService());
  }, [dispatch]);

  useEffect(() => {
    const subCharge =
      +state.baseRate +
      +state.insurance +
      +state.melanil +
      +state.PPE +
      +state.OSP +
      +state.tax;
    setState((prev) => ({
      ...prev,
      subCharge,
    }));
  }, [
    state.baseRate,
    state.insurance,
    state.melanil,
    state.PPE,
    state.OSP,
    state.tax,
  ]);

  useEffect(() => {
    const initialCharge = (+state.markup / 100) * +state.subCharge;
    const dailyRate = initialCharge + +state.subCharge;

    setState((prev) => ({
      ...prev,
      dailyRate,
    }));
  }, [state.markup, state.subCharge]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(createPersonnelPriceList(state));
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const validCheck = () =>
    !state.description ||
    !state.division ||
    !state.classification ||
    !state.serviceProvider ||
    !state.code ||
    !state.dailyRate ||
    !state.tax ||
    !state.insurance ||
    !state.melanil ||
    !state.PPE ||
    !state.OSP ||
    !state.subCharge ||
    !state.baseRate ||
    !state.markup ||
    //!state.charge ||
    !state.currency;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      fullScreen
      maxWidth="md"
    >
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <AppBar
        className={classes.appBar}
        variant="elevation"
        position="fixed"
        color="inherit"
        elevation={1}
      >
        <Toolbar>
          <Tooltip title="close" arrow>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <div className={classes.container}>
        <Typography className={classes.title} variant="overline">
          Create Personnel Price List
        </Typography>
        <div>
          <form
            component={Paper}
            className={classes.form}
            onSubmit={handleSubmit}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Personnel / Description"
                  name="description"
                  variant="outlined"
                  value={state.description}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel>Division</InputLabel>
                  <Select
                    inputProps={{
                      id: "division",
                      name: "division",
                    }}
                    value={state.division}
                    onChange={handleChange}
                  >
                    <MenuItem disabled>Select Division</MenuItem>
                    {divisions.map((division) => (
                      <MenuItem key={division._id} value={division._id}>
                        {division.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth required variant="outlined">
                  <InputLabel id="class">Service Type</InputLabel>
                  <Select
                    labelId="class"
                    name="class"
                    value={state.class || ""}
                    onChange={handleChange}
                  >
                    <MenuItem disabled>Select</MenuItem>
                    {services &&
                      services.map((item) => (
                        <MenuItem key={item._id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel>Nationale</InputLabel>
                  <Select
                    inputProps={{
                      id: "classification",
                      name: "classification",
                    }}
                    value={state.classification}
                    onChange={handleChange}
                  >
                    <MenuItem disabled>Select Nationale</MenuItem>
                    <MenuItem value="Expatriate">Expatriate</MenuItem>
                    <MenuItem value="National">National</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel>Service Provider</InputLabel>
                  <Select
                    inputProps={{
                      id: "serviceProvider",
                      name: "serviceProvider",
                    }}
                    value={state.serviceProvider}
                    onChange={handleChange}
                  >
                    <MenuItem disabled>Select Provider</MenuItem>
                    <MenuItem value="In House">In House</MenuItem>
                    <MenuItem value="Vendor">Vendor</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  label="Service Code"
                  name="code"
                  variant="outlined"
                  value={state.code}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel>Currency</InputLabel>
                  <Select
                    inputProps={{
                      id: "currency",
                      name: "currency",
                    }}
                    value={state.currency}
                    onChange={handleChange}
                  >
                    <MenuItem disabled>Select Currency</MenuItem>
                    {currencies.map((currency) => (
                      <MenuItem key={currency._id} value={currency.code}>
                        {currency.code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Base Rate"
                  name="baseRate"
                  type="number"
                  variant="outlined"
                  value={state.baseRate}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Medivac / Insurance"
                  name="insurance"
                  type="number"
                  variant="outlined"
                  value={state.insurance}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Malanil"
                  name="melanil"
                  type="number"
                  variant="outlined"
                  value={state.melanil}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="PPE"
                  name="PPE"
                  type="number"
                  variant="outlined"
                  value={state.PPE}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="OSP"
                  name="OSP"
                  type="number"
                  value={state.OSP}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="PAYE Tax"
                  name="tax"
                  type="number"
                  value={state.tax}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={9} />

              <Grid item xs={12} md={3}>
                <TextField
                  label="Sub Total"
                  name="subCharge"
                  value={state.subCharge}
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={9} />

              <Grid item xs={12} md={3}>
                <TextField
                  label="Markup (%)"
                  name="markup"
                  value={state.markup}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={9} />

              <Grid item xs={12} md={3}>
                <TextField
                  label={`Daily Rate (${getSymbol(
                    state.currency || "NGN",
                    0
                  )})`}
                  name="dailyRate"
                  variant="outlined"
                  value={state.dailyRate}
                  type="number"
                  fullWidth
                  disabled
                  InputProps={{
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          {getSymbol(state.currency || "NGN", 0)}
                        </InputAdornment>
                        <Divider
                          className={classes.divider2}
                          orientation="vertical"
                        />
                      </>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Grid container justify="center" alignItems="center" spacing={3}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.saveBtn}
                  type="submit"
                  disabled={validCheck() || loading}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

export default PersonnelModal;
