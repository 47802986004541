import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { connect, useDispatch } from "react-redux";
import {
  ButtonGroup,
  Typography,
  makeStyles,
  TextField,
  Grid,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../../Feedback";

import {
  approveAllMonthlyBudget,
  getMonthlyBudgetById,
} from "../../../../actions/monthlyBudgetAction";
import { useHistory } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 12,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title3: {
    display: "block",
    fontSize: 20,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const FinalMonthlyApproveModal = ({
  openFinalApprove,
  handleCloseFinalApprove,
  id,
  approveAllMonthlyBudget,
  getMonthlyBudgetById,
  error,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [comment, setComment] = useState("");
  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const handleChange = (e) => {
    e.persist();
    setComment(e.target.value);
  };

  const handleApprove = () => {
    setLoading((prev) => !prev);
    let data = {};

    data.id = id;
    data.CEOComment = comment;
    data.path = "budget/monthly/view";

    approveAllMonthlyBudget(data)
      .then(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,

          success: true,
        });
        setComment("");
        dispatch(getMonthlyBudgetById(id));
      })
      .catch(() => {
        setFeed({
          loading: false,
          open: !feed.open,

          success: false,
        });
      })
      .finally(() => {
        handleCloseFinalApprove();
        setTimeout(() => {
          history.push("/budget/all/monthly");
        }, 2000);
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message || (error && error.error)}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message || "Approved Successfully."}
        />
      )}

      <Dialog
        open={openFinalApprove}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseFinalApprove}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                {" "}
                {"Budget Approval"}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseFinalApprove}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            label="Comment"
            name="comment"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            value={comment}
            helperText={
              <Typography variant="overline">
                By Clicking 'Approve', it means you're satisfied with the
                reviewed budget(s) .
              </Typography>
            }
            required
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button
              onClick={handleCloseFinalApprove}
              color="secondary"
              className={classes.saveBtn}
            >
              Cancel
            </Button>
            <Button
              onClick={handleApprove}
              color="primary"
              disabled={!comment || loading}
              className={classes.saveBtn}
              variant="contained"
              endIcon={
                loading ? <CircularProgress size={20} color="primary" /> : null
              }
            >
              {loading ? "Loading..." : "Approve"}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  error: state.monthlyBudget.error,
});

const mapDispatchToProps = {
  approveAllMonthlyBudget,
  getMonthlyBudgetById,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinalMonthlyApproveModal);
