import React, { useState, useEffect, useCallback} from "react";
import {
    Paper,
    Typography,
    makeStyles,
    TextField,
    Grid,
    FormControl,
    Select,
    MenuItem,
    Button,
    Divider,
    Icon,
    darken,
    Backdrop,
    ButtonGroup,
    Hidden,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import Feedback from "../../../Feedback";
import {
    getAllOpenMarkets,
    getOneOpenMarket,
    updateOpenMarket,
    submitOpenMarket
} from "../../../actions/purchasingActions";
import { getProfile } from "../../../actions/userActions";
// import { getDivisionMonthlyBudgetItems } from "../../../actions/monthlyBudgetAction";
import { getDivisionYearAnnualBudgetLines } from "../../../actions/annualAction";

// Procurement Division
import ApproveOPMModal from "../Modals/Purchasing/ApproveOPMModal";
import RejectOPMModal from "../Modals/Purchasing/RejectOPMModal";
import CashAdvanceOPMModal from "../Modals/Purchasing/CashAdvanceOPMModal";

import { formatCurrency } from "../../../utils";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold"
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontWeight: "bold",
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(1),
        marginBottom: 20,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginTop: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },

    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 240,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
            // color: "#fff",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    backBtn: {
        letterSpacing: 2.5,
        padding: theme.spacing(1.2),
        paddingLeft: 20,
        paddingRight: 20,
    },
    status: {
        width: "fit-content",
        color: "#979292",
        padding: 6,
        background: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusTitle: {
        color: "#b5b5b5",
        fontSize: "0.75rem",
    },
    statusDarkGreen: {
        borderColor: "#4caf50",
    },
    statusGreen: {
        borderColor: "#1de9b6",
    },
    statusYellow: {
        borderColor: "#fbc02d",
    },
    statusRed: {
        borderColor: "#ef5350",
    },
    statusOrange: {
        borderColor: "#ff9800",
    },
    embed: {
        width: "100%",
        height: 100,
        objectFit: "contain",
        objectPosition: "center",
        marginRight: 5,
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        zIndex: 1,
        "&:hover": {
            transform: "scale(2.5)",
            zIndex: 1000000000000,
        },
    },
    audit: {
        padding: 10,
        fontFamily: "Rubik",
        fontSize: 10,
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        // fontWeight: 500,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditTitle: {
        padding: 10,
        // background: theme.palette.grey[600],
        color: theme.palette.type === "light" ? theme.palette.grey[600] : "#cccccc",
        fontFamily: "Rubik",
        fontSize: 10,
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        letterSpacing: 1.5,
        [theme.breakpoints.down("sm")]: {
            fontSize: 9,
        },
    },
    auditContainer: {
        width: "100%",
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    small: {
        fontSize: 11,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    link: {
        display: "block",
        color: theme.palette.type === "dark" && "#cccccc",
    },
    centered: {
        textAlign: "center",
    },
    paperBlack: {
        color: "#f5f5f5",
        // background: "#3c3737",
        background: theme.palette.grey.A400,
        marginTop: 10,
        marginBottom: 10,
        padding: 5,
        textAlign: "center",
        maxWidth: 480,
        marginLeft: "auto",
        marginRight: "auto",
    },
    info2: {
        marginBottom: 20,
        fontWeight: 500,
    },
}));

const INIT_STATE = {
    employee: "",
    employeeId:"",
    vendor: "",
    month: "",
    year: "",
    purpose:"",
    division: "",
    purchaseRequisition: "",
    requisitionType: "",
    purchaseType:"",
    shippingType:"",
    dateNeeded: "",
    totalBudgetAmount: "",
    totalPurchaseAmount: "",
    totalQuantityNeeded: "",
    items: [],
};

const ViewOpenMarket = () => {
    const classes = useStyles();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = useState(INIT_STATE);
    // const { divItems } = useSelector((state) => state.monthlyBudget);

    const [openApprove, setOpenApprove] = useState(false);
    const [openReject, setOpenReject] = useState(false);
    const [openAdvance, setOpenAdvance] = useState(false);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    const { profile } = useSelector((state) => state.user);
    const { error, loading, opm } = useSelector((state) => state.openmarket);
    const { annualBudgetLines } = useSelector((state) => state.annualBudget);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    useEffect(() => {
        dispatch(getAllOpenMarkets());
        dispatch(getOneOpenMarket(id));
        dispatch(getProfile)
    }, [dispatch, id]);

    
    useEffect(() => {
        setState((prev) => ({
            ...prev,
            employee: (opm && opm.employee) || "",
            division: (opm && opm.division) || "",
            dateNeeded: (opm && moment(opm.dateNeeded).format("l")) || "",            
            month: (opm && opm.month) || "",
            year: (opm && opm.year) || "",
            purpose: (opm && opm.purpose) || "",
            totalBudgetAmount: (opm && opm.totalBudgetAmount) || "",
            totalPurchaseAmount: (opm && opm.totalPurchaseAmount) || "",
            totalQuantityNeeded: (opm && opm.totalQuantityNeeded) || "",

            purchaseRequisition: (opm && opm.purchaseRequisition) || "",

            items: (opm && opm.items) || [],
            status: (opm && opm.status) || "",
            documentNo: (opm && opm.documentNo) || "",

            createdBy: (opm && opm.createdBy) || "",
            createdAt: (opm && opm.createdAt) || "",

            submittedBy: (opm && opm.submittedBy) || "",
            submittedAt: (opm && opm.submittedAt) || "",

            approvedBy: (opm && opm.approvedBy) || "",
            approvedAt: (opm && opm.approvedAt) || "",
            approveComment: (opm && opm.approveComment) || "",
            
            rejectedBy: (opm && opm.rejectedBy) || "",
            rejectedAt: (opm && opm.rejectedAt) || "",
            rejectComment: (opm && opm.rejectComment) || "",

            acceptedBy: (opm && opm.acceptedBy) || "",
            acceptedAt: (opm && opm.acceptedAt) || "",
            acceptComment: (opm && opm.acceptComment) || "",

            declinedBy: (opm && opm.declinedBy) || "",
            declinedAt: (opm && opm.declinedAt) || "",
            declineComment: (opm && opm.declineComment) || "",

        }));
    }, [opm]);

    // const getDivisionMonthlyBudgetItemsCallback = useCallback(() => {
    //     let data = {
    //         year: state.year && state.year,
    //         month: state.month && state.month,
    //         serviceDivision: state && state.division._id,
    //     };
    //     dispatch(getDivisionMonthlyBudgetItems(data));
    // }, [dispatch, state]);

    // useEffect(() => {
    //     getDivisionMonthlyBudgetItemsCallback();
    // }, [getDivisionMonthlyBudgetItemsCallback]);

    // use annual budget
    const getDivisionYearAnnualBudgetLinesCallback = useCallback(() => {
        if(state.division !==""){
            let data = {
                year: state.year && state.year,
                division: state && state.division._id,
            };
            dispatch(getDivisionYearAnnualBudgetLines(data));
        }
    }, [dispatch, state]);

    useEffect(() => {
        getDivisionYearAnnualBudgetLinesCallback();
    }, [getDivisionYearAnnualBudgetLinesCallback]);



    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    // procurement
    const handleOpenApprove = () => {
        setOpenApprove(true);
    };
    const handleCloseApprove = () => {
        setOpenApprove(false);
    };
    const handleOpenReject = () => {
        setOpenReject(true);
    };
    const handleCloseReject = () => {
        setOpenReject(false);
    };
    const handleOpenAdvance = () => {
        setOpenAdvance(true);
    };
    const handleCloseAdvance = () => {
        setOpenAdvance(false);
    };

    const isCanEdit = state ? state.status &&
        ((state.status === "draft" || state.status === "declined" || state.status === "rejected")
        )
        : false;

    const handleSave = async (e) => {
        e.preventDefault();
        let quantity = state.items.reduce((acc, cur) => { return acc + +cur.quantityNeeded; }, 0);
        let budgeted = state.items.reduce((acc, cur) => { return acc + +cur.budgetAmount; }, 0);
        let purchased = state.items.reduce((acc, cur) => { return acc + +cur.purchaseAmount; }, 0);
        
        if((budgeted > 0) && (purchased > 0) && (quantity > 0)){
            const data = {
                items: state.items,
                totalPurchaseAmount: purchased,
                totalBudgetAmount: budgeted,
                totalQuantityNeeded: quantity,
                id
            }
            await dispatch(updateOpenMarket(data));
            setTimeout(async () => {
                await dispatch(getOneOpenMarket(id));
                setFeed((prev) => ({
                    loading: false,
                    open: !prev.open,
                    message: "Open Market Updated Successfully.",
                    success: true,
                }));
            }, 500);
        } else {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Please fill the purchase amount of the items.",
                success: false,
            }));
        }
    };

    // capture item changes
    const handleItemChange = (i) => (e) => {
        e.persist();
        const newItems = state.items.map((item, index) => {
            if (i !== index) {
                return item;
            }
            if(item.budgetAmount >= e.target.value){
                return {
                    ...item,
                    [e.target.name]: parseInt(e.target.value) || '',
                };
            }else {
                return {
                    ...item,
                };
            }
        });
        setState((prev) => ({
            ...prev,
            items: newItems,
        }));
    };

    const handleSubmitHandler = async (e) => {
        e.preventDefault();
        const data = {
            ...state,
            items: state.items,
            id,
            path: `purchasing/openmarket/view/${id}`
        };
        await dispatch(submitOpenMarket(data));
        await dispatch(getOneOpenMarket(id));
        setFeed((prev) => ({
            loading: false,
            open: !prev.open,
            message: "Open Market Submitted Successfully.",
            success: true,
        }));
        setTimeout(() => {
            history.push("/purchasing/openmarket");
        }, 1500);
    };

    const isAdmin = profile
        ? profile &&
        profile.groups &&
        profile.groups.some(
            (grp) => grp.name === "admin" 
        )
        : false;

    const isProcurement = profile
        ? profile &&
        profile.division &&
        profile?.division?.name === "Procurement Management"
        : false;

    const validCheck = () => {
        let budgeted = state.items.map((item) => item.budgetAmount).reduce((prev, curr) => prev + curr, 0);
        let quoted = state.items.map((item) => item.purchaseAmount).reduce((prev, curr) => prev + curr, 0);
        if(budgeted < 0 && quoted < 0){return true}else {return false}
    };

    // console.log(annualBudgetLines, state, opm)

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}

            {/* Procurement */}
            <ApproveOPMModal {...{ openApprove, handleCloseApprove, id }} />
            <RejectOPMModal {...{ openReject, handleCloseReject, id }} />
            <CashAdvanceOPMModal {...{ openAdvance, handleCloseAdvance, id, state }} />
            
            <Typography variant="overline" className={classes.title}>
                View Open Market Purchase
            </Typography>
            <Grid container justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.backBtn}
                        startIcon={<ArrowBackIosIcon />}
                        onClick={() => history.push("/purchasing/openmarket")}
                    >
                        Back
                    </Button>

                    {(state.status === "approved") && (isProcurement || isAdmin) && 
                        <Button
                            color="secondary"
                            variant="contained"
                            className={classes.backBtn}
                            onClick={handleOpenAdvance}
                            style={{marginLeft: 20}}
                        >
                            Create Cash Advance
                        </Button>
                    }
                </Grid>
                <Grid item>
                    <Paper variant="outlined" className={classes.status}>
                        <Typography variant="overline" className={classes.statusTitle}>
                            Document No.
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        {loading ? "Loading..." : state.documentNo}
                    </Paper>
                </Grid>
            </Grid>

            <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
                style={{ marginBottom: 20 }}
            >
                <Hidden smDown>
                    <Grid item></Grid>
                </Hidden>
                <Grid item>
                    <Paper
                        variant="outlined"
                        className={clsx(
                            state.status === "draft"
                                ? classes.statusYellow
                                : state.status === "submitted"
                                    ? classes.statusOrange
                                    : (state.status === "accepted" || state.status === "approved" || state.status === "confirmed" || state.status === "consented") ? classes.statusGreen
                                        : (state.status === "issued" || state.status === "collected" || state.status === "transferred" || state.status === "returned" || state.status === "received" || state.status === "completed")
                                            ? classes.statusDarkGreen
                                            : classes.statusRed,
                            classes.status
                        )}
                    >
                        <Typography variant="overline" className={classes.statusTitle}>
                            Status
                        </Typography>
                        <Divider className={classes.divider2} orientation="vertical" />
                        <Typography variant="overline" className={classes.statusTitle}>
                            {loading ? "Loading..." : state.status}
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            <form onSubmit={handleSubmitHandler}>
                <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Employee</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            disabled
                            value={(state.createdBy && state.createdBy.userName) || ""}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Division/Subdivision</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            disabled
                            value={(state.division && state.division.name) || ""}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Date Needed</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            required
                            disabled
                            value={state.dateNeeded || ""}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Month</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={(state.month && state.month) || ""}
                            disabled
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <label style={{ fontWeight: "bold" }}>Year</label>
                        <TextField
                            variant="outlined"
                            fullWidth
                            value={(state.year && state.year) || ""}
                            disabled
                            required
                        />
                    </Grid>
                </Grid>

                <Typography variant="overline" className={classes.title2}>
                    Purchase Requisition Details
                </Typography>

                <Paper variant="outlined" square className={classes.paper}>
                    <Grid container spacing={2} className={classes.grid2}>
                        <Grid item xs={12} md={4}>
                            <label style={{ fontWeight: "bold" }}>Requisition Type</label>
                            <FormControl fullWidth variant="outlined" required>
                                <Select
                                    inputProps={{
                                        id: "requisitionType",
                                        name: "requisitionType",
                                    }}
                                    disabled
                                    value={state.purchaseRequisition.requisitionType || ""}
                                >
                                    <MenuItem value="Service">Service</MenuItem>
                                    <MenuItem value="Product">Product</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <label style={{ fontWeight: "bold" }}>Shipping Type</label>
                            <FormControl fullWidth variant="outlined" required>
                                <Select
                                    labelId="shippingType"
                                    inputProps={{
                                        id: "shippingType",
                                        name: "shippingType",
                                    }}
                                    disabled
                                    value={state.purchaseRequisition.shippingType || ""}
                                >
                                    <MenuItem value="Digital">Digital (Download)</MenuItem>
                                    <MenuItem value="Vendor">Vendor Delivery</MenuItem>
                                    <MenuItem value="Courier">Courier</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <label style={{ fontWeight: "bold" }}>Purchase Type</label>
                            <FormControl fullWidth variant="outlined" required>
                                <Select
                                    labelId="purchaseType"
                                    inputProps={{
                                        id: "purchaseType",
                                        name: "purchaseType",
                                    }}
                                    disabled
                                    value={state.purchaseRequisition.purchaseType || ""}
                                >
                                    <MenuItem value="Vendor">Vendor</MenuItem>
                                    <MenuItem value="Open Market">Open Market</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper variant="outlined" square className={classes.paper}>
                    <Typography variant="overline" className={classes.title2}>
                        Please enter the amount each of the items will be purchased at the Open Market.
                    </Typography>
                </Paper>

                <Paper variant="outlined" square className={classes.paper}>
                    {state.items.map((sitem, i) => (
                        <Grid
                            container
                            spacing={2}
                            className={classes.grid}
                            key={i}
                        >
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined" required>
                                    <label style={{ fontWeight: "bold" }}>Budget Item</label>
                                    <Select
                                            inputProps={{
                                                id: "budgetItem",
                                                name: "budgetItem",
                                            }}
                                            value={sitem.budgetItem || ""}
                                            disabled
                                        >
                                            <MenuItem disabled>Select</MenuItem>
                                            {annualBudgetLines &&
                                                annualBudgetLines.map((stuff) => (
                                                    <MenuItem key={stuff._id} value={stuff._id}>
                                                        {stuff?.item?.name}
                                                    </MenuItem>
                                                ))
                                            }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <label style={{ fontWeight: "bold" }}>Description</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    name="description"
                                    value={sitem.description || ""}
                                    onChange={handleItemChange(i)}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <label style={{ fontWeight: "bold" }}>Quantity ({sitem.unit || ""})</label>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    required
                                    type="text"
                                    name="quantityNeeded"
                                    value={sitem.quantityNeeded || ""}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={2} >
                                <FormControl fullWidth variant="outlined" required>
                                    <label style={{ fontWeight: "bold" }}>Budget Amount</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        disabled
                                        name="budgetAmount"
                                        value={`${formatCurrency(sitem.budgetAmount, "en-NG", "NGN") || 0}` || ""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={2} >
                                <FormControl fullWidth variant="outlined" required>
                                    <label style={{ fontWeight: "bold" }}>Purchase Amount (NGN)</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        disabled={state.status !== "draft"}
                                        type="number"
                                        name="purchaseAmount"
                                        value={sitem.purchaseAmount || ""}
                                        onChange={handleItemChange(i)}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    ))}
                </Paper>

                <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={12} md={6} />
                    <Grid item xs={12} md={2}>
                        <label style={{ fontWeight: "bold" }}>Total Quantity of Items</label>
                        <TextField
                            placeholder="Total Quantity of Items"
                            name="totalQuantityNeeded"
                            value={state.totalQuantityNeeded || "0"}
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <label style={{ fontWeight: "bold" }}>Total Budget Amount (NGN)</label>
                        <TextField
                            placeholder="Total Budget Amount"
                            name="totalBudgetAmount"
                            value={formatCurrency(state && state.totalBudgetAmount, "en-NG", "NGN")}
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <label style={{ fontWeight: "bold" }}>Total Purchase Amount (NGN)</label>
                        <TextField
                            placeholder="Total Purchase Amount"
                            name="totalPurchaseAmount"
                            value ={formatCurrency(state && state.totalPurchaseAmount, "en-NG", "NGN")}
                            variant="outlined"
                            fullWidth
                            disabled
                        />
                    </Grid>
                </Grid>
            
                <Grid
                    container
                    alignItems="center"
                    wrap="nowrap"
                    spacing={1}
                    style={{ marginBottom: 5 }}
                >
                    <Grid item>
                        <Typography variant="overline" className={classes.title2}>
                            Audit Trail
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Icon className="fas fa-clipboard-list" color="disabled" />
                    </Grid>
                </Grid>
                <Paper variant="outlined" square className={classes.paper}>
                    <Grid container justify="space-between" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Paper
                                className={classes.auditContainer}
                                component="div"
                                elevation={1}
                                variant="outlined"
                                square
                            >
                                <span className={classes.auditTitle}>Created By</span>
                                <Divider orientation="vertical" flexItem />
                                <span className={classes.audit}>{state.createdBy && state.createdBy.userName}</span>
                                <Divider orientation="vertical" flexItem />
                                <span className={classes.audit}>{state.createdAt}</span>
                            </Paper>
                        </Grid>

                        {(state.submittedAt && state.submittedBy) && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Submitted By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.submittedBy && state.submittedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.submittedAt}</span>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                        {(state.acceptedAt && state.acceptedBy) && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Accepted By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.acceptedBy && state.acceptedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.acceptedAt}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.auditTitle}>Comment</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.acceptComment}</span>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                        {(state.rejectedAt && state.rejectedBy) && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Rejected By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.rejectedBy && state.rejectedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.rejectedAt}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.auditTitle}>Comment</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.rejectComment}</span>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                        {(state.approvedAt && state.approvedBy) && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Approved By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.approvedBy && state.approvedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.approvedAt}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.auditTitle}>Comment</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.approveComment}</span>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                        {(state.declinedAt && state.declinedBy) && (
                            <>
                                <Grid item xs={12} md={12}>
                                    <Paper
                                        className={classes.auditContainer}
                                        component="div"
                                        elevation={1}
                                        variant="outlined"
                                        square
                                    >
                                        <span className={classes.auditTitle}>Declined By</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.declinedBy && state.declinedBy.userName}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.declinedAt}</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.auditTitle}>Comment</span>
                                        <Divider orientation="vertical" flexItem />
                                        <span className={classes.audit}>{state.declineComment}</span>
                                    </Paper>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Paper>

                {isCanEdit && state.status === "draft" ?  (
                    <Grid container justify="center">
                        <Grid item>
                            <ButtonGroup>
                                <Button
                                    className={classes.saveBtn}
                                    color="primary"
                                    variant="contained"
                                    disabled={loading || validCheck()}
                                    onClick={handleSave}
                                >
                                    {loading ? "Loading..." : "Save Changes"}
                                </Button>
                                <Button
                                    type="submit"
                                    className={classes.saveBtn2}
                                    variant="contained"
                                    onClick={handleSubmitHandler}
                                    disabled={loading || validCheck()}
                                >
                                    {loading ? "Loading..." : "Submit"}
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                ) : null}

                {/* ACTION BUTTONS */}
                {(isProcurement || isAdmin) && state.status === "submitted" ? (
                    <Grid container justify="center">
                        <Grid item>
                            <div className={classes.centered}>
                                <Paper className={classes.paperBlack}>
                                    <HelpOutlineIcon fontSize="large" />
                                    <Typography placeholder="Info" className={classes.info2}>
                                        These actions are to be carried out by the Procurement Subdivision.
                                    </Typography>
                                </Paper>
                            </div>
                            <ButtonGroup>
                                <Button
                                    onClick={handleOpenApprove}
                                    className={classes.saveBtn2}
                                    variant="contained"
                                >
                                    Approve
                                </Button>
                                <Button
                                    onClick={handleOpenReject}
                                    className={classes.saveBtn}
                                    color="secondary"
                                    variant="contained"
                                >
                                    Reject
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                ) : null}
             </form>

            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default ViewOpenMarket;
