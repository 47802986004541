import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  DialogTitle,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Feedback from "../../../Feedback";
import {
  getSequence,
  updateSequence,
  getAllSequences,
} from "../../../actions/sequenceAction";
import { getAllModulesAndSubmodules } from "../../../actions/moduleAction";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },

  centered: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 20,
  },
  btn: {
    letterSpacing: 2.5,
    padding: theme.spacing(1.2),
    paddingLeft: 20,
    paddingRight: 20,
  },
  createBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  saveBtn2: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 200,
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
}));

const INIT_STATE = {
  name: "",
  module: "",
  prefix: "",
  nextNumber: 1,
};

const EditSequenceModal = ({
  openEdit,
  handleCloseEdit,
  getAllModulesAndSubmodules,
  allmodules,
  id,
  getSequence,
  sequence,
  updateSequence,
  getAllSequences,
  error,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [state, setState] = useState(INIT_STATE);
  const [loading, setLoading] = useState(false);

  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  React.useEffect(() => {
    getAllModulesAndSubmodules();
  }, [getAllModulesAndSubmodules]);

  const getSequenceCallback = React.useCallback(() => {
    setLoading((prev) => !prev);
    getSequence(id).then(() => {
      setLoading(false);
    });
  }, [getSequence, id]);

  React.useEffect(() => {
    getSequenceCallback();
  }, [getSequenceCallback]);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      name: sequence && sequence.name,
      module: sequence && sequence.module,
      prefix: sequence && sequence.prefix,
      nextNumber: sequence && sequence.nextNumber,
    }));
  }, [sequence]);

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    let data = {
      id,
      name: state.name,
      nextNumber: state.nextNumber,
      module: state.module,
      prefix: state.prefix.toUpperCase(),
    };
    updateSequence(data)
      .then(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          // message: `Sequence Created Successfully`,
          success: true,
        });
        dispatch(getAllSequences());
      })
      .catch(() => {
        setLoading(false);
        setFeed({
          loading: false,
          open: !feed.open,
          // message: `Sequence Created Successfully`,
          success: false,
        });
      })
      .finally(() => {
        handleCloseEdit();
      });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
      {error && error.error ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={(error && error.error) || feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message || "Sequence Updated Successfully."}
        />
      )}
      <Dialog
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
        // fullScreen={fullScreen}
      >
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Update Sequence
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleCloseEdit}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container justify="center" alignItems="center" spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={loading ? "Loading..." : state.name || ""}
                  onChange={handleChange}
                  variant="outlined"
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth required variant="outlined">
                  <InputLabel id="moduleId">Module</InputLabel>
                  <Select
                    id="module"
                    labelId="moduleId"
                    name="module"
                    inputProps={{
                      id: "module",
                      name: "module",
                    }}
                    value={loading ? "Loading..." : state.module || ""}
                    onChange={handleChange}
                    disabled={loading}
                  >
                    <MenuItem value="---SELECT MODULE---" disabled>
                      <em>Select Module</em>
                    </MenuItem>
                    {allmodules &&
                      allmodules.map((mod) => (
                        <MenuItem key={mod._id} value={mod.code}>
                          {mod.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Prefix"
                  name="prefix"
                  value={loading ? "Loading..." : state.prefix || ""}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Next Number"
                  name="nextNumber"
                  value={loading ? "Loading..." : state.nextNumber || 1}
                  onChange={handleChange}
                  disabled={loading}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Button
                color="primary"
                type="submit"
                variant="contained"
                fullWidth
                size="large"
                disabled={loading}
                className={classes.saveBtn}
              >
                {loading ? "Loading..." : "Update"}
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  allmodules: state.module.allmodules,
  sequence: state.sequence.sequence && state.sequence.sequence.data,
  error: state.sequence.error,
});

const mapDispatchToProps = {
  getAllModulesAndSubmodules,
  getSequence,
  updateSequence,
  getAllSequences,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSequenceModal);
